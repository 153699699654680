import { EXHIBITORS_IDENTIFIER, INFORMATION_IDENTIFIER, ARTICLES_IDENTIFIER, EVENTS_IDENTIFIER, PRODUCTS_IDENTIFIER } from 'utils/exhibitor';
import qs from 'query-string';
import { DIRECTORY_IDENTIFIER } from 'utils/exhibitor/index';

export const SEARCH_TYPE_EXHIBITOR = 'Exhibitor-Info-Only';
export const SEARCH_TYPE_PRODUCT = 'Product-Info-Only';
export const TAGS_IDENTIFIER = 'Topics';
export const SEARCH_TYPES = [EXHIBITORS_IDENTIFIER, INFORMATION_IDENTIFIER, ARTICLES_IDENTIFIER, EVENTS_IDENTIFIER];
export const FILTER_TYPES = [EXHIBITORS_IDENTIFIER, PRODUCTS_IDENTIFIER, INFORMATION_IDENTIFIER, ARTICLES_IDENTIFIER, EVENTS_IDENTIFIER, DIRECTORY_IDENTIFIER];


function inPath(path, tab_type) {
    return typeof path !== 'undefined' && path.toLowerCase().includes(tab_type);
}

export function getSearchTypeFromPath(path) {
    return SEARCH_TYPES.find(type => inPath(path, type));
}

export function getFilterKeyFromPath(search) {
    const _qs = qs.parse(search, { ignoreQueryPrefix: true });
    return FILTER_TYPES.find(type => type in _qs) ;

}

export function resetFilter(type, qsParsed) {
    const filterType = FILTER_TYPES.find(type => type in qsParsed);
    if (filterType !== type) {
        delete qsParsed[filterType];
    }
    return qsParsed;
}

export function sanatizeSearchQuery(searchQuery) {
    return searchQuery.replace(/%5C/g, '').replace(/\\+$/, '');
}
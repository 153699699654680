import * as types from '../actions/multiSelectActionTypes';
import cloneDeep from 'lodash/cloneDeep';

const InitialState = {
    selectedItems: [],
    multiSelectMode: false,
};

/**
 * Reducer for search actions
 * @param {object} state State of the search
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function typeAheadSearchReducer(state = InitialState, action) {
    switch (action.type) {
        case types.MULTISELECT_ITEM_CHANGE:
            let newSelected = cloneDeep(state.selectedItems);
            if (action.itemChanged.selected)
                newSelected.push(action.itemChanged);
            else {
                newSelected = newSelected.filter(e => e.id !== action.itemChanged.id);
            }
            return Object.assign({}, state, { selectedItems: newSelected });

        case types.MULTISELECT_VIEW_CHANGE:
            return Object.assign({}, state, { multiSelectMode: action.multiSelectMode });
        case types.MULTISELECT_RESET:
            return Object.assign({}, state, InitialState);
        default:
            return state;
    }
}

import React from 'react';
import DropdownMenu from 'modules/dropdownmenu/DropdownMenu';
import CampusMenu from './CampusMenu';

interface CompanyNavigationProps {
    fields?: any;
}

const CompanyNavigation: React.FC<CompanyNavigationProps> = ({fields}) => {

    const campusMenus = fields?.data?.model?.cities?.filter((campus?: any, index?: any) => {
        return campus?.type?.name === 'City Menu';
    }).map((campus: any) => {
        campus.mobilelabel = campus.heading.data.value.split(" ").map((word: string) => {
            return word.charAt(0).toUpperCase();
        }).join('');
        if (campus.mobilelabel == 'A') {
            campus.mobilelabel = 'ATL';
        }
        if (campus.mobilelabel == 'NY') {
            campus.mobilelabel = 'NYC';
        }
        return campus;
    });

    if (!campusMenus) {
        return (<></>);
    }

    return (
        <>
            {campusMenus?.length > 0 && campusMenus.map((campus?: any, index?: any) => {
                return (
                    <DropdownMenu buttonClassName={`top-nav-button`} key={`campus-${index}`} label={campus.heading.data.value} mobilelabel={campus.mobilelabel}>
                        <CampusMenu columns={campus.columns} />
                    </DropdownMenu>
                );
            })}
        </>
    );
}

export default CompanyNavigation;
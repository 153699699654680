/**
  * @type {string}
 */
export const MULTISELECT_ITEM_CHANGE = 'MULTISELECT_ITEM_CHANGE';

/**
  * @type {string}
 */
export const MULTISELECT_VIEW_CHANGE = 'MULTISELECT_VIEW_CHANGE';

/**
  * @type {string}
 */
export const MULTISELECT_RESET = 'MULTISELECT_RESET';

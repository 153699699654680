import React from 'react';
import TagCloud from './components/TagCloud.jsx'
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';

const TagFilter = (props) => {
  const {rendering} = props;

  if (!rendering.fields) {
    if (isEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    }
    return (
      <h1 className="alarm">
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }
  const tagCloudtProps = {
    location: {
        search: true,
    },
    filterKey: 'articles',
    tagKey: 'tags',
    tags: rendering.fields.visiblePageTags.map((item)=> item.fields.name.value),
    landingLabels: {
      landingFilterLabel: rendering.fields.title,
      landingViewingTags: rendering.fields.selectedTagsTitle,
      landingViewingAll: rendering.fields.allTagsTitle,
  }
};


  return(
  <TagCloud {...tagCloudtProps}/>
)};

export default TagFilter;

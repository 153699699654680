import React from 'react';

interface Props {
    count: number;
    title: string;
}

export const CountedItem: React.FC<Props> = ({ count, title }) => {
    return (
        <>
            <span className={`${count > 0 ? `top-nav-orange` : `top-nav-gray`}`}>{count}</span>
            <span className={`${count > 0 ? `` : `top-nav-gray`}`}>{title}</span>
        </>
    );
};
import {
    SET_SAVED_SEARCHES,
    DELETE_MARKETPLANNER_SAVED_SEARCH_SUCCESS,
    DELETE_MARKETPLANNER_SAVED_SEARCH_FAIL,
    SHOW_LOADING,
} from '../actions/actionTypes';

const initialState = {
    data: undefined,
    showLoading: true,
    deleteSucess: null
};

export default function savedSearchesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SAVED_SEARCHES:
            return Object.assign({}, state, {
                data: action.lists
            });
        
        case DELETE_MARKETPLANNER_SAVED_SEARCH_SUCCESS:
            return Object.assign({}, state, {
                deleteSucess: true
            });
        
        case DELETE_MARKETPLANNER_SAVED_SEARCH_FAIL:
            return Object.assign({}, state, {
                deleteSucess: false
            });

        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
            
        default:
            return state;
    }
}
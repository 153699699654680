import { SET_PRODUCT_FILTERS, SET_PRODUCT_FILTERS_ERR, SET_FILTER_KEY } from '../actions/actionTypes';

const initialState = {
    filters: null,
};

function preprocessFilters(filters) {
    let _filters = {};
    if (filters)
        Object.keys(filters).forEach((item) => {
            _filters[filters[item].name] = filters[item];
        });
    return _filters;
}

/**
 * Reducer for exhibitor information
 * @param {object} state State of the exhibitor information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function filterReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PRODUCT_FILTERS:
            const _filters = preprocessFilters(action.filters);
            return {
                ...state,
                filters: _filters,
                filterErr: null,
            };
        case SET_PRODUCT_FILTERS_ERR:
            return {
                ...state,
                filters: initialState.filters,
                filterErr: action.status || 404,
            };
        case SET_FILTER_KEY:
            return {
                ...state,
                filterKey: action.filterKey,
            };
        default: return state;
    }
}

import AuthFetch from 'utils/authfetch';
import UserUtility from '../../../utils/userutility';

const _authFetch = new AuthFetch();
/**
 * Posts data for Contact Us Form
 * @param {string} url API endpoint
 * @param {object} fields API endpoint
 * @returns {object} Object returned containing field names and values
 */
export default function submitEmail(url, fields) {
    return _authFetch.authFetch(url, {
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify(fields),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Channel': UserUtility.getChannelData().name
        },
    }).then(response => response);
}

// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import  Link  from '../../Link';
import ImcDataLayer from 'utils/datalayer';
/**
 * Component for displaying a Navigation Tier 3 item within a Navigation component,
 * handling display logic to show the tier 3 container content when the item is active
 */
export default class NavigationTier3 extends Component {
    /**
     * @method constructor
     * @description On instantiation dynamically sets the tier 3 navigation items
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.navigateTier3 = this.navigateTier3.bind(this);
    }

    /**
     * Navigates through the tier 3 items
     * @param {object} e Keydown event object
     */
    navigateTier3(e) {
        const code = e.keyCode || e.which;
        // left arrow OR the user passed for the last item in the list
        if (code === 37 || document.activeElement.classList.contains('imc-navigation__tier3--active')) {
            e.preventDefault();
            this.props.focusTier2();
        }
    }

    /**
     * @method render
     * @description Renders the ModelCard DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { content } = this.props;
        return (
            content.map((item3tier, key) =>
                <Link onClick={()=>ImcDataLayer.pushInteractionEvent("navigation", "header", "tier3 - " + item3tier.nodeName)} field={item3tier.link.data}
                    onKeyDown={this.navigateTier3}
                    key={key}
                    className="imc-navigation__tier3Link"
                ></Link>
            ));
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{children, isActive: (*)}}
 */
NavigationTier3.propTypes = {
    content: PropTypes.array.isRequired, // Content to inject into the tier 3 container
    active: PropTypes.bool.isRequired, // Indicator for whether the tier 3 container is active
    focusTier2: PropTypes.func.isRequired, // Focuses onto the parent tier 2 item
    fullWidth: PropTypes.bool, // Flag if the menu is full width
    currentTier1: PropTypes.number, // Currently selected tier 1 item
};

/**
 * @property defaultProps
 * @type {{tier1Label: string}}
 */
NavigationTier3.defaultProps = {
    fullWidth: false,
    currentTier1: -1,
    content: [],
};

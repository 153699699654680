import React, {useEffect} from 'react';
import {ComponentRendering} from '@sitecore-jss/sitecore-jss-react';
import FlexGrid from '../Flex-Grid';
import BasicTwoColumnSection from '../Basic-Two-Column-Section';
import ContentSectionComponent from '../Content-Section-Component';
import RichTextComponent from '../Rich-Text-Component';
import ImcCta from '../Imc-cta';
import StandaloneImageComponent from '../Standalone-Image-Component';
import StandaloneVideoComponent from '../Standalone-Video-Component';
import {newComponent, contentSectionComponentRendering, richTextRendering, flexGridRendering, imcCtaRendering, standaloneImageRendering, returnMediaSection, returnRichTextHeading, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'

// ---------------------- //
// define props interface //
// ---------------------- //
interface BasicParenthesisSectionProps {
    rendering: ComponentRendering;
    fields?: any;
};
// ---------------------- //

// ------------------------ //
// Basic Two Column Section //
// ------------------------ //
const BasicParenthesisSection: React.FC<BasicParenthesisSectionProps> = ({rendering, fields}) => {
    const defaultJson = require('./props.json');
    let sectionComponent = newComponent(defaultJson);

    sectionComponent.fields.headline = newComponent(fields.headline);
    sectionComponent.fields.subheading = newComponent(fields.subheading);
    sectionComponent.fields.linkHeadline = newComponent(fields.linkHeadline);
    sectionComponent.fields.linkHeadlineSecondary = newComponent(fields.linkHeadlineSecondary);
    sectionComponent.fields.headlineOverride = newComponent(fields.headlineOverride);
    sectionComponent.fields.linkImage = newComponent(fields.linkImage);
    sectionComponent.fields.linkImageSecondary = newComponent(fields.linkImageSecondary);
    sectionComponent.fields.body = newComponent(fields.body);
    sectionComponent.fields.image = newComponent(fields.image);
    sectionComponent.fields.videoFile = newComponent(fields.videoFile);
    sectionComponent.fields.primaryCTA = newComponent(fields.primaryCTA);
    sectionComponent.fields.secondaryCTA = newComponent(fields.secondaryCTA);
    sectionComponent.fields.backgroundColor = newComponent(fields.backgroundColor);
    sectionComponent.fields.reverseColumns = newComponent(fields.reverseColumns);
    if (sectionComponent?.fields?.backgroundColor?.value == "") {
        sectionComponent.fields.backgroundColor = {
            value: " one-col-pare"
        }
    }
    if (fields?.extraClasses?.length > 0) sectionComponent.fields.extraClasses = newComponent(fields.extraClasses);
    if (fields?.headingExtraClasses?.length > 0) sectionComponent.fields.headingExtraClasses = newComponent(fields.headingExtraClasses);
    if (fields?.bodyClasses?.length > 0) sectionComponent.fields.bodyClasses = newComponent(fields.bodyClasses);
    if (fields?.primaryCTAclass?.length > 0) sectionComponent.fields.primaryCTAclass = newComponent(fields.primaryCTAclass);
    if (fields?.secondaryCTAclass?.length > 0) sectionComponent.fields.secondaryCTAclass = newComponent(fields.secondaryCTAclass);
    if (fields?.accentColor?.value) sectionComponent.fields.accentColor = newComponent(fields.accentColor);
    if (fields?.headlineOverride?.value) sectionComponent.fields.headlineOverride = newComponent(fields.headlineOverride);
    if (fields?.accentOverride?.value) sectionComponent.fields.accentOverride = newComponent(fields.accentOverride);
    if (fields?.backgroundOverride?.value) sectionComponent.fields.backgroundOverride = newComponent(fields.backgroundOverride);

    if (fields?.accentOverride?.value && rendering?.uid) {
        sectionComponent.fields.imageWrapper[0].fields.className.value += ` pare-${rendering.uid.split("-")[0]}`;
        sectionComponent.fields.styles = {
            "--pare": `${fields.accentOverride.value}`
        }
    }

    return (
        <BasicTwoColumnSection {...sectionComponent} rendering={sectionComponent} />
    );
};
// ------------------------ //

export default BasicParenthesisSection;
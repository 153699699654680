export class Environment {
    static get isProduction() {
        return process.env.NODE_ENV === 'production';
    }

    // Are we on the server? Note that sometimes loibs define windows as {}, so extra checks are needed.
    static get isServer() {
        return typeof window === 'undefined' ||
        (Object.keys(window).length === 0 && window.constructor === Object);
    }
    
    static get reactAppProcessEnv(){
        const typedEnv =  process.env;
        return typedEnv;
    }

    static get isHot() {
        return module.hot;
    }
}
import * as types from '../actions/actionTypes';
import { InitialState } from './initialState';

/**
 * Reducer for add to list actions
 * @param {object} state State of the market planner
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function changeNavStatus(state = InitialState, action) {
    switch (action.type) {
    case types.SHOW_MAIN_NAV:
        return Object.assign({}, state, {
            showMainNav: action.val,
        });
        
    case types.SET_INDEX:
        return Object.assign({}, state, {
            currentIndex: action.value
        });

    case types.SET_VISIBILITY:
        return Object.assign({}, state, {
            showTabs: action.showTabs
        });
    default:
        return state;
    }
}

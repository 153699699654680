import React, {Component} from 'react';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class VerticalTabComponent extends Component {

    /**
     * @method constructor
     * @description 
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
    }

    render(){
      let item = null;
        if (this.props.sitecoreContext.pageEditing) {
            item = <Placeholder className='imc-gallery__item imc-vertical-tabs-content' name='imc-tab-content' rendering={this.props.rendering} />
        }
        if (!this.props.sitecoreContext.pageEditing && this.props.currentIndex == this.props.rendering.uid){
    		item = (
              <div className="imc-gallery__item imc-vertical-tabs-content">
                <Placeholder name='imc-tab-content' rendering={this.props.rendering}/>
              </div>
            );
        }
      
    	return(item)
    }
}

export default withSitecoreContext()(VerticalTabComponent);
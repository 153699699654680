import {
    SET_CONTACT_EXHIBITOR_INFO,
    SET_CONTACT_EXHIBITOR_INFO_ERR,
    SHOW_LOADING
} from './actionTypes';
import ExhibitorsAPI from 'api/exhibitor';


/**
 * Instantiates the Exhibitors API
 * @type {ExhibitorsAPI}
 * @private
 */
const _exhibitorsApi = new ExhibitorsAPI();



/**
 * Action to get Contact Exhibitor Info
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getContactExhibitorInfo(exhibitorId, pageId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getExhibitorDetail(exhibitorId, pageId).then((response) => {
            if (response && Array.isArray(response.data) && response.data.length === 1) {
                const exhibitor = response.data[0];
                dispatch({
                    type: SET_CONTACT_EXHIBITOR_INFO,
                    companyName: ('companyDetails' in exhibitor) ? exhibitor.companyDetails.companyName : '',
                    productCategories: exhibitor.productCategories,
                    contactInfo: exhibitor.directoryContactInfo,
                    socialMedia: exhibitor.socialMedia,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_CONTACT_EXHIBITOR_INFO_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}

import React from 'react';
import { Placeholder, isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import navState from '../MP-Page-Link-List-Component/reducers/navReducer';
import * as navActions from '../MP-Page-Link-List-Component/actions/navActions';
import { createAppState } from 'modules/redux-app-state';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from 'modules/loading/components/Loading';

const appState = createAppState();

class VerticalTabbedNavigationComponent extends React.Component {
	/**
		 * @method constructor
		 * @description On instantiation dynamically sets the moduleName based on the `mediaGalleryId` and
		 * creates aliases to methods
		 * @param {object} props Incoming props
		 */
	constructor(props) {
		super(props);
		if (!props.fields) {
			if (isEditorActive()) {
				return <h1 className="alarm">Datasource isn't set.</h1>;
			}
			return (
				<h1 className="alarm">
					Data is not provided. Contact administrators, please.
				</h1>
			);
		}

		this.state = {
			currentIndex: this.props.currentIndex ? this.props.currentIndex : null,
			currentLabel: "",
			showTabs: this.props.showTabs ? this.props.showTabs : true,
		};


		appState.reducerRegistry.register({
			navState,
		});


		this.renderTab = this.renderTab.bind(this);
		this.renderTabs = this.renderTabs.bind(this);
		this.setActiveItem = this.setActiveItem.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.back = this.back.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		let tabList = [];
		let activeTab = {};
		if (this.props.rendering != null && this.props.rendering.placeholders["imc-vertical-tabbed-navigation-content"] != null) {
			for (let i = 0; i < this.props.rendering.placeholders["imc-vertical-tabbed-navigation-content"].length; i++) {
				const element = this.props.rendering.placeholders["imc-vertical-tabbed-navigation-content"][i];
				if (element.componentName == "Vertical-Tab-Component") {
					tabList.push(element);
				}
			}
		}

		if (tabList != null && tabList.length > 0) {
			this.setState({
				currentIndex: this.props.currentIndex ? this.props.currentIndex : tabList[0].uid,
				currentLabel: tabList[0].fields.title.value,
				tabList: tabList
			});
		}
	}

	/**
 * @method setActiveItem
 * @description Sets the state with the current index
 * @param {Number} index The index number of the carousel item to select
 * @param {object=} e Event object
 */
	setActiveItem(index, title, e) {
		const code = e ? e.keyCode || e.which : undefined;
		if (code === undefined || code === 13 || code === 32) {
			this.setState({
				currentIndex: index,
				currentLabel: title
			});
			this.props.actions.setIndex(index);
			this.props.actions.setVisibility(false);
		}
		this.setState({ showTabs: false });
	}

	/**
 * Renders a single tab item
 * @param {number} index tab index
 * @param {Object} fields tab object
 * @param {function} onSelect Method to call when selected
 * @param {boolean} active Flag to determine active or inactive tab
 * @returns {XML} Tab markup
 */
	renderTab(index, fields, onSelect, active) {
		let title;
		if (fields != null && fields.title != null) {
			title = fields.title.value;
		}
		if (active || this.props.sitecoreContext.pageEditing) {
			return (
				<div
					key={index}
					className="imc-vertical-tab active"
					onClick={() => onSelect(index, title)}
				>
					{title}
				</div>
			);
		}
		return (
			<a
				key={index}
				role="button"
				tabIndex={0}
				className="imc-vertical-tab"
				onClick={() => onSelect(index, title)}
				onKeyDown={e => onSelect(index, title, e)}
			>{title}</a>
		);
	}

	/**
	 * @method renderTabs
	 * @description
	 * @returns {Element} Rendered Tab Items
	 */
	renderTabs() {
		if (this.state.tabList != null) {
			return (
				<div className="imc-vertical-tabs-nav">
					{this.state.tabList.map((item, index) => (
						this.renderTab(item.uid, item.fields, this.setActiveItem, item.uid === this.state.currentIndex)
					))}
				</div>
			);
		} else {
			return (<div>No data</div>);
		}
	}

	back() {
		if (this.props.router != null) {
			this.props.router.navigate(-1);
		} else {
			this.props.actions.changeNavStatus(true);
		}
	}

	showTabs() {
		this.setState({ showTabs: true });
		this.props.actions.setVisibility(true);
	}

	render() {
		const { showNav, showTabs } = this.props;
		let tabVisibility;
		if (showTabs === undefined) {
			tabVisibility = this.state.showTabs;
		} else {
			tabVisibility = this.props.showTabs;
		}

		return (
			<React.Fragment>
				<div className={`imc-section  imc-section--full-width imc-section--full-width-mobile `}>
					<div className="imc-gallery imc-gallery--20-80 imc-gallery--1-2--mobile-full-width">
						<div className={"imc-gallery__item imc-vertical-tabs-nav-container " + ((!tabVisibility) ? "imc-breakpoint-display--hide-mobile" : "")}>
							<div className="imc-breakpoint-display--hide-desktop imc-content--display-flex imc-content--display-flex-space-between imc-content--display-flex-center imc-section--margin-medium">
								<a
									onClick={() => this.back()}
									className="imc-button--primary-inverted imc-content--display-flex imc-content--display-flex-center"
								>
									<svg width="16" height="16">
										<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconBackArrow"></use>
									</svg>
								</a>
								<h3 className="imc-heading--giga-desktop imc-heading--kilo imc-heading imc-heading--headingh2 imc-content--padded-large-right">{this.state.currentLabel}</h3>
							</div>
							{this.renderTabs()}
						</div>
						<div className={"imc-gallery__item imc-vertical-tabs-nav-container " + ((tabVisibility) ? "imc-breakpoint-display--hide-mobile" : "")}>
							<div className="imc-breakpoint-display--hide-desktop imc-content--display-flex imc-content--display-flex-space-between imc-content--display-flex-center imc-section--margin-medium">
								<button onClick={() => this.showTabs()} className="imc-button--primary-inverted imc-content--display-flex imc-content--display-flex-center" >
									<svg width="16" height="16">
										<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconBackArrow"></use>
									</svg>
								</button>
								<h3 className="imc-heading--giga-desktop imc-heading--kilo imc-heading imc-heading--headingh2 imc-content--padded-large-right">{this.state.currentLabel}</h3>
							</div>
							<Placeholder name='imc-vertical-tabbed-navigation-content' rendering={this.props.rendering} currentIndex={this.state.currentIndex} />
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}


/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {{actions: *}} Action creators
 */
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(Object.assign({}, navActions), dispatch),
	};
}
/**
	* Maps state to props for connect
	* @param {object} state State object
	* @returns {{search: *}} State to props mapping
	*/
function mapStateToProps(state) {
	const showNav = state.navState ? state.navState.showMainNav : '';
	const currentIndex = state.navState ? state.navState.currentIndex : '';
	const showTabs = state.navState ? state.navState.showTabs : '';
	return {
		showNav: showNav,
		currentIndex: currentIndex,
		showTabs: showTabs
	};
}
export default withSitecoreContext()(connect(mapStateToProps, mapDispatchToProps)(VerticalTabbedNavigationComponent));

import React from "react";
import PropTypes from 'prop-types';

const Button = ({
  displayName,
  name,
  cssClass,
  onClick, 
}) => {
  return (
    <button 
      onClick = {onClick}
      name = {name}
      className = {cssClass}  
    >
      {displayName}
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  cssClass: PropTypes.string
}

Button.defaultProps = {
  onClick: () => {},
  cssClass: ''
}

export default Button;
// Library dependencies
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component for rendering a Carousel navigation meatball element and
 * setting a callback for when the component is clicked
 * @returns {Element} Rendered carousel meatballs
 */
const CarouselMeatball = ({ index, isActive, onSelect }) => {
    const isActiveClass = isActive ? 'imc-carousel__meatball--active' : '';
    return (
        <a
            role="button"
            tabIndex={0}
            className={`imc-carousel__meatball imc-carousel__navitem ${isActiveClass}`}
            onClick={e => onSelect(index, e)}
            onKeyDown={e => onSelect(index, e)}
            aria-label={`Show Carousel Slide ${index + 1}`}
            data-xpath="carousel.meatball"
        >
            <span className="imc-carousel__circles"> &nbsp;</span>
        </a>
    );
};

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{isActive: (*), onSelect}}
 */
CarouselMeatball.propTypes = {
    index: PropTypes.number.isRequired, // Current meatball index
    isActive: PropTypes.bool, // Boolean flag to determine if the current meatball is active or not
    onSelect: PropTypes.func.isRequired, // Callback handler to be called when clicking the current meatball button
};

/**
 * defaultProps
 * @type {{isActive: boolean}}
 */
CarouselMeatball.defaultProps = {
    isActive: false,
};

// Export the react component
export default CarouselMeatball;

import React, { Component } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class  tags extends Component {
    render() {
        const { sitecoreContext} = this.props;
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
        const t = this.props.tags;
        return (
            t.map((tag) => {
                const tagName = tag.fields.name.value;
                return(
                <span className="imc-eventdetail__tag-item">
                    <em><a href={`${(isEditing) ? '' : 'https://www.lasvegasmarket.com/search/events?information=filter=true&tags=' + tagName + '&article=filter=true&tags=' + tagName + '&event=filter=true&tags=' + tagName}`} className="imc-eventdetail--tag-link imc-button--muted-inverted">{tagName}</a></em>
                </span>
                )
            })
        )
    }
}

export default withSitecoreContext()(tags);
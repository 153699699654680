import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'react-moment';
import {withSitecoreRouter} from 'utils/withRouter';
import EventsUtility from '../../utils/events/index.ts';
//Actions

//Utils
import { uniqueid } from 'utils/uniqueid';
import { getDictionaryValue } from 'utils/dictionary/index';
import { updateUrl } from '../../utils/tagupdate';
import UserUtility from "utils/userutility";

//modules
import { CardTags } from 'modules/cardtags';
import Link from '../../components/Link';

/**
 * Event Card Component
 * @param {object} props incoming props
 * @returns {XML} EventCard Component
 */
class EventCard extends Component {
    /**
    * Constuctor
    @param {object} props incoming props
    */
    constructor(props) {
        super(props);
        this.renderCardTags = this.renderCardTags.bind(this);
        this.tagClick = this.tagClick.bind(this);

    }

    /**
 * Tag click event
 * @returns {JSX} Card tags
 */
    tagClick(event) {
        const { router, match, filterKey, tagKey } = this.props;
        const tag = event.target.firstChild.nodeValue;
        updateUrl(tag, router, match, filterKey, tagKey);
    }


    renderCardTags() {
        const { destinationUrl, tag, eventType } = this.props;
        const id = uniqueid('event-');
        return <CardTags
            className="imc-eventcard__tag"
            dataXpath="eventcard.tags"
            destinationUrl={destinationUrl}
            maxTagsToDisplay={2}
            tags={tag}
            id={id}
            clickEvent={this.tagClick}
            eventType={eventType}
        />
    }

    /** Render Exhibitor Card
    * @returns {XML} elements to render
    */
    render() {
        const {
            title,
            teaserText,
            eventDate,
            formattedDate,
            locations,
            destinationUrl,
            time,
            startDateTime,
            endDateTime
        } = this.props;
        const event = this.props;
        
        const timezone = (event.time.timezone) ? event.time.timezone.title : (UserUtility.getUserData().timeZone ? UserUtility.getUserData().timeZone : "");
        return (
            <div className="imc-vr--xlarge imc-eventcard">
                <div className="imc-eventcard__header">
                    <span className="imc-eventcard__title imc-type--title-3">
                        <Link href={destinationUrl} className="imc-type--color-neutral-heaviest">{title}</Link>
                    </span>
                </div>
                <div className="imc-eventcard__body">
                    <div className="imc-eventcard__border">
                        {time &&
                            <div className="imc-type--title-3-ui">
                                <span className="">{getDictionaryValue('eventDateAndTime', 'Date & Time:')}</span>&nbsp;
                                {formattedDate}&nbsp;|&nbsp;<Moment format="hh:mm A">{startDateTime.replace("Z","")}</Moment>&nbsp;-&nbsp;<Moment format="hh:mm A" local>{endDateTime.replace("Z","")}</Moment>&nbsp;{timezone}
                            </div>
                        }
                        {(locations && locations.length > 0) &&
                            <div className="imc-type--title-3-ui">
                                <span>{getDictionaryValue('eventLocation', 'Location:')} </span>
                                {
                                   `${EventsUtility.getEventLocation(locations[0]).DisplayLocation}`
                                }
                            </div>
                        }
                    </div>
                    <div className="imc-eventcard__details imc-type--title-2-ui">
                        {teaserText}
                    </div>

                    <div className="imc-gallery imc-gallery--33-66">
                        <div className="imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right imc-padding--top--medium--desktop">
                            <Link
                                className={'imc-button--primary-inverted'}
                                href={destinationUrl}
                            >{getDictionaryValue('seeMoreDetails', 'See More Details')}</Link>
                        </div>
                        <div className="imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right">
                            {this.renderCardTags()}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
const propTypes = {
    actions: PropTypes.object,
    destinationUrl: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    teaserText: PropTypes.string,
    itemType: PropTypes.string,
    eventDate: PropTypes.string,
    formattedDate: PropTypes.string,
    time: PropTypes.object,
    speakerNames: PropTypes.array,
    location: PropTypes.array,
    sponsor: PropTypes.string,
    id: PropTypes.string.isRequired,
    tag: PropTypes.array,
    labelEventSpeaker: PropTypes.string,
    labelEventSponsor: PropTypes.string,
    labelEventLocation: PropTypes.string,
    labelDetailsLink: PropTypes.string,
    labelMoreTags: PropTypes.string,
    labelProducts: PropTypes.object.isRequired,
    savedContent: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
};

const defaultProps = {
    labelEventSpeaker: 'SPEAKER',
    labelEventSponsor: 'SPONSOR',
    labelEventLocation: 'Location',
    labelDetailsLink: 'See Details',
    destinationUrl: '',
    itemType: 'Event',
    image: '',
    title: 'EVENT TITLE',
    teaserText: 'some teaser text',
    eventDate: 'Some Data',
    formattedDate: '',
    time: null,
    speakerNames: [],
    sponsor: null,
    location: [],
    tag: [],
    labelMoreTags: '+ More',
    actions: {
    },
    labelProducts: {},
    labels: {},
    savedContent: {},
    filterKey: 'events',
    tagKey: 'Topics',
};


EventCard.propTypes = propTypes;
EventCard.defaultProps = defaultProps;

// Export the react component
//export { EventCard as UnwrappedEventCard };
export default withSitecoreRouter(EventCard);


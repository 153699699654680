import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";


const ContentSectionWithBackgroundColor = ({ rendering }) => (
  <div className="imc-section imc-section--padded-inner imc-optin imc-content imc-content--center imc-hide-print">

    <Placeholder name="section-placeholder" rendering={rendering} />
  </div>
);

const _withSitecoreContext = withSitecoreContext()(
  ContentSectionWithBackgroundColor
);

export default _withSitecoreContext;

import React, { Component } from 'react'
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class DigitalTab extends Component {
   
    render() {
        let item;
        const digitalTab = this.props.rendering.fields.title.value;
        if(this.props.sitecoreContext.pageEditing){
    		item =  <Placeholder tabData={this.props.tabData} name='imc-tab-content' rendering={this.props.rendering}/>
        }
    	if(this.props.currentIndex == this.props.rendering.dataSource && !this.props.sitecoreContext.pageEditing){
    		item = <div
                className="imc-tabs__body imc-section"
                aria-live="polite"
                data-xpath="tabs.containerInner"
                id={'searchContainer'}
            >
                <Placeholder currentTab={this.props.rendering.fields} pageSize={parseInt(this.props.rendering.fields.size.value || this.props.defaultPageSize) } tabData={this.props.tabData} name='imc-tab-content' rendering={this.props.rendering}/>
            </div>
        }
    	return(
    		<div>
                {this.props.sitecoreContext.pageEditing ? <p style={{padding: "20px 0 0 0"}}>{digitalTab} Digital Tab Content:</p> : ''}
                {item}
    		</div>
    	)
    }
}

export default withSitecoreContext()(DigitalTab);

import React, { Component } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class StyleGuide extends Component {
    render() {
        return (
            <div>
                {this.props.sitecoreContext.pageEditing ? <h2>Style Guide Component:</h2> : ''}
                <section class="imc-section imc-vr">
                    <h1 class="imc-heading imc-heading--kilo imc-vr">Style Guide</h1>

                    <div class="imc-content imc-content--alpha imc-vr imc-vr--divided">
                        <p>This style guide can be used as a reference for the implementation of common styling properties, elements and
                            modules. For a complete set of properties and modules please refer to the SASS modules within the
                            'frontend/las-vegas-market/src/assets/scss' directory.</p>
                    </div>
                </section>
                <section class="imc-section imc-vr">
                    <h1 class="imc-heading imc-heading--kilo imc-vr">Headings</h1>

                    <div class="imc-content imc-content--alpha imc-vr imc-vr--divided">
                        <h1>Heading 1</h1>
                        <h2>Heading 2</h2>
                        <h3>Heading 3</h3>
                        <h4>Heading 4</h4>
                        <h5>Heading 5</h5>
                        <h6>Heading 6</h6>
                    </div>
                </section>

                <section class="imc-section imc-vr">
                    <h1 class="imc-heading imc-heading--kilo imc-vr">Base Properties</h1>

                    <div class="imc-vr imc-vr--divided">
                        <h1 class="imc-heading imc-heading--alpha imc-vr">Common Properties</h1>

                        <div class="imc-content imc-content--alpha">
                            <p>The common properties can be used as a reference for implementing style properties and values such as
                                margins, padding, typography and colors. Additional style properties, functions and mixins can be found
                                within each property stylesheet under the 'frontend/las-vegas-market/src/assets/scss/common/scss/base' directory.</p>
                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <div class="imc-vr--xxlarge">
                            <h2 class="imc-heading imc-heading--alpha imc-vr">Grid</h2>
                        </div>
                        <div class="imc-vr">
                            <div class="imc-vr--xxlarge">
                                <h3 class="imc-heading imc-heading--beta imc-vr">Mobile Grid</h3>

                                <div class="imc-content imc-vr">
                                    <p>The mobile grid consists of a 15 column grid.</p>
                                </div>
                            </div>

                            <div class="imc-vr">
                                <h3 class="imc-heading imc-heading--beta imc-vr">Desktop Grid</h3>

                                <div class="imc-content">
                                    <p>The desktop grid consists of a 24 column grid, with 50px outer margins. The grid is gutterless, and
                                        begins at 992px viewport width. The max width of the grid is 1366px, including the outer margins.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <div class="imc-vr imc-vr--xxlarge">
                            <h2 class="imc-heading imc-heading--beta imc-vr">Margins and Padding</h2>

                            <div class="imc-content imc-vr">
                                <p>When setting margins and padding for an element the following sizes can be set via the margin
                                    and
                                    padding mixins. Both of these mixins use the same TRBL syntax as the CSS properties; however
                                    instead specifying a pixel value, the alias size names below should be used.</p>
                            </div>

                            <div class="imc-sg-note">
                                <p><b>Note:</b> Vertical margins for elements should be set using a one directional flow, set at
                                    the
                                    bottom of the element, and should only push inward on the module. For example an element
                                    should
                                    only have vertical margins set to its bottom if it requires space vertically between it and
                                    other
                                    element. Using this pattern resolves many issues and conflicts with collapsing margins and
                                    colliding
                                    styles of surrounding elements.</p>
                                <p>In addition, margins should not be set on the outer regions of the module to not affect
                                    modules
                                    surrounding it. Rather, use decorator modules such as the <b>vr</b> (vertical-rhythm), <b>section</b>,
                                    or <b>gallery</b> modules to set the positioning between modules.</p>
                            </div>
                        </div>

                        <div>
                            <h3 class="imc-heading imc-heading--gamma imc-vr">Spacing Properties</h3>
                            <table class="imc-sg-table imc-vr imc-vr--large">
                                <thead class="imc-sg-table__header">
                                    <tr>
                                        <th class="imc-sg-table__heading">Property Name</th>
                                        <th class="imc-sg-table__heading">Output Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">jumbodecimal</td>
                                        <td class="imc-sg-table__item">100px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">hulk</td>
                                        <td class="imc-sg-table__item">90px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">titan</td>
                                        <td class="imc-sg-table__item">80px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">collosal</td>
                                        <td class="imc-sg-table__item">70px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">jumbo</td>
                                        <td class="imc-sg-table__item">60px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">xxxlarge</td>
                                        <td class="imc-sg-table__item">50px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">xxmedium</td>
                                        <td class="imc-sg-table__item">48px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">xxlarge</td>
                                        <td class="imc-sg-table__item">40px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">xlarge</td>
                                        <td class="imc-sg-table__item">30px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">xlargemedium</td>
                                        <td class="imc-sg-table__item">28px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">largemedium</td>
                                        <td class="imc-sg-table__item">25px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">large</td>
                                        <td class="imc-sg-table__item">20px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">mediumlarge</td>
                                        <td class="imc-sg-table__item">18px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">medium</td>
                                        <td class="imc-sg-table__item">15px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">mediumsmall</td>
                                        <td class="imc-sg-table__item">13px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">small</td>
                                        <td class="imc-sg-table__item">10px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">smallmedium</td>
                                        <td class="imc-sg-table__item">8px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">xsmall</td>
                                        <td class="imc-sg-table__item">5px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">xxsmall</td>
                                        <td class="imc-sg-table__item">3px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">micro</td>
                                        <td class="imc-sg-table__item">2px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">nano</td>
                                        <td class="imc-sg-table__item">1px</td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">none</td>
                                        <td class="imc-sg-table__item">0px</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="imc-sg-code-block">
                                <h2 class="imc-sg-code-block__heading">Example Usage</h2>

                                <code class="imc-sg-code-block__body">
                                    <p class="imc-sg-code-block__snippet">Sass input: @include margin(small medium);</p>
                                    <p class="imc-sg-code-block__snippet">CSS Output: margin: 0.625rem 0.9375rem;</p>
                                </code>
                            </div>

                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <div class="imc-vr imc-vr--xxlarge">
                            <h2 class="imc-heading imc-heading--alpha imc-vr">Typography</h2>

                            <div class="imc-content imc-vr">
                                <p>When setting the font family, size and line-height of an element, the following mixins and
                                    properties can be used.</p>
                            </div>
                        </div>

                        <div class="imc-vr imc-vr--divided">
                            <div class="imc-vr imc-vr--xxlarge">
                                <h3 class="imc-heading imc-heading--beta imc-vr">Font Types</h3>

                                <div class="imc-content imc-vr">
                                    <p>This application contains several font families, each which are used for different content
                                        types. A serif font for heading content, and a san-serif font for body content. To set the
                                        properties for each content type the following "type-heading" and "type-content" mixins can be used.</p>
                                </div>

                                <div class="imc-sg-note">
                                    <p><b>Note:</b>
                                        font styles use the mixins `type-heading` and `type-content` to set the proper weight.</p>
                                </div>
                            </div>

                            <div class="imc-vr imc-vr--divided">
                                <h4 class="imc-heading imc-heading--gamma imc-vr">Heading Font Properties</h4>

                                <table class="imc-sg-table imc-vr imc-vr--xlarge">
                                    <thead class="imc-sg-table__header">
                                        <tr>
                                            <th class="imc-sg-table__heading">Titles</th>
                                            <th class="imc-sg-table__heading">Links</th>
                                            <th class="imc-sg-table__heading">Inverted</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-1">Heading 1</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-1-link">Heading 1 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--title-1-inverted">Heading 1 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-2">Heading 2</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-2-link">Heading 2 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--title-2-inverted">Heading 2 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-3">Heading 3</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-3-link">Heading 3 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--title-3-inverted">Heading 3 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-4">Heading 4</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-4-link">Heading 4 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--title-4-inverted">Heading 4 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-5">Heading 5</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-5-link">Heading 5 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--title-5-inverted">Heading 5 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-6">Heading 6</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-6-link">Heading 6 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--title-6-inverted">Heading 6 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-7">Heading 7</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-7-link">Heading 7 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--title-7-inverted">Heading 7 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-8">Heading 8</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--title-8-link">Heading 8 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--title-8-inverted">Heading 8 Invert</h1>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="imc-sg-code-block">
                                    <h2 class="imc-sg-code-block__heading">Example Heading Usage</h2>

                                    <code class="imc-sg-code-block__body">
                                        <p class="imc-sg-code-block__snippet">Sass input:<br />
                                            @include type-heading(kilo, primary);<br />
                                            Arguments: $font-size, $font-variant, $type-color<br />
                                            <i>Defaults: $font-size: 'giga', $font-variant: 'primary', $type-color:
                                                'default'</i>
                                        </p>

                                        <p class="imc-sg-code-block__snippet">CSS Output: <br />
                                            font-family: "Avenir 45 Book", "sans-serif";<br />
                                            font-size: 1.5rem;<br />
                                            line-height: 1.875rem;<br />
                                            color: #000;
                                        </p>
                                        <p class="imc-sg-code-block__snippet">Class Reference: <br />
                                            .imc-type--title-1 <br />
                                            .imc-type--title-1-link <br />
                                            .imc-type--title-1-inverted <br />
                                            **replace "1" with style you want to use**
                                        </p>
                                    </code>
                                </div>

                            </div>

                            <div>
                                <h4 class="imc-heading imc-heading--gamma imc-vr">Content Font Properties</h4>

                                <table class="imc-sg-table imc-vr imc-vr--xlarge">
                                    <thead class="imc-sg-table__header">
                                        <tr>
                                            <th class="imc-sg-table__heading">Body</th>
                                            <th class="imc-sg-table__heading">Links</th>
                                            <th class="imc-sg-table__heading">Inverted</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-1">Body 1</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-1-link">Body 1 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--body-1-inverted">Body 1 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-2-ui">Body 2</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-2-ui-link">Body 2 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--body-2-ui-inverted">Body 2 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-3">Body 3</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-3-link">Body 3 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--body-3-inverted">Body 3 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-4-ui">Body 4</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-4-ui-link">Body 4 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--body-4-ui-inverted">Body 4 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-5">Body 5</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-5-link">Body 5 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--body-5-inverted">Body 5 Invert</h1>
                                            </td>
                                        </tr>

                                        <tr class="imc-sg-table__row">
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-6-ui">Body 6</h1>
                                            </td>
                                            <td class="imc-sg-table__item">
                                                <h1 class="imc-type--body-6-ui-link">Body 6 Link</h1>
                                            </td>
                                            <td class="imc-sg-table__item" bgcolor="#000000">
                                                <h1 class="imc-type--body-6-ui-inverted">Body 6 Invert</h1>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="imc-sg-code-block">
                                    <h2 class="imc-sg-code-block__heading">Example Content Usage</h2>

                                    <code class="imc-sg-code-block__body">
                                        <p class="imc-sg-code-block__snippet">Sass input:<br />
                                            @include type-content(delta, regular);<br />
                                            Arguments: $font-size, $font-variant, $type-color<br />
                                            <i>Defaults: $font-size: 'gamma', $font-variant: 'regular', $type-color:
                                                'default'</i></p>
                                        <p class="imc-sg-code-block__snippet">CSS Output: <br />
                                            font-family: 'Avenir 65 Medium', 'serif';<br />
                                            font-size: 0.875rem;<br />
                                            line-height: 1.375rem;<br />
                                            color: #000;</p>
                                        <p class="imc-sg-code-block__snippet">Class Reference: <br />
                                            Body styles 1,3,5: <br />
                                            .imc-type--body-1 <br />
                                            .imc-type--body-1-link <br />
                                            .imc-type--body-1-inverted <br />
                                            Body styles 2,4,6 (include '-ui' to class name): <br />
                                            .imc-type--body-2-ui <br />
                                            .imc-type--body-2-ui-link <br />
                                            .imc-type--body-2-ui-inverted <br />
                                            **replace "1" with style you want to use**
                                        </p>
                                    </code>
                                </div>

                            </div>
                        </div>

                        <div>
                            <div class="imc-vr imc-vr--xxlarge">
                                <h3 class="imc-heading imc-heading--beta imc-vr">Type Size Properties</h3>

                                <div class="imc-content imc-vr">
                                    <p>When setting the font size of an element, the following sizes should be used in
                                        conjunction
                                        with the "type-heading" and "type-content" mixin.</p>
                                </div>

                                <div class="imc-sg-note">
                                    <p><b>Note:</b> font-sizes should never be set using an exact pixel value (for example
                                        "font-size:
                                        16px"), instead the type mixins should be used (for example <b>"@include
                                            type-content(zeta)"</b></p>
                                </div>
                            </div>

                            <table class="imc-sg-table">
                                <thead class="imc-sg-table__header">
                                    <tr>
                                        <th class="imc-sg-table__heading">Property Name</th>
                                        <th class="imc-sg-table__heading">Output Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--peta">peta</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--peta">104px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--tera">tera</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--tera">72px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--omni">omni</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--omni">42px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--giga">giga</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--giga">32px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--mega">mega</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--mega">26px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--kilo">kilo</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--kilo">24px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--alpha">alpha</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--alpha">20px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--beta">beta</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--beta">18px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--gamma">gamma</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--gamma">16px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--delta">delta</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--delta">14px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--epsilon">epsilon</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--epsilon">13px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--zeta">zeta</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--zeta">12px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--micro">micro</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--micro">11px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--milli">milli</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--milli">10px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--mini">mini</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--mini">9px</h1>
                                        </td>
                                    </tr>
                                    <tr class="imc-sg-table__row">
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--nano">nano</h1>
                                        </td>
                                        <td class="imc-sg-table__item">
                                            <h1 class="imc-sg-type__content imc-sg-type__content--nano">6px</h1>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <h1 class="imc-heading imc-heading--kilo imc-vr">Base Properties</h1>

                        <div class="imc-vr imc-vr--xxlarge">
                            <h2 class="imc-heading imc-heading--alpha imc-vr">Color Properties</h2>

                            <div class="imc-content imc-vr">
                                <p>When setting the color properties of an element, the color mixins should be used to set the proper
                                    available color variants based on the color type. For example, setting the background or outline colors
                                    of an element, the mixin "get-box-color" can be used for retrieving the available colors from the
                                    $colors map.</p>
                            </div>

                            <div class="imc-sg-note">
                                <p><b>Note:</b> For additional color properties and implementation mixins see
                                    "scss/base/_colors.scss"</p>
                            </div>
                        </div>

                        <div class="imc-vr imc-vr--divided">
                            <div class="imc-vr">
                                <h3 class="imc-heading imc-heading--gamma imc-vr">Variable Colors</h3>

                                <div class="imc-content">
                                    <p>Variable colors as they are defined in code for this particular market.</p>
                                </div>
                            </div>

                            <div>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-primary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-primary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-primary-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-primary-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-primary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-primary-light"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-secondary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-secondary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-secondary-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-secondary-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-secondary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-secondary-light"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-tertiary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-tertiary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-tertiary-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-tertiary-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-tertiary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-tertiary-light"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-neutral-heaviest"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heaviest</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-neutral-heaviest"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-neutral-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-neutral-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-neutral-heavy-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heavy Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-neutral-heavy-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-neutral-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-neutral-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-neutral-medium-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Medium Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-neutral-medium-light"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-neutral-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-neutral-light"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-junipermarket-green"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Juniper Market Green</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-junipermarket-green"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-white"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">White</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-white"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-link"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Link</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-link"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-alert"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Alert</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-alert"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--variable-success"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Success</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--variable-success"></p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="imc-vr imc-vr--divided">
                            <div class="imc-vr">
                                <h3 class="imc-heading imc-heading--gamma imc-vr">Box Colors</h3>

                                <div class="imc-content">
                                    <p>Box color properties can be used for setting background and outlines of elements.</p>
                                </div>
                            </div>

                            <div>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-primary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-primary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-primary-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-primary-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-primary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-primary-light"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-secondary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-secondary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-secondary-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-secondary-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-secondary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-secondary-light"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-tertiary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-tertiary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-tertiary-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-tertiary-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-tertiary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-tertiary-light"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-neutral-heaviest"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heaviest</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-neutral-heaviest"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-neutral-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-neutral-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-neutral-heavy-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heavy Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-neutral-heavy-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-neutral-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-neutral-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-neutral-medium-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Medium Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-neutral-medium-light"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--box-neutral-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--box-neutral-light"></p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="imc-vr imc-vr--divided">
                            <div class="imc-vr">
                                <h3 class="imc-heading imc-heading--gamma imc-vr">Type Colors</h3>

                                <div class="imc-content imc-vr">
                                    <p>Type color properties can be used for setting the type color of an elements.</p>
                                </div>

                                <div class="imc-sg-note">
                                    <p><b>Note:</b>Setting the color of type can be set with both the
                                        "type-content/type-heading" mixins
                                        as well as the "get-type-color" mixins. When setting the font size of an element, the
                                        preferred method
                                        to set the color is with the "type-content/type-heading" mixins with the color argument.
                                        If overriding
                                        an inherited color, using the "get-type-color" method is the preferred method.</p>
                                </div>
                            </div>

                            <div>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-primary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-primary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-primary-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-primary-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-primary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-primary-light"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-secondary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-secondary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-secondary-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-secondary-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-secondary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-secondary-light"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-tertiary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-tertiary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-tertiary-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-tertiary-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-tertiary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-tertiary-light"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-neutral-heaviest"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heaviest</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-neutral-heaviest"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-neutral-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-neutral-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-neutral-heavy-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heavy Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-neutral-heavy-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-neutral-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-neutral-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-neutral-medium-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Medium Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-neutral-medium-light"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--type-neutral-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--type-neutral-light"></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="imc-vr imc-vr--divided">
                            <div class="imc-vr">
                                <h3 class="imc-heading imc-heading--gamma imc-vr">Link Colors</h3>

                                <div class="imc-content">
                                    <p>Link color properties can be used for setting the color of link elements (NOT USED).</p>
                                </div>
                            </div>

                            <div>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-neutral-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-neutral-light"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-neutral-medium-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Medium Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-neutral-medium-light"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-neutral-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-neutral-medium"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-neutral-heavy-medium"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heavy Medium</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-neutral-heavy-medium"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-neutral-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-neutral-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-neutral-heaviest"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Neutral Heaviest</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-neutral-heaviest"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-primary"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-primary"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-primary-heavy"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Heavy</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-primary-heavy"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-secondary"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-secondary"></p>
                                        </div>
                                    </div>

                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-tertiary"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-tertiary"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--link-tertiary-light"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertirary Light</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--link-tertiary-light"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="imc-vr imc-vr--divided">
                            <div class="imc-vr">
                                <h3 class="imc-heading imc-heading--gamma imc-vr">Button Colors</h3>

                                <div class="imc-content">
                                    <p>Button color properties can be used for setting the color of button elements.</p>
                                </div>
                            </div>

                            <div>
                                <h3 class="imc-heading imc-heading--delta">Primary</h3>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-primary-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-primary-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-primary-active-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Active Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-primary-active-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-primary-text"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Text</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-primary-text"></p>
                                        </div>
                                    </div>
                                </div>

                                <h3 class="imc-heading imc-heading--delta">Primary Inverted</h3>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-primary-inverted-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Inverted Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-primary-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-primary-inverted-active-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Inverted Active Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-primary-active-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-primary-inverted-text"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Primary Inverted Text</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-primary-text"></p>
                                        </div>
                                    </div>
                                </div>

                                <h3 class="imc-heading imc-heading--delta">Secondary</h3>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-secondary-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-secondary-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-secondary-active-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Active Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-secondary-active-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-secondary-text"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Text</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-secondary-text"></p>
                                        </div>
                                    </div>
                                </div>

                                <h3 class="imc-heading imc-heading--delta">Secondary Inverted</h3>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-secondary-inverted-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Inverted Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-secondary-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-secondary-inverted-active-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Inverted Active Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-secondary-active-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-secondary-inverted-text"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Secondary Inverted Text</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-secondary-text"></p>
                                        </div>
                                    </div>
                                </div>

                                <h3 class="imc-heading imc-heading--delta">Tertiary</h3>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-tertiary-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-tertiary-active-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Active Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-active-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-tertiary-text"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Text</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-text"></p>
                                        </div>
                                    </div>
                                </div>

                                <h3 class="imc-heading imc-heading--delta">Tertiary Inverted</h3>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-tertiary-inverted-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Inverted Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-tertiary-inverted-active-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Inverted Active Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-active-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-tertiary-inverted-text"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Tertiary Inverted Text</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-text"></p>
                                        </div>
                                    </div>
                                </div>

                                <h3 class="imc-heading imc-heading--delta">Shop Zio</h3>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-atmarket-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Shop Zio Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-atmarket-active-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Shop Zio Active Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-active-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-atmarket-text"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Shop Zio Text</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-text"></p>
                                        </div>
                                    </div>
                                </div>

                                <h3 class="imc-heading imc-heading--delta">Muted Inverted</h3>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-muted-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Muted Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-muted-active-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Muted Active Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-active-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-muted-text"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Muted Text</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-tertiary-text"></p>
                                        </div>
                                    </div>
                                </div>

                                <h3 class="imc-heading imc-heading--delta">Muted Inverted</h3>
                                <div class="imc-sg-colors">
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-muted-inverted-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Muted Inverted Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-muted-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-muted-inverted-active-background"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Muted Inverted Active Background</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-muted-active-background"></p>
                                        </div>
                                    </div>
                                    <div class="imc-sg-colors__cell">
                                        <div class="imc-sg-colors__color imc-sg-colors__color--button-muted-inverted-text"></div>
                                        <div class="imc-sg-colors__details">
                                            <p class="imc-sg-colors__label">Muted Inverted Text</p>
                                            <p class="imc-sg-colors__name imc-sg-colors__name--button-muted-text"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="imc-vr">
                            <h3 class="imc-heading imc-heading--gamma imc-vr">Notification Colors</h3>

                            <div class="imc-content">
                                <p>Notification color properties can be used for setting the color of the background for the notification banner.</p>
                            </div>
                        </div>

                        <div>
                            <div class="imc-sg-colors">
                                <div class="imc-sg-colors__cell">
                                    <div class="imc-sg-colors__color imc-sg-colors__color--notification-default"></div>
                                    <div class="imc-sg-colors__details">
                                        <p class="imc-sg-colors__label">Default</p>
                                        <p class="imc-sg-colors__name imc-sg-colors__name--notification-default"></p>
                                    </div>
                                </div>
                                <div class="imc-sg-colors__cell">
                                    <div class="imc-sg-colors__color imc-sg-colors__color--notification-green"></div>
                                    <div class="imc-sg-colors__details">
                                        <p class="imc-sg-colors__label">Green</p>
                                        <p class="imc-sg-colors__name imc-sg-colors__name--notification-green"></p>
                                    </div>
                                </div>
                                <div class="imc-sg-colors__cell">
                                    <div class="imc-sg-colors__color imc-sg-colors__color--notification-red"></div>
                                    <div class="imc-sg-colors__details">
                                        <p class="imc-sg-colors__label">Red</p>
                                        <p class="imc-sg-colors__name imc-sg-colors__name--notification-red"></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="imc-vr">
                            <h3 class="imc-heading imc-heading--gamma imc-vr">State Colors</h3>

                            <div class="imc-content">
                                <p>State color properties can be used for setting the color of an elements to display its
                                    state (e.g. error, active, alert, etc).</p>
                            </div>
                        </div>

                        <div>
                            <div class="imc-sg-colors">
                                <div class="imc-sg-colors__cell">
                                    <div class="imc-sg-colors__color imc-sg-colors__color--state-highlight"></div>
                                    <div class="imc-sg-colors__details">
                                        <p class="imc-sg-colors__label">Highlight</p>
                                        <p class="imc-sg-colors__name imc-sg-colors__name--state-highlight"></p>
                                    </div>
                                </div>
                                <div class="imc-sg-colors__cell">
                                    <div class="imc-sg-colors__color imc-sg-colors__color--state-alert"></div>
                                    <div class="imc-sg-colors__details">
                                        <p class="imc-sg-colors__label">Alert</p>
                                        <p class="imc-sg-colors__name imc-sg-colors__name--state-alert"></p>
                                    </div>
                                </div>
                                <div class="imc-sg-colors__cell">
                                    <div class="imc-sg-colors__color imc-sg-colors__color--state-error"></div>
                                    <div class="imc-sg-colors__details">
                                        <p class="imc-sg-colors__label">Error</p>
                                        <p class="imc-sg-colors__name imc-sg-colors__name--state-error"></p>
                                    </div>
                                </div>
                                <div class="imc-sg-colors__cell">
                                    <div class="imc-sg-colors__color imc-sg-colors__color--state-success"></div>
                                    <div class="imc-sg-colors__details">
                                        <p class="imc-sg-colors__label">Success</p>
                                        <p class="imc-sg-colors__name imc-sg-colors__name--state-success"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="imc-section imc-section--padded">
                    <h1 class="imc-heading imc-heading--kilo imc-vr">Content Modules</h1>

                    <div class="imc-vr imc-vr--divided">
                        <h1 class="imc-heading imc-heading--alpha imc-vr">Content Modules</h1>

                        <div class="imc-content imc-content--alpha">
                            <p>The common elements can be used as a reference for reusable content modules that are commonly used within
                                the LVM/Portal applications. For additional modules and implementation methods you can refer to each
                                content module file under the "src/assets/scss/modules/content" directory.</p>
                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <div class="imc-section imc-section--padded imc-section--alt ">
                            <div class="imc-vr imc-vr--xlarge">
                                <h2 class="imc-heading imc-heading--beta imc-heading--invert">Content (alternating background color - heavy)</h2>
                            </div>

                            <div class="imc-content imc-content--invert">
                                <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                                    molestias quas quisquam repellat veritatis!</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
                            </div>
                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <div class="imc-section imc-section--padded imc-section--alt-medium ">
                            <div class="imc-vr imc-vr--xlarge">
                                <h2 class="imc-heading imc-heading--beta imc-heading--invert">Content (alternating background color - medium)</h2>
                            </div>

                            <div class="imc-content imc-content--invert">
                                <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                                    molestias quas quisquam repellat veritatis!</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
                            </div>
                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <div class="imc-section imc-section--padded imc-section--alt-light ">
                            <div class="imc-vr imc-vr--xlarge">
                                <h2 class="imc-heading imc-heading--beta imc-heading--accent">Content (alternating background color - light)</h2>
                            </div>

                            <div class="imc-content imc-content--accent">
                                <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                                    molestias quas quisquam repellat veritatis!</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
                            </div>
                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <div class="imc-section imc-section--padded imc-section--alt-gray ">
                            <div class="imc-vr imc-vr--xlarge">
                                <h2 class="imc-heading imc-heading--beta imc-heading--invert">Content (alternating background color - gray dark)</h2>
                            </div>

                            <div class="imc-content imc-content--invert">
                                <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                                    molestias quas quisquam repellat veritatis!</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
                            </div>
                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <div class="imc-section imc-section--padded imc-section--alt-gray-medium ">
                            <div class="imc-vr imc-vr--xlarge">
                                <h2 class="imc-heading imc-heading--beta imc-heading--invert">Content (alternating background color - gray medium)</h2>
                            </div>

                            <div class="imc-content imc-content--invert">
                                <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                                    molestias quas quisquam repellat veritatis!</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
                            </div>
                        </div>
                    </div>

                    <div class="imc-content imc-vr imc-vr--divided">
                        <div class="imc-vr imc-vr--xlarge">
                            <h2 class="imc-heading imc-heading--beta">Links</h2>
                        </div>

                        <div class="imc-gallery imc-gallery--1-2">
                            <div class="imc-gallery__item">
                                <span class="imc-type--body-1">Example of a body 1 14pt <a href="#" class="imc-link" title="Default link">Default link</a></span>
                            </div>
                            <div class="imc-gallery__item">
                                <span class="imc-type--body-3">Example of a body 3 16pt <a href="#" class="imc-link" title="Default link">Default link</a></span>
                            </div>
                            <div class="imc-gallery__item">
                                <a href="#" class="imc-link imc-link--caret-after" title="Link with Caret">Link with Caret</a>
                            </div>
                            <div class="imc-gallery__item" style={{ backgroundColor: 'black' }}>
                                <a href="#" class="imc-link--invert" title="inverted link">Inverted link</a>
                            </div>
                            <div class="imc-gallery__item">
                                <span class="imc-type--body-1">Example of a body 1 16pt  <a href="#" class="imc-link--alt" title="Alternate link">Alternate link</a></span>
                            </div>
                            <div class="imc-gallery__item">
                                <span class="imc-type--body-3">Example of a body 3 16pt <a href="#" class="imc-link--alt" title="Alternate link">Alternate link</a></span>
                            </div>

                        </div>
                    </div>

                    <div class="imc-vr imc-vr--divided">
                        <div class="imc-vr imc-vr--xlarge">
                            <h2 class="imc-heading imc-heading--beta">Buttons with Class Names</h2>
                        </div>

                        <section className="imc-vr">
                            <div class="imc-vr">
                                <button class="imc-button imc-vr--xsmall">Primary Button</button>
                                <p>imc-button</p>
                            </div>
                            <div class="imc-vr">
                                <button class="imc-button imc-button--small imc-vr--xsmall">Small Button</button>
                                <p>imc-button imc-button--small</p>
                            </div>
                            <div class="imc-vr">
                                <button class="imc-button--primary-inverted imc-vr--xsmall">Primary Inverted Button</button>
                                <p>imc-button--primary-inverted</p>
                            </div>
                            <div class="imc-vr">
                                <button class="imc-button imc-button--secondary imc-vr--xsmall">Secondary Button</button>
                                <p>imc-button--secondary</p>
                            </div>
                            <div class="imc-vr">
                                <button class="imc-button imc-button--secondary-inverted imc-vr--xsmall">Secondary Inverted Button</button>
                                <p>imc-button--secondary-inverted</p>
                            </div>
                            <div class="imc-vr">
                                <button class="imc-button imc-button--tertiary imc-vr--xsmall">Tertiary Button</button>
                                <p>imc-button--tertiary</p>
                            </div>
                            <div class="imc-vr">
                                <button class="imc-button imc-button--tertiary-inverted imc-vr--xsmall">Tertiary Inverted Button</button>
                                <p>imc-button--tertiary-inverted</p>
                            </div>
                            <div class="imc-vr">
                                <button class="imc-button imc-button--muted imc-vr--xsmall">Muted Button</button>
                                <p>imc-button--muted</p>
                            </div>
                            <div class="imc-vr">
                                <button class="imc-button imc-button--muted-inverted imc-vr--xsmall">Muted Inverted Button</button>
                                <p>imc-button--muted-inverted</p>
                            </div>
                        </section>
                        <section className="imc-vr">
                            <h2 style={{ textDecoration: 'underline' }} class="imc-heading imc-heading--beta">Button With Icons</h2>
                            <div class="imc-vr">
                                <button class="imc-button imc-button--atmarket-icon">Shop Zio</button>
                                <p>imc-button imc-button--atmarket-icon</p>
                            </div>
                            <div class="imc-vr">
                                <button class="imc-button imc-button--download imc-button--icon imc-vr--xsmall">Icon</button>
                                <p>imc-button imc--button-download</p>
                            </div>
                        </section>
                    </div>
                </section>
            </div>
        )
    }
}


export default withSitecoreContext()(StyleGuide);
import React from "react";
import { Image, Link } from "@sitecore-jss/sitecore-jss-react";

class SocialButton extends React.Component {
  render() {
    const { image, link } = this.props;
    return (
      <div className="imc-optin-social__item imc-vr--large">
        <Link
          className="imc-socialicons"
          field={link}
        >
          <Image field={image} />
        </Link>
      </div>
    );
  }
}

export default SocialButton;

import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import Link from '../Link';
import { argsToArgsConfig } from '../../../node_modules/graphql/type/definition';
class MPHeaderLinkComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render2() {
    return (
      <div>
        <h1>MP-Header-Link-Component Component</h1>
        <p>{JSON.stringify(this.props)}</p>
      </div>
    );
  }

  render() {
    const extraClass = "";
    let item = this.props;
    let customClassesForMPLink = "";
    if (item.isMobileApp) {
      customClassesForMPLink = "imc-header__flexDirectionColumn"
    }
    return (
      <div className={`imc-header-subnav__item ${extraClass}`}>
        {!!item.fields &&
          <a href={item.fields.link.value.href}
            target=""
            className={`imc-header-subnav__link imc-header-subnav__link--account ${customClassesForMPLink}`}>
            <div style={{ alignItems: "center", flexDirection: "row", display: "flex" }}>

              {item.fields.icon.value.svgCode !== undefined &&
                <span dangerouslySetInnerHTML={{ __html: item.fields.icon.value.svgCode }} />
              }
              {item.fields.icon.value.svgCode === undefined &&
                <Image className="imc-image--responsive" media={item.fields.icon} />
              }

              <div style={{ paddingLeft: .75 + 'rem', flexGrow: 3, flexShrink: 2 }} className={item.isMobileApp ? "imc-header-subnav__right" : ""}>
                {
                  (item.isMobileApp) ?
                    <span className="imc-header-subnav__link-text max-two-lines" title={item.fields.plannerDesktop.value || item.fields.link.value.text} >{item.fields.plannerDesktop.value || item.fields.link.value.text}</span>
                    :
                    <React.Fragment>
                      <span className="imc-breakpoint-display--hide-mobile imc-header-subnav__link-text max-two-lines" title={item.fields.plannerDesktop.value || item.fields.link.value.text} >{item.fields.plannerDesktop.value || item.fields.link.value.text}</span>
                      <span className="imc-breakpoint-display--hide-desktop imc-header-subnav__link-text imc-breakpoint-display--hide-mobile max-two-lines" title={item.fields.plannerMobile.value}>{item.fields.plannerMobile.value}</span>
                    </React.Fragment>
                }
              </div>
            </div>
          </a>
        }
      </div>
    );
  }
}

export default MPHeaderLinkComponent;
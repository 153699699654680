/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_MARKET_PLANNER_CHANNEL_SELECTOR = 'SET_MARKET_PLANNER_CHANNEL_SELECTOR';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_MARKET_PLANNER_CHANNEL_SELECTOR_ERR = 'SET_MARKET_PLANNER_CHANNEL_SELECTOR_ERR';


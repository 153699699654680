import {
    getSitecoreApiHost,
    getSitecoreApiKey
} from '../AppGlobals';
import qs from 'query-string';
/**
 *  MOCKS
 *
 */
import { getExhibitorDetail_mock } from './mocks/exhibitors';
import cacheFetch from '../modules/cachedfetch'
import UserUtility from '../utils/userutility';


const APIKEY_PLACEHOLDER = "$$APIKEY";
const EXHIBITORID_PLACEHOLDER = "$$EXHIBITORID";
const UID_PLACEHOLDER = "$$EXHIBITORUID";
const LINEID_PLACEHOLDER = "$$LINEID";
const PRODUCTSIDS_PLACEHOLDER = "$$PRODUCTSIDS";
const CATEGORY_PLACEHOLDER = "$$CATEGORY";
const SORTORDER_PLACEHOLDER = "$$SORTORDER";
const PRODUCTSORDER_PLACEHOLDER = "$$PRODUCTSORDER";
const PRODUCTSONLY_PLACEHOLDER = "$$PRODUCTSONLY";
const SHOPZIOONLY_PLACEHOLDER = "$$SHOPZIOONLY";
const FLOORNUMBER_PLACEHOLDER = "$$FLOORNUMBER";
const BUILDINGNAME_PLACEHOLDER = "$$BUILDINGNAME";
const COUNT_PLACEHOLDER = "$$COUNT";
const PAGEID_PLACEHOLDER = "$$PAGEID";
const PAGE_PLACEHOLDER = "$$PAGEX";
const PAGESIZE_PLACEHOLDER = "$$PAGESIZE";
const TERM_PLACEHOLDER = "$$TERM";
const CRM_ID = "$$CRMID";
const CHARACTER_PLACEHOLDER = "$$CHARACTER_PLACEHOLDER";

const apiEndPoints = {
    'exhibitorDetails': `/imc-api/v2/exhibitors/OpenDetails?sc_apikey=${APIKEY_PLACEHOLDER}&exhibitorIds=${EXHIBITORID_PLACEHOLDER}&pageId=${PAGEID_PLACEHOLDER}&`,
    'exhibitorByUID': `/imc-api/v1/floor-plan/exhibitorsByUid?sc_apikey=${APIKEY_PLACEHOLDER}&uids=${UID_PLACEHOLDER}&crmId=${CRM_ID}`,
    'lineDetails': `/imc-api/v2/lines/details?sc_apikey=${APIKEY_PLACEHOLDER}&manufactureIds=${LINEID_PLACEHOLDER}`,
    'contact': `/imc-api/showrooms/v1/digital-showroom-contact?sc_apikey=${APIKEY_PLACEHOLDER}&exhibitorId=${EXHIBITORID_PLACEHOLDER}`,
    'productsOverview': `/imc-api/v2/products/search?sc_apikey=${APIKEY_PLACEHOLDER}&exhibitorId=${EXHIBITORID_PLACEHOLDER}&featured=true&page=1&pageSize=6&searchPage=${PAGEID_PLACEHOLDER}`,
    'lineProductsOverview': `/imc-api/v2/products/lineProducts?sc_apikey=${APIKEY_PLACEHOLDER}&manufactureId=${LINEID_PLACEHOLDER}&featured=true&pageSize=6&searchPage=${PAGEID_PLACEHOLDER}`,
    'catalogOverview': `/imc-api/v2/catalogs/search?sc_apikey=${APIKEY_PLACEHOLDER}&exhibitorId=${EXHIBITORID_PLACEHOLDER}&featured=true&page=1&pageSize=6&searchPage=${PAGEID_PLACEHOLDER}`,
    'lineCatalogsOverview': `/imc-api/v2/catalogs/lineCatalogs?sc_apikey=${APIKEY_PLACEHOLDER}&lineId=${LINEID_PLACEHOLDER}&featured=true&page=1&pageSize=6&searchPage=${PAGEID_PLACEHOLDER}`,
    'linesOverview': `/imc-api/v2/lines/search?sc_apikey=${APIKEY_PLACEHOLDER}&exhibitorId=${EXHIBITORID_PLACEHOLDER}&featured=true&page=1&pageSize=50&${SORTORDER_PLACEHOLDER}&${PRODUCTSONLY_PLACEHOLDER}&${SHOPZIOONLY_PLACEHOLDER}&${PRODUCTSORDER_PLACEHOLDER}&searchPage=${PAGEID_PLACEHOLDER}&term=${TERM_PLACEHOLDER}`,
    // 'lineCatalogsOverview': `/imc-api/v1/catalogs/linecatalogs?sc_apikey=${APIKEY_PLACEHOLDER}&lineId=${LINEID_PLACEHOLDER}&featured=true&page=1&pageSize=6`,
    'productDetail': `/imc-api/v2/products/details?sc_apikey=${APIKEY_PLACEHOLDER}&${PRODUCTSIDS_PLACEHOLDER}`,
    'relatedProductsOverview': `/imc-api/v2/products/search?sc_apikey=${APIKEY_PLACEHOLDER}&exhibitorId=${EXHIBITORID_PLACEHOLDER}&${CATEGORY_PLACEHOLDER}&page=1&pageSize=6`,
    'lineRelatedProductsOverview': `/imc-api/v2/products/lineProducts?sc_apikey=${APIKEY_PLACEHOLDER}&lineId=${LINEID_PLACEHOLDER}&${CATEGORY_PLACEHOLDER}&page=1&pageSize=6`,
    'exhibitedOnThisFloor': `/imc-api/v1/floor-plan/exhibited?floorNumber=${FLOORNUMBER_PLACEHOLDER}&buildingName=${BUILDINGNAME_PLACEHOLDER}&count=${COUNT_PLACEHOLDER}&sc_apikey=${APIKEY_PLACEHOLDER}&${SORTORDER_PLACEHOLDER}&${PRODUCTSONLY_PLACEHOLDER}&${SHOPZIOONLY_PLACEHOLDER}`,
    //'exhibitorSearch':`/imc-api/v2/exhibitors/search?sc_apikey=${APIKEY_PLACEHOLDER}${querystring}&searchPage=${PAGEID_PLACEHOLDER}` //NOT USED YET
    'exhibitorSpecials': `/imc-api/v1/show-specials/get-by-channel?exhibitorId=${EXHIBITORID_PLACEHOLDER}&sc_apikey=${APIKEY_PLACEHOLDER}`,
    'allSpecials': `/imc-api/v1/show-specials/market?pageId=${PAGEID_PLACEHOLDER}&page=${PAGE_PLACEHOLDER}&pageSize=${PAGESIZE_PLACEHOLDER}&sc_apikey=${APIKEY_PLACEHOLDER}`,
    'eventsOverview': `/imc-api/v1/events/exhibitor?exhibitorId=${EXHIBITORID_PLACEHOLDER}&sc_apikey=${APIKEY_PLACEHOLDER}`,
    'exhibitorCount': `/imc-api/v2/search/exhibitorCount?exhibitorId=${EXHIBITORID_PLACEHOLDER}&sc_apikey=${APIKEY_PLACEHOLDER}`,
    'exhibitorDirectoryInvalidChars': `/imc-api/v2/exhibitors/invalidaz?sc_apikey=${APIKEY_PLACEHOLDER}`,
    'exhibitorDirectoryList': `/imc-api/v2/exhibitors/az?sc_apikey=${APIKEY_PLACEHOLDER}&az=${CHARACTER_PLACEHOLDER}`,
    'exhibitorCountArray': `/imc-api/v2/lines/count?sc_apikey=${APIKEY_PLACEHOLDER}&${EXHIBITORID_PLACEHOLDER}`,
}

/**
 * Exhibitors API
 */
export default class ExhibitorsAPI {
    /**
     * Constructor
     */
    constructor() {
        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         *
         */
        this.getExhibitorDetail = this.getExhibitorDetail.bind(this);
        this.getDigitalShowroomContactInfo = this.getDigitalShowroomContactInfo.bind(this);
    }
    /**
     * Gets Exhibitor's Digital Showroom Info Endpoint Url with params
     * @param {array} exhibitorId array
     * @param {string} apiId
     * @returns {string} Endpoint url to fetch data
     */
    getEndpointWithParams(replaceArray, apiId) {

        let endpointWithParams = apiEndPoints[apiId].replace(APIKEY_PLACEHOLDER, getSitecoreApiKey());
        for (var replaceKey in replaceArray) {
            endpointWithParams = endpointWithParams.replace(replaceKey, replaceArray[replaceKey]);
        }
        return `${getSitecoreApiHost()}${endpointWithParams}`;
    }

    /**
     * Gets Exhibitor's Details
     * @param {number} exhibitorId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getExhibitorDetail(exhibitorIds, pageId) {
        var multiple = (Array.isArray(exhibitorIds)) ? exhibitorIds.join("&exhibitorIds=") : exhibitorIds;

        return (
            cacheFetch.cacheFetch(this.getEndpointWithParams({ [EXHIBITORID_PLACEHOLDER]: multiple, [PAGEID_PLACEHOLDER]: pageId }, 'exhibitorDetails'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
            // .catch((err) => {
            //     // todo: Error Handling
            //     console.log('handle err here', err);
            // })
        );
    }

    /**
     * Gets Exhibitor's Details
     * @param {number} exhibitorId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getExhibitorbyShowRoomUID(exhibitorUIDs, crmId) {

        var multiple = (Array.isArray(exhibitorUIDs)) ? exhibitorUIDs.join("&uids=") : exhibitorUIDs;

        return (
            fetch(this.getEndpointWithParams({ [UID_PLACEHOLDER]: multiple, [CRM_ID]: crmId }, 'exhibitorByUID'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }

    /**
 * Gets Exhibitor's Line Details
 * @param {number} lineId array
 * @returns {Promise.<T>} Promise object that returns the Api Object result
 */
    getExhibitorLineDetail(lineId, exhibitorId) {
        return (
            cacheFetch.cacheFetch(this.getEndpointWithParams({ [LINEID_PLACEHOLDER]: lineId, [EXHIBITORID_PLACEHOLDER]: exhibitorId }, 'lineDetails'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            }).catch((err) => {
                // todo: Error Handling
                console.log('handle err here', err);
            })
        );
    }

    /**
     * Gets Exhibitor's Digital Showroom Contact Info
     * @param {number} exhibitorId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getDigitalShowroomContactInfo(exhibitorId) {
        return (
            fetch(this.getEndpointWithParams({ [EXHIBITORID_PLACEHOLDER]: exhibitorId }, 'contact'))
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }

    /**
     * Gets Exhibitor's Digital Showroom Products Overview
     * @param {number} exhibitorId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getDigitalShowroomProductsOverview(exhibitorId, pageId) {
        return (
            fetch(this.getEndpointWithParams({ [EXHIBITORID_PLACEHOLDER]: exhibitorId, [PAGEID_PLACEHOLDER]: pageId }, 'productsOverview'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }

    /**
     * Gets Exhibitor's Line Digital Showroom Products Overview
     * @param {number} lineId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getDigitalShowroomLineProductsOverview(lineId, pageId) {
        return (
            fetch(this.getEndpointWithParams({ [LINEID_PLACEHOLDER]: lineId, [PAGEID_PLACEHOLDER]: pageId }, 'lineProductsOverview'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }


    /**
     * Gets Exhibitor's Line Digital Showroom Products Overview
     * @param {number} lineId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getDigitalShowroomCatalogsOverview(exhibitorId, pageId) {
        return (
            fetch(this.getEndpointWithParams({ [EXHIBITORID_PLACEHOLDER]: exhibitorId, [PAGEID_PLACEHOLDER]: pageId }, 'catalogOverview'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }

    /**
     * Gets Exhibitor's Line Digital Showroom Products Overview
     * @param {number} lineId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
    */
    getDigitalShowroomLineCatalogsOverview(lineId, pageId) {
        return (
            fetch(this.getEndpointWithParams({ [LINEID_PLACEHOLDER]: lineId, [PAGEID_PLACEHOLDER]: pageId }, 'lineCatalogsOverview'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }


    /**
     * Gets Exhibitor's Line Digital Showroom Products Overview
     * @param {number} exhibitorId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
    */
    getDigitalShowroomLinesOverview(exhibitorId, alpha, numProd, prodOnly, shopZioOnly, pageId, term) {
        return (
            fetch(this.getEndpointWithParams(
                {
                    [EXHIBITORID_PLACEHOLDER]: exhibitorId,
                    [SORTORDER_PLACEHOLDER]: qs.stringify({ sortOrder: alpha }),
                    [PRODUCTSORDER_PLACEHOLDER]: qs.stringify({ productsOrder: numProd }),
                    [PRODUCTSONLY_PLACEHOLDER]: qs.stringify({ productsOnly: prodOnly }),
                    [SHOPZIOONLY_PLACEHOLDER]: qs.stringify({ shopZioOnly: shopZioOnly }),
                    [PAGEID_PLACEHOLDER]: pageId,
                    [TERM_PLACEHOLDER]: term,
                }, 'linesOverview'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }


    /**
     * Gets Exhibitor's Line Digital Showroom Products Overview
     * @param {number} lineId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    // getDigitalShowroomLineCatalogsOverview(lineId) {
    //     return (
    //         fetch(this.getEndpointWithParams(lineId, 'lineCatalogsOverview'))
    //             .then(response => response.json())
    //             .catch((err) => {
    //                 // todo: Error Handling
    //                 console.log('handle err here', err);
    //             })
    //     );
    // }


    /**
     * Gets Exhibitor's Digital Showroom Product Detail
     * @param {Array.<string>} productsIds array of prudcts Ids
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getDigitalShowroomProductsDetail(productsIds) {
        return (
            fetch(this.getEndpointWithParams({ [PRODUCTSIDS_PLACEHOLDER]: qs.stringify({ productUniqueIds: productsIds }) }, 'productDetail'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }

    /**
   * Gets Exhibitor's Digital Showroom Products Overview
   * @param {number} exhibitorId array
   * @returns {Promise.<T>} Promise object that returns the Api Object result
   */
    getDigitalShowroomRelatedProductsOverview(exhibitorId, categories) {
        return (
            fetch(this.getEndpointWithParams({ [EXHIBITORID_PLACEHOLDER]: exhibitorId, [CATEGORY_PLACEHOLDER]: qs.stringify({ category: categories }) }, 'relatedProductsOverview'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }

    /**
     * Gets Exhibitor's Line Digital Showroom Products Overview
     * @param {number} lineId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getDigitalShowroomLineRelatedProductsOverview(lineId, categories) {
        return (
            fetch(this.getEndpointWithParams({ [LINEID_PLACEHOLDER]: lineId, [CATEGORY_PLACEHOLDER]: qs.stringify({ category: categories }) }, 'lineRelatedProductsOverview'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }

    /**
     * Gets Exhibitors exhibiting in a floor and building
     * @param {number} buildingId, floorId
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getExhibitedOnThisFloor(buildingId, floorId, count, hasProducts, shopZioAvailable, sortType) {//&count={count}
        return (
            fetch(this.getEndpointWithParams({
                [BUILDINGNAME_PLACEHOLDER]: buildingId,
                [FLOORNUMBER_PLACEHOLDER]: floorId,
                [COUNT_PLACEHOLDER]: count,
                [SORTORDER_PLACEHOLDER]: qs.stringify({ sortType: sortType }),
                [PRODUCTSONLY_PLACEHOLDER]: qs.stringify({ productsOnly: hasProducts }),
                [SHOPZIOONLY_PLACEHOLDER]: qs.stringify({ shopZioOnly: shopZioAvailable })
            }, 'exhibitedOnThisFloor'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }


    /**
     * Gets Exhibitors exhibiting in a floor and building
     * @param {number} buildingId, floorId
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getExhibitorSpecials(exhibitorId) {//&count={count}
        return (
            fetch(this.getEndpointWithParams({ [EXHIBITORID_PLACEHOLDER]: exhibitorId }, 'exhibitorSpecials'),
                {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Channel': UserUtility.getChannelData().name,
                    }
                })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }

    getAllSpecials(pageNum, pageSize, pageId) {

        return (
            fetch(this.getEndpointWithParams(
                {
                    [PAGE_PLACEHOLDER]: pageNum,
                    [PAGESIZE_PLACEHOLDER]: pageSize,
                    [PAGEID_PLACEHOLDER]: pageId,
                }, 'allSpecials'),
                {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Channel': UserUtility.getChannelData().name,
                    }
                })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );

    }

    /**
     * Gets Exhibitor's Line Digital Showroom Products Overview
     * @param {number} lineId array
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getDigitalShowroomEventsOverview(exhibitorId) {
        return (
            fetch(this.getEndpointWithParams({ [EXHIBITORID_PLACEHOLDER]: exhibitorId }, 'eventsOverview'), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }

    getExhibitorCount(exhibitorId) {
        return fetch(this.getEndpointWithParams({ [EXHIBITORID_PLACEHOLDER]: exhibitorId }, 'exhibitorCount'), {
            method: 'get',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Channel': UserUtility.getChannelData().name,
            }
        })
            .then(response => response.json())
            .catch((err) => console.log('Count Error'))
    }

    async getInvalidCharactersAzDirectory() {
        return fetch(this.getEndpointWithParams({}, 'exhibitorDirectoryInvalidChars'), {
            method: 'get',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Channel': UserUtility.getChannelData().name,
            }
        })
            .then(response => response.json())
            .catch((err) => console.log('Invalid Character Error'))
    }

    async getExhibitorsDirectoryList(azChar) {
        return fetch(this.getEndpointWithParams({ [CHARACTER_PLACEHOLDER]: azChar }, 'exhibitorDirectoryList'), {
            method: 'get',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Channel': UserUtility.getChannelData().name,
            }
        })
            .then(response => response.json())
            .catch((err) => console.log('Exhibitor List Error'))
    }

    /**
* Gets Exhibitor's Line counts
* @param {Array.<string>} exhibitorIds array
* @returns {Promise.<T>} Promise object that returns the Api Object result
*/
    async getExhibitorCountArray(exhibitorIds) {
        return await fetch(this.getEndpointWithParams({[EXHIBITORID_PLACEHOLDER]: qs.stringify({id: exhibitorIds})}, 'exhibitorCountArray'), {
            method: 'get',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Channel': UserUtility.getChannelData().name,
            }
        })
            .then(response => response.json())
            .catch((err) => console.log('Count Error'))
    }
}

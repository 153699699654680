// Library dependencies
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component for displaying a Tooltip module
 * @returns {Element} Rendered tooltip component
 */
const Tooltip = ({ content, stableSelector, renderAsHtml, tooltipclass }) => (
    <div
        className="imc-content--inline-block imc-tooltip"
        data-xpath={`tooltipContainer${stableSelector}`}
    >
        <svg
            width="22"
            height="22"
            className="imc-tooltip__icon imc-content--margin-left-small"
            role="button"
            tabIndex={0}
            data-xpath={`tooltipIcon${stableSelector}`}
        >
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#tooltip" />
        </svg>
        <div
            className={`imc-content imc-content--bold imc-content--delta
            imc-content--alt imc-tooltip__content ${tooltipclass}`}
            data-xpath={`tooltipContent${stableSelector}`}
        >
            <div>
                {(renderAsHtml) ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content }
            </div>
        </div>
    </div>
);

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{content: *, stableSelector: *}}
 */
Tooltip.propTypes = {
    content: PropTypes.string.isRequired, // Text to be displayed on hover/click of tooltip icon
    stableSelector: PropTypes.string, // Stable Selector
    renderAsHtml: PropTypes.bool,
    tooltipclass: PropTypes.string,
};

/**
 * @property defaultProps
 * @type {{stableSelector: string}}
 */
Tooltip.defaultProps = {
    stableSelector: '',
    renderAsHtml: false,
    tooltipclass: '',
};

// Export the react component
export default Tooltip;

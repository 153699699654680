import { isString } from 'lodash';
import qs from 'query-string';

/**
 * Converts an object
 * @param {object} obj Object to convert
 * @returns {{}} Object ready for stringification
 * @private
 */
function _convertObject(obj) {
    const newObj = {};
    if (obj) {
        Object.entries(obj).forEach((entry) => {
            const [key, value] = entry;

            if (Array.isArray(value)) {
                newObj[key] = value.join('|');
            } else if (typeof value === 'object') {
                newObj[key] = _convertObject(value);
            } else {
                newObj[key] = value;
            }
        });
    }

    return newObj;
}

/**
 * Converts a query param object back to the original object format
 * @param {object} obj Object to convert
 * @returns {object} Converted object
 * @private
 */
function _deconvertObject(obj) {
    const newObj = {};

    Object.entries(obj).forEach((entry) => {
        const [key, value] = entry;
        let newValue = value;
        if (newValue) {
            if (newValue.indexOf('|') !== -1) {
                newValue = newValue.split('|');
            } else {
                newValue = [newValue];
            }
        }

        newObj[key] = newValue;
    });

    return newObj;
}

/**
 * Encodes an object into a query string. This will:
 * - Convert spaces to + symbols
 * - Convert arrays to pipe delimited
 * @param {object} obj Object to convert into a query string
 * @returns {string} Stringified object
 */
export function encode(obj, code = true, andmore = true) {
    if (!andmore) {
        Object.keys(obj).forEach((key) => {
            if (Array.isArray(obj[key])) {
                obj[key] = obj[key].map((s) => {
                    return s.replace(/&/g, '%26');
                });
            } else if (typeof obj[key] === 'string') {
                obj[key] = obj[key].replace(/&/g, '%26');
            }
        });
    }
    let str = qs.stringify(_convertObject(obj), {encode: code})
        .replace(/%20/g, ' ')
        .replace(/%3E/g, '%253E')
        .replace(/%3D/g, '=')
        .replace(/ $/, '%20');
    if (andmore) {
        str = str.replace(/&/g, '%26');
    }
    return str;
}

/**
 * Decodes a query string into an object. This will:
 * - Convert + symbols to spaces
 * - Convert pipe delimited strings to an array
 * @param {string} str Query string to convert
 * @returns {object} Decoded object
 */
export function decode(str) {
    let outObject = {};
    if (str) {
        if (str.includes('|')) {
            outObject = qs.parse(str);
            for (let key in outObject) {
                outObject[key] = [outObject[key]];
                if (outObject[key][0].includes('|') && !outObject[key][0].includes('=')) {
                    outObject[key] = outObject[key][0].split('|');
                }
            }
        } else {
            const stringConvert = str
                .replace(/%253E/g, '%3E');
            outObject = _deconvertObject(qs.parse(stringConvert));
        }
    }
    return outObject;
}
import {
    getSitecoreApiHost,
    getSitecoreApiKey
} from '../AppGlobals';

const APIKEY_PLACEHOLDER = "$$APIKEY";
const apiEndPoints = {
    'current': `/imc-api/market-planner/v1/market/current?sc_apikey=${APIKEY_PLACEHOLDER}`,
}

/**
 * Account API
 */
export default class MarketAPI {
    /**
     * Constructor
     */
    constructor() {
        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         * 
         */
        this.getCurrentMarket = this.getCurrentMarket.bind(this);
    }


    /**
 * Gets Endpoint Url
 * @param {string} apiId 
 * @returns {string} Endpoint url to fetch data
 */
    getMarketApiEndpoint(apiId) {
        return apiEndPoints[apiId];
    }

    /**
 * Gets Endpoint Url with params
 * @param {string} apiId 
 * @param {string} accountId 
 * @returns {string} Endpoint url to fetch data
 */
    getMarketApiEndpointWithParams(apiId) {
        return `${getSitecoreApiHost()}${apiEndPoints[apiId].replace(APIKEY_PLACEHOLDER,getSitecoreApiKey())}`;
    }

    /**
     * Gets Current Market
     * @param {string} 
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getCurrentMarket(channel) {
        return new Promise((resolve)=>resolve (
            fetch(this.getMarketApiEndpointWithParams('current'),
            {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'channel': channel,
                }
            }).then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        ));
    }
}
import { Mutex } from 'async-mutex';
const mutex = new Mutex();

async function cacheFetch(url, opt){
    
    let fetchPromise = await mutex.runExclusive(async () => {
        let key = url;
        if(opt.headers){
            key +="-"+JSON.stringify(opt.headers);
        }
        localStorageSpace();
        let cachedResp = getWithExpiry(key);
        if(cachedResp){
            console.log("CACHED: ", key);
            cachedResp = JSON.parse(cachedResp);
            let fetchPromise = new Promise((resolve) => {
                resolve(cachedResp)
            });
            return fetchPromise;
        }else{
            console.log("CALL: ", key);
            let fetchPromise = new Promise((resolve) => {
                fetch(url, opt).then(function(response){
                    response.json().then(data => {
                        setWithExpiry(key, JSON.stringify(data))
                        resolve(data)
                    });
                });
            });
            return fetchPromise;
        }
    });

    return fetchPromise;

}

function localStorageSpace(){
    var data = '';

    console.log('Current local storage: ');

    for(var key in window.localStorage){

        if(window.localStorage.hasOwnProperty(key)){
            data += window.localStorage[key];
        }

    }
    
   
    var usedSpace = ((data.length * 16)/(8 * 1024)).toFixed(2);
    if(usedSpace >= (5120/2)){
        console.log(data ? '\n' + 'Total space used: ' + ((data.length * 16)/(8 * 1024)).toFixed(2) + ' KB' : 'Empty (0 KB)');
        console.log(data ? 'Approx. space remaining: ' + (5120 - ((data.length * 16)/(8 * 1024)).toFixed(2)) + ' KB' : '5 MB');
        console.log("clear Local Storage");
        localStorage.clear();
        console.log(data ? '\n' + 'Total space used: ' + ((data.length * 16)/(8 * 1024)).toFixed(2) + ' KB' : 'Empty (0 KB)');
        console.log(data ? 'Approx. space remaining: ' + (5120 - ((data.length * 16)/(8 * 1024)).toFixed(2)) + ' KB' : '5 MB');
    }
};

function setWithExpiry(key, value) {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + 60000,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export default {
    cacheFetch
};
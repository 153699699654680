import React from 'react';
import { Placeholder, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

const GridColumnComponent = (props) => (
  <div className="imc-gallery__item imc-gallery__item--minheight imc-content-nav-container--column">
    <Placeholder currentTab={props.currentTab ? props.currentTab : ''} tabData={props.tabData ? props.tabData : ''} name="imc-grid-column-placeholder" rendering={props.rendering} />
  </div>
);

const _withSitecoreContext = withSitecoreContext()(
  GridColumnComponent
);

export default _withSitecoreContext;

import React from 'react';
import { Text, Image, isEditorActive } from '@sitecore-jss/sitecore-jss-react';

const ContentBreakerComponent = (props) => {
    if (!props.fields) {
        if (isEditorActive()) {
          return <h1 className="alarm">Datasource isn't set.</h1>;
        }
        return (
          <h1 className="alarm">
            Data is not provided. Contact administrators, please.
          </h1>
        );
    }
    let breakerImage="";
    if (props.fields.image) {
      breakerImage = props.fields.image.value
      breakerImage.width = null;
      breakerImage.height = null;
    }
 return(
 <div>
    <Image className="imc-content-breaker" field={breakerImage} />
  </div>
);
}
export default ContentBreakerComponent;

import MeridianAPI from "api/meridian";

/**
 *
 * @param {string} location
 * @param showroom
 * @param building
 * @param floor
 * @return {*} a Promise<placemark>
 */
export function GetPlacemark(location, showroom, building, floor){
    const api = new MeridianAPI();

    let uid = `${building}|${floor}|${showroom}`;

    if (showroom.includes("|"))
        uid = showroom;

    return api.getPlacemarksByUid(location, uid).then(r=>{
        let placemark = undefined;
         if (r.results.length>0)
             placemark = r.results[0];

            return new Promise( (resolve) => {
                resolve(placemark);
            });
    });
}

/**
 * @param {string} location meridian Location ID
 * @param {string} buildingName building Name
 * @param {string} floorNo Floor Number
 * @returns {string} a promise with the meridian FloorID for the matching floor, or null
 */

export function GetFloorID(location, buildingName, floorNo){
    //const memoGetFloors = React.useMemo(GetFloors, [GetFloors]);
    return GetFloors(location).then(floors=>{
        let floorID = GetFloorIDFromBuilding(floors, buildingName, floorNo);

        return new Promise( (resolve) => {
            resolve(floorID);
        });
    });
}

function pad2(number) {

    if (isNaN(number))
        return number;

    return (number < 10 ? '0' : '') + number
}

/**
 *
 * @param {Array} floors
 * @param {string} buildingName
 * @param {string} floorNo
 * @returns {string} FloorID of the floor from floors that matches building name and floorNo.
 */
export function GetFloorIDFromBuilding(floors, buildingName, floorNo){

    let result = floors.filter(f=>f.group_name.toLowerCase() === buildingName.toLowerCase() && f.name.toLowerCase() ==="Floor ".concat(pad2(floorNo)).toLowerCase());
    if (result.length>0)
        return result[0].id;
    else
        return null;
}

/**
 *
 * @param {string} location Meridian Location ID
 * @returns a promise with the list of Floors for the location
 */
function GetFloors(location) {
    var api = new MeridianAPI();
    console.log("calling get floors", location);

    return api.getFloors(location).then(r => {
        let floors = r.results;

        return new Promise((resolve) => {
            resolve(floors);
        });
    });
}

import React from 'react';

const Styleguide = (props) => (
  <React.Fragment>
  <header class="imc-section imc-section--padded imc-vr" role="banner"> 
      <div class="imc-heading--center">
          <svg width="291" height="92" class="imc-sg-logo">
              <title>Las Vegas Market</title>
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#logo-lvm"></use>
          </svg>
      </div>
  </header>

  <section class="imc-section imc-vr">
      <h1 class="imc-heading imc-heading--kilo imc-vr">Style Guide</h1>

      <div class="imc-content imc-content--alpha imc-vr imc-vr--divided">
          <p>This style guide can be used as a reference for the implementation of common styling properties, elements and
              modules. For a complete set of properties and modules please refer to the SASS modules within the
              '/cms/bootstrap/webfiles/src/main/resources/site/src/common/scss' directory.</p>
      </div>
  </section>
  <section class="imc-section imc-vr">
      <h1 class="imc-heading imc-heading--kilo imc-vr">Headings</h1>

      <div class="imc-content imc-content--alpha imc-vr imc-vr--divided">
          <h1>Heading 1</h1>
          <h2>Heading 1</h2>
          <h3>Heading 1</h3>
          <h4>Heading 1</h4>
          <h5>Heading 1</h5>
      </div>
  </section>

    <section class="imc-section imc-section--padded">

      <div class="imc-vr imc-vr--divided">
          <div class="imc-section imc-section--padded imc-section--alt ">
              <div class="imc-vr imc-vr--xlarge">
                  <h2 class="imc-heading imc-heading--beta imc-heading--invert">Content (alternating background color - heavy)</h2>
              </div>

              <div class="imc-content imc-content--invert">
                  <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                      molestias quas quisquam repellat veritatis!</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
              </div>
          </div>
      </div>

      <div class="imc-vr imc-vr--divided">
          <div class="imc-section imc-section--padded imc-section--alt-medium ">
              <div class="imc-vr imc-vr--xlarge">
                  <h2 class="imc-heading imc-heading--beta imc-heading--invert">Content (alternating background color - medium)</h2>
              </div>

              <div class="imc-content imc-content--invert">
                  <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                      molestias quas quisquam repellat veritatis!</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
              </div>
          </div>
      </div>

      <div class="imc-vr imc-vr--divided">
          <div class="imc-section imc-section--padded imc-section--alt-light ">
              <div class="imc-vr imc-vr--xlarge">
                  <h2 class="imc-heading imc-heading--beta imc-heading--accent">Content (alternating background color - light)</h2>
              </div>

              <div class="imc-content imc-content--accent">
                  <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                      molestias quas quisquam repellat veritatis!</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
              </div>
          </div>
      </div>

      <div class="imc-vr imc-vr--divided">
          <div class="imc-section imc-section--padded imc-section--alt-gray ">
              <div class="imc-vr imc-vr--xlarge">
                  <h2 class="imc-heading imc-heading--beta imc-heading--invert">Content (alternating background color - gray dark)</h2>
              </div>

              <div class="imc-content imc-content--invert">
                  <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                      molestias quas quisquam repellat veritatis!</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
              </div>
          </div>
      </div>

      <div class="imc-vr imc-vr--divided">
          <div class="imc-section imc-section--padded imc-section--alt-gray-medium ">
              <div class="imc-vr imc-vr--xlarge">
                  <h2 class="imc-heading imc-heading--beta imc-heading--invert">Content (alternating background color - gray medium)</h2>
              </div>

              <div class="imc-content imc-content--invert">
                  <p>Example content, default font size. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Asperiores commodi culpa dolore error excepturi, facere fugit harum illo impedit labore
                      molestias quas quisquam repellat veritatis!</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, similique?</p>
              </div>
          </div>
      </div>

      <div class="imc-content imc-vr imc-vr--divided">
          <div class="imc-vr imc-vr--xlarge">
              <h2 class="imc-heading imc-heading--beta">Links</h2>
          </div>

          <div class="imc-gallery imc-gallery--1-2">
              <div class="imc-gallery__item">
                  <a href="#" class="imc-link" title="Default link">Default link</a>
              </div>
              <div class="imc-gallery__item">
                  <a href="#" class="imc-link imc-link--caret-after" title="Link with Caret">Link with Caret</a>
              </div>
          </div>
      </div>

      <div class="imc-vr imc-vr--divided">
          <div class="imc-vr imc-vr--xlarge">
              <h2 class="imc-heading imc-heading--beta">Buttons</h2>
          </div>

          <div class="imc-content">
              <div class="imc-vr">
                  <button class="imc-button">Primary Button</button>
              </div>
              <div class="imc-vr">
                  <button class="imc-button imc-button--secondary">Secondary Button</button>
              </div>
              <div class="imc-vr">
                  <button class="imc-button imc-button--tertiary">Tertiary Button</button>
              </div>
              <div class="imc-vr">
                  <button class="imc-button imc-button--gray">Gray Button</button>
              </div>
              <div class="imc-vr">
                  <button class="imc-button imc-button--dark">Dark Button</button>
              </div>
              <div class="imc-vr">
                  <button class="imc-button imc-button--invert">Invert Button</button>
              </div>
              <div class="imc-vr">
                  <button class="imc-button imc-button--invert-gray">Invert Gray Button</button>
              </div>

              <div class="imc-vr">
                  <button class="imc-button imc-button--invert-gray imc-button--round">Invert Gray Ronded Button</button>
              </div>

              <div class="imc-vr">
                  <button class="imc-button--invert-green-small imc-button--round">Invert Gray Ronded Button</button>
              </div>

              <div class="imc-vr">
                  <button class="imc-button--invert-green-small imc-button--round imc-button--add">Invert Gray Ronded Button</button>
              </div>
          </div>
      </div>
      <div class="imc-vr imc-vr--divided">
          <div class="imc-vr imc-vr--xlarge">
              <h2 class="imc-heading imc-heading--beta">Lists</h2>
          </div>
            <br></br>
            <ul class="imc-market-planner-list"><li><div class="imc-market-planner-list_row_title">Favorites</div><div class="imc-market-planner-list_row_right_column"><span class="imc-market-planner-list_right_item"><a>Duplicate</a></span><span class="imc-market-planner-list_right_item"><a>Edit List</a></span></div></li><li><div class="imc-market-planner-list_row_title">List Name 2</div><div class="imc-market-planner-list_row_right_column"><span class="imc-market-planner-list_right_item"><a>Duplicate</a></span><span class="imc-market-planner-list_right_item"><a>Edit List</a></span></div></li></ul>
        </div>
    </section>
  </React.Fragment>
);

export default Styleguide;

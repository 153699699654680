/**
 * Returns the Full url
 * or throw error if endpoint is not available in config
 * @param {string} endPointKey endpoint key
 * @returns {string} api endpoint from config
 */
export function getRedirectionUrl(endPointKey) {
    const siteMainURL = `${window.location.origin}${window.common.siteUrlSlug}`;
    const endpoint = window.sitemapUrls && window.sitemapUrls[endPointKey];
    if (!siteMainURL || !endpoint) {
        throw new Error(`There was a problem getting the proper URL for the ${endPointKey} API`);
    }

    return `${siteMainURL}${endpoint}`;
}
/**
 * Returns the Full url
 * or throw error if endpoint is not available in config
 * @param {string} endPointKey endpoint key
 * @returns {string} api endpoint from config
 */
export function getLocalRedirectionUrl(endPointKey) {
    //const siteMainURL = `${window.location.origin}${window.common.siteUrlSlug}`;
    const endpoint = window.sitemapUrls && window.sitemapUrls[endPointKey];
    if (!endpoint) {
        throw new Error(`There was a problem getting the proper URL for the ${endPointKey} API`);
    }

    return `${endpoint}`;
}

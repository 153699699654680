import React, { Component } from 'react'

export default class DropDown extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
    */
    constructor(props) {
        super(props);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.state = {
            errorMsg: ''
        }
    }

    handleOnChange(field, fieldValue, callback) {
        var valid = true;
        var errorMessages = [];
        // custom client validation logic here
        if (field.model.required && !fieldValue) {
            valid = false;
            errorMessages.push(field.model.title + " is required");
            this.setState({
                errorMsg: field.model.title + " is required"
            })
        }
        this.setState({
            value: fieldValue,
        });
        callback(field.valueField.name, fieldValue, valid, errorMessages);
    }

    render() {
        const { fieldData } = this.props;
        const data = fieldData.field.model;
        const options = data.items;
        return (
            <div className={'imc-vr--xlarge'}>
                <div
                    className={'imc-formfield imc-dropdown imc-content'}
                >
                    <div className={'imc-vr--xsmall'}>
                        <label htmlFor={'id'}>
                            {data.title}
                            <span className="imc-content--alt-darkred">
                                {data.required ? '*' : ''}
                            </span>
                        </label>
                    </div>
                    <select className={fieldData.isValid ? '' : 'invalid'} onChange={(e) => this.handleOnChange(fieldData.field, e.target.value, fieldData.onChange)}>
                        {data.showEmptyItem &&
                            <option value="">Select</option>
                        }
                        {options.map((option, key) =>
                            <option selected={option.selected} key={key} value={option.value}>{option.text}</option>
                        )}
                    </select>
                </div>
                {this.state.errorMsg !== '' || !fieldData.isValid &&
                    <div className={'imc-vr--xsmall'}>
                        <p className={'imc-type--error'}>{data.title + " is required"}</p>
                    </div>
                }
            </div>
        )
    }
}

import SubmitButton from "../../../modules/formelements/components/SubmitButton";
import React, { useState } from "react";
import { Checkbox, TextField } from "../../../modules/formelements";
import "../styles.css";
import {
  fetchRequest,
  globalOptOutCancelUnsubscribeUrl,
  globalOptOutUnsubscribeUrl,
  submitEmailUrl
} from "../api";
import { SuccessErrorMessage } from "./SuccessErrorMessage";
import { getDictionaryValue } from "../../../utils/dictionary";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const DictionaryKeys = {
  emailFormatError: "channelPreferenceCenter_EmailFormatError",
  emailValidationError: "channelPreferenceCenter_EmailValidationError",
  enterEmail: "channelPreferenceCenter_EnterEmail",
  optOutAllChannelsText: "channelPreferenceCenter_OptOutAllChannelsText",
  submit: "channelPreferenceCenter_Submit"
};

export const RefuseEmailForm = props => {
  const {
    refuseOfEmailsLabel,
    isGlobalOptOut,
    globalOptOutListId,
    contactId,
    errorMessage,
    successMessage,
    cbSetDisabledPreferences,
    messageId,
    warningLabel
  } = props;
  const [message, setMessage] = useState({
    isSuccess: false,
    isError: false,
    message: ""
  });
  const [emailState, setEmail] = useState("");
  const [error, setError] = useState({ isError: false, message: "" });
  const [isRefuse, setRefuse] = useState(isGlobalOptOut);
 // const [submitting, setSubmitting] = useState(isGlobalOptOut);

  const [isSavedEmail, setSavedEmail] = useState(isGlobalOptOut);

  const validateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleChangeEmail = (name, value) => {
    setEmail(value);
    if (!validateEmail(value)) {
      return setError({
        isError: true,
        message: getDictionaryValue(
          DictionaryKeys.emailFormatError,
          "Incorrect email format"
        )
      });
    }
    setError({ isError: false, message: "" });
  };
  const handleRefuse = async (name, value) => {
    setRefuse(value);
    setEmail("");
    if (!value) {
      setError({
        isError: false,
        message: ""
      });
    }

    if (!value && isSavedEmail) {
      const response = await fetchRequest({
        optOutListId: globalOptOutListId,
        contactId
      })(globalOptOutCancelUnsubscribeUrl);
      if (!response || !response.ok) {
        setMessage({
          isError: true,
          isSuccess: false,
          message: errorMessage
        });
        return setRefuse(!value);
      }
      setMessage({
        isError: false,
        isSuccess: true,
        message: successMessage
      });
      cbSetDisabledPreferences(value);
      setSavedEmail(false);
    }
  };

  const handleSubmit = async () => {
    if (error.isError) {
      return;
    }
    if (!emailState) {
      return setError({
        isError: true,
        message: "This field is required"
      });
    }

    const emailResponse = await fetchRequest({ email: emailState, contactId })(
      submitEmailUrl
    );
    const data = await emailResponse.json();

    if (!emailResponse || !emailResponse.ok || (data && !data.emailValid)) {
      setMessage({
        isError: true,
        isSuccess: false,
        message:
          data && !data.emailValid
            ? getDictionaryValue(
                DictionaryKeys.emailValidationError,
                "Incorrect contact's email"
              )
            : errorMessage
      });
      cbSetDisabledPreferences(false);
      return setRefuse(true);
    }

    const url = isRefuse
        ? globalOptOutUnsubscribeUrl
        : globalOptOutCancelUnsubscribeUrl;

    const response = await fetchRequest({
      optOutListId: globalOptOutListId,
      contactId,
      messageId
    })(url);
    if (!response) {
      setMessage({
        isError: true,
        isSuccess: false,
        message: errorMessage
      });
      cbSetDisabledPreferences(false);
      return setRefuse(false);
    }
    setMessage({
      isError: false,
      isSuccess: true,
      message: successMessage
    });
    cbSetDisabledPreferences(true);
    
    setTimeout(()=>{setEmail("");setSavedEmail(true)},30000);
  };

  const renderEmailBlock = () => (
    <div className={"imc-email-block"}>
      
      <div className={"imc-email-block--inputs"}>
      <Text tag={"span"} field={{value:"Email Address"}} className={"imc-preferences-email-label"}/>
        
        <TextField
          label={""}
          type="text"
          name="email"
          placeholder={getDictionaryValue(
            DictionaryKeys.enterEmail,
            "Enter email Address"
          )}
          value={emailState}
          additionalClass={"imc-email-field"}
          updateValue={handleChangeEmail}
          tabindex={1}
        />
        <div className={"imc-refuse-submit"}>
        <SubmitButton
          labelSubmit={getDictionaryValue(DictionaryKeys.submit, "Submit")}
          additionalClass= { (error.isError  || emailState.length == 0? "imc-preferences-button-disabled":"")+ " imc-preferences-button"}
          submitClick={handleSubmit}
          isDisabled={error.isError  || emailState.length == 0}
        />
        {error.isError && (
          <div className={`${error.isError && "imc-vr--large imc-success-error-container imc-error-block"}`}>
            <span className="imc-content imc-content--error">{error.message}</span>
          </div>
        )}
        <SuccessErrorMessage status={message} />
        </div>
      </div>
      
    </div>
  );

  return (
    <section
      className={
        "imc-refuse-container imc-padding--left--large imc-padding--right--large imc-padding--top--large imc-padding--bottom--large"}>
      
      <div className={"imc-refuse-block"}>
        <Checkbox
          additionalClass="imc-type--title-3-ui imc-type--color-neutral-heavy-medium imc-padding--top--xsmall imc-unsubscribe-checkbox"
          checkboxClasses="imc-checkbox--small"
          name="unsubscribe"
          label={refuseOfEmailsLabel}
          checked={isRefuse}
          updateValue={handleRefuse}
        />
         <Text  tag='div'
                    field={{value: warningLabel}}
                    className={'imc-note imc-padding--top--xsmall'}
                />
        {isRefuse && !isSavedEmail && renderEmailBlock()}
        
      </div>
    </section>
  );
};

import {
    SET_SHOWROOM_PRODUCTS_OVERVIEW,
    SET_SHOWROOM_PRODUCTS_OVERVIEW_ERR,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {
    products: [],
    shopZioURL: '',
    productsCount: 0,
    showLoading: true,
};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function digitalShowroomProductsOverviewReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_SHOWROOM_PRODUCTS_OVERVIEW:
            return Object.assign({}, state, {
                products: action.products,
                shopZioURL: action.imcdiUrl,
                productsCount: action.productsCount,
                showLoading: true
            });
        case SET_SHOWROOM_PRODUCTS_OVERVIEW_ERR:
            return Object.assign({}, state, {
                products: initialState.products,
                shopZioURL: initialState.imcdiUrl,
                productsCount: initialState.productsCount,
                errCode: action.status || 404,
            });
        default:
            return state;
    }
}
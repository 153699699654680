import React, { Component, Children } from 'react';
import { Text, Placeholder, RichText, isEditorActive, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import "./SubscribeForm.css";
import Optinmodal from './components/Optinmodal';
// import SocialButtonsList from '../Social-Icon-List-Component';
// import Form from '../Base-Form-Component';
export const props = require('../Social-Icon-List-Component/props.json');

class index extends Component {
    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
      super(props);

      this.state = {
          showModal: false,
          errors: [],
          updatedEmailValue: '',
          showError: false,
      };
      this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
      this.renderForm = this.renderForm.bind(this);
      // this.customSubmit = this.customSubmit.bind(this);
  }


  /**
     * @method handleOpenModal
     * @description this will open the modal window
     */
    handleOpenModal(e) {
      e.preventDefault();
      const currentValue = this.state.updatedEmailValue;
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(currentValue)) {
        this.setState({ 
          showModal: true,
          showError: false, 
        });
      } else {
        this.setState({ 
          showError: true 
        });
      }
  }

  /**
   * @method handleCloseModal
   * @description this will Close the modal window
  */
  handleCloseModal() {
      this.setState({ 
        showModal: false 
      });
  }
  /**
   * @method customSubmit
   * @description this will Close the modal window
   * @param {object} fields all valid fields
   */

   /**
   * @method handleChange
   * @description this will set state of email
   */
  handleChange(e) {
    this.setState({ 
      updatedEmailValue: e.target.value
    });
}
  /**
   * Renders the initial form
   * @returns {*} intital form
   */
  renderForm() {
    const { fields, rendering } = this.props;
    return(
      <form action="" method="post" onSubmit={this.handleOpenModal}>
        <div className="imc-optin-form">
          <div className="imc-optin-form__email imc-vr--small">
            <div className="imc-formfield imc-content imc-optin-form__emailfield">
              <input onChange={this.handleChange} placeholder={fields.inputPlaceholder.value} type="text" />
            </div>
          </div>
          <div className="imc-optin-form__submit imc-vr--small">
            <input
              type="submit"
              className="imc-button imc-button--tertiary imc-optinbutton"
              value={fields.buttonText.value}
            />
          </div>
        </div>
      </form>
    )
  }


  render() {
    const { fields, rendering } = this.props;
    const { showModal, updatedEmailValue} = this.state;
    if (!fields) {
      if (isEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
      </h1>
      );
    }
    const form = <Placeholder formType={'subscribe'} currentEmail={updatedEmailValue} name="imc-subscribe-form-popup-placeholder" rendering={rendering} />;
    if(!this.props.sitecoreContext.pageEditing){
      return (
        <div className="imc-section imc-section--padded-inner imc-optin imc-content imc-content--center imc-hide-print">
          <div>
          <RichText field={fields.header} />
          <div className={'imc-vr--xlarge'}>
            <div className="imc-optin-form-wrapper">
              {this.state.showError &&
                <div class="imc-vr--large">
                  <ul class="imc-content imc-content--error" tabindex="-1">
                    <li class="">A valid email is required.</li>
                  </ul>
                </div>
              }
              {this.renderForm()}
            </div>
          </div> 
              
          </div>
          {showModal &&
              <Optinmodal form={form} handleCloseModal={this.handleCloseModal} showModal={showModal} />
          }
      </div>
      )
    } else {
      return(
        <div className="imc-section imc-section--padded-inner imc-optin imc-content imc-content--center imc-hide-print">
          <div>
            <RichText field={fields.header} />
            {this.renderForm()}
            <p style={{ color :'#ffffff'}}>Subscribe Form:</p>
            <Placeholder formType={'subscribe'} currentEmail={updatedEmailValue} name="imc-subscribe-form-popup-placeholder" rendering={rendering} />
          </div>
        </div>
      )
    }
  }
}

export default withSitecoreContext()(index);
import { Subject } from 'rxjs';

export default class SearchPubSubService {


  constructor() {
    this.messageChanged = new Subject();
    this.matchedResultsCountChanged = new Subject();
    this.multiSelectViewChanged = new Subject();
    this.multiSelectItemsChanged = new Subject();
  }

  static getInstance() {

    if (!window.searchPubSub) {
      window.searchPubSub = new SearchPubSubService();
    }
    return window.searchPubSub;
  }
  /* Message Changed */
  emitMessageChanged(value) {
    this.messageChanged.next(value);
  }
  messageChange() {
    return this.messageChanged.asObservable();
  }
  /* Matched Results Changed */
  emitMatchedResultsCountChanged(value) {
    this.matchedResultsCountChanged.next(value);
  }
  matchedResultsCountChange() {
    return this.matchedResultsCountChanged.asObservable();
  }
  /* Multi Select View Changed */
  emitMultiSelectViewChanged(value) {
    this.multiSelectViewChanged.next(value);
  }
  multiSelectViewChange() {
    return this.multiSelectViewChanged.asObservable();
  }
  /* Multi Select Items Changed */
  emitMultiSelectItemsChanged(value) {
    this.multiSelectItemsChanged.next(value);
  }
  multiSelectItemsChange() {
    return this.multiSelectItemsChanged.asObservable();
  }
}

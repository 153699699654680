/**
 * Returns numbers/alpha only when passing in the string
 * @param {string} str String to convert
 * @param {string} zipCodeTypes contain country code
 * @returns {string} Returned alphanumeric string
 */
export function checkValidZipCode(str, zipCodeTypes) {
    let zipCodeRegex = /(.*?)/;
    if (zipCodeTypes === '307') {             // USA
        zipCodeRegex = /^\d{5,10}$|^(\d{5})-(\d{4})$/;
    }
    if (zipCodeTypes === '116') {             // Canada
        zipCodeRegex = /^[A-Z][0-9][A-Z]\s[0-9][A-Z][0-9]$/g;
    }
    return zipCodeRegex.test(str);          // other states
}

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

import ArrowButton from './ArrowButton';

const ALL_CHARACTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '#'];

function AlphabetNavigation(props) {
  const renderAlphabet = () => {
    return ALL_CHARACTERS.map((char, index) => {
      const invalidIncluded = props.invalidCharacters.includes(char);
      const isSelected = char === props.selectedCharacter;
      return <div
        key={`char ${index}`}
        className={classNames('alpha-nav--char', {
          'alpha-nav--char-disabled': invalidIncluded,
          'alpha-nav--char-enabled': !invalidIncluded,
          'alpha-nav--char-selected': isSelected,
        })}
        onClick={!invalidIncluded ? () => props.onChangeCharacter(char) : null}
      >
        {char}
      </div>
    })
  }

  const findNextViableChar = (direction) => {
    if (props.loading) return

    const movementValue = direction === 'forward' ? 1 : -1;
    const currentCharIndex = ALL_CHARACTERS.findIndex((char) => char === props.selectedCharacter);
    let nextCharIndex = currentCharIndex + movementValue;
    let found = false;

    while (!found) {
      if (nextCharIndex > ALL_CHARACTERS.length - 1) {
        nextCharIndex = 0;
      }
      if (nextCharIndex < 0) {
        nextCharIndex = ALL_CHARACTERS.length - 1;
      }

      if (props.invalidCharacters.includes(ALL_CHARACTERS[nextCharIndex])) {
        nextCharIndex = nextCharIndex + movementValue;
      } else {
        found = true;
      }
    }
    props.onChangeCharacter(ALL_CHARACTERS[nextCharIndex]);
  }

  return (
    <div className='alpha-nav--container'>
      <ArrowButton back onClickButton={findNextViableChar} />
      <div className='alpha-nav--letter-container'>
        {renderAlphabet()}
      </div>
      <ArrowButton forward onClickButton={findNextViableChar} />
    </div>
  )
}

AlphabetNavigation.propTypes = {
  invalidCharacters: PropTypes.array.isRequired,
  selectedCharacter: PropTypes.string.isRequired,
  onChangeCharacter: PropTypes.func.isRequired,
}

export default React.memo(AlphabetNavigation);
import {
    SET_SHOWROOM_EVENTS_OVERVIEW,
    SET_SHOWROOM_EVENTS_OVERVIEW_ERR,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {
    events: [],
    // shopZioURL: '',
    eventsCount: 0,
    showLoading: true,
};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function digitalShowroomEventsOverviewReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_SHOWROOM_EVENTS_OVERVIEW:
            return Object.assign({}, state, {
                events: action.events,
                // shopZioURL: action.imcdiUrl,
                eventsCount: action.eventsCount,
                showLoading: true
            });
        case SET_SHOWROOM_EVENTS_OVERVIEW_ERR:
            return Object.assign({}, state, {
                events: initialState.events,
                // shopZioURL: initialState.imcdiUrl,
                eventsCount: initialState.eventsCount,
                errCode: action.status || 404,
            });
        default:
            return state;
    }
}
/**
 * Set Filters Relevant to exhibitors & products
 * * @type {string}
 */
export const SET_PRODUCT_FILTERS = 'SET_PRODUCT_FILTERS';

/**
 * Set Filters Relevant to exhibitors & products
 * * @type {string}
 */
export const SET_PRODUCT_FILTERS_ERR = 'SET_PRODUCT_FILTERS_ERR';

/**
 * Action to get filters for exhibitors & products
 * * @type {string}
 */
export const GET_PRODUCT_FILTERS = 'GET_PRODUCT_FILTERS';

/**
 * Action to set sort options for products
 * @type {string}
 */
export const SET_PRODUCT_SORT = 'SET_PRODUCT_SORT';

export const SET_FILTER_KEY = 'SET_FILTER_KEY';


import React, { Component } from 'react'
// import { SingleLineText, RadioField, DropDown, CheckBox, Button, TextField } from './Form-Fields';

export default class Section extends Component {
    render() {
        const { fieldData } = this.props;
        const cssClass = fieldData.field.model.cssClass;
        const field = fieldData.field, fieldFactory = fieldData.fieldFactory;
        if (field.fields !== undefined) {
            return(
                <div className={cssClass}>
                    {field.fields.map(fieldFactory)}
                </div>
            )
        } else {
            return (
                <div className={cssClass}></div>
            )
        }
        
    }
}

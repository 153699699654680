import React from 'react';
import RelatedContentAPI from 'api/search/relatedcontent';
import { Image, isEditorActive } from '@sitecore-jss/sitecore-jss-react';

class RecommendedContent extends React.Component {
	/**
	* @method contructor
	* @description Lifecycle method
	* @param {object} props incoming props
	*/
	constructor(props) {
		super(props);
		if (!props) {
	        if (isEditorActive()) {
	            return <h1 className="alarm">Datasource isn't set.</h1>;
	        }
	        return (
	            <h1 className="alarm">
	                Data is not provided. Contact administrators, please.
	          </h1>
	        );
	    }
		this.state = { data :[], firstElement:null };
		
		this._relatedContent = new RelatedContentAPI();
		this.getData();
	}

	getData() {
		var tags = [];
		for (var i = 0; i < this.props.fields.tags.length; i++) {
			tags.push(this.props.fields.tags[i].fields.name.value);
		}
		this._relatedContent.getRelatedContent(tags)
			.then(response => {
				this.setState({ data: response })
				this.setState({ firstElement: this.state.data.shift() })
			})
			
			.catch(err => console.error(this.props.url, err.toString()))
	}

	render() {
		for (var i = 0; i < this.state.data.length; i++) {
			var img = {};
			img.value = { src: this.state.data[i].ImageSrc.replace("/sitecore/shell",""), alt: this.state.data[i].ImageAlt };
			this.state.data[i].img = img;
		}

		if(this.state.firstElement==null){
			return null;
		} else {
			var img = {};
			img.value = { src: this.state.firstElement.ImageSrc.replace("/sitecore/shell",""), alt: this.state.firstElement.ImageAlt };
			this.state.firstElement.img = img;
		}

		let smallSection;

		if(this.state.data.length<2){
			smallSection = (
				<div className="imc-recommendedcontent-firstitem  ">
					{this.state.data.map((item, index) => (
	                    <a className="imc-vr--small imc-link" href={item.URL} title={item.Title}>
	                        <Image className="imc-recommendedcontent-firstitem__image" field={item.img} />
	                        <div className="imc-recommendedcontent-firstitem__text">
	                            <h3 className="imc-heading imc-heading--alpha">
	                            	{item.Title}
	                            </h3>
	                            <p className="imc-link imc-link--caret-after imc-link--alt-darkred">Read More</p>
	                        </div>
	                    </a>
                    ))}
                </div>
			)
		}else{
			smallSection = (
				<div className="imc-recommendedcontent-smallitems">
					{this.state.data.map((item, index) => (
						<a key={index} className="imc-vr--small imc-link" href={item.URL} title={item.Title}>
                            <div className="imc-recommendedcontent-smallitems__row imc-vr--small">
                                <Image className="imc-recommendedcontent-smallitems__image" field={item.img} />
                                <div className="imc-recommendedcontent-smallitems__text imc-content imc-content--alt imc-content--alpha">
                                    {item.Title}
                                </div>
                            </div>
                        </a>
					))}
                </div>
			)
		}

		return(
			<section className="imc-section imc-section--padded imc-section--padded-inner-mobile imc-content ">
	            <div className="imc-vr--large">
	                <h2 className="imc-heading imc-heading--mega">{this.props.fields.title.value}</h2>
	            </div>

	            <div className="imc-recommendedcontent item-count-5">
	                <div className="imc-recommendedcontent-firstitem  ">
	                    <a className="imc-vr--small imc-link" href={this.state.firstElement.URL} title={this.state.firstElement.Title}>
	                        <Image className="imc-recommendedcontent-firstitem__image" field={this.state.firstElement.img} />
	                        <div className="imc-recommendedcontent-firstitem__text">
	                            <h3 className="imc-heading imc-heading--alpha">
	                            	{this.state.firstElement.Title}
	                            </h3>
	                            <p className="imc-link imc-link--caret-after imc-link--alt-darkred">Read More</p>
	                        </div>
	                    </a>
	                </div>

	                {smallSection}
					
	            </div>
	        </section>
		);
	}
}

export default RecommendedContent;
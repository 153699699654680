// Dependencies
import { combineReducers } from 'redux';

// Reducers
import download from './downloadReducer';
import saveSearch from './saveSearchReducer';
import saveSearchModal from './saveSearchModalReducer';

/**
 * Combines all search reducers
 * @type {Reducer<any>}
 */
export default combineReducers({
    download,
    saveSearch,
    saveSearchModal,
});

import React from 'react';
import FiltersAPI from 'api/search/filters';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import FilterSearchByLetter from '../Filter-Search-By-Letter';
import DefineSearchIntent from '../Define-Search-Intent';
import SortSearchResults from '../Sort-Search-Results';
import FilterOptions from '../Filter-Options';

class FilterBarComponent extends React.Component {
	/**
	* @method contructor
	* @description Lifecycle method
	* @param {object} props incoming props
	*/
	constructor(props) {
		super(props);
		if (!props) {
	        if (isEditorActive()) {
	            return <h1 className="alarm">Datasource isn't set.</h1>;
	        }
	        return (
	            <h1 className="alarm">
	                Data is not provided. Contact administrators, please.
	          </h1>
	        );
	    }
	}

	render() {
		return(
			<div className="imc-searchactionbar">
				<div className="imc-searchactionbar__mobileactions">
					<DefineSearchIntent />
					<SortSearchResults  />
					<FilterSearchByLetter />
					<FilterOptions />
				</div>
			</div>
		);
	}
}

export default FilterBarComponent;
import {
    SET_FLOOR_ID, SET_MAP_DATA, SHOW_EX_DETAILS_UID, CLOSE_EX_MODAL
} from '../actions/actionTypes';

const initialState = {
    floorId: undefined,
    floors: undefined,
    map: undefined
};


export default function mapReducer(state = initialState, action) {
    switch (action.type) {
    
    case SET_MAP_DATA:
        return Object.assign({}, state, {
            floorId: action.floorId,
            floors: action.floors,
            map: action.map
        });
    case SET_FLOOR_ID:
        return Object.assign({}, state, {
           floorId: action.floorId,
        });
    case SHOW_EX_DETAILS_UID:
            return Object.assign({}, state, {
               selectedEx: action.selectedEx,
               showExModal: true
            });
    case CLOSE_EX_MODAL:
        return Object.assign({}, state, {
            selectedEx: undefined,
            showExModal: false
        });
    default:  
        return state;
    }
}

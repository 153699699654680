import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Calendar as ReactCalendar } from 'react-calendar';

export default class CalendarComponent extends Component {
  static propTypes = {
    events: PropTypes.array,
    formatDate: PropTypes.func,
    onClickDay: PropTypes.func,
    onChange: PropTypes.func,
    onResetDate: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      activeStartDate: new Date(),
      currentDate: null,
    }

    this.onClickDay = this.onClickDay.bind(this);
    this.returnValidMarketDate = this.returnValidMarketDate.bind(this);
    this.resetCalendar = this.resetCalendar.bind(this);
  }

  returnValidMarketDate(date) {
    const formattedDate = this.props.formatDate(date);
    if (!this.props.events.some((eventItem) => eventItem.eventDate === formattedDate)) {
      return 'react-calendar--no-event'
    }
  }

  resetCalendar() {
    this.setState({ activeStartDate: new Date(), currentDate: null })
    this.props.onResetDate()
  }

  onClickDay(date) {
    const { formatDate } = this.props;
    this.setState({ currentDate: date });
    this.props.onClickDay(formatDate(date))
  }

  render() {
    const { activeStartDate } = this.state;

    return (
      <div className='calendar-container'>
        <div className='calendar-header'>
          <p className='calendar-header--title'>Select Date</p>
          <p
            onClick={this.resetCalendar}
            className='calendar-header--reset'>Reset</p>
        </div>
        <ReactCalendar
          activeStartDate={activeStartDate}
          value={this.state.currentDate}
          calendarType="US"
          onClickDay={this.onClickDay}
          onChange={this.props.onChange}
          onActiveStartDateChange={({ activeStartDate }) => { this.setState({ activeStartDate }) }}
          tileClassName={({ date }) => this.returnValidMarketDate(date)}
        />
      </div>
    )
  }
}
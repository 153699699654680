import { decode } from 'utils/querystring';
import { getAPIEndpoint } from 'utils/getapiendpoint';
import { filterquerymodifier } from 'utils/filterquerymodifier';
import { serialize } from 'utils/serialize';
import AuthFetch from 'utils/authfetch';
import qs from 'query-string';
import UserUtility from '../../utils/userutility';
import { getSitecoreApiHost, getSitecoreApiKey}  from '../../AppGlobals';
const _authFetch = new AuthFetch();


/**
 * Download Search API
 */
export default class SaveSearchAPI {
    /**
     * Constructor
     */
    constructor() {
        this.saveSearch = this.saveSearch.bind(this);

        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         */
        this._apiKey = 'apiSaveSearchResults';
    }

    /**
     * Saves search results
     * @param {string} searchNameIn Type of search being saved
     * @param {string} invokedLocation location from where saveSearch is called
     * @returns {Promise.<T>} Promise object that returns the SaveSearchAPI results
     */
    saveSearch(searchNameIn, invokedLocation) {
        let query = '';
        const queryObject = qs.parse(typeof window != "undefined" ? window.location.search : null);
        const { q, exactmatch, exhibitors, type, events, articles, information } = queryObject;
        const exhibitorsQueryObject = decode(exhibitors) || {};
        const eventsQueryObject = decode(events) || {};
        const articlesQueryObject = decode(articles) || {};
        const informationQueryObject = decode(information) || {};
        const writableQueryObject = {
            
        };
        const userInfo = UserUtility.getUserData();
        if (q) {
            writableQueryObject.q = q;
        }
        if (exactmatch) {
            writableQueryObject.exactmatch = exactmatch;
        }
        if (type) {
            writableQueryObject.type = type;
        }
        if(information)
            writableQueryObject.information = informationQueryObject;      
        if(events)
            writableQueryObject.events = eventsQueryObject;
        if(articles)
             writableQueryObject.articles = articlesQueryObject;
        if(exhibitors)
            writableQueryObject.exhibitors = exhibitorsQueryObject;
       
        if (writableQueryObject) {
            query = filterquerymodifier(serialize(writableQueryObject));
        }
        const outObject = {
            searchTerm: query,
            searchName: searchNameIn,
            accountId: userInfo.accountId,
            organizationId: 0, //no longer needed
            searchContext: invokedLocation,
        };
        const url = getAPIEndpoint(this._apiKey, true) +`?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url, {
                method: 'post',
                cache: 'no-cache',
                body: JSON.stringify(outObject),
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'channel': UserUtility.getChannelData().name,
                }})
            .then(response => response.json())
            .catch((err) => {
                // todo: Error Handling
                console.log('handle err here', err);
            })
        );

        /*return _authFetch.authFetch(getAPIEndpoint(this._apiKey, true), {
            method: 'post',
            cache: 'no-cache',
            body: JSON.stringify(outObject),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
        }).then(response => response.json());*/
    }
}

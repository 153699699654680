//import TagManager from 'react-gtm-module';
import ProductImpressions from './eCommerce/products/productImpressions';
import ProductClicks from './eCommerce/products/productClicks';
import PageTracking from './eCommerce/pageTracking';
import ProductDetailView from './eCommerce/products/productDetailView';
import ProductAddToCart from './eCommerce/products/productAddToCart';
import ProductRemovefromCart from './eCommerce/products/productRemoveFromCart';
import PromotionImpressions from './eCommerce/promotions/promotionImpressions';
import PromotionClick from './eCommerce/promotions/promotionClick';
import InteractionEvent from './interaction/interactionEvent';
import EntityEvent from './interaction/entityEvent';
import TagManager from 'react-gtm-module';

//const gtmId = 'GTM-T3VH92K';

/**
    * @typedef {Object} Product
    * @property {string} productTitle The name of the product (e.g. Android T-Shirt). *Either this field or id must be set.
    * @property {string} uniqueId The product ID or SKU (e.g. P67890). *Either this field or name must be set.
    * @property {string} lineName The brand associated with the product
    * @property {string[]} productCategoryTags The categories to which the product belongs (e.g. Apparel). Array all categories the product belongs to.
    * @property {string[]} productCategories The categories to which the product belongs (e.g. Apparel). Array all categories the product belongs to.
    * @property {string} exhibitorName Exhibitor name that listed the product
    * @property {string} exhibitorId Exhibitor id that listed the product
    * @property {string} shopZioUrl  ecommerce url (shopzio, etc)
*/


/**
     * @typedef {Object} ShowSpecial
     * @property {string} showSpecialsId 
     * @property {string} marketId 
     * @property {string} marketName
     * @property {string} channelId
     * @property {string} exhibitorId
     * @property {string} exhibitorName
     * @property {string} lineId
     * @property {string} lineName
     * @property {string} description
*/


class ImcDataLayer {
    static isInitialized = false;

    constructor() {
        //TagManager.initialize({gtmId: gtmId});
        this.promotionsImpressions = new PromotionImpressions();
        this.promotionClick = new PromotionClick();
        this.productImpressions = new ProductImpressions();
        this.productClicks = new ProductClicks();
        this.productAddToCart = new ProductAddToCart()
        this.productRemovefromCart = new ProductRemovefromCart()
        this.pageTracking = new PageTracking();
        this.productDetailView = new ProductDetailView();
    }

    static Initialize({ gtm_auth, gtm_preview, container_id }) {

        if (!!ImcDataLayer.isInitialized)
            return;

        let tagManagerArgs = { //default values qa
            gtmId: 'GTM-T3VH92K',
            auth: 'e3958TyL6HfCQMTf9hsZGg',
            preview: 'env-336'
        }

        if (!!gtm_auth) {
            tagManagerArgs.gtmId = container_id;
            tagManagerArgs.auth = gtm_auth;
            tagManagerArgs.preview = gtm_preview;
        }
        else {
            console.warn("GTM settings missing, using default ones.");
        }

        //TODO: set the right tag manager args according to environment
        TagManager.initialize(tagManagerArgs);
        ImcDataLayer.isInitialized = true;
    }

    /**
     * Adds a collection of products to productImpressions data layer and push
     * @param {Product[]} products 
     */
    static PushProductImpressions(products) {
        try {
            //datalayer prodImpressions
            const dl = new ImcDataLayer();
            if (!!products)
                products.forEach(p => {
                    const idx = products.indexOf(p);
                    dl.productImpressions.add(p.productTitle, p.uniqueId, p.lineName, p.productCategoryTags, p.exhibitorName, p.exhibitorId, !!p.shopZioUrl, document.location.pathname, idx);
                });
            dl.productImpressions.push();
        } catch (error) {
            console.error("Error pushing GTM PushProductImpressions", error);
        }
    }

    /**
    * Adds a product to productDetailView data layer and push
    * @param {Product} p 
    */
    static PushProductDetail(p) {
        try {
            const dl = new ImcDataLayer();
            dl.productDetailView.add(p.productTitle, p.uniqueId, p.lineName, p.productCategories, p.exhibitorName, p.exhibitorId, !!p.shopZioUrl, 'Product Detail', 0);
            dl.productDetailView.push();
        } catch (error) {
            console.error("Error pushing GTM PushProductDetail", error);
        }
    }

    /**
    * Adds a product to productClick data layer and push
    * @param {Product} p 
    */
    static PushProductClick(p) {
        try {
            const dl = new ImcDataLayer();
            dl.productClicks.add(p.productTitle, p.uniqueId, p.lineName, p.productCategories, p.exhibitorName, p.exhibitorId, !!p.shopZioUrl, null, 0);
            dl.productClicks.setPage(document.location.pathname)
            dl.productClicks.push();
        } catch (error) {
            console.error("Error pushing GTM PushProductClick", error);
        }
    }

    /**
    * Adds a product to productAddToCart data layer and push
    * @param {Product[]} products 
    */
    static PushAddToCart(products) {
        try {
            const dl = new ImcDataLayer();
            products.forEach(p => {
                dl.productAddToCart.add(p.productTitle, p.uniqueId, p.lineName, p.productCategories, p.exhibitorName, p.exhibitorId, !!p.shopZioUrl, null, 0, 1);
            });

            dl.productAddToCart.push();
        } catch (error) {
            console.error("Error pushing GTM PushAddToCart", error);
        }
    }

    /**
    * Adds a product to productAddToCart data layer and push
    * @param {Product[]} products 
    */
    static PushRemoveFromCart(products) {
        try {
            const dl = new ImcDataLayer();
            products.forEach(p => {
                dl.productRemovefromCart.add(p.productTitle, p.uniqueId, p.lineName, p.productCategories, p.exhibitorName, p.exhibitorId, !!p.shopZioUrl, null, 0, 1);
            });

            dl.productRemovefromCart.push();
        } catch (error) {
            console.error("Error pushing GTM PushRemoveFromCart", error);
        }
    }


    /**
    * Adds promotions to PromotionsImpression data layer and push
    * @param {ShowSpecial[]} promotions
    */
    static PushPromotionImpressions(promotions) {
        try {
            //datalayer PromImpressions
            const dl = new ImcDataLayer();
            if (!!promotions)
                promotions.forEach(p => {
                    dl.promotionsImpressions.add("", p.showSpecialsId, p.description, document.location.pathname); //name, id, creative, position
                });
            dl.promotionsImpressions.push();
        } catch (error) {
            console.error("Error pushing GTM PushProductImpressions", error);
        }
    }

    /**
    * Adds a product to productClick data layer and push
    * @param {ShowSpecial} p 
    */
    static PushPromotionClick(p) {
        try {
            const dl = new ImcDataLayer();
            dl.promotionClick.add("", p.showSpecialsId, p.description, document.location.pathname);
            dl.promotionClick.push();
        } catch (error) {
            console.error("Error pushing GTM PushPromotionClick", error);
        }
    }

    /**
     * Pushes a MP interaction event
     * @param {string} action 
     * @param {string} label 
     */
    static pushMPInteraction(action, label) {
        try {
            InteractionEvent.pushMarketPlannerEvent(action, label);
        } catch (error) {
            console.error("Error pushing GTM pushMPInteraction", error);
        }
    }

    /**
     * Pushes a MP interaction event
     * @param {string} action 
     * @param {string} label 
     */
    static pushMPItemInteraction(action, item) {
        try {
            const flat = ((!!item.itemContents) && item.itemContents.length == 1) ? { itemId: item.itemContents[0].itemId, itemType: item.itemContents[0].itemType, itemName: item.itemContents[0].contentName } : { itemId: item.itemId, itemType: item.itemType, itemName: item.itemName || item.label };
            action = `${action}: ${flat.itemType}`;
            const label = flat.itemName;
            InteractionEvent.pushMarketPlannerEvent(action, label);
        } catch (error) {
            console.error("Error pushing GTM pushMPInteraction", error);
        }
    }

    /**
     * Pushes a generic interaction event
     * @param {string} category 
     * @param {string} action 
     * @param {string} label 
     */
    static pushInteractionEvent(category, action, label) {
        console.log("Push", category, action, label)
        try {
            InteractionEvent.pushEvent(category, action, label);
        } catch (error) {
            console.error("Error pushing GTM pushInteractionEvent", error);
        }
    }

    /**
      * add an exhibitorView event
      * on exhibitor pages only, can be ommited elsewhere
      * @param {string} name 
      * @param {string} id 
      * @param {string[]} categories
      */
    static pushExhibitorViewEvent(name, id, categories) {
        try {
            const exhibitor = {
                id: id,
                name: name,
                categories: Array.isArray(categories) ? categories.join(',') : ""
            };

            EntityEvent.push("exhibitorView", "exhibitor", exhibitor);
        } catch (error) {
            console.error("Error pushing GTM pushExhibitorViewEvent", error);
        }
    }

    /**
    * push an articleViewevent
    * on Articles pages only, canbe ommited elsewhere
    * @param {string} name 
    * @param {string} id 
    * @param {string} author
    * @param {string} date
    */
    static pushArticleViewEvent(name, id, author, date) {
        try {
            const article = {
                id: id,
                name: name,
                author: author,
                date: date
            };

            EntityEvent.push("artcielView", "article", article);
        } catch (error) {
            console.error("Error pushing GTM pushArticleViewEvent", error);
        }
    }

    /**
     * push an eventView event
     * on events pages only, canbe ommited elsewhere
     * @param {string} name 
     * @param {string} id 
     * @param {string} type
     * @param {string} date
     * @param {string} sponsor
     * @param {string[]} tags
     */
    static pushEventViewEvent(name, id, type, date, sponsor, tags) {
        try {
            const event = {
                id: id,
                type: type,
                tags: Array.isArray(tags) ? tags.join(',') : '',
                name: name,
                sponsor: sponsor,
                date: date
            };

            EntityEvent.push("eventView", "event_", event);
        } catch (error) {
            console.error("Error pushing GTM pushEventViewEvent", error);
        }
    }
}

export default ImcDataLayer;
import React, { useState, useEffect, useRef } from 'react';
import Moment from 'react-moment';
import Link from '../Link';
import { useDispatch, useSelector } from 'react-redux';
import { compareShowrooms } from 'modules/marketplan/helpers/helpers';
import { removeFromPlan } from '../../modules/marketplan/actions/actions';
import { LineInterface } from 'modules/marketplan/reducers/interface';
import SvgIcon from 'modules/svgicon';

interface ShowroomProps {
    showroom: any;
    index: number;
    toggleDrawer?: any;

}

interface ConditionalWrapperProps {
    link?: string;
    children: React.ReactNode;
}

const ShowroomListItem: React.FC<ShowroomProps> = ({showroom, index, toggleDrawer}) => {
    
    const [showRemoveMenu, setRemoveMenu] = useState<boolean>(false);
    const [showLines, setLines] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<any>(null);
    const [isRemoved, setRemoved] = useState<any>(false);
    const itemRef = useRef<HTMLLIElement>(null);
    const [itemHeight, setHeight] = useState<number | null>(null);
    const removeRef = useRef<HTMLDivElement>(null);
    let lines: any[] = showroom?.lines ? [...showroom.lines] : [];
    // if (index == 0) {
    //     lines = [
    //         {
    //             lineId: "1",
    //             lineGuid: "bfb27700-8d68-0d32-5a0b-13e8b3cf7a9b",
    //             name: "Duke Cannon"
    //         },
    //         {
    //             lineId: "1",
    //             lineGuid: "960c3259-509b-df6e-c4e5-b57c29ca1c06",
    //             name: "Pavilion Gift Company"
    //         },
    //         {
    //             lineId: "1",
    //             lineGuid: "f60d445c-2b58-cab5-153e-bbdb94029998",
    //             name: "Napa Home & Garden"
    //         }
    //     ]
    // }
    const dispatch = useDispatch<any>();
    const plan = useSelector((state: any) => state.marketPlanReducer);

    const toggleMenu = (e: any) => {
        e.preventDefault();
        setRemoveMenu(!showRemoveMenu);
    };

    const removeItem = (e: any) => {
        e.preventDefault();
        dispatch(removeFromPlan(showroom, plan));
        toggleMenu(e);
    };

    const toggleLines = (e: any) => {
        e.preventDefault();
        setLines(!showLines);
    };

    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        if (removeRef.current && !removeRef.current.contains(e.target as Node)) {
            setRemoveMenu(false);
        }
    };

    const handleItemClick = (event: MouseEvent, status: boolean) => {
        // let click with CTRL / SHIFT / META (for OSX) open in new Tab / Window / tab
        if (!event.ctrlKey && !event.shiftKey && !event.metaKey) {
            toggleDrawer(event, status);
        }
    };

    const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ children }) => {
        if (showroom.exhibitorId) {
            return <Link
                href={`/exhibitor/${ showroom.exhibitorId }`}
                className={`top-nav-item-link top-nav-plan-link ${showRemoveMenu ? `inactive` : ``}`}
                onClick={(event?: any) => handleItemClick(event, false)}
            >{children}</Link>;
        } else {
            return <div
                className={`top-nav-item-link top-nav-plan-link ${showRemoveMenu ? `inactive` : ``}`}
            >{children}</div>;
        }
    };
    
    // Add event listener to handle clicks outside the dropdown menu
    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    useEffect(() => {
        let loading = false;
        let removed = true;
        plan.loading.forEach((load: any) => {
            if (compareShowrooms(load, showroom)) {
                loading = true;
            }
        });
        plan.showrooms.forEach((planRoom: any) => {
            if (compareShowrooms(planRoom, showroom)) {
                removed = false;
            }
        });
        if (removed == true) {
            const element = itemRef.current as HTMLLIElement;
            if (element && !isRemoved) {
                setHeight(element.clientHeight)
            };
            setRemoved(true);
            setTimeout(() => {
                setHeight(0);
            }, 500);
        };
        if (loading != isLoading) setLoading(loading);
    }, [plan]);

    let lastVisited: Date = new Date(showroom.lastVisited);

    return (
        <li ref={itemRef} className={`${isLoading ? `top-nav-plan-removing` : ``}${isRemoved ? ` removing` : ``} ${showRemoveMenu ? ` inactive` : ``}`} style={itemHeight !== null ? { maxHeight: `${itemHeight}px` } : undefined} key={index}>
            <ConditionalWrapper>
                <div className='top-nav-item-heading-wrapper imc-gallery--align-flex-center'>
                    <div>
                        <div className="top-nav-item-heading top-nav-plan-heading">{ showroom.showroomName }</div>
                        {showroom.shownBy && <div className="top-nav-item-subheading">Shown By { showroom.shownBy }</div>}
                        <div className={`top-nav-item-subheading ${!showroom.lastVisited ? 'top-nav-darkestgray' : ''}`}>
                            {showroom.lastVisited && <span>Last Visit <Moment format={`${`M/D/YYYY`}`}>{lastVisited}</Moment></span>}
                            {!showroom.lastVisited && <span>Not Visisted</span>}
                            <>|</>
                            <span>{ showroom.photosCount } Photos</span>
                            {showroom.note && <>
                                <>|</>
                                <SvgIcon width={12} height={12} className={`top-nav-user-icon svg-black`} xlinkHref={"#juniper-orders"} />
                                </>
                            }
                        </div>
                    </div>
                    {!showroom.lastVisited && <div className="top-nav-item-visit">
                        <button
                            disabled={isLoading}
                            onClick={(event?: any) => {
                                event.stopPropagation();
                                toggleMenu(event);
                            }} 
                            className={`top-nav-remove-button imc-button imc-button--atmarket-inverted imc-button--xsmall-xy imc-button--no-border imc-button--border-full imc-content--normal${(plan.isFetching) ? ' imc-button--atmarket--fetching' : '' }`}
                        >
                            {isLoading && <SvgIcon width={12} height={12} className={`svg-rotating svg-orange imc-section--margin-right-xsmall`} useClassName={`svg-orange`} xlinkHref={"#juniper-refresh"} />}
                            <span>{isLoading ? `Removing` : `Remove`}</span>
                        </button>
                    </div>}
                    {false && <div className="top-nav-item-visit">
                        <SvgIcon width={12} height={12} className={`top-nav-user-icon svg-black ${(showroom.exhibitorId) ? `` : `imc-hide`}`} xlinkHref={"#juniper-right-chevron"} />
                    </div>}
                </div>
                {lines?.length > 0 && <div className={`top-nav-plan-brands-button-wrapper imc-content--center-margin`}>
                    <div className={`top-nav-plan-brands-button imc-button imc-button--atmarket-inverted imc-button--xsmall-xy imc-button--no-border imc-button--border-full imc-content--normal ${(showLines) ? `active` : ``}`}
                        // onClick={(event?: any) => {
                        //     event.stopPropagation();
                        //     toggleLines(event);
                        // }}
                    >{`${lines.length} Showroom Brand${(lines.length > 1) ? `s` : ``}`}</div>
                </div>}
                {lines?.length > 0 && <div className={`top-nav-plan-brands ${(showLines) ? `active` : ``}`}>
                    <div className={`top-nav-plan-brands-list`}>
                        {lines.map((line: LineInterface, index: number) => {
                            if (line?.lineGuid !== null) {
                                return (
                                    <Link
                                        href={`/exhibitor/${ showroom.exhibitorId }/line/${ line.lineGuid }`}
                                        key={index}
                                        className={`top-nav-plan-brand`}
                                        onClick={(event?: any) => handleItemClick(event, false)}
                                    >
                                        {line.name}
                                    </Link>
                                );
                            } else {
                                return (
                                    <div
                                        key={index}
                                        className={`top-nav-plan-brand`}
                                    >
                                        {line.name}
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>}
            </ConditionalWrapper>
            {!showroom.lastVisited && <div className={`top-nav-item-remove ${showRemoveMenu ? `active` : ``}`} ref={removeRef}>
                <div className="top-nav-item-link top-nav-plan-link">
                    <div className="top-nav-item-heading-wrapper imc-gallery--align-flex-center">
                        <div>
                            <div className="top-nav-item-heading imc-type--color-neutral-heaviest imc-section--margin-right-small">Remove { showroom.showroomName } From Market Plan?</div>
                        </div>
                        <button className="imc-button imc-button--small imc-button--transparent" onClick={toggleMenu}>NO</button>
                        <button className="imc-button imc-button--small" onClick={removeItem}>YES</button>
                    </div>
                </div>
            </div>}
        </li>
    );
}

export default ShowroomListItem
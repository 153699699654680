import { serialize } from 'utils/serialize';
//import { getAPIEndpoint } from 'utils/getapiendpoint';
import { filterquerymodifier } from 'utils/filterquerymodifier';
import { getSitecoreApiHost, getSitecoreApiKey } from '../../AppGlobals';

/**
 * API key name
 * @type {string}
 */
const searchImagesEndpointKey = 'apiImagesResourceUrl';

/**
 * Fallback endpoint if the key does not exist
 * @type {string}
 */
const endpointFallback = '/site/api/imagegallery';

/**
 * Videos API
 */
export default class SearchImagesAPI {
    /**
     * Constructor
     */
    constructor() {
        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         */
        this._apiKey = searchImagesEndpointKey;
        this.getImages = this.getImages.bind(this);
    }

    /**
     * Gets search results for images
     * @param {array} tags array
     * @param {string} pagenum of current page
     * @returns {Promise.<T>} Promise object that returns the SearchVideosAPI results
     */
    getImages(tags = [], pagenum = 0, pageSize, datasourceIds) {
        let totalSkip = 0;
        if(pagenum > 1){
            totalSkip = (pagenum - 1) * pageSize;
        }
        const params = {
            take: pageSize,
            skip: totalSkip,
            datasourceIds: !!datasourceIds?datasourceIds.split('|'):''
        };

        if (tags.length > 0) {
            params.tags = tags;
        }
        let querystring = serialize(params);
        querystring = filterquerymodifier(querystring);
        //TODO GET API ENDPOINT any KEY http://imc_xp0.sc/imc-api/v1/items/imagecards-list?sc_apikey={68C22CFE-5A44-4471-8BA8-13BB1A3E58C0}&take=12&skip=0
        return (
            fetch(`${getSitecoreApiHost()}/imc-api/v1/items/imagecards-list?sc_apikey=${getSitecoreApiKey()}&${querystring}`)
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }
}

// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Module dependencies
import { uniqueid } from 'utils/uniqueid';
import { maxlength } from 'utils/maxlength';
import { numbersonly } from 'utils/numbersonly';
import { alphanumeric } from 'utils/alphanumeric';
import { Tooltip } from 'modules/tooltip';

/**
 * Component for displaying a TextField module and maintaining its state
 */
export default class TextField extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            id: uniqueid('textfield-'),
            errorEmpty: this.props.errorEmpty,
            errorInvalid: this.props.errorInvalid,
        };

        this.fieldChange = this.fieldChange.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
        this.trimString = this.trimString.bind(this);
    }

    /**
     * Handles client side validations before sending to the parent
     * @param {string} value String value coming from the text field
     */
    fieldChange(value) {
        const { name, updateValue, maxLength, numbersOnly, alphaNumeric, type } = this.props;

        // Remove spaces because React 15.x has a bug that don`t fire onChange when the space key
        // is pressed only for inputs type email
        let final = (type === 'email') ? value.replace(/ /g, '') : value;

        // max length check
        if (maxLength !== 0) {
            final = maxlength(value, maxLength);
        }

        // numbers only check
        if (numbersOnly) {
            final = numbersonly(final, true);
        }

        // alpha numeric check
        if (alphaNumeric) {
            final = alphanumeric(final);
        }

        updateValue(name, final);
    }

    /**
     * Removes spaces from email fields, BUG in react 15.x
     * @param {string} name String value coming from the text field
     * @param {string} valueField String value coming from the text field
     */
    trimString(name, valueField) {
        const { updateValue, onFieldBlurUpdate, type } = this.props;
        const value = (type === 'email') ? valueField.replace(/ /g, '') : valueField;

        // Remove spaces because React 15.x has a bug that don`t fire onChange when the space key
        // is pressed only for inputs type email
        if (type === 'email') {
            updateValue(name, value);
        }

        onFieldBlurUpdate(name, value);
    }

    /**
     * Handles rendering of tooltip if required
     * @returns {*} Rendered tooltip component
     */
    renderTooltip() {
        const { tooltipContent, stableSelector, renderAsHtml } = this.props;
        if (tooltipContent) {
            return (<Tooltip
                content={tooltipContent}
                stableSelector={stableSelector}
                renderAsHtml={renderAsHtml}
                tooltipclass="imc-tooltip__contentlarge"
            />);
        }
        return '';
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const {
            label,
            type,
            name,
            additionalClass,
            labelClass,
            isRequired,
            isSingleFieldRequired,
            minLength,
            hideLabel,
            placeholder,
            stableSelector,
            value,
            inputCls,
            onFieldUpdate,
            readOnly,
            isError,
        } = this.props;
        const { id } = this.state;
        const attr = {
            id,
            name,
            value,
            type,
            placeholder,
            onChange: e => this.fieldChange(e.target.value),
            onFocus: e => onFieldUpdate(e.target.value),
            onBlur: e => this.trimString(e.target.name, e.target.value),
            readOnly,
        };
        if (isRequired) {
            attr.required = 'required';
        }
        if (minLength) {
            attr.minLength = minLength;
        }
        return (
            <div
                className={`imc-formfield imc-content ${additionalClass}`}
                data-xpath={`${stableSelector}Container`}
            >
                <div className={`imc-vr--xsmall ${hideLabel ? 'imc-visuallyhide' : ''}`}>
                    <label htmlFor={id} data-xpath={`${stableSelector}Label`}  className={labelClass}>
                        {label}<span>
                            {isRequired && !isSingleFieldRequired ? '*' : ''}
                        </span>
                    </label>
                    {this.renderTooltip()}
                </div>
                <input
                    {...attr}
                    className={`${inputCls}${isError ? ' imc-field--error' : ''}`}
                    data-xpath={stableSelector}
                />
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{label: *, name: *, type: shim, updateValue: shim, isRequired: shim, additionalClass: shim}}
 */
TextField.propTypes = {
    label: PropTypes.string.isRequired, // Label for the text field
    name: PropTypes.string.isRequired, // Content that expands or collapses
    type: PropTypes.string, // text|email
    value: PropTypes.string, // Populates the value if available
    updateValue: PropTypes.func, // method to update the value in the parent
    isRequired: PropTypes.bool, // determines if expanded by default
    errorEmpty: PropTypes.string, // Error message for an empty field
    errorInvalid: PropTypes.string, // Error message for an invalid entry
    additionalClass: PropTypes.string, // Additional class to include as part of the wrapper
    labelClass: PropTypes.string, // Additional class to include as part of the label
    maxLength: PropTypes.number, // Max length for the field
    minLength: PropTypes.number, // Min length for the field
    numbersOnly: PropTypes.bool, // Flag if field should be numbers only
    alphaNumeric: PropTypes.bool, // Flag if field should be alpha numeric only
    hideLabel: PropTypes.bool, // Flag to visually hide the label (still available for screen readers)
    placeholder: PropTypes.string, // Placeholder text
    stableSelector: PropTypes.string, // Stable Selector
    inputCls: PropTypes.string,
    onFieldUpdate: PropTypes.func, // Change call back
    onFieldBlurUpdate: PropTypes.func, // Change on blur event call back
    readOnly: PropTypes.bool,
    tooltipContent: PropTypes.string, // Tooltip content
    isSingleFieldRequired: PropTypes.bool,
    renderAsHtml: PropTypes.bool,
    isError: PropTypes.bool, // Flag if field has error
};

/**
 * @property defaultProps
 * @type {{type: string, updateValue: (function()), isRequired: boolean, additionalClass: string}}
 */
TextField.defaultProps = {
    type: 'text',
    value: '',
    updateValue: () => {},
    onFieldUpdate: () => {},
    onFieldBlurUpdate: () => {},
    isRequired: false,
    inputCls: '',
    errorEmpty: 'Field is empty',
    errorInvalid: 'Invalid entry',
    additionalClass: '',
    labelClass:'',
    maxLength: 0,
    minLength: 0,
    numbersOnly: false,
    alphaNumeric: false,
    hideLabel: false,
    placeholder: '',
    stableSelector: 'form.textfield',
    readOnly: false,
    tooltipContent: '',
    isSingleFieldRequired: false,
    renderAsHtml: false,
    isError: false,
};

// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Module dependencies
import { uniqueid } from 'utils/uniqueid';

/**
 * Component for displaying a TextField module and maintaining its state
 */
export default class DropDown extends Component {
    /**
     * Handeling sorting with object
     * @param {object} obj String value coming from the text field
     * @return {array} returning sorted array
     */
    static sortProperties(obj) {
        // convert object into array
        const sortable = [];
        Object.keys(obj).forEach((data) => {
            sortable.push([data, obj[data]]); // each item is an array in format [key, value]
        });
         // sort items by value
        sortable.sort((a, b) => {
            const x = a[1].toLowerCase();
            const y = b[1].toLowerCase();
            let sortresult = -1;
            if (x < y) {
                sortresult = -1;
            } else if (x > y) {
                sortresult = 1;
            } else {
                sortresult = 0;
            }
            return sortresult;
        });
        return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
    }
    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            id: uniqueid('dropdown-'),
            value: this.props.value,
            errorEmpty: this.props.errorEmpty,
        };

        this.fieldChange = this.fieldChange.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
    }

    /**
     * Updates value of drop down when sortedOptions prop is passed
     * @param {object} nextProps Updated props
     */
    componentWillReceiveProps(nextProps) {
        const { sortedOptions, updateValue, name } = nextProps;
        if (sortedOptions !== this.props.sortedOptions) {
            const selectedOption = sortedOptions.find(option => option.checked === '1');
            if (selectedOption) {
                updateValue(name, selectedOption.code);
            }
        }
    }

    /**
     * Handles client side validations before sending to the parent
     * @param {string} value String value coming from the text field
     */
    fieldChange(value) {
        const { name, updateValue, dropdownChange } = this.props;
        let updatedValue = '';
        if (value !== '') {
            updatedValue = isNaN(value) ? value : parseInt(value, 10);
        }
        this.setState({
            value: updatedValue,
        });

        updateValue(name, updatedValue);
        dropdownChange(updatedValue, name || null);
    }

    /**
     * Renders the options in the dropdown
     * @returns {*} Dropdown options
     */
    renderOptions() {
        const { sortedOptions } = this.props;
        const options = this.props.options || {};
        const alphaSortOptions = this.props.alphaSortOptions;
        if (sortedOptions && sortedOptions.length > 0) {
            return sortedOptions.map((option, index) => (
                <option key={index} value={option.code}>{option.displayValue}</option>
            ));
        }
        if (options.constructor === Array) {
            return options.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ));
        }
        if (alphaSortOptions) {
            const keysSorted = DropDown.sortProperties(options);
            return keysSorted.map((optionValue, index) => (
                <option key={index} value={optionValue[0]}>{optionValue[1]}</option>
            ));
        }
        return Object.keys(options).map((optionValue, index) => (
            <option key={index} value={optionValue}>{options[optionValue]}</option>
        ));
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { label, name, additionalClass, isRequired, firstOption, hideLabel, stableSelector, value,
            isDefaultOption, labelClass } = this.props;
        const { id } = this.state;
        const attr = {
            id,
            name,
            value,
            onChange: e => this.fieldChange(e.target.value),
        };
        if (isRequired) {
            attr.required = 'required';
        }

        return (
            <div
                className={`imc-formfield imc-dropdown imc-content ${additionalClass}`}
                data-xpath={`${stableSelector}Container`}
            >
                <div className={`imc-vr--xsmall ${hideLabel ? 'imc-visuallyhide' : ''}`}>
                    <label htmlFor={id} data-xpath={`${stableSelector}Label`} className={labelClass}>
                        {label}{isRequired ? '*' : ''}
                    </label>
                </div>
                <select
                    {...attr}
                    data-xpath={stableSelector}
                >
                    {isDefaultOption && <option value="">{firstOption}</option>}
                    {this.renderOptions()}
                </select>
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{label: *, name: *, type: shim, updateValue: shim, isRequired: shim, additionalClass: shim}}
 */
DropDown.propTypes = {
    label: PropTypes.string.isRequired, // Label for the text field
    name: PropTypes.string.isRequired, // Content that expands or collapses
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Dropdown values and labels
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Populates the value if available
    firstOption: PropTypes.string, // Label for the first option
    updateValue: PropTypes.func, // method to update the value in the parent
    isRequired: PropTypes.bool, // determines if expanded by default
    errorEmpty: PropTypes.string, // Error message for an empty field
    additionalClass: PropTypes.string, // Additional class to include as part of the wrapper
    hideLabel: PropTypes.bool, // Flag to visually hide the label (still available for screen readers)
    stableSelector: PropTypes.string, // Stable Selector
    dropdownChange: PropTypes.func, // return value on filed change
    isDefaultOption: PropTypes.bool, // To show default select option or not
    alphaSortOptions: PropTypes.bool, // sorting object as per value
    sortedOptions: PropTypes.array, // Dropdown values and labels in sorted way
    labelClass: PropTypes.string, // Error message for an empty field
};

/**
 * @property defaultProps
 * @type {{type: string, updateValue: (function()), isRequired: boolean, additionalClass: string}}
 */
DropDown.defaultProps = {
    value: '',
    updateValue: () => {},
    dropdownChange: () => {},
    isRequired: false,
    additionalClass: '',
    hideLabel: false,
    firstOption: 'Select an option',
    options: [],
    errorEmpty: 'Please select a value',
    stableSelector: 'form.textfield',
    isDefaultOption: true,
    alphaSortOptions: false,
    sortedOptions: [],
    labelClass:""
};

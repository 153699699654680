
import { SET_FLOOR_ID, SET_MAP_DATA, SHOW_EX_DETAILS_UID, CLOSE_EX_MODAL } from './actionTypes';
import ExhibitorsAPI from './../../../api/exhibitor';
import { mockUID } from './mock';

/**
 * 
 * @param {map} map meridian map pointer (what createMap returns)
 * @param {string} floorId selected floor ID
 * @param {string} floors list of floors in map
 */
export function setMapData(map, floorId, floors){
    
    return (dispatch) => {
        dispatch({
            type: SET_MAP_DATA,
            floorId: floorId,
            floors: floors,
            map: map
        });
    };
}

/**
 * 
 * @param {map} map meridian map pointer (what createMap returns)
 * @param {string} floorId selected floor ID
 * @param {string} floors list of floors in map
 */
export function setFloorID(floorId){
    
    return (dispatch) => {
        dispatch({
            type: SET_FLOOR_ID,
            floorId: floorId,
        });
    };
}

function getExhibitor(exhibitor){
    return {
        companyDirectoryImage: ('logoAndImage' in exhibitor && exhibitor.logoAndImage) ? exhibitor.logoAndImage.heroImageOriginal : '',
        companyLogoImage: ('logoAndImage' in exhibitor && exhibitor.logoAndImage) ? exhibitor.logoAndImage.logoImageOriginal : '',
        companyName: ('companyDetails' in exhibitor && exhibitor.companyDetails) ? exhibitor.companyDetails.companyName : '',
        companySite: ('companyInformation' in exhibitor && exhibitor.companyInformation) ? exhibitor.companyInformation.companyWebsiteUrl : '',
        copyBlock: ('companyInformation' in exhibitor && exhibitor.companyInformation) ? exhibitor.companyInformation.completeDescription : '',
        lineName: exhibitor.lineName,
        activeLeases: ('companyDetails' in exhibitor && exhibitor.companyDetails) ? exhibitor.companyDetails.activeLeases : [],
        productCategories: exhibitor.productCategories,
        contactInfo: exhibitor.directoryContactInfo,
        shopZioURL: exhibitor.imcdiUrl,
        productsCount: exhibitor.productsCount,
        showroom3DUrl: exhibitor.showroom3DUrl,
        exhibitId: exhibitor.directoryContactInfo.organizationId,
        lineId: null
    };
}
export function showExhibitorDetails(uid, crmId, pageId){
    let api = new ExhibitorsAPI;
    
    //uid = "Building 3|1|217"; //for testing
    let channelCode = window.channel.code;
    uid = uid.replace(`${channelCode}|`, "");

    return (dispatch) => {
        //first we need to find out exhibitorID out of the showroomUID
        api.getExhibitorbyShowRoomUID([uid],crmId).then(uidResult=>{
            uidResult = uidResult.data.exhibitorLocationUids[0];
            if (!uidResult){
                console.error("Couldn't get exhibitor out of UID");
                return;
            }
            
            let exId=  uidResult.exhibitorId;
            //let exId=  "66047"; //"66277";
            api.getExhibitorDetail(exId, pageId).then(results=>{
                let ex = getExhibitor(results.data[0]);
                ex.uid = uid;
                    dispatch({
                        type: SHOW_EX_DETAILS_UID,
                        selectedEx: ex,
                    });
                
            });
        });
    }
}


export function closeExhibitorDetails(uid){
    return (dispatch) => {
        dispatch({
            type: CLOSE_EX_MODAL,
        });
    };
}
import { getFloorPlanURL, getBooth } from '../floorplan';

class EventLocation{

    public Url: string="";
    public DisplayLocation: string="";
}

export default class EventsUtility{

    //Tests the 
    static getEventLocation ( event: any): EventLocation 
    {
        let location:EventLocation = new EventLocation();
        if(!!event.showroomBuildingFloor)
            location.DisplayLocation = event.showroomBuildingFloor;
        else
        {
            var array = [ event.showroomBuilding, "Floor " + event.showroomFloor, getBooth(event.showroomBuilding, event.showroomFloor, event.showroom) ];
            location.DisplayLocation = array.filter(x=>x).join(", ");
            //location.DisplayLocation = `${event.showroomBuilding}, Floor ${event.showroomFloor}, ${event.showroom}`;
        }
        //The URL is only constructed if the three fields are valid.  
        if (!!event.showroomBuilding && !!event.showroomFloor && !!event.showroom)
        {
            let url:string = getFloorPlanURL(event.showroomBuilding, event.showroomFloor, event.showroom);
            location.Url = url;
        }
        
        return location;
        
    }  
}
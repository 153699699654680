import {
    SET_ARTICLES,
    SET_ARTICLES_ERR,
    SET_ARTICLE_COUNT,
    RUN_SEARCH,
    SHOW_LOADING,
} from '../actions/actionTypes';


const initialState = {
    articles: [],
    resultsUpToDate: false,
    searchQuery: null,
    showLoading: false,
    numResults: '',
};

/**
 * Reducer for video information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function articlesDirectoryReducer(state = initialState, action) {
    switch (action.type) {
    case RUN_SEARCH:
        return Object.assign({}, state, {
            resultsUpToDate: false,
            searchQuery: action.searchQuery,

        });
    case SHOW_LOADING:
        return Object.assign({}, state, {
            showLoading: action.status,
        });
    case SET_ARTICLES:
        return Object.assign({}, state, {
            articles: action.articles,
            count: action.count,
            resultsUpToDate: true,
            errCode: null,
            numResults: action.count,
        });
    case SET_ARTICLE_COUNT:
        return Object.assign({}, state, {
            numResults: action.count,
        });
    case SET_ARTICLES_ERR:
        return Object.assign({}, state, {
            articles: [],
            resultsUpToDate: true,
            errCode: action.status || 404,
            numResults: '',
        });
    default:
        return state;
    }
}

import * as types from './actionTypes';
import FormSubmitAPI from '../api/formSubmit';

/**
 * Action to submit the form
 * @param {string} url Action URL for the query
 * @param {object} data Form data as an object
 * @param {string} contentType Content type of data in submission
 * @returns {Function} Promise object that returns the FormSubmitAPI results
 */
export function submitForm(url, data, contentType) {
    return function returnResults(dispatch) {
        dispatch({
            type: types.SUBMIT_SUCCESS,
            form: {
                status: '',
                message: '',
            },
        });
        return FormSubmitAPI.submit(url, data, contentType)
            .then((response) => {
                const form = (response && response.status) ? response : {
                    status: 'fail',
                    message: '',
                };
                dispatch({
                    type: types.SUBMIT_SUCCESS,
                    form,
                });
            })
            .catch(() => {
                const form = {
                    status: 'fail',
                    message: '',
                };
                dispatch({
                    type: types.SUBMIT_FAIL,
                    form,
                });
            });
    };
}

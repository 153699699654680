import React from 'react';
import FlexGrid from '../Flex-Grid';
import BasicSingleColumnComponent from '../Basic-Single-Column-Component';
import RichTextComponent from '../Rich-Text-Component';
import ImcCta from '../Imc-cta';
import StandaloneImageComponent from '../Standalone-Image-Component';
import {newComponent, contentSectionComponentRendering, richTextRendering, flexGridRendering, imcCtaRendering, standaloneImageRendering, returnMediaSection, returnRichTextHeading, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'


// ---------------------- //
// define props interface //
// ---------------------- //
interface BasicCardCircleProps {
    fields?: any;
};
// ---------------------- //

// ------------------------ //newComponent
// Basic Two Column Section //
// ------------------------ //
const BasicCardCircle: React.FC<BasicCardCircleProps> = ({fields}) => {
    const defaultJson = require('./props.json');
    let cardComponent = newComponent(defaultJson);
    cardComponent.fields.primaryCTA = newComponent(fields.primaryCTA);
    if (fields.primaryCTAclass.length > 0) cardComponent.fields.primaryCTAclass = newComponent(fields.primaryCTAclass);
    cardComponent.fields.image = newComponent(fields.image);
    return (
        <BasicSingleColumnComponent {...cardComponent} rendering={cardComponent} />
    );
};
// ------------------------ //

export default BasicCardCircle;

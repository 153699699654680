import {
    SET_IMAGES,
    SET_IMAGE_ERR,
    SET_IMAGES_COUNT,
    RUN_SEARCH,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {
    images: [],
    resultsUpToDate: false,
    searchQuery: null,
    showLoading: false,
    numResults: '',
};

/**
 * Reducer for video information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function imagesDirectoryReducer(state = initialState, action) {
    switch (action.type) {
        case RUN_SEARCH:
            return Object.assign({}, state, {
                resultsUpToDate: false,
                searchQuery: action.searchQuery,
            });
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_IMAGES:
            return Object.assign({}, state, {
                images: action.images,
                count: action.count,
                resultsUpToDate: true,
                errCode: null,
                numResults: action.count,
            });
        case SET_IMAGES_COUNT:
            return Object.assign({}, state, {
                numResults: action.count,
            });
        case SET_IMAGE_ERR:
            return Object.assign({}, state, {
                images: [],
                resultsUpToDate: true,
                errCode: action.status || 404,
                numResults: '',
            });
        default:
            return state;
    }
}
import config from "../../temp/config";
import { getSitecoreApiHost, getSitecoreApiKey } from "../../AppGlobals";

export const submitEmailUrl = "/imc-api/preference-center/v1/validate-email";
export const preferencesSubscribesUrl =
  "/imc-api/preference-center/v1/save-preferences";
export const channelOptOutUnsubscribeUrl =
  "/imc-api/preference-center/v1/channel-exclude";
export const channelOptOutCancelUnsubscribeUrl =
  "/imc-api/preference-center/v1/channel-cancel-exclude";

export const vipOptOutUnsubscribeUrl =
  "/imc-api/preference-center/v1/vip-exclude";
export const vipOptOutCancelUnsubscribeUrl =
  "/imc-api/preference-center/v1/vip-cancel-exclude";

export const globalOptOutUnsubscribeUrl =
  "/imc-api/preference-center/v1/global-exclude";
export const globalOptOutCancelUnsubscribeUrl =
  "/imc-api/preference-center/v1/global-cancel-exclude";

const getUrlWithApiHost = url => `${getSitecoreApiHost()}${url}`;

const setRequestBody = body => {
  return body ? JSON.stringify(body) : "";
};
const getUrlWithParams = (url, params) => {
  const apiUrl = new URL(url);
  if (params) {
    const searchParams = {};
    Object.keys(params).forEach(key => {
      searchParams[key] = params[key] || "";
    });
    searchParams.sc_apikey = getSitecoreApiKey();
    apiUrl.search = decodeURI(new URLSearchParams(searchParams).toString());
  } else {
    apiUrl.search = decodeURI(
      new URLSearchParams({ sc_apikey: getSitecoreApiKey() }).toString()
    );
  }

  return apiUrl.toString();
};

export const fetchRequest = data => async url => {
  const requestUrl = getUrlWithApiHost(url);
  const response = await fetch(getUrlWithParams(requestUrl), {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: setRequestBody(data)
  });
  if (!response.ok) {
    console.error("Something went wrong...");

    return null;
  }
  return response;
};

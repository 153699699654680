// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Local dependencies
import NavigationTier2 from './NavigationTier2.jsx';
import ImcDataLayer from 'utils/datalayer';

/**
 * Component for displaying a navigation item (tier 1) and maintaining its state
 */
export default class NavigationTier1 extends Component {

    /**
     * @method constructor
     * @description On instantiation dynamically sets the tier 1 navigation item
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.navigateTier1 = this.navigateTier1.bind(this);
        this.focusTier1 = this.focusTier1.bind(this);
    }

    /**
     * Focuses on the tier 1 item
     */
    focusTier1() {
        this.tier1node.focus();
    }

    /**
     * Navigates through the tier 1 items
     * @param {object} e Keydown event object
     */
    navigateTier1(e) {
        const { previousTier1, nextTier1 } = this.props;
        const code = e.keyCode || e.which;
        // left arrow
        if (code === 37) {
            previousTier1();
        }
        // right arrow
        if (code === 39) {
            nextTier1();
        }
        // down arrow
        if (code === 40) {
            if (this.tier2.itemref && this.tier2.itemref.length > 0) {
                e.preventDefault();
                this.tier2.itemref[0].focus();
            }
        }
    }

    /**
     * @method render
     * @description Renders the Tier 1 DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { fullWidth, label, title, url, setTier1, currentIndex, currentTier1, items, target } = this.props;
        const fullWidthClass = fullWidth ? 'imc-navigation__tier1Item--fullwidth' : '';
        const tier2id = `navigation-tier2-${title.replace(/\s+/g, '')}`;
        const isActive = currentIndex === currentTier1;
        const activeClass = isActive ? 'imc-navigation__tier1Item-inner--active' : '';
        const actualUrl = (url === '') ? '#' : url;
        return (
            <div
                className={`imc-navigation__tier1Item ${fullWidthClass}`}
                ref={(node) => { this.tier1 = node; }}
            >
                <div className={`imc-navigation__tier1Item-inner ${activeClass} tier1`}>
                    <a
                        href={actualUrl}
                        ref={(node) => { this.tier1node = node; }}
                        onClick={(e) => {
                            e.preventDefault();
                            ImcDataLayer.pushInteractionEvent("navigation", "header", "tier1 - " + label);
                            setTier1(currentIndex);
                        }}
                        onKeyDown={e => this.navigateTier1(e)}
                        className="imc-navigation__tier1Link"
                        aria-expanded={isActive}
                        aria-controls={tier2id}
                        data-xpath="navigation.tier1Link"
                        target={target === '_blank' ? '_blank' : '_self'}
                        dangerouslySetInnerHTML={{ __html: label }}
                    ></a>
                </div>
                {isActive && (
                    <NavigationTier2
                        ref={(node) => { this.tier2 = node; }}
                        id={tier2id}
                        tier1Label={title}
                        currentTier1={currentTier1}
                        currentIndex={currentIndex}
                        onClick={() => this.focusTier1()}
                        nextTier1={() => this.props.nextTier1()}
                        items={items}
                        fullWidth={fullWidth}
                        tier1Offset={this.tier1 ? this.tier1.offsetLeft : 0}
                    />)
                }
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{items}}
 */
NavigationTier1.propTypes = {
    currentIndex: PropTypes.number.isRequired, // current key
    label: PropTypes.string.isRequired, // Label for the tier 1 link
    title: PropTypes.string.isRequired, // Title for the tier 1 link
    url: PropTypes.string.isRequired, // URL for the tier 1 link
    items: PropTypes.array.isRequired, // Array of tier 2 items
    fullWidth: PropTypes.bool.isRequired, // Flag if the menu is full width
    currentTier1: PropTypes.number.isRequired, // Currently selected tier 1 item
    setTier1: PropTypes.func.isRequired, // Function to set the current selected tier 1 item
    nextTier1: PropTypes.func.isRequired, // Function to set the next tier 1 item
    previousTier1: PropTypes.func.isRequired, // Function to set the next tier 1 item
    target: PropTypes.string, // Determine if the link is opening a new window (internal|external)
};

/**
 * @property defaultProps
 * @type {{target: string}}
 */
NavigationTier1.defaultProps = {
    target: 'internal',
    title: '',
};

// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueid } from 'utils/uniqueid';

// Module dependencies
import { ExpandCollapse } from 'modules/expandcollapse';
import ImcDataLayer from 'utils/datalayer';

const LOCATIONS_IDENTIFIER = "Locations";
/**
 * Create a function that will set
 * semi selected inputs to indeterminate
 * @param {object} checkedState object which contains information on nodes checked status
 * @returns {function} Function used for assignment of indeterminate status
*/
function setIndeterminate(checkedState) {
    return ((input) => {
        if (input) {
            // eslint-disable-next-line no-param-reassign
            input.indeterminate = (checkedState.status === 'indeterminate');
        }
    });
}

/**
 * @method removeSubFilters
 * @description remove provided
 * @param {array} filtersToRemove array of parents subfilters
 * @param {array} arrayOfFilters currently selected filters
 * @param {string} parent of filters to remove
 * @returns {array} cleaned array
 */
function removeSubFilters(filtersToRemove, arrayOfFilters, parent) {
    filtersToRemove.forEach((filter) => {
        const index = arrayOfFilters.indexOf(filter.name);
        // eslint-disable-next-line no-unused-expressions
        (index !== -1) ? arrayOfFilters.splice(index, 1) : arrayOfFilters;
    });
    // after removing all subFilters checking for parent if exists then remove that as well
    const finalArrayOfFilters = arrayOfFilters.filter(filter => filter !== parent);
    return finalArrayOfFilters;
}


/**
 * Appends parent and child names together
 * to create full reference name
 * @param {string} parentName name of parent filter
 * @param {string} child name of child filter
 * @returns {string} child items's full name
 */
function getChildsFullName(parentName, child) {
    return `${parentName} > ${child}`;
}

/**
 * Get Array of Filter Names
 * @param {array} filters array of filter objects
 * @param {string} parent of these filters
 * @returns {array} Array of filter names
 */
function getFilterNames(filters, parent) {
    const finalFilters = filters.map(filter => (
        filter.name
    ));
    // at the time of applying particular filter with subFilters
    // adding parent in final selection as parent is also required in call
    if (parent) finalFilters.push(parent);
    return finalFilters;
}

/**
 * Are parents children all selected, some selected, or no selected?
 * @param {string} parentName name of parent filter
 * @param {array} children array of children filters
 * @param {array} selectedFilters array of currently selected filters
 * @return {object} information on selected children
 */
export function getParentsCheckedStatus(parentName, children = [], selectedFilters) {
    let selectedChildrenCount = 0;
    children.forEach((child) => {
        if (selectedFilters.includes(child.name)) {
            selectedChildrenCount++;
        }
    });

    if (selectedChildrenCount === children.length) {
        return {
            status: 'all',
            count: 'All',
        };
    }
    if (selectedChildrenCount > 0) {
        return {
            status: 'indeterminate',
            count: selectedChildrenCount,
        };
    }
    if (selectedFilters.includes(parentName)) {
        return {
            status: 'parentonly',
            count: 0
        };
    }
    /*if (selectedChildrenCount == 0) {
        return {
            status: 'parentonly',
            count: 0,
        };
    }*/
    return null;
}

/**
 * Component for displaying a single Filter Item module and maintaining its state
 * It will be assumed that incoming/outgoing filter options will already be decoded/encoded by the parent.
 */
export default class FilterItem extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            filters: {},
            parentExpanded: {},
        };


        /**
         * Stores the refs for checkbox inputs
         * @type {{}}
         */
        this.checkboxes = {};
        this.filterIndices = {};

        /**
         * Store unique identifiers to use for keys
         * @type {{}}
         */
        this.checkboxIds = {};

        this.renderOptions = this.renderOptions.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.renderCheckboxes = this.renderCheckboxes.bind(this);
        this.setIndeterminate = setIndeterminate;
        this.getChildsFullName = getChildsFullName;
        this.toggleParents = this.toggleParents.bind(this);
    }



    /**
     * Updates the expanded state for the corresponding parent item
     * @param {string} parent Parent filter to update
     * @param {boolean} expanded Expanded flag coming from the ExpandCollapse module
     */
    parentExpanded(parent, expanded) {
        const newExpanded = { ...this.state.parentExpanded };
        newExpanded[parent] = expanded;
        this.setState({
            parentExpanded: { ...newExpanded },
        });
    }

    /**
     * Closes all of the parent items
     */
    closeParents() {
        const newExpanded = { ...this.state.parentExpanded };
        Object.keys(newExpanded).forEach((parent) => {
            newExpanded[parent] = false;
        });
        this.setState({
            parentExpanded: { ...newExpanded },
        });
    }

    /**
     * Expands the clicked filter
     * todo: Add functionality
     * @param {string} item Item to scroll to after expanding
     */
    expandFilter(item) {
        this.setState({
            expanded: true,
        });
        this.parentExpanded(item, true);
    }

    /**
     * For Every Parent Value Available,
     * Check to see if any or all of
     * Parent's children are selected.
     * @param {array} parents list of tier1 filters
     * @param {array} selectedFilters list of selectedFilters
     * @returns {object} obj with information about tier1 filters with selected children
     */
    checkParentsStatus(parents, selectedFilters) {

        const self = this;
        self.selectedFilters = selectedFilters;
        const parentsWithSelectedChildren = {};

        if (!selectedFilters || selectedFilters.length === 0) {
            return {};
        }
        parents.forEach((parent) => {
            if (typeof parent === 'string') {
                // item has no children, return
                return;
            }

            const checkedStatus = parent.subFilters && parent.subFilters.length > 0 ?
                getParentsCheckedStatus(parent.name, parent.subFilters, self.selectedFilters) :
                null;

            if (checkedStatus) {
                parentsWithSelectedChildren[parent.name] = checkedStatus;
            }
        });

        return parentsWithSelectedChildren;
    }

    /**
     * Determines if the main filter has been expanded or not
     * @param {boolean} expanded Flag if main filter is expanded
     */
    filterExpanded(expanded) {
        this.setState({
            expanded,
        });

        if (!expanded) {
            this.closeParents();
        }
    }
    /**
     * Checks the parent when toggled. This will also set the status of all of its children.
     * @param {string} parent Parent checkbox to check the toggle for
     * @param {object} checkedStatus checked status of selected parent
     * @param {object} e event
     */
    toggleParent(parent, checkedStatus) {
        const { subFilters, updateFilter, selectedFilters, filterCategory, name, filterKey } = this.props;
        const parentObj = subFilters.filter(item => item.name === parent)[0];
        // If there are currently selected subfilters
        // DESELECT all subfilters.
        // If there are currently NO selected subfilters
        // SELECT all subfiltesr
        // eslint-disable-next-line no-extra-boolean-cast
        let newFilterSelections;
        const filterUpdate = {};

        if (!!(checkedStatus && checkedStatus.status)) {
            newFilterSelections = removeSubFilters(parentObj.subFilters, selectedFilters, parent);
        } else {
            newFilterSelections = getFilterNames(parentObj.subFilters, name !== LOCATIONS_IDENTIFIER ? parent : null).concat(selectedFilters);
            //gtm event with parent display name
            let gtmDesc = filterCategory + "=" + parentObj.displayName;
            ImcDataLayer.pushInteractionEvent("search", "filter: " + filterKey, gtmDesc);
        }

        // const newFilterSelections = !!(checkedStatus && checkedStatus.status) ?
        //     // deselect all subfilters of parent
        //     removeSubFilters(parentObj.subFilters, selectedFilters, parent) :
        //     // select all filters
        //     getFilterNames(parentObj.subFilters, name !== LOCATIONS_IDENTIFIER ? parent : null).concat(selectedFilters);

        filterUpdate[filterCategory] = newFilterSelections;

        updateFilter(filterUpdate);
        // Set the state
        this.setState({
            filters: { ...newFilterSelections },
        });
    }

    /**
     * Checks the parent when toggled. This will also set the status of all of its children.
     * @param {string} parent Parent checkbox to check the toggle for
     * @param {object} checkedStatus checked status of selected parent
     */
    toggleParents() {
        const { subFilters, updateFilter, selectedFilters, filterCategory } = this.props;
        subFilters.forEach((e) => {
            const parent = e.name;
            if (e.subFilters) {
                removeSubFilters(e.subFilters, selectedFilters, parent);
            }
        });
        // If there are currently selected subfilters
        // DESELECT all subfilters.
        // If there are currently NO selected subfilters
        // SELECT all subfiltesr
        // eslint-disable-next-line no-extra-boolean-cast
        const filterUpdate = {};
        filterUpdate[filterCategory] = [];
        updateFilter(filterUpdate);

        // Set the state
        this.setState({
            filters: { filterCategory: [] },
        });
    }

    /**
     * Toggles the filter option
     * @param {string} optionName Option to add or remove from the selected filters
     */
    toggleFilter(optionName, parent) {
        const { updateFilter, filterCategory, selectedFilters, subFilters, filterKey } = this.props;
        const { filters } = this.state;
        const newSelected = typeof selectedFilters === 'string' ? [selectedFilters] : [...selectedFilters];
        const newFilters = { ...filters };

        const idx = newSelected.indexOf(optionName);
        if (idx === -1) {
            newSelected.push(optionName);
            newFilters[optionName] = true;
            let gtmDesc;
            if (!!parent)
                gtmDesc = filterCategory + "=" + subFilters.find(p => p.name == parent).subFilters.find(s => s.name == optionName).displayName;
            else
                gtmDesc = filterCategory + "=" + subFilters.find(s => s.name == optionName).displayName;

            ImcDataLayer.pushInteractionEvent("search", "filter: " + filterKey, gtmDesc);
        } else {
            newSelected.splice(idx, 1);
            newFilters[optionName] = false;
        }


        const filterUpdate = {};
        filterUpdate[filterCategory] = newSelected;
        updateFilter(filterUpdate);

        this.setState({
            filters: { ...newFilters },
        });
    }
    /**
 * Renders a single option
 * @param {string} option Option to render
 * @param {string} displayName of filters
 * @param {String} parent Name of the parent to check for the status
 * @param {number} index index of checkbox
 * @returns {XML} JSX option markup
 */
    renderCheckbox(option, displayName, parent, index = 0) {
        const { selectedFilters, filterCategory } = this.props;
        const optionName = parent ? this.getChildsFullName(parent, option) : option;
        this.checkboxIds[optionName] = this.checkboxIds[optionName] || uniqueid('filteritem');
        this.filterIndices[optionName] = index;
        const checkedClass = selectedFilters.includes(option) ? 'is-checked' : '';
        return (
            <div
                key={`checkbox-${filterCategory}-${index}-${optionName}`}
                className="imc-filteritem__option"
                data-xpath="filteritem.checkboxwrapper"
            >
                <input
                    id={this.checkboxIds[optionName]}
                    type="checkbox"
                    name={optionName}
                    onChange={() => this.toggleFilter(option, parent)}
                    checked={selectedFilters.includes(option) || false}
                    aria-checked={selectedFilters.includes(option) || false}
                    className="imc-checkbox"
                    data-xpath="filteritem.checkbox"
                    aria-label={displayName}
                />
                <label
                    htmlFor={this.checkboxIds[optionName]}
                    className={`imc-checkbox--label imc-filteritem--label-width imc-type--title-2-ui`}
                    data-xpath="filteritem.checkboxlabel"
                >
                    {displayName}
                </label>
            </div>
        );
    }

    /**
     * Renders an array of options
     * @param {Array} options Array of options to render
     * @param {String} parent Name of the parent to check for the status
     * @returns {Array} JSX rendered options and corresponding functionality
     */
    renderCheckboxes(options, parent) {
        const filteroptions = [];

        options.forEach((option, index) => {
            filteroptions.push(this.renderCheckbox(option.name, option.displayName, parent, index));
        });

        return filteroptions;
    }

    /**
     * Renders the Tier2 filter options. It will either render a single level set of options, or two level.
     * @param {object} parentsWithSelectedChildren obj containing information about parent filters
     * who have selected children
     * @returns {*} Filter options
     */
    renderOptions(parentsWithSelectedChildren) {
        const { subFilters } = this.props;
        const { parentExpanded } = this.state;
        if (subFilters === null) {
            return null;
        }
        if (subFilters.length > 0) {
            return subFilters.map((filter, index) => {
                // do we need two tiers of filters?
                if (!filter.subFilters || (filter.subFilters && filter.subFilters.length === 0)) {
                    return this.renderCheckbox(filter.name, filter.displayName, null, index);
                }
                this.checkboxIds[filter.name] = this.checkboxIds[filter.name] || uniqueid('filteritem');
                const parentActive = parentExpanded[filter.name] ? 'imc-filteritem__parent--active' : '';
                // does this filter have any children elements that are selected?
                const checkStatus = parentsWithSelectedChildren[filter.name] || false;
                // const activeLabel = checkStatus ? 'active' : '';
                const indeterminateClass = (checkStatus && checkStatus.status === 'indeterminate') ?
                    'imc-checkbox--indeterminate' : '';
                // const invertClass = parentExpanded[filter.name] ? 'imc-checkbox--invert' : '';
                // const checkboxClass = `imc-checkbox imc-checkbox--small ${invertClass} ${indeterminateClass}`;
                const expandClass = `imc-filteritem__parent imc-filteritem__parent--tier2 ${parentActive}`;

                return (
                    <ExpandCollapse
                        key={`expandcollapse-${this.checkboxIds[filter.name]}-${filter.name}`}
                        expanded={parentExpanded[filter.name] || false}
                        additionalHeadingClass={expandClass}
                        scrollToTop={false}
                        expandedUpdated={expanded => this.parentExpanded(filter.name, expanded)}
                        name={filter.displayName}
                    >
                        <div>
                            <div className="imc-filteritem__tier2heading imc-type--title-2-ui" data-xpath="filteritem.tier2heading">
                                <div className="imc-filteritem__option">
                                    <input
                                        id={this.checkboxIds[filter.name]}
                                        type="checkbox"
                                        name={filter.name}
                                        checked={!!checkStatus}
                                        ref={this.setIndeterminate(checkStatus)}
                                        onChange={e => this.toggleParent(filter.name, checkStatus, e)}
                                        className={`imc-checkbox ${indeterminateClass}`}
                                        data-xpath="filteritem.tier2checkbox"
                                        aria-label={filter.displayName}
                                    />
                                    <label
                                        htmlFor={this.checkboxIds[filter.name]}
                                        className={`imc-checkbox--label imc-filteritem--label-width`}
                                        data-xpath="filteritem.tier2label"
                                    >
                                        {filter.displayName}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="imc-filteritem__tier2options" data-xpath="filteritem.tier2options">
                                {this.renderCheckboxes(filter.subFilters, filter.name)}
                            </div>
                        </div>
                    </ExpandCollapse>
                );
            });
        }
        return null;
    }

    /**
     * Renders the selected items if the parent is collapsed
     * @param {object} parentsWithSelectedChildren obj containing information
     * about parent filters who have selected children
     * @returns {*} JSX for selected filters in the collapsed state
     */
    renderSelected(parentsWithSelectedChildren = {}) {
        const selected = [];
        const { subFilters, selectedFilters } = this.props;
        const { expanded } = this.state;

        if (expanded) {
            return null;
        }

        if (subFilters === null || subFilters.length === 0) {
            return null;
        }

        subFilters.forEach((item) => {
            const itemSubFilters = (item.subFilters && item.subFilters.length > 0) ?
                item.subFilters : null;
            if (!(itemSubFilters) && selectedFilters.includes(item.name)) {
                // there are not children of this filter, and
                // this filter has currently been selected.
                selected.push(
                    <div
                        key={`filterpeaked-${item.name}`}
                        className="imc-link imc-filteritem__clearlink"
                        data-xpath={`filteritem.selected${item.name}`}
                    >
                        <button onClick={() => this.expandFilter(item.name)}>
                            {item.name}
                        </button>
                    </div>,
                );
            } else if (parentsWithSelectedChildren[item.name]) {
                // List Parent categories that have currently selected children.
                const number = parentsWithSelectedChildren[item.name].count;
                selected.push(
                    <div
                        key={`filterandsubfilterpeak-${item.name}`}
                        className="imc-link imc-filteritem__clearlink"
                        data-xpath={`filteritem.selected${item.name}`}
                    >
                        <button onClick={() => this.expandFilter(item.name)}>
                            {item.name} ({number})
                        </button>
                    </div>,
                );
            }
        });

        return selected;
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { subFilters, selectedFilters, searchLabels } = this.props;
        const { name, displayName } = this.props;
        let { expanded } = this.state;
        const parentsWithSelectedChildren = this.checkParentsStatus(subFilters, selectedFilters);
        const labelAf = searchLabels.clearAdditionalFiltersLabel;
        const labelBp = searchLabels.clearBuyerPreferencesLabel;
        const labelL = searchLabels.clearLocationLabel;
        const labelPp = searchLabels.clearPricePointLabel;
        const labelPc = searchLabels.clearProductCategoryLabel;
        const labelPnf = searchLabels.clearProductNewFilter;
        const labelS = searchLabels.clearStyleLabel;
        const activeLabel = (selectedFilters.length > 0) ? 'active' : '';
        const expandedClass = (expanded ? 'expanded' : 'collapsed');
        let clearLabel = 'Clear Filters';

        if (Object.keys(searchLabels).length !== 0) {
            if (name === 'Additional Filters') {
                clearLabel = labelAf;
            } else if (name === 'Product Category') {
                clearLabel = labelPc;
            } else if (name === 'Styles') {
                clearLabel = labelS;
            } else if (name === 'Price Point') {
                clearLabel = labelPp;
            } else if (name === 'Buyer Preferences') {
                clearLabel = labelBp;
            } else if (name === 'Location') {
                clearLabel = labelL;
            } else if (name === 'Product New') {
                clearLabel = labelPnf;
            }
        }
        if (selectedFilters.length > 0) {
            expanded = true;
        }

        return (
            <div className="imc-filteritem" data-xpath="filteritem.wrapper">
                <ExpandCollapse
                    expanded={expanded}
                    expandedUpdated={isExpanded => this.filterExpanded(isExpanded)}
                    additionalHeadingClass="imc-filteritem__parent"
                    scrollToTop={false}
                    heading={displayName}
                >
                    <div>
                        <div className="imc-filteritem__tier1">
                            <h4 className={`imc-type--title-3-ui imc-type--color-neutral-heavy-medium ${expandedClass}`} data-xpath="filteritem.heading">{displayName}</h4>
                        </div>
                    </div>
                    <div>
                        <div className="imc-filteritem__tier2">
                            <a
                                href="#self"
                                className={`clear-filter ${activeLabel}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleParents();
                                }}
                            >{clearLabel}
                            </a>
                            {this.renderOptions(parentsWithSelectedChildren)}
                        </div>
                    </div>
                </ExpandCollapse>
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{name: *, type: shim, updateValue: shim, isRequired: shim, additionalClass: shim}}
 */
FilterItem.propTypes = {
    filterCategory: PropTypes.string,
    name: PropTypes.string,
    subFilters: PropTypes.array,
    updateFilter: PropTypes.func,
    selectedFilters: PropTypes.array,
    searchLabels: PropTypes.object,
    // history: PropTypes.object,
    // location: PropTypes.object,
    // match: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {{type: string, updateValue: (function()), isRequired: boolean, additionalClass: string}}
 */
FilterItem.defaultProps = {
    filterCategory: 'filter category',
    name: 'filter',
    subFilters: null,
    updateFilter: () => { },
    selectedFilters: [],
    searchLabels: {},
    // history: {},
    // location: {},
    // match: {},
};

import React from 'react';
import { getDictionaryValue } from 'utils/dictionary';
//Modules
import { ReadMore } from "modules/readmore";

export default class ProductDetailBasicInfo extends React.Component {

  render() {
    const { productDetail } = this.props;
    return <div className="imc-product-details--section imc-section--basic-white imc-exhibitors-info imc-product-details--border-bottom">
      <div className="imc-section imc-section--width-100-percent">
        <div className="imc-gallery imc-gallery--66-33">
          <div className="imc-gallery__item imc-vr--xxlarge imc-vr--novr-desktop">
            <h5 className="imc-margin--bottom--medium">
              {getDictionaryValue('productDescription', 'Product Description')}
            </h5>
            {productDetail.productDescription &&
              <ReadMore
                content={productDetail.productDescription}
              ></ReadMore>
            }
            {!productDetail.productDescription &&
              <span className="imc-content--neutral-medium">
                {getDictionaryValue('descriptionNotAvailable', 'Description not available')}
              </span>

            }
          </div>
          <div className="imc-gallery__item">
            <h5 className="imc-margin--bottom--medium">{getDictionaryValue('productCategories', 'Product Categories')}</h5>
            {Array.isArray(productDetail.productCategories) && productDetail.productCategories.length > 0 &&
              <div className="imc-gallery imc-gallery--justify-left imc-gallery--1-1">
                {productDetail.productCategories.slice(0, 5).map(function (category, index) {
                  return <div className="imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right imc-type--title-8 imc-padding--bottom--xsmall" key={index}>{category}</div>;
                })}
              </div>
            }
            {(Array.isArray(productDetail.productCategories) && productDetail.productCategories.length === 0) &&
              <span className="imc-content--neutral-medium">
                {getDictionaryValue('productCategoriesNotAvailable', 'Product Categories not available')}
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  }
}

import React, { Component } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default class NavigationBarComponent extends Component {
    render() {
        let extraCss = "";

        if(this.props.fields.class!= null &&
            this.props.fields.class.fields!= null &&
            this.props.fields.class.fields.className!= null &&
            this.props.fields.class.fields.className.value!=""){
            extraCss = this.props.fields.class.fields.className.value;
        }

        return (
            <div className={"imc-section imc-market-planner-toolbar "+ extraCss}>
                <div>
                    <Placeholder name='imc-navigation-bar-content' rendering={this.props.rendering} />
                </div>
            </div>
        )
    }
}
import pushDataLayer from '../../dataLayer';
import ProductEvent from "./productBaseEvent";

class ProductRemoveFromCart extends ProductEvent{
     
    constructor() {
        super();
        this.push = this.push.bind(this);
    }

    /**
     * Puhses recorded data to GTM datalayer
     */
    push(){
        if (this.products.length==0)
            return;

        const EVENT_NAME = "removeFromCart";
        const data = {
            event: EVENT_NAME,
            ecommerce: {
              currencyCode: "USD",  
              add: {
                  products: this.products
                }
            }
        }
        
        pushDataLayer(data);
    }
}

export default ProductRemoveFromCart;
/* eslint-disable no-underscore-dangle */
/**
 * Module for storing and dynamically registering reducers to a redux store
 */

/**
 * Class representing a ReducerRegistry for managing a collection of reducers
 * and registered additional reducers dynamically
 */
export default class ReducerRegistry {
    /**
     * Constructor
     * @param {object} initialReducers initial reducers to include
     */
    constructor(initialReducers = {}) {
        this._reducers = { ...initialReducers };
        this._emitChange = null;
    }

    /**
     * @method
     * @param {Object} newReducers Object of reducers to register
     * @description Adds new reducer(s) to the collection of _reducers and emits the change callback
     */
    register(newReducers) {
        if (typeof newReducers !== 'object') {
            throw new Error(`ReducerRegistry.register was expecting an object but receive a ${typeof newReducers}.`);
        }

        this._reducers = { ...this._reducers, ...newReducers };

        if (this._emitChange !== null) {
            this._emitChange(this.getReducers());
        }
    }

    /**
     * @method
     * @returns {{}} Collection of reducers
     * @description Getter for retrieving _reducers collection
     */
    getReducers() {
        return { ...this._reducers };
    }

    /**
     * @method setChangeListener
     * @param {Function} listener Callback method to call on change event
     * @description Sets a callback function to _emitChange property
     */
    setChangeListener(listener) {
        if (this._emitChange !== null) {
            throw new Error('Can only set the listener for a ReducerRegistry once.');
        }
        this._emitChange = listener;
    }
}

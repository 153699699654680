import { serialize } from 'utils/serialize';
//import { getAPIEndpoint } from 'utils/getapiendpoint';
import { filterquerymodifier } from 'utils/filterquerymodifier';
import { getSitecoreApiHost, getSitecoreApiKey } from '../../AppGlobals';
/**
 * API key name
 * @type {string}
 */
const searchArticlesEndpointKey = 'apiArticlesResourceUrl';

/**
 * Fallback endpoint if the key does not exist
 * @type {string}
 */
const endpointFallback = '/site/api/articles';

/**
 * Videos API
 */
export default class RelatedContentAPI {
    /**
     * Constructor
     */
    constructor() {
        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         */
        this._apiKey = searchArticlesEndpointKey;
        this.getRelatedContent = this.getRelatedContent.bind(this);
    }

    /**
     * Gets search results for images
     * @param {array} tags array
     * @param {string} pagenum of current page
     * @returns {Promise.<T>} Promise object that returns the SearchArticlesAPI results
     */
    getRelatedContent(tags = [], type="article", pageSize=3) {       
        const params = {
            take: pageSize,
            type: type,
        };

        if (tags.length > 0) {
            params.tags = tags;
        }

        let querystring = serialize(params);
        querystring = filterquerymodifier(querystring);
        
        return (
            fetch(`${getSitecoreApiHost()}/imc-api/v1/content/related?sc_apikey=${getSitecoreApiKey()}&${querystring}`)
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }
}

import pushDataLayer from '../../dataLayer';
import ProductEvent from "./productBaseEvent";

class ProductImpressions extends ProductEvent{
    constructor() {
        super();

        this.push = this.push.bind(this);
    }

    /**
     * Puhses recorded data to GTM datalayer
     */
    push(){
        if (this.products.length==0)
            return;

        const EVENT_NAME = "productImpressions";
        const data = {
            event: EVENT_NAME,
            productResultsNum: this.products.length,
            ecommerce: {
              currencyCode: "USD",                   
              impressions: this.products
            }
        }
        
        pushDataLayer(data);
    }
}

export default ProductImpressions;
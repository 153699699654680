import MarketAPI from '../../api/market';
import { Environment } from '../../Environment';
export default class UserUtility {

    static isLoggedIn(){
        return (typeof window !== 'undefined' && typeof window.userInfo !== 'undefined' && !!window.userInfo.accountId);
    }

    static getUserData() {
         if(typeof window !== 'undefined')
             return window.userInfo;
         else
             return null;
    }

    //returns a promise with the favorites listID as a result.
    static getCurrentMarketID(host) {

        if (Environment?.isHot) {
            return new Promise( (resolve) => {
                if (window?.channel?.code == 'lvm') {
                    resolve(156);
                } else if (window?.channel?.code == 'atlmkt') {
                    resolve(149);
                } else {
                    resolve(13);
                }
            });
        }
            
        if (typeof window != 'undefined' && window?.userInfo?.marketId) {
            return new Promise( (resolve) => {
                if (window?.channel?.code == 'lvm' && host?.includes('qa-lvm')) {
                    resolve(156);
                } else if (window?.channel?.code == 'atlmkt' && host?.includes('qa-atlmkt')) {
                    resolve(149);
                } else {
                    resolve(window.userInfo.marketId);
                }
            });
        }
            
        if (typeof window?.channel !== 'undefined') {
            if ((window?.channel?.code == 'lvm' && host.includes('qa-lvm')) || (window?.channel?.code == 'atlmkt' && host.includes('qa-atlmkt'))) {
                return new Promise( (resolve) => {
                    if (window?.channel?.code == 'lvm' && host?.includes('qa-lvm')) {
                        resolve(156);
                    } else if (window?.channel?.code == 'atlmkt' && host.includes('qa-atlmkt')) {
                        resolve(149);
                    } else {
                        resolve(13);
                    }
                });
            } else {
                return _MarketAPI.getCurrentMarket(window.channel.name).then(function(response){
                    const marketId = (!!response.data && !!response.data.marketId)?response.data.marketId:0;
                    //set value in window
                    window.userInfo.marketId = marketId;
                    return new Promise( (resolve) => {
                        resolve(window.userInfo.marketId);
                    });
                });
            }
        }

        const _MarketAPI = new MarketAPI();
        return new Promise( (resolve) => {
            resolve(0);
        });
        
    }

    static setUserProfileData(userProfile)
    {
        if(typeof window !== 'undefined')
        {
            console.log("user profile", userProfile);
            window.userProfile = userProfile;
        }
        else console.warn("can't set user profile data outside of browser context");
    }

    static setUserData(userData)
    {
        //TODO: fix these 2 lines
        //userData.marketId = 13;
        if(typeof window !== 'undefined')
        {
            window.userInfo = userData;
            if(!!userData.accountId && !!userData.email)
                this.verifyAccount();

        }
        else console.warn("can't set user data outside of browser context");
    }
    /*       window.userInfo =  {
            'marketId' : 13,
            'accountId' : "b07907be-181b-4fda-b619-f0c4762c0d4a",
            'authToken' : '6e8ce642-8cfc-4db8-ae44-776bfae60291',
            'organizationId' : 83906,
            'demandDriverId': 996862,
            'name': "Martin Arias",
            'email': "martin.arias@definition6.com"
        };
    }*/

    static getChannelData() {
        if(typeof window != 'undefined' && window?.channel)
            return  window.channel;
        else{
            console.warn("Returning fake channel Info")
            return {name: "las-vegas-market", code: "lvm"};
        }
    }

    /* This method makes sure the account exists inmarket planner*/
    static verifyAccount()
    {
        //const _accountAPI = new AccountAPI();
        //_accountAPI.getAccountAssociated(window.userInfo.email, window.userInfo.accountId);
    }

    static redirectToLogin()
    {
        console.log(`Redirecting to login ${window.location.href}`);
        window.location = `/identity/login/${window.channel.name}/SitecoreIdentityServer/MP-IdS4-AzureB2C?ReturnUrl=${encodeURIComponent(window.location.href)}`;
    }


}


import React from 'react';
import { getDictionaryValue, renderDesktopMobileVariations } from 'utils/dictionary';
import PropTypes from "prop-types"

//Utils
import { getShopzioUrl, getCurrentPathProductsURL, exhibitorIsLine } from 'utils/exhibitor';
import SearchItemThumb from 'modules/searchItemThumb';
import Link from "../../components/Link";
import ImcDataLayer from "utils/datalayer";
import {isMarketCtaShouldBeShown} from '../../utils/general';
import {withSitecoreRouter} from '../../utils/withRouter';

class ProductsStripe extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        const { products } = this.props;

        if (products && Object.keys(products).length > 0 && prevProps.products != products) {
            ImcDataLayer.PushProductImpressions(products);
        }
    }

    getTitle(exhibitor) {
        return exhibitorIsLine(exhibitor) ? exhibitor.lineName : exhibitor.companyName;
    }

    getProductUrl(product) {
        return ((product.isLineEntity) ? "/exhibitor/$1/line/$2/prod/$3" : "/exhibitor/$1/prod/$3").replace("$1", product.exhibitorId).replace("$2", product.lineId).replace("$3", product.uniqueId);
    }

    renderProducts(products) {
        return (
            <div className="imc-products-overview--gallery">{
                products.map((product, index) => {
                    let isLine = product.isLineEntity;
                    const mainType = isLine ? 'line' : 'exhibitor';
                    const catFav = {
                        itemId: isLine ? product.lineId : product.exhibitorId,
                        itemType: mainType,
                        label: product.productTitle,
                        contentName: '',
                        itemContents: [{
                            deleted: true, //Leave this in true for adding and item thru the copy endpoint
                            contentName: product.productTitle,
                            itemId: product.uniqueId,
                            itemType: 'Product',
                        }],
                    };

                    return (
                        <SearchItemThumb
                            key={index}
                            url={this.getProductUrl(product)}
                            image={product.images && product.images.some(o => (o.fullPath !== '')) ? `${product.images.find(o => (o.fullPath !== '')).fullPath}` : (process.env.PUBLIC_URL + '/assets/images/175-175.png')}
                            newWindow={false}
                            showActionBar={true}
                            actionItem={catFav}
                            name={product.productTitle}
                            extraClass={'imc-catalog__item-sixth'}
                            mainClass="imc-catalog"
                            product={product}
                            width={179}
                            height={179}
                            isNew={product.newProductForMarket}
                        />
                    )

                })
            }
            </div>
        )
    }
    render() {
        const { products, productsCount, shopZioURL, router, compact, title } = this.props;

        return (
            <>
                <div className="imc-content--display-flex-wrap">
                    {title !== ''
                        ? <h5>{title}</h5>
                        : <>
                            < span className="imc-heading--h5">{getDictionaryValue('products', 'Products')}</span>
                            <span className="imc-type--body-2-ui imc-padding--left--small">
                                {productsCount > 0
                                    ? getDictionaryValue('countProductsShown', `${productsCount} Products Shown`, { count: productsCount })
                                    : getDictionaryValue('noProductsShown', 'No Products Shown')
                                }
                            </span>
                        </>
                    }
                </div>
                <div className="imc-content--display-flex-wrap">
                    {(products && products.length > 0) &&
                        this.renderProducts(products)
                    }
                </div>
                {
                    !compact &&
                    <div className="imc-content--display-flex--right imc-content--full-width-mobile imc-products-overview--cta-bar imc-content--display-flex imc-content--display-flex-center">
                        {shopZioURL && isMarketCtaShouldBeShown(this.props) &&
                            <div className="imc-products-overview--cta-bar__item">
                                <Link
                                    onClick={() => ImcDataLayer.pushInteractionEvent("exit", 'Connect on @Market', shopZioURL)}
                                    href={getShopzioUrl(shopZioURL)} target="_blank" rel="noopener noreferrer" className="imc-button imc-button--atmarket imc-button--full-bleed-mobile">{renderDesktopMobileVariations('shopZio', 'Connect on @Market')}</Link>
                            </div>
                        }
                        <div className="imc-products-overview--cta-bar__item imc-padding--left--xlarge--desktop">
                            {productsCount > 0 ?

                                <Link href={getCurrentPathProductsURL(router.location.pathname)} className="imc-content--inline-block imc-button imc-button--primary-inverted imc-button--full-bleed-mobile">{renderDesktopMobileVariations('seeAllProducts', `See${(productsCount > 1) ? ' All' : ''} ${productsCount} Product${(productsCount > 1) ? 's': ''}`, { count: productsCount })}</Link>
                                :
                                <button className="imc-button imc-button--muted-inverted imc-button--full-bleed-mobile" disabled>
                                    {`0 ${getDictionaryValue('products', 'Products')}`}
                                </button>
                            }
                        </div>
                    </div>
                }
            </>
        );
    }
}
const defaultProps = {
    productsCount: 0,
    shopZioURL: '',
    compact: false,
    title: '',
};
const propTypes = {
    products: PropTypes.array.isRequired,
    productsCount: PropTypes.number,
    shopZioURL: PropTypes.string,
    router: PropTypes.object,
    compact: PropTypes.bool,
    title: PropTypes.any,
};

ProductsStripe.propTypes = propTypes;
ProductsStripe.defaultProps = defaultProps;



export default withSitecoreRouter(ProductsStripe);

/**
 * Loads the form post results from a successful submission
 * @type {string}
 */
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';

/**
 * Failed call for form submission
 * @type {string}
 */
export const SUBMIT_FAIL = 'SUBMIT_FAIL';

import React, { Component } from 'react';
import { isEditorActive, Text } from '@sitecore-jss/sitecore-jss-react';
import Tag from './components/tags';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { AddToCalendar } from 'modules/addtocalendar'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';
import UserUtility from "../../utils/userutility";
// export const props = require('./props.json');
import { getFloorPlanURL } from 'utils/floorplan';
class ImcEventDetailComponent extends Component {
  /**
    * @method constructor
    * @param {object} props Incoming props
    */
    constructor(props) {
        super(props);

        this.formatDate = this.formatDate.bind(this);
    }

    formatDate(date, type){
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var d = new Date(date);
        var day = days[d.getDay()];
        var hr = d.getHours();
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        var ampm = "am";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "pm";
        }
        if( hr < 10 ) {
            hr = '0'+hr;
        }
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();

        if(type == 'time'){
            return hr + ':' + min + '' + ampm;
        } else if(type == 'date'){
            return day + ', ' + month + ' ' + date + ', ' + year;
        } else {
            return day + ' ' + month + ' ' + date + ', ' + year + ' ' + hr + ':' + min + '' + ampm;
        }
    }

    mapLocation(e)
    {
        
        return {
            type: (e.fields["Type"] && e.fields["Type"].value? e.fields["Type"].value:null),
            showroom: (e.fields["Showroom"] && e.fields["Showroom"].value? e.fields["Showroom"].value:null),
            showroomBuilding: (e.fields["Showroom Building"] && e.fields["Showroom Building"].value? e.fields["Showroom Building"].value:null),
            showroomBuildingFloor: (e.fields["Showroom Building Floor"] && e.fields["Showroom Building Floor"].value?e.fields["Showroom Building Floor"].value:null),
            showroomFloor: (e.fields["Showroom Floor"] && e.fields["Showroom Floor"].value?e.fields["Showroom Floor"].value:null),
            data: (e.fields["Data"] && e.fields["Data"].value?e.fields["Data"].value:null)

        }
    }

    getEventLocation(event)
        {/*location: { "type": "showroom",
      "data": {
                    "showroom": "A140",
                    "showroomBuilding": "wmcvphase1buildinga",
                    "showroomFloor": "f001",
                    "showroomBuildingName": "WMCV Phase 1 / Building A"
                  }
      }
      }
      of
      location: {"type":"other", "data": "Description"} */
    var building, showroom;
    
    if(!event["Location"])
        return(<></>);
    return event["Location"].map(aLocation=>{
        var location  = this.mapLocation(aLocation);
        
        if(!location.showroom)
        {
          building = location; //just getting a string
        } 
        else 
        {
          building = location.type=="showroom" || location.showroom ? location.showroomBuilding:location.data;
          showroom = location.type=="showroom"|| location.showroom ? location.showroom:null;
        }
        if(!showroom)
          return (<><span class="imc-heading imc-heading--quaternary imc-heading--gamma">{building}</span></>)
        else return (
                <a href={getFloorPlanURL(location.showroomBuilding, location.showroomFloor, location.showroom)} target="_blank" title={`${location.showroomBuilding}  ${location.showroomFloor} ${location.showroom}`} class="imc-link"><span class="imc-heading imc-heading--quaternary imc-heading--gamma">{building}</span>
                  {showroom && <span class="imc-content imc-content--secondary"> {showroom} </span>}</a>);
      });
      
  }


  render() {
    // const { isLoading, event } = this.state;
    // if (!this.props.fields) {
    //   if (isEditorActive()) {
    //     return <h1 className="alarm">Datasource isn't set.</h1>;
    //   }
    //   return (
    //     <h1 className="alarm">
    //       Data is not provided. Contact administrators, please.
    //     </h1>
    //   );
    // }

    // const eventTime = (typeof event.time !== 'undefined' && event.time !== null) ? ` | ${event.time.startTime} - ${event.time.endTime}` : '';
    // if (eventTime === '') {
    // return
    // }
    const event = this.props.fields;
    const eventStart = event['Event DateTime Start'].value;
    const eventEnd = event['Event DateTime End'].value;
    
    return (
    <div>
        <div className={`imc-static-hero imc-section imc-section--full-width imc-content--center ${(event['Main Image']) ? 'imc-section--alt-gray-light imc-content--padded-xlarge-desktop' : ''}`}>
        {   !!event['Main Image'] && <img src={`${event['Main Image'].value}`} />}
        </div>
        <section className="imc-section imc-content imc-content--center imc-section--padded-jumbo-titan imc-eventdetail">
        <h1 className="imc-heading imc-heading--omni-desktop imc-heading--center imc-heading--h1-desktop-mobile">{event['Event Title'].value}</h1>
        <div className="imc-heading imc-heading--gamma imc-content--primary-desktop">
            {`${this.formatDate(eventStart)}`} 
            {`${(this.formatDate(eventEnd)) ? ' - ' + this.formatDate(eventEnd) : ''}`}
        </div>
        </section>
        <section class="imc-content imc-content--center">
            <div class="imc-section--alt-gray-light imc-eventdetail__box imc-eventdetail__box--eventpadding imc-content--primary-desktop">
                <div class="imc-vr--medium">
                    {this.getEventLocation(event)}
                    <span class="imc-heading imc-heading--quaternary imc-heading--gamma">| Event Type:</span> <span class="imc-content--secondary">{event['Event Type'].fields.Title.value}</span>
                </div>
                <ul class="imc-exhibitors--add-links">
                    <li>
                        <div>
                            <AddToCalendar className="imc-content--small imc-eventdetail__box--type imc-eventdetail__type--color"
                                title={event['Event Title'].value}
                                startDate={this.formatDate(eventStart, 'date')}
                                startTime={this.formatDate(eventStart, 'time').toString()}
                                endDate={this.formatDate(eventEnd, 'date')}
                                endTime={this.formatDate(eventEnd, 'time').toString()}
                                eventTitle={event['Event Title'].value}
                                eventDesc={event['Teaser Text'].value}
                                timezone={event.time.timezone}
                                eventLocation={event.Location[0].fields['Showroom Building'].value +':'+ event.Location[0].fields['Showroom'].value}
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <section className="imc-section imc-content imc-content--center imc-section--padded-jumbo-titan imc-eventdetail">
            <div className="imc-vr--xxxlarge imc-content--primary-desktop imc-eventdetail--tag-box">
            <div className="imc-heading imc-heading--quaternary  imc-heading--gamma"><span>Tags:</span></div>
            <div className="imc-eventdetail__tag">
                <Tag tags={event.Tags} />
            </div>
            </div>
        </section>

        <section class="imc-section imc-section--padded imc-section--alt-gray-light">
            <p>{event['Summary'].value}</p>
        </section>
    </div>
    )
  }
}

export default withSitecoreContext()(
    withSitecoreRouter(ImcEventDetailComponent)
);
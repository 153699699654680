import {
    getSitecoreApiHost,
    getSitecoreApiKey
} from '../AppGlobals';

const ACCOUNT_ID_PLACEHOLDER = "$$ACCOUNT_ID";
const APIKEY_PLACEHOLDER = "$$APIKEY";
const EMAIL_PLACEHOLDER = "$$EMAIL";
const apiEndPoints = {
    'info': `/imc-api/market-planner/v1/accounts/${ACCOUNT_ID_PLACEHOLDER}/my/info?sc_apikey=${APIKEY_PLACEHOLDER}`,
    'channels': `/imc-api/market-planner/v1/channels?sc_apikey=${APIKEY_PLACEHOLDER}`,
    'associateAccount': `/imc-api/v1/association/account/${ACCOUNT_ID_PLACEHOLDER}/associate?sc_apikey=${APIKEY_PLACEHOLDER}`
}

/**
 * Account API
 */
export default class AccountAPI {
    /**
     * Constructor
     */
    constructor() {
        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         * 
         */
        this.getAccountMyInfo = this.getAccountMyInfo.bind(this);
    }


    /**
 * Gets Endpoint Url
 * @param {string} apiId 
 * @returns {string} Endpoint url to fetch data
 */
    getAccountApiEndpoint(apiId) {
        return apiEndPoints[apiId];
    }

    /**
     * Gets Endpoint Url with params
     * @param {string} apiId 
     * @param {string} accountId 
     * @returns {string} Endpoint url to fetch data
     */
    getAccountMyInfoApiEndpointWithParams(apiId, accountId) {
        return `${getSitecoreApiHost()}${this.getAccountApiEndpoint(apiId).replace(ACCOUNT_ID_PLACEHOLDER, accountId).replace(APIKEY_PLACEHOLDER,getSitecoreApiKey())}`;
    }

    /**
     * Gets Endpoint Url with params
     * @param {string} apiId 
     * @param {string} accountId 
     * @returns {string} Endpoint url to fetch data
     */
    getAccountChannelsApiEndpointWithParams(apiId) {
        return `${getSitecoreApiHost()}${apiEndPoints[apiId].replace(APIKEY_PLACEHOLDER,getSitecoreApiKey())}`;
    }

     /**
     * Gets Endpoint Url with params
     * @param {string} apiId 
     * @param {string} accountId 
     * @returns {string} Endpoint url to fetch data
     */
    putAccountAssociateApiEndpointWithParams( email, accountId) {
        return `${getSitecoreApiHost()}${apiEndPoints['associateAccount'].replace(ACCOUNT_ID_PLACEHOLDER, accountId).replace(EMAIL_PLACEHOLDER, email).replace(APIKEY_PLACEHOLDER, getSitecoreApiKey())}`;
    }

    /**
     * Gets Account My Info
     * @param {string} accountId 
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getAccountMyInfo(accountId, channel) {
        return (
            fetch(this.getAccountMyInfoApiEndpointWithParams('info', accountId),{
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'channel': channel,
                }
            })
            .then(response => response.json())
            .catch((err) => {
                // todo: Error Handling
                console.log('handle err here', err);
            })
        );
    }

    /**
     * Gets Account My Info
     * @returns {Promise.<T>} Promise object that returns the Api Object result
     */
    getAccountChannelSelector() {
        return (
            fetch(this.getAccountChannelsApiEndpointWithParams('channels'))
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }
    /*
        Ensure account is associated to market planner account
    */ 
    getAccountAssociated(email, accountId) {
        return (
            fetch(this.putAccountAssociateApiEndpointWithParams(email,accountId),    
            {   method:'put', 
                headers: new Headers({
                    'Content-Type': 'application/json', // <-- Specifying the Content-Type
                }),
                body: `"${email}"`
            })
            .then(response => response.json())
            .catch((err) => {
                // todo: Error Handling
                console.log('handle err here', err);
            })
        );
    }
}
/**
 * Success for getting quick add modal list
 * @type {string}
 */
export const GET_ALLSPECIALS_PRODUCTS_SUCCESS = 'GET_ALLSPECIALS_PRODUCTS_SUCCESS';

export const GET_ALLSPECIALS_PRODUCTS_FAIL = 'GET_ALLSPECIALS_PRODUCTS_FAIL';

export const SHOW_LOADING = 'SHOW_LOADING';

export const END_LOADING = 'END_LOADING';
/**
 * Base class for products events
 */
class ProductEvent{
    
    constructor() {
        this.products = [];
        this.add = this.add.bind(this);
    }


    /**
     * Returns a product object
     * @param {string} name 
     * @param {string} id 
     * @param {string} brand
     * @param {string[]} category
     * @param {string} exhibitorName
     * @param {string} exhibitorId
     * @param {string} page where the product is listed
     * @param {boolean} hasShopZio
     * @param {number} position position in which the product is listed in list
     * @param {number} quantity Used for cart operations.
     * @returns {any} a product object
     */
      getProduct(name, id, brand, category, exhibitorName, exhibitorId, page, hasShopZio, position, quantity ){
        let prod = {
            name: name,
            id: id,
            brand: brand,
            category: !!category?category.join(','):'',
            variant: exhibitorName,
            exhibitorid: exhibitorId,
            dimension34: hasShopZio,
            position: position,
        };
        
        if (!!page){
            prod.list = page;
        }
        if (!!quantity)
            prod.quantity = quantity;
            
        return prod;
    }


    /**
     * add one product to the collection of producst  to be pushed later.
     * @param {string} name The name of the product (e.g. Android T-Shirt). *Either this field or id must be set.
     * @param {string} id The product ID or SKU (e.g. P67890). *Either this field or name must be set.
     * @param {string} brand The brand associated with the product
     * @param {string[]} category The categories to which the product belongs (e.g. Apparel). Array all categories the product belongs to.
     * @param {string} exhibitorName Exhibitor name that listed the product
     * @param {string} exhibitorId Exhibitor id that listed the product
     * @param {boolean} hasEcommerce  Has ecommerce (shopzio, etc)
     * @param {string} page Where the product impression took place (e.g. Search Results, Category: Home Decor, etc). 
     * @param {number} position The products position in a list or collection where the impressions happened (e.g. 2).
     * @param {number} quantity Used for cart operations.
     */
    add (name, id, brand, category, exhibitorName, exhibitorId, hasEcommerce, page, position, quantity=null){
        const product = this.getProduct(name, id, brand, category, exhibitorName, exhibitorId, page, hasEcommerce, position, quantity);
        this.products.push(product);
    }
}

export default ProductEvent;
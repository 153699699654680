import { getSitecoreApiHost, getSitecoreApiKey } from '../AppGlobals';
import UserUtility from '../utils/userutility';

interface ChannelData {
    name: string;
}

export const getDetails = async (eventId: string, channel: string): Promise<any> => {
    const url = `${getSitecoreApiHost()}/imc-api/v1/events/details?sc_apikey=${getSitecoreApiKey()}&eventIds=${eventId}`;
    return fetch(url, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'channel': channel,
        },
    })
        .then((response) => response.json())
        .catch((err) => {
            // todo: Error Handling
            console.error('handle err here', err);
        });
};

export const generateEventICSFileLink = (eventId: string): string | undefined => {
    const channel = (window as any).channel?.name;
    if (eventId) {
        return `${getSitecoreApiHost()}/imc_api/v1/calendarevents/GetICS?eventid=${eventId}&Channel=${channel}`;
    }
};

export const getEventTypes = async (filterKey: string, features = false): Promise<any> => {
    const channelData: ChannelData = UserUtility.getChannelData();
    const channel = channelData.name;
    let filter = '';
    let featuresQuery = '';
    if (filterKey) {
        filter = `&type=${filterKey}`;
    }
    if (features) {
        featuresQuery = '&features=true';
    }
    const serviceUrl = `${getSitecoreApiHost()}/imc-api/v2/events/all/?sc_apikey=${getSitecoreApiKey()}${filter}${featuresQuery}`;

    const response = await fetch(serviceUrl, {
        method: 'get',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'channel': channel,
        },
    });
    return response.json();
};

export const getEventTypesFor = async (): Promise<any> => {
    const channelData: ChannelData = UserUtility.getChannelData();
    const channel = channelData.name;
    const serviceUrl = `${getSitecoreApiHost()}/imc-api/v1/events/filters?sc_apikey=${getSitecoreApiKey()}`;

    const response = await fetch(serviceUrl, {
        method: 'get',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'channel': channel,
        },
    });
    return response.json();
};

import React from 'react';
import { Image, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { isMobile } from 'react-device-detect';

class PersonDocument extends React.Component {
    /**
    * @method contructor
    * @description Lifecycle method
    * @param {object} props incoming props
    */
	constructor(props) {
		super(props);
		if (!props) {
			if (isEditorActive()) {
				return <h1 className="alarm">Datasource isn't set.</h1>;
			}
			return (
				<h1 className="alarm">
					Data is not provided. Contact administrators, please.
				</h1>
			);
		}

		this.callPhone = this.callPhone.bind(this);
	}

	callPhone() {
		if (isMobile) {
			window.open('tel: ' + this.props.fields.phoneNumber.value);
		}
	}

	render() {
		if (this.props.fields.profilePicture != null) {
			this.props.fields.profilePicture.value.src = this.props.fields.profilePicture.value.src.replace('/-/media', '/-/jssmedia').replace("/sitecore/shell", "");
		}
		return (
			<div class="imc-circle-image-text">
				<div class="imc-circle-image-text__image imc-circle-image-text__image--mobile-nopadding imc-vr--xlarge">
					<Image className="imc-content-breaker" field={this.props.fields.profilePicture.value} />
				</div>
				<div class="imc-heading imc-heading--alt imc-heading--alpha imc-vr--small" data-xpath="imagespotlight.contentline1">
					{this.props.fields.name.value}
				</div>
				<div class="imc-vr--small imc-content--alt imc-heading--beta imc-content--primary" data-xpath="imagespotlight.contentline2">
					{this.props.fields.biography.value}
				</div>
				<div class="imc-vr--small imc-content--alt imc-content--primary imc-heading--beta imc-spotlight__padding-left-right" data-xpath="imagespotlight.contentline3">
					<a className="imc-content--alt" href={"mailto:" + this.props.fields.email.value}>{this.props.fields.email.value}</a> | <a onClick={this.callPhone} >{this.props.fields.phoneNumber.value}</a>
				</div>
			</div>
		);
	}
}

export default PersonDocument;
/**
 * Loads the search count from a successful search
 * @type {string}
 */
export const SEARCHRESULTS_COUNT_SUCCESS = 'SEARCHRESULTS_COUNT_SUCCESS';

/**
 * Failed call for search count
 * @type {string}
 */
export const SEARCHRESULTS_COUNT_FAIL = 'SEARCHRESULTS_COUNT_FAIL';

/**
 * Updates the current timestamp to denote removal of loading layer
 * @type {string}
 */
export const SEARCHRESULTS_TIMESTAMP_UPDATE = 'SEARCHRESULTS_TIMESTAMP_UPDATE';

/**
 * Used for updates when new search is ran, etc
 * marking any current results as out of date
 * @type {string}
 */
export const RUN_SEARCH = 'RUN_SEARCH';

/**
 * Used for updates when new search is ran, etc
 * marking any current results as out of date
 * @type {string}
 */
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
/**
 * Used for resetting the clear search function
 * @type {string}
 */
export const CLEAR_SEARCH_COMPLETE = 'CLEAR_SEARCH_COMPLETE';
/**
 * Used for clearing search results from display
 * @type {string}
 */
export const CLEAR_DIRECTORY_SEARCH = 'CLEAR_DIRECTORY_SEARCH';

/**
 * Show Results Tabs
 * @type {string}
 */
export const SHOW_RESULTS = 'SHOW_RESULTS';

/**
 * Show Results Tabs
 * @type {string}
 */
export const SHOW_LOADING = 'SHOW_LOADING';

/**
 * Set Filters Relevant for given key
 * * @type {string}
 */
export const SET_FILTERS = 'SET_FILTERS';

/**
 * Action to get filters for given key
 * * @type {string}
 */
export const GET_FILTERS = 'GET_FILTERS';

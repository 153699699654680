import * as types from './multiSelectActionTypes';

export function itemChanged(item) {
    return dispatch => dispatch({
        type: types.MULTISELECT_ITEM_CHANGE,
        itemChanged: item,
    });
}

export function viewChange(multiSelectMode) {
    return dispatch => dispatch({
        type: types.MULTISELECT_VIEW_CHANGE,
        multiSelectMode: multiSelectMode,
    });
}

export function multiSelectReset() {
    return dispatch => dispatch({
        type: types.MULTISELECT_RESET,
    });
}

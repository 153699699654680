import React, { Component } from 'react'

export default class TextField extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
    */
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            errorMsg: ''
        };
        // this.updateValue = this.updateValue.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    /**
     * Renders data after mounted
     */
    componentDidMount() {
        const { fieldData } = this.props;
        const field = fieldData.field.model;
        this.setState({
            value: (this.props.currentEmail === '' || this.props.currentEmail === undefined) ? field.value : this.props.currentEmail
        })
        if ( (this.props.currentEmail !== '' || this.props.currentEmail === undefined) && this.props.formType === 'subscribe') {
            var valid = true;
            var errorMessages = [];
            if (field.required && !this.props.currentEmail) {
                valid = false;
                errorMessages.push(field.title + " is required");
            }
        
            fieldData.onChange(fieldData.field.valueField.name, this.props.currentEmail, valid, errorMessages);
        }
    }

    handleOnChange(field, fieldValue, callback) {
        var valid = true;
        var errorMessages = [];
        // custom client validation logic here
        if (field.model.required && !fieldValue) {
            valid = false;
            errorMessages.push(field.model.title + " is required");
            this.setState({
                errorMsg: field.model.title + " is required"
            })
        }
        this.setState({
            value: fieldValue,
        });
        callback(field.valueField.name, fieldValue, valid, errorMessages);
    }





    render() {
        const { fieldData } = this.props;
        const field = fieldData.field.model;
        const valueField = fieldData.field.valueField;
        return (
            <div className={field.cssClass}>
                <div
                className={'imc-formfield imc-content'}
                >
                    <div className={'imc-vr--xsmall'}>
                        <label>
                            {field.title}
                            <span className="imc-content--alt-darkred">
                                {field.required ? '*' : ''}
                            </span>
                        </label>
                    </div>
                    <input 
                        placeholder={field.placeholderText} 
                        className={fieldData.isValid ? '' : 'invalid'} 
                        value={this.state.value} 
                        onChange={(e) => this.handleOnChange(fieldData.field, e.target.value, fieldData.onChange)} 
                        id={valueField.id} 
                        name={valueField.name} 
                        type="text"
                    />

                    {this.state.errorMsg !== '' || !fieldData.isValid &&
                        <div className={'imc-vr--xsmall'}>
                            <p className={'imc-type--error'}>{field.title + " is required"}</p>
                        </div>
                    }

                </div>
            </div>
        )
    }
}
/**
 * Checks the browser and viewing device to allow for alternate experiences
 * and graceful degradation. Includes a method tha returns browser and device
 * type.
 */
export default class UserAgent {
    /**
     * Detect if browser is IE (from version 6 to 11)
     * @returns {boolean} flag if browser is IE
     */
    static isIE() {
        // eslint-disable-next-line spaced-comment
        return /*@cc_on!@*/false || !!document.documentMode;
    }

    /**
     * Detects if the browser is Edge 20+
     * @returns {boolean} flag if browser is Edge
     */
    static isEdge() {
        return !UserAgent.isIE() && !!window.StyleMedia;
    }

    /**
     * Detects if Android
     * @returns {boolean} Flag if the OS is Android
     */
    static isAndroid() {
        return navigator.userAgent.match(/Android/i);
    }

    /**
     * Detects if iOS
     * @returns {boolean} Flag if the OS is iOS
     */
    static isIOS() {
        return navigator.userAgent.match(/iPhone|iPad/i);
    }

    /**
     * Detects if iPad
     * @returns {boolean} Flag if the device is an iPad
     */
    static isIPad() {
        return navigator.userAgent.match(/iPad/i);
    }

    /**
     * Detects Firefox 1.0+
     * @returns {boolean} Flag if the browser is Firefox
     */
    static isFirefox() {
        return typeof InstallTrigger !== 'undefined';
    }

    /**
     * Detects Safari 3.0+
     * @returns {boolean} Flag if the browser is Safari
     */
    static isSafari() {
        return /constructor/i.test(window.HTMLElement) ||
            (function checkSafari(p) {
                return p.toString() === '[object SafariRemoteNotification]';
            }(!window.safari || window.safari.pushNotification));
    }

    /**
     * Adds class names if necessary
     * @private
     */
    static addClasses() {
        if (this.isIE()) {
            document.body.classList.add('msie');
        }

        if (this.isEdge()) {
            document.body.classList.add('ms-edge');
        }

        if (this.isAndroid()) {
            document.body.classList.add('android');
        }

        if (this.isIOS()) {
            document.body.classList.add('ios');
        }

        if (this.isIPad()) {
            document.body.classList.add('ipad');
        }

        if (this.isSafari()) {
            document.body.classList.add('safari');
        }

        if (this.isFirefox()) {
            document.body.classList.add('firefox');
        }
    }
}

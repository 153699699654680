import { GET_ALLSPECIALS_PRODUCTS_SUCCESS, GET_ALLSPECIALS_PRODUCTS_FAIL, SHOW_LOADING, END_LOADING } from '../actions/actionTypes';
import { InitialState } from './initialState';
import ImcDataLayer from 'utils/datalayer';

function gtmPushSpecials(markets) {
    if (!markets)
        return;

    const specials = Object.keys(markets).flatMap(index => {
        return markets[index].specials;
    });

    ImcDataLayer.PushPromotionImpressions(specials);
}

/**
 * Reducer for loader actions
 * @param {object} state State of the exhibitor categories
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function allSpecialsReducer(state = InitialState.exhibitorSpecials.isLoading, action) {
    switch (action.type) {
        case GET_ALLSPECIALS_PRODUCTS_SUCCESS:
            //reorganize the results...
            var count = 0;
            var markets = {};
            var exhibitors = {};

            action.specials.map(x => {
                if (x.data) {
                    x.data.map(y => {
                        if (typeof markets[y.marketId] == 'undefined')
                            markets[y.marketId] = { 'specials': [] };
                        y.list.map(z => {
                            markets[y.marketId].specials.push(z);

                        });
                        count += y.list.length;
                    })

                    exhibitors[x.exhibitorId] = JSON.parse(x.companyDetails.company_details_json_t);
                }
            });

            gtmPushSpecials(markets);

            return {
                ...state,
                specials: markets,
                exhibitors: exhibitors,
                specialsCount: count,
                specialsUpToDate: true,
                error: false
            };

        case GET_ALLSPECIALS_PRODUCTS_FAIL:
            return {
                ...state,
                error: true,
                specials: [],
                specialsCount: 0,
                specialsUpToDate: false,
            };

        case SHOW_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case END_LOADING:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

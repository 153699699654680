import {
  GraphQLLayoutService,
  RestLayoutService,
  constants,
} from '@sitecore-jss/sitecore-jss-react';
import config from '../temp/config';

export class LayoutServiceFactory {
    create(sitename) {
        return process.env.REACT_APP_FETCH_WITH === constants.FETCH_WITH.GRAPHQL
            ? new GraphQLLayoutService({
                apiKey: config.sitecoreApiKey,
                siteName: sitename ?? config.sitecoreSiteName,
                configurationName: 'sxa-jss',
            })
            : new RestLayoutService({
                apiKey: config.sitecoreApiKey,
                siteName: sitename ?? config.sitecoreSiteName,
                configurationName: 'sxa-jss',
            });
    }
}

export const layoutServiceFactory = new LayoutServiceFactory();

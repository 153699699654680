import React from 'react';
import FlexGrid from '../Flex-Grid';
import ContentSectionComponent from '../Content-Section-Component';
import RichTextComponent from '../Rich-Text-Component';
import ImcCta from '../Imc-cta';
import StandaloneImageComponent from '../Standalone-Image-Component';
import StandaloneVideoComponent from '../Standalone-Video-Component';
import {contentSectionComponentRendering, richTextRendering, flexGridRendering, imcCtaRendering, standaloneImageRendering, returnMediaSection, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'

// ---------------------- //
// define props interface //
// ---------------------- //
interface BasicPillBoxSectionProps {
    fields?: any;
};
// ---------------------- //

// ------------------------ //
// Basic Two Column Section //
// ------------------------ //
const BasicPillBoxSection: React.FC<BasicPillBoxSectionProps> = ({fields}) => {
    let grid = flexGridRendering();
    let headline = richTextRendering();
    let copy = richTextRendering();
    let textWrapper = contentSectionComponentRendering();
    let textSection = contentColumnSectionRendering(fields);
    let imageSection = contentSectionComponentRendering();
    let secondaryImageSection = contentSectionComponentRendering();
    let primaryCTA = imcCtaRendering();
    let secondaryCTA = imcCtaRendering();
    let columnCount = 4;
    let imageWidth = 2;
    let showSecondImage = false;
    let firstImagePosition = (fields.reverseColumns.value) ? 'three' : 'one';
    let secondImagePosition = "two";
    let contentWidth = 2;
    let backgroundColor = (fields?.backgroundColor?.value) ? fields?.backgroundColor?.value : 'basic-white';
    let accentColor = (fields?.accentColor?.value) ? fields?.accentColor?.value : 'primary-light';
    let secondaryAccentColor = (fields?.accentColor?.value) ? fields?.accentColor?.value : 'secondary-light';
    let pillBoxClass = "";
    if (fields.extraClasses?.length > 0) {
        fields.extraClasses.forEach((c?: any) => {
            if (c.fields.className.value.toLowerCase().includes("pillbox")) {
                pillBoxClass += " " + c.fields.className.value.toLowerCase();
            }
        });

        // extra values from extra classes
        if (pillBoxClass.includes("column")) {
            pillBoxClass.split(" ").forEach((word: string) => {
                if (word.includes("column")) {
                    columnCount = parseInt(word.split("-")[0]);
                }
            });
        }
        if (pillBoxClass.includes("image")) {
            pillBoxClass.split(" ").forEach((word: string) => {
                if (word.includes("image")) {
                    if (parseInt(word.split("-")[0]) == 2) {
                        showSecondImage = true;
                        imageWidth = 1;
                    }
                }
            });
        }
        if (pillBoxClass.includes("content")) {
            pillBoxClass.split(" ").forEach((word: string) => {
                if (word.includes("content")) {
                    if (parseInt(word.split("-")[0]) == 3) {
                        contentWidth = 3;
                    }
                }
            });
        }
    }
    if (columnCount == 4 && fields.reverseColumns.value) {
        firstImagePosition = 'three';
        if (contentWidth === 3 || showSecondImage) {
            firstImagePosition = 'four';
            secondImagePosition = 'three';
        }
    }

    grid.params.columnCount = `{\"className\":{\"value\":\"${columnCount}\"}}`;
    grid.params.padding = "{\"className\":{\"value\":\"imc-gallery--no-padded\"}}";
    grid.params.mobileColumnCount = "{\"className\":{\"value\":\"2\"}}";
    grid.params.tabletColumnCount = "{\"className\":{\"value\":\"2\"}}";
    grid.params.extraClasses = "{\"className\":{\"value\":\"am-grid--pill-box\"}}";

    if (fields.extraClasses?.length > 0) {
        fields.extraClasses.forEach((c?: any) => {
            if (c.fields?.className?.value?.toLowerCase().includes("imc-gallery--")) {
                let v = c.fields?.className?.value?.toLowerCase();
                if (v.includes("padded")) {
                    grid.params.padding = `{\"className\":{\"value\":\"${v}\"}}`;
                }
            }
        });
    }

    textWrapper.fields.backgroundColor.value = `${backgroundColor} am-interior am-interior-content`;
    if (fields.backgroundOverride?.value) {
        textWrapper.fields.styles = {
            "backgroundColor": `${fields.backgroundOverride.value}`
        };
    }
    textWrapper.fields.extraClass.fields.className.value = ` rect-1-h rect-${contentWidth}-w`;
    textSection.fields.paddingTop.value = "jumbo";
    textSection.fields.paddingBottom.value = "jumbo";
    textSection.fields.paddingLeft.value = "xlarge";
    textSection.fields.paddingRight.value = "xlarge";

    imageSection.fields.backgroundColor.value = `${fields.accentColor.value} am-interior`;
    imageSection.fields.extraClass.fields.className.value = ` rect-1-h rect-${imageWidth}-w`;
    if (fields.accentOverride?.value) {
        imageSection.fields.styles = {
            "backgroundColor": `${fields.accentOverride.value}`
        };
    }

    secondaryImageSection.fields.backgroundColor.value = `${fields.secondaryAccentColor.value} am-interior`;
    secondaryImageSection.fields.extraClass.fields.className.value = ` rect-1-h rect-${imageWidth}-w`;
    if (fields.secondaryAccentOverride?.value) {
        secondaryImageSection.fields.styles = {
            "backgroundColor": `${fields.secondaryAccentOverride.value}`
        };
    }

    primaryCTA.fields.extraClass.fields.className.value = "imc-button--left";
    secondaryCTA.fields.extraClass.fields.className.value = "imc-button--left";

    // -------------------- //
    // build content column //
    // -------------------- //
    textWrapper.placeholders['imc-jss-content-section'].push(textSection);
    // -------------------- //
    // build  image  column //
    // -------------------- //
    
    if (fields.videoFile?.value?.href != "" || (fields.primaryCTA?.value?.href != "" && fields.linkImage.value)) {
        imageSection.placeholders['imc-jss-content-section'].push(returnMediaSection(fields.image, fields.videoFile, null, fields.primaryCTA, fields.linkImage));
    } else {
        let primaryImageRendering = standaloneImageRendering();
        primaryImageRendering.fields.image = fields.image;
        imageSection.placeholders['imc-jss-content-section'].push(primaryImageRendering);
    }
    grid.placeholders[`imc-grid-column-${firstImagePosition}-placeholder`].push(imageSection);
    if (showSecondImage) {
        let secondaryImageRendering = standaloneImageRendering();
        if (fields.secondaryVideoFile?.value?.href != "" || (fields.secondaryCTA?.value?.href != "" && fields.linkImage.value)) {
            secondaryImageSection.placeholders['imc-jss-content-section'].push(returnMediaSection(fields.secondaryImage, fields.secondaryVideoFile, null, fields.secondaryCTA, fields.linkSecondaryImage));
        } else {
            secondaryImageRendering.fields.image = fields.secondaryImage;
            secondaryImageSection.placeholders['imc-jss-content-section'].push(secondaryImageRendering);
        }
        grid.placeholders[`imc-grid-column-${secondImagePosition}-placeholder`].push(secondaryImageSection);
    }
    grid.placeholders[`imc-grid-column-${(fields.reverseColumns.value) ? 'one' : 'three'}-placeholder`].push(textWrapper);
    // -------------------- //
    if (fields.reverseColumns.value) grid.params.reverseMobile = "1";
    // -------------------- //
    return (
        <FlexGrid {...grid} rendering={grid} />
    );
};
// ------------------------ //

export default BasicPillBoxSection;

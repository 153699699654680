import {
    GET_EXHIBITORSPECIALS_PRODUCTS_SUCCESS,
    GET_EXHIBITORSPECIALS_PRODUCTS_FAIL,
    SHOW_LOADING, END_LOADING } from './actionTypes';

import ExhibitorsAPI from '../../../api/exhibitor.js';
const _exhibitorAPI = new ExhibitorsAPI();

/**
 * Action to get exhibitors related to privided search term
 * @param {string} query Query to run
 * @param {object} filters Object of selected filters
 * @param {string} exhibitId of current exhibitor
 * @param {bool} isLine  is Line page
 * @param {string} pagenum of current page
 * @returns {Function} Promise object with fetch to the SearchExhibitors
 */
export function fetchSpecials(exhibitorId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
        });
        
        return _exhibitorAPI.getExhibitorSpecials(exhibitorId).then((response) => {
            const count = response.length>0? response[0].list?.length:0;
            dispatch({
                type: GET_EXHIBITORSPECIALS_PRODUCTS_SUCCESS,
                specials: response,
                count: count,
            });
            dispatch({
                type: END_LOADING,
            });
            
        }).catch(err=>{
            dispatch({
                type: GET_EXHIBITORSPECIALS_PRODUCTS_FAIL,
                err: err,
            });
            dispatch({
                type: END_LOADING,
            });
        })
        ;
    };
}
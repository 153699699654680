import i18n from 'i18next';
import React from 'react';

const DESKTOP_POSTFIX = '_desktop';
const MOBILE_POSTFIX = '_mobile';

export const SEARCH_RESULTS_DICTIONARY_KEY_PREFIX = 'searchResultsMessage_';

function replaceValues(format, replaceObj = {}){
  let results = format;
  Object.keys(replaceObj).forEach(k=>{
      let key = `{${k}}`;
      results = results.replace(key, replaceObj[k]);
  });
  return results;
}

/**
 * 
 * @param {string} key  dictionary key
 * @param {string} defaultValue valur returned if key is not present in the dictionary
 * @param {*} replaceObj object with values for string replacement, same functionality that i18n.t provides
 */
export function getDictionaryValue(key, defaultValue, replaceObj = {}) {
  if(key === null || typeof key ==='undefined'){
    return "";
  }
  let value = i18n.t(key.split('.').join('_'), replaceObj);
  return (value === key.split('.').join('_')) ? replaceValues(defaultValue, replaceObj) : value;
}
 

export function renderDesktopMobileVariations(key, defaultValue, replaceObj = {}) {
  const desktopKey = `${key.split('.').join('_')}${DESKTOP_POSTFIX}`;
  const mobileKey = `${key.split('.').join('_')}${MOBILE_POSTFIX}`;
  let desktopValue = i18n.t(desktopKey, replaceObj);
  desktopValue = (desktopValue === desktopKey) ? defaultValue : desktopValue;
  let mobileValue = i18n.t(mobileKey, replaceObj);
  mobileValue = (mobileValue === mobileKey) ? defaultValue : mobileValue;
  return <><span className="imc-breakpoint-display--hide-mobile">{desktopValue}</span><span className="imc-breakpoint-display--hide-desktop">{mobileValue}</span></>;
}

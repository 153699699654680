import React, { Component } from 'react'

export default class TextField extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
    */
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            errorMsg: ''
        };
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    /**
     * Handles client side validations before sending to the parent
     * @param {string} value String checked coming from the Checked field
     */
    // updateValue(e) {
    //     this.setState({
    //         value: e.target.value,
    //     });
    // }

    handleOnChange(field, fieldValue, callback) {
        var valid = true;
        var errorMessages = [];
        // custom client validation logic here
        if (field.model.required && !fieldValue) {
            valid = false;
            errorMessages.push(field.model.title + " is required");
            this.setState({
                errorMsg: field.model.title + " is required"
            })
        }
        this.setState({
            value: fieldValue,
        });
        callback(field.valueField.name, fieldValue, valid, errorMessages);
    }


    render() {
        const { fieldData } = this.props;
        const { value } = this.state;
        const field = fieldData.field.model;
        const valueField = fieldData.field.valueField;
        return (
            <div className={'imc-vr--xlarge'}>
                <div
                className={'imc-formfield imc-content'}
                data-xpath={'Container'}
                >
                    <div className={'imc-vr--xsmall'}>
                        <label>
                            {field.title}
                            <span className="imc-content--alt-darkred">
                                {field.required ? '*' : ''}
                            </span>
                        </label>
                    </div>
                    <textarea className={fieldData.isValid ? '' : 'invalid'} onChange={(e) => this.handleOnChange(fieldData.field, e.target.value, fieldData.onChange)} id={valueField.id} name={valueField.name} value={value}></textarea>
                    {this.state.errorMsg !== '' || !fieldData.isValid &&
                        <div className={'imc-vr--xsmall'}>
                            <p className={'imc-type--error'}>{field.title + " is required"}</p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

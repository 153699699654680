import React from 'react';
import { isEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Link from '../Link';

class LinktoChannelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocation: ''
    };
  }

  componentDidMount() {
    this.setState({ currentLocation: window.location.origin });
  }
  render() {
    const { fields, rendering } = this.props;
    if (!fields) {
      if (isEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    const { currentLocation } = this.state;
    const leftAlign = fields.links.filter((item) => { return !item.fields.rightAlign.value });
    const rightAlign = fields.links.filter((item) => { return item.fields.rightAlign.value });
    return (
      <div className={`link-to-channel imc-breakpoint-display--hide-mobile campus-bar ${isEditorActive() ? 'ee-active' : ''}`}>
        <div className="link-to-channel--wrap">
          <div className="year-round">
            <ul className="links-wrap">
              {leftAlign.map((item, key) =>
                <li key={key}>
                  <Link
                    field={item.fields.link.value}
                    className={currentLocation === item.fields.link.value.href ? 'active' : ''}
                  />
                </li>
              )}
            </ul>
          </div>
          <div className="market-shows">
            <ul className="links-wrap">
              {rightAlign.map((item, key) =>
                <li key={key}>
                  <Link
                    field={item.fields.link.value}
                    className={currentLocation === item.fields.link.value.href ? 'active' : ''}
                    key={key}
                  />
                </li>
              )}
            </ul>
          </div>
          <Placeholder name="imc-header-user-icon" rendering={rendering} />
        </div>
      </div>
    )
  }
}
export default LinktoChannelComponent;

import { getSitecoreApiHost, getSitecoreApiKey } from '../../../AppGlobals';

const requestOptions = (channel: string) => {
    return {
        method: "GET",
        headers: {
            Channel: `${channel}`,
        },
        redirect: "follow" as RequestRedirect
    }
};

class MarketAPIClass {
    constructor() {
        this.getCurrentMarket = this.getCurrentMarket.bind(this);
        this.getNextMarket = this.getNextMarket.bind(this);
        this.getAllFutureMarkets = this.getAllFutureMarkets.bind(this);
    }

    getCurrentMarket(channel: string) {
        return fetch(`${getSitecoreApiHost()}/imc-api/markets/v1/current?sc_apikey=${getSitecoreApiKey()}`, requestOptions(channel))
            .then(response => response.json());
    }

    getNextMarket(channel: string) {
        return fetch(`${getSitecoreApiHost()}/imc-api/markets/v1/next?sc_apikey=${getSitecoreApiKey()}`, requestOptions(channel))
            .then(response => response.json());
    }

    getAllFutureMarkets(channel: string) {
        return fetch(`${getSitecoreApiHost()}/imc-api/markets/v1/future?sc_apikey=${getSitecoreApiKey()}`, requestOptions(channel))
            .then(response => response.json());
    }
}

export default MarketAPIClass;
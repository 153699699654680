import React, {Component} from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 42,
        boxShadow: null,
        borderRadius: 0,
        borderColor: 'black',
        '&:hover': {
            borderColor: 'black',
        },
    }),
};

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectValue: null
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOption) {
        this.setState(() => {
            return {selectValue: selectedOption};
        });
        this.props.onSelect(selectedOption);
    }

    render() {


        const {className, options, placeholder, width} = this.props;

        if (typeof this.state.selectValue?.value !== 'undefined'
            && options.findIndex(item => item.value === this.state.selectValue.value) === -1) {
            this.handleChange(options[0]);
        }

        return (
            <div style={{width: width}}>
                <Select
                    classNamePrefix="react-select"
                    className={className}
                    styles={customStyles}
                    value={this.state.selectValue}
                    width={width}
                    options={options}
                    placeholder={placeholder || 'Select'}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

Dropdown.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

Dropdown.defaultProps = {
    width: '200px',
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;

import { getAPIEndpoint } from 'utils/getapiendpoint';
import { serialize } from 'utils/serialize';
import { decode } from 'utils/querystring';
import { filterquerymodifier } from 'utils/filterquerymodifier';
import qs from 'query-string';

/**
 * Download Search API
 */
export default class DownloadResultsAPI {
    /**
     * Constructor
     */
    constructor() {
        this.downloadResults = this.downloadResults.bind(this);

        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         */
        this._apiKey = 'searchApiDownloadExhibitors';
    }

    /**
     * Gets the CSV results
     * @param {string} filterKey Key for the filter to use
     */
    downloadResults(filterKey) {
        const url ='https://www.lasvegasmarket.com/search-api/download/downloadExhibitors' /* getAPIEndpoint(this._apiKey); */
        const queryObject = qs.parse(typeof window != "undefined" ? window.location.search : null);
        const params = {};
        let filters = '';
        let finalQuery = '';
        if (queryObject.q) {
            const q = queryObject.q;
            params.q = q.replace(/['"]+/g, '');
            if (q.charAt(0) === '"' && q.charAt(q.length - 1) === '"') {
                params.exactmatch = true;
            }
            finalQuery = serialize(params);
        }
        if (filterKey) {
            filters = queryObject[filterKey] &&
                queryObject[filterKey].length > 0 ? decode(queryObject[filterKey]) : '';
            filters = filterquerymodifier(serialize(filters));
            if (finalQuery.length > 0) {
                finalQuery += `&${filters}`;
            } else {
                finalQuery += `${filters}`;
            }
        }
        window.open(`${url}?${finalQuery}`,'_blank');
    }
}

import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Link from '../Link';
import { withCookies, Cookies } from 'react-cookie';
import ImcDataLayer from 'utils/datalayer';

class HeaderUserIconComponent extends React.Component {
  constructor(props) {
    super(props);
    this.toogleDropdown = this.toogleDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.settingsLinkonClick = this.settingsLinkonClick.bind(this);
    this.state = {
      showDropdown: false,
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeDropdown();
    }
  }

  toogleDropdown() {
    this.setState({
      showDropdown: !this.state.showDropdown,
    })
  }
  
  closeDropdown() {
    if (this.state.showDropdown)
      this.setState({
        showDropdown: false,
      })
  }
  
  settingsLinkonClick(e){
    const { cookies } = this.props;
    
    if (!!e.target.href && e.target.href.includes("logout")){
      console.info("removing signup form cookie");
      cookies.remove('updateProfileAcknowledge');
    }
  }

  render() {
    const { fields } = this.props;
    if (!fields) {
      if (isEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    if ('loginLink' in fields) {
      return <Link 
      onClick={()=>ImcDataLayer.pushInteractionEvent("account", "login", "attempt")} 
      className="imc-header-user-icon--loginlink" field={fields.loginLink.fields.link.value}></Link>
    }
    const { showDropdown } = this.state;
    if ('userGreeting' in fields) {
      return <div className="imc-header-user-icon--wrapper" ref={(e) => this.wrapperRef = e}>
        <button className={`imc-header-user-icon--greeting ${showDropdown ? 'open' : ''}`} onClick={this.toogleDropdown}>{fields.userGreeting.value}</button>
        {showDropdown &&
          <div className="imc-header-user-icon--dropdown">
            {('portalLinks' in fields) &&
              <div className="imc-header-user-icon--links-portal">
                {fields.portalLinks.map((portalLink, index) => <Link key={index} className="imc-link imc-header-user-icon--link" field={portalLink.fields.link.value}></Link>)}
              </div>
            }
            {('settingsLinks' in fields) &&
              <div className="imc-header-user-icon--links-settings">
                {fields.settingsLinks.map((settingsLink, index) => <Link onClick={this.settingsLinkonClick} className="imc-link imc-header-user-icon--link" key={index} field={settingsLink.fields.link.value}></Link>)}
              </div>
            }
          </div>
        }
      </div>
    }
  }
}

export default withCookies (HeaderUserIconComponent);

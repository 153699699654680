import {
    SET_CONTACT_EXHIBITOR_INFO,
    SET_CONTACT_EXHIBITOR_INFO_ERR,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {
    companyName: '',
    productCategories: [],
    contactInfo: {},
    socialMedia: {},
    showLoading: true,
};

/**
 * Reducer for Contact Exhibitor Information
 * @param {object} state State of the contact exhibitor information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function contactExhibitorInfoReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_CONTACT_EXHIBITOR_INFO:
            return Object.assign({}, state, {
                companyName: action.companyName,
                productCategories:action.productCategories,
                contactInfo:action.contactInfo,
                socialMedia:action.socialMedia,
                showLoading: true
            });
        case SET_CONTACT_EXHIBITOR_INFO_ERR:
            return Object.assign({}, state, {
                companyName: initialState.companyName,
                productCategories:initialState.productCategories,
                contactInfo:initialState.contactInfo,
                socialMedia:initialState.socialMedia,
                errCode: action.status || 404,
            });
        default:
            return state;
    }
}
import React from 'react';
import ReactModal from 'react-modal';
import { Image, } from "@sitecore-jss/sitecore-jss-react";
import { useSwipeable, Swipeable } from 'react-swipeable'

//Local components
import Floor from "../components/Floor.jsx";

//Local utils
import { sortDescElements } from "../utils";

//Utils
import { getDictionaryValue } from 'utils/dictionary';

ReactModal.setAppElement('#root');

class Building extends React.Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.renderModalFloors = this.renderModalFloors.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
    }
    componentDidMount() {
        this.scrollToBottom();
    }
    componentDidUpdate() {
        this.scrollToBottom();
    }


    renderEqualGroundFloors(count) {
        return Array.from({ length: count - 1 }, (_, k) => (
            <div key={k} className="imc-campus-view--floor-empty"></div>
        ))
    }

    renderIcon(icon) {
        if (icon && icon.value !== undefined)
            return <div className="imc-campus-view--building-icon"> {icon.value.svgCode !== undefined ?
                <div dangerouslySetInnerHTML={{ __html: icon.value.svgCode }} /> :
                <Image media={icon.value} />}
            </div>
    }

    renderFloors(buildingName, orderedFloors, firstFloorOrder, icon, backgroundColor, fontColor) {
        return <>{orderedFloors.map((floor, index) => {
            return <Floor icon={icon} defaultBackgroundColor={backgroundColor} defaultFontColor={fontColor} {...floor.fields} key={index} buildingName={buildingName} ></Floor>
        })}
            {this.renderEqualGroundFloors(firstFloorOrder)}
        </>
    }
    closeModal() {
        this.props.resetSelectedStructure();

    }
    scrollToBottom() {
        const { id, selectedStructure } = this.props;
        const isOpen = id === selectedStructure;
        if (isOpen)
            setTimeout(() => {
                if (this.modalContent) {
                    const scrollHeight = this.modalContent.scrollHeight;
                    const height = this.modalContent.clientHeight;
                    const maxScrollTop = scrollHeight - height;
                    this.modalContent.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
                }
                else
                    this.scrollToBottom()
            }, 1);

    }

    renderModalFloors(buildingName, orderedFloors, firstFloorOrder, icon, backgroundColor, fontColor, isOpen) {
        const { prevClick, nextClick, id } = this.props;
        return <ReactModal
            isOpen={isOpen}
            overlayClassName="imc-modal--overlay"
            className="imc-campus-view--modal"
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
            shouldFocusAfterRender={false}
        >
            <Swipeable 
                onSwipedRight={(e) => {prevClick(e,id)}}
                onSwipedLeft={(e) => {nextClick(e,id)}}
            >
                <div
                    className="imc-campus-view--modal-content" 
                    ref={(el) => { this.modalContent = el; }}
                >
                    <div className="imc-campus-view--modal-content-align-wrapper">
                        <div className="imc-campus-view--modal-content-grow"></div>
                        <div className="imc-campus-view--modal-content-items-wrapper">
                            {this.renderFloors(buildingName, orderedFloors, firstFloorOrder, icon, backgroundColor, fontColor)}
                        </div>
                    </div>
                </div>
            </Swipeable>
            <div className="imc-campus-view--modal-base">
                <button className="imc-campus-view--modal-arrow imc-campus-view--modal-arrow-left" data-structureid={id} onClick={prevClick}></button>
                <div className="imc-content--display-flex imc-content--display-flex-center imc-content--display-flex-center">{this.renderIcon(icon)} <span>{buildingName}</span></div>
                <button className="imc-campus-view--modal-arrow imc-campus-view--modal-arrow-right" data-structureid={id} onClick={nextClick}></button>
            </div>
            <button className="imc-campus-view--modal-close" onClick={this.closeModal}></button>
        </ReactModal>
    }

    render() {
        const { id, icon, backgroundColor, fontColor, name, floors, image, roofWalkwayLabel, onClick, mobileView, selectedStructure } = this.props;
        const orderedFloors = floors.sort(sortDescElements);
        if (orderedFloors.length > 0) {
            const firstFloorOrder = orderedFloors[orderedFloors.length - 1].fields.order.value;
            const isOpen = id === selectedStructure;
            return (
                <div className="imc-campus-view--clickeable imc-campus-view--building" id={id} onClick={onClick}>
                    <div className="imc-campus-view--building-image">
                        {
                            image.value.svgCode !== undefined
                                ? <div dangerouslySetInnerHTML={{ __html: image.value.svgCode }} />
                                : <Image media={image.value} />
                        }
                    </div>
                    {mobileView ?
                        <>
                            {this.renderModalFloors(name.value, orderedFloors, firstFloorOrder, icon, backgroundColor, fontColor, isOpen)}
                        </>
                        :
                        <>
                            <div className="imc-campus-view--building-title-wrap">
                                <div className="imc-campus-view--building-title">{name.value}</div>
                                {
                                    roofWalkwayLabel &&
                                    <div className="imc-campus-view--building-walkway-label imc-breakpoint-display--hide-mobile">
                                        {getDictionaryValue('walkways', 'WALKWAYS')}
                                    </div>
                                }
                            </div>
                            {this.renderFloors(name.value, orderedFloors, firstFloorOrder, icon, backgroundColor, fontColor)
                            }


                        </>
                    }
                    <div className="imc-campus-view--building-base">
                        {this.renderIcon(icon)} <span>{name.value}</span>
                    </div>
                </div>
            )
        }
        return null;
    }
}

export default Building;

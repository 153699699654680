import React from 'react';
import { Image, } from "@sitecore-jss/sitecore-jss-react";
import Link from '../../Link';
import { getFloorPlanURL } from "utils/floorplan";

class Section extends React.Component {

    renderIcon(icon) {
        if (icon && icon.value !== undefined)
            return <div className="imc-campus-view--section-icon"> {icon.value.svgCode !== undefined ?
                <div dangerouslySetInnerHTML={{ __html: icon.value.svgCode }} /> :
                <Image media={icon.value} />}
            </div>
    }
    render() {
        const { id, number, icon, backgroundColor, defaultBackgroundColor, fontColor, defaultFontColor, name, pavilionName } = this.props;
        const _backgroundColor = (backgroundColor !== null && backgroundColor.fields && backgroundColor.fields.hexColor)
            ? backgroundColor.fields.hexColor.value
            : (defaultBackgroundColor !== null && defaultBackgroundColor.fields && defaultBackgroundColor.fields.hexColor)
                ? defaultBackgroundColor.fields.hexColor.value : null;
        const _fontColor = (fontColor !== null && fontColor.fields && fontColor.fields.hexColor)
            ? fontColor.fields.hexColor.value
            : (defaultFontColor !== null && defaultFontColor.fields && defaultFontColor.fields.hexColor)
                ? defaultFontColor.fields.hexColor.value : null;

        let colorStyles = {};
        if (_backgroundColor) colorStyles.backgroundColor = _backgroundColor;
        if (_fontColor) colorStyles.color = _fontColor;
        let hasMobileCookie = false;
        if (typeof document !=="undefined"){
            hasMobileCookie = decodeURIComponent(document.cookie).includes("ImcMobileApp");
        }
        return (
            <div className="imc-campus-view--section" id={id}>
                <div className="imc-campus-view--section-number">
                    {!hasMobileCookie && <Link href={getFloorPlanURL(pavilionName, number.value)} target="_blank" className="imc-campus-view-link">{this.renderIcon(icon)}<span>{number.value}</span>
                    </Link>}
                    {hasMobileCookie && <a href={getFloorPlanURL(pavilionName, number.value)}  className="imc-campus-view-link">{this.renderIcon(icon)}<span>{number.value}</span>
                    </a>}
                </div>
                <div className={`imc-campus-view--section-name`} style={colorStyles}>
                {!hasMobileCookie && <Link href={getFloorPlanURL(pavilionName, number.value)} target="_blank" className="imc-campus-view-link" style={colorStyles}>{name.value}
                    </Link>}
                {hasMobileCookie && <a href={getFloorPlanURL(pavilionName, number.value)}  className="imc-campus-view-link" style={colorStyles}>{name.value}
                    </a>}
                </div>
            </div>
        )
    }
}


export default Section;

import React from 'react';
import Pavilion from "./Pavilion.jsx";
import ReactModal from 'react-modal';
import { Image, } from "@sitecore-jss/sitecore-jss-react";
import { sortAscElements } from "../utils";

class Pavilions extends React.Component {

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.renderModalPavilions = this.renderModalPavilions.bind(this);
  }

  renderIcon(icon) {
    if (icon && icon.value !== undefined)
      return <div className="imc-campus-view--pavilions-icon"> {icon.value.svgCode !== undefined ?
        <div dangerouslySetInnerHTML={{ __html: icon.value.svgCode }} /> :
        <Image media={icon.value} />}
      </div>
  }

  renderPavilions(orderedPavilions, icon) {
    return orderedPavilions.map((pavilion, index) => {
      return <Pavilion icon={icon} {...pavilion.fields} key={index} ></Pavilion>
    });
  }

  closeModal() {
    this.props.resetSelectedStructure();

  }

  renderModalPavilions(pavilionName, orderedPavilions, icon, isOpen) {
    const { prevClick, nextClick, id } = this.props;
    return <ReactModal
      isOpen={isOpen}
      overlayClassName="imc-modal--overlay"
      className="imc-campus-view--modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      shouldFocusAfterRender={false}
    >
      <div className="imc-campus-view--modal-content-align-wrapper">
        <div className="imc-campus-view--modal-content-grow"></div>
        <div className="imc-campus-view--modal-content-items-wrapper">
          {this.renderPavilions(orderedPavilions, icon)}
        </div>
      </div>
      <div className="imc-campus-view--modal-base">
        <button className="imc-campus-view--modal-arrow imc-campus-view--modal-arrow-left" data-structureid={id} onClick={prevClick}></button>
        <div className="imc-content--display-flex imc-content--display-flex-center imc-content--display-flex-space-between">{this.renderIcon(icon)} <span>{pavilionName}</span></div>
        <button className="imc-campus-view--modal-arrow imc-campus-view--modal-arrow-right" data-structureid={id} onClick={nextClick}></button>
      </div>
      <button className="imc-campus-view--modal-close" onClick={this.closeModal}></button>
    </ReactModal>
  }



  render() {

    const { id, icon, name, subtitle, pavilions, image, onClick, mobileView, selectedStructure } = this.props;
    const orderedPavilions = pavilions.sort(sortAscElements);
    const isOpen = id === selectedStructure;
    if (orderedPavilions.length > 0) {
      return (
        <div className="imc-campus-view--clickeable imc-campus-view--pavilions" id={id} onClick={onClick}>
          <div className="imc-campus-view--pavilions-image">
            {
              image.value.svgCode !== undefined
                ? <div dangerouslySetInnerHTML={{ __html: image.value.svgCode }} />
                : <Image media={image.value} />
            }
          </div>
          {mobileView ?
            <>{this.renderModalPavilions(name.value, orderedPavilions, icon, isOpen)}</>
            : <>
              <div className="imc-campus-view--pavilions-title">{name.value}</div>
              {subtitle.value &&
                <div className="imc-campus-view--pavilions-subtitle">{subtitle.value}</div>
              }
              {this.renderPavilions(orderedPavilions, icon)}
            </>
          }
          <div className="imc-campus-view--pavilions-base">
            {this.renderIcon(icon)} <span>{name.value}</span>
          </div>
        </div>
      )
    }
    return null;
  }
}

export default Pavilions;

import {
    SET_PRODUCT_DETAIL,
    SET_PRODUCT_DETAIL_ERR,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {
    productDetail: {},
    showLoading: true,
};

/**
 * Reducer for digital showroom product detail
 * @param {object} state State of the product detail information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function digitalShowroomProductDetailReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_PRODUCT_DETAIL:
            return Object.assign({}, state, {
                productDetail: action.productDetail,
                showLoading: true
            });
        case SET_PRODUCT_DETAIL_ERR:
            return Object.assign({}, state, {
                productDetail: initialState.productDetail,
                errCode: action.status || 404,
            });
        default:
            return state;
    }
}
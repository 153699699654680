import {
    SET_SHOWROOM_CONTACT_INFO,
    SET_SHOWROOM_CONTACT_INFO_ERR,
    SHOW_LOADING
} from './actionTypes';
import ExhibitorsAPI from 'api/exhibitor';


/**
 * Instantiates the Exhibitors API
 * @type {ExhibitorsAPI}
 * @private
 */
const _exhibitorsApi = new ExhibitorsAPI();



/**
 * Action to get Digital Showroom Info
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getDigitalShowroomContactInfo(exhibitorId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getDigitalShowroomContactInfo(exhibitorId).then((response) => {
            const infoResponse = response || [];
            const status = 200;
            if (status === 200) {
                dispatch({
                    type: SET_SHOWROOM_CONTACT_INFO,
                    title: infoResponse.title,
                    socialIcons: infoResponse.socialIcons,
                    mailingAddress: infoResponse.mailingAddress,
                    phone: infoResponse.phone,
                    directoryContact: infoResponse.directoryContact,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_SHOWROOM_CONTACT_INFO_ERR,
                    status,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}

import React, { Component } from 'react'

export default class Button extends Component {
    render() {
        const { fieldData, disableSubmit } = this.props;
        const data = fieldData.field.model;
        return (
            <input type="submit" className={`${data.cssClass} ${disableSubmit ? 'imc-button--disabled imc-button--muted' : ''}`} value={data.title} disabled={disableSubmit} />
        )
    }
}

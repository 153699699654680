import React from 'react';
import { Image, } from "@sitecore-jss/sitecore-jss-react";
import { getDictionaryValue } from 'utils/dictionary/index';
import Link from '../../Link';
import { getFloorPlanURL } from "utils/floorplan";


const HEIGH = 30;
class Floor extends React.Component {

    renderIcon(icon) {
        if (icon && icon.value !== undefined)
            return <div className="imc-campus-view--floor-icon"> {icon.value.svgCode !== undefined ?
                <div dangerouslySetInnerHTML={{ __html: icon.value.svgCode }} /> :
                <Image media={icon.value} />}
            </div>
    }

    renderWalkways(walkways, side, hasWalkwayLabel) {
        if (hasWalkwayLabel)
            return (
                <div className="imc-campus-view--floor-walkway-label imc-breakpoint-display--hide-mobile">
                    {getDictionaryValue('walkways', 'WALKWAYS')}
                </div>
            )
        return walkways.map((way, index) => <div className="imc-campus-view--floor-walkway" key={index}>
            {side === 'left' &&
                <div className={`imc-campus-view--floor-walkway-${side}-arrow imc-campus-view--floor-walkway-${side}-arrow${way.level}`}></div>
            }
            <span>{way.title}</span>
            {side === 'right' &&
                <div className={`imc-campus-view--floor-walkway-${side}-arrow imc-campus-view--floor-walkway-${side}-arrow${way.level}`}></div>
            }
        </div>
        )
    }

    render() {
        const { id, number, icon, backgroundColor, defaultBackgroundColor, fontColor, defaultFontColor, name, size, leftWalkways, rightWalkways, active, hasWalkwayLabel, buildingName } = this.props;
        const _backgroundColor = (!!backgroundColor && !!backgroundColor.fields && backgroundColor.fields.hexColor)
            ? backgroundColor.fields.hexColor.value
            : (!!defaultBackgroundColor && !!defaultBackgroundColor.fields && defaultBackgroundColor.fields.hexColor)
                ? defaultBackgroundColor.fields.hexColor.value : null;

        const _fontColor = (!!fontColor && !!fontColor.fields && fontColor.fields.hexColor)
            ? fontColor.fields.hexColor.value
            : (!!defaultFontColor && !!defaultFontColor.fields && defaultFontColor.fields.hexColor)
                ? defaultFontColor.fields.hexColor.value : null;

        let colorStyles = {};
        if (_backgroundColor) colorStyles.backgroundColor = _backgroundColor;
        if (_fontColor) colorStyles.color = _fontColor;

        const _size = isNaN(size.value) ? 1 : parseInt(size.value);
        const heightStyle = {
            height: _size * HEIGH,
        }
        let hasMobileCookie = false;
        if (typeof document !=="undefined"){
            hasMobileCookie = decodeURIComponent(document.cookie).includes("ImcMobileApp");
        }
        return (
            <div className="imc-campus-view--floor" id={id} style={heightStyle}>
                <div className={`imc-campus-view--floor-walkway-wrapper ${leftWalkways.length > 0 ? 'active' : ''}`}>
                    {this.renderWalkways(leftWalkways, 'left', false)}
                </div>

                <div className="imc-campus-view--floor-number">
                    {active.value && !hasMobileCookie && <Link href={getFloorPlanURL(buildingName, number.value)} className="imc-campus-view-link">
                        {this.renderIcon(icon)}<span>{number.value}</span>
                    </Link>}
                    {active.value && hasMobileCookie && <a href={getFloorPlanURL(buildingName, number.value)} className="imc-campus-view-link">
                        {this.renderIcon(icon)}<span>{number.value}</span>
                    </a>}
                    {!active.value && <a className="imc-campus-view-link">
                        {this.renderIcon(icon)}<span>{number.value}</span>
                    </a>}
                </div>
                <div className={`imc-campus-view--floor-name${!active ? ' inactive' : ''}`} style={colorStyles}>
                    {active.value && !hasMobileCookie && <Link href={getFloorPlanURL(buildingName, number.value)}  className="imc-campus-view-link" style={colorStyles}>
                    {name.value}</Link>}
                    {active.value && hasMobileCookie && <a href={getFloorPlanURL(buildingName, number.value)}  className="imc-campus-view-link" style={colorStyles}>
                    {name.value}</a>}
                    {!active.value && <a className="imc-campus-view-link" style={colorStyles}>
                    {name.value}</a>}
                </div>
                <div className={`imc-campus-view--floor-walkway-wrapper ${rightWalkways.length > 0 ? 'active' : ''}`}>
                    {this.renderWalkways(rightWalkways, 'right', hasWalkwayLabel)}
                </div>

            </div>
        )
    }
}


export default Floor;

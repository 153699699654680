// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Module dependencies
import { maxlength } from 'utils/maxlength';
import { numbersonly } from 'utils/numbersonly';
import { alphanumeric } from 'utils/alphanumeric';
import { uniqueid } from 'utils/uniqueid';
import { Tooltip } from 'modules/tooltip';

/**
 * Component for displaying a TextArea module and maintaining its state
 */
export default class TextArea extends Component {

    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            id: uniqueid('textarea-'),
            value: this.props.value,
            errorEmpty: this.props.errorEmpty,
            errorInvalid: this.props.errorInvalid,
        };
        this.renderTooltip = this.renderTooltip.bind(this);
    }

    /**
     * Handles client side validations before sending to the parent
     * @param {string} value String value coming from the text field
     */
    fieldChange(value) {
        const { name, updateValue, maxLength, numbersOnly, alphaNumeric } = this.props;
        let final = value;

        // max length check
        if (maxLength !== 0) {
            final = maxlength(value, maxLength);
        }

        // numbers only check
        if (numbersOnly) {
            final = numbersonly(final, true);
        }

        // alpha numeric check
        if (alphaNumeric) {
            final = alphanumeric(final);
        }

        updateValue(name, final);

        this.setState({
            value: final,
        });
    }

    /**
     * Handles rendering of tooltip if required
     * @returns {*} Rendered tooltip component
     */
    renderTooltip() {
        const { tooltipContent, stableSelector } = this.props;
        if (tooltipContent) {
            return (<Tooltip
                content={tooltipContent}
                stableSelector={stableSelector}
            />);
        }
        return '';
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const {
            label,
            type,
            name,
            rows,
            additionalClass,
            labelClass,
            isRequired,
            isSingleFieldRequired,
            hideLabel,
            placeholder,
            stableSelector,
            value,
            isDisabled,
            isError,
        } = this.props;
        const { id } = this.state;
        const attr = {
            id,
            name,
            type,
            value,
            onChange: e => this.fieldChange(e.target.value),
            rows,
            placeholder,
        };
        if (isRequired) {
            attr.required = 'required';
        }
        if (isDisabled) {
            attr.disabled = 'disabled';
        }

        return (
            <div className={`imc-formfield imc-content ${additionalClass}`} data-xpath={`${stableSelector}Container`}>
                <div className={`imc-vr--xsmall ${hideLabel ? 'imc-visuallyhide' : ''}`}>
                    <label className={labelClass} htmlFor={id} data-xpath={`${stableSelector}Label`}>
                        {label}{isRequired && !isSingleFieldRequired ? '*' : ''}
                    </label>
                    {this.renderTooltip()}
                </div>
                <textarea
                    {...attr}
                    data-xpath={stableSelector}
                    className={`${isError ? ' imc-field--error' : ''}`}
                />
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{label: *, name: *, type: shim, updateValue: shim, isRequired: shim, additionalClass: shim}}
 */
TextArea.propTypes = {
    label: PropTypes.string.isRequired, // Label for the text field
    name: PropTypes.string.isRequired, // Content that expands or collapses
    value: PropTypes.string, // Populates the value if available
    type: PropTypes.string, // text|email
    updateValue: PropTypes.func, // method to update the value in the parent
    isRequired: PropTypes.bool, // determines if expanded by default
    isDisabled: PropTypes.bool, // determines if expanded by default
    errorEmpty: PropTypes.string, // Error message for an empty field
    errorInvalid: PropTypes.string, // Error message for an invalid entry
    additionalClass: PropTypes.string, // Additional class to include as part of the wrapper
    labelClass: PropTypes.string, // Additional class to include as part of the label
    rows: PropTypes.number, // Number of rows for the textarea
    maxLength: PropTypes.number, // Max length for the field
    numbersOnly: PropTypes.bool, // Flag if field should be numbers only
    alphaNumeric: PropTypes.bool, // Flag if field should be alpha numeric only
    hideLabel: PropTypes.bool, // Flag to visually hide the label (still available for screen readers)
    placeholder: PropTypes.string, // Placeholder text
    stableSelector: PropTypes.string, // Stable Selector
    tooltipContent: PropTypes.string, // Tooltip content
    isSingleFieldRequired: PropTypes.bool,
    isError: PropTypes.bool, // Flag if field has error
};

/**
 * @property defaultProps
 * @type {{type: string, updateValue: (function()), isRequired: boolean, additionalClass: string}}
 */
TextArea.defaultProps = {
    type: 'text',
    value: '',
    updateValue: () => {},
    isRequired: false,
    isDisabled: false,
    errorEmpty: 'Field is empty',
    errorInvalid: 'Invalid entry',
    additionalClass: '',
    labelClass: '',
    rows: 6,
    maxLength: 0,
    numbersOnly: false,
    alphaNumeric: false,
    hideLabel: false,
    placeholder: '',
    stableSelector: 'form.textarea',
    tooltipContent: '',
    isSingleFieldRequired: false,
    isError: false,
};

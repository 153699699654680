// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import moment from 'moment';

import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';
  
// Module dependencies
import { uniqueid } from 'utils/uniqueid';

const DATE_FMT = "MM-DD-YYYY";
const DATE_TIME_FMT = "MM-DD-YYYY HH:mm";
/**
 * Component for displaying a DatePickerField module and maintaining its state
 */
export default class DatePickerField extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            id: (this.props.id !== '') ? this.props.id : uniqueid('datepicker-'),
            selectedDay: (this.props.value !== '') ? this.props.value : undefined,
            errorEmpty: this.props.errorEmpty,
        };

        this.clearValue = this.clearValue.bind(this);
        this.fieldChange = this.fieldChange.bind(this);
        this.timeChange = this.timeChange.bind(this);
        this.renderTimeSelector = this.renderTimeSelector.bind(this);
        this.render = this.render.bind(this);
    }

    /**
     * Clears out the value if the form is submitted
     * @param {object} prevProps Previous prop object
     */
    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && this.props.value === '') {
            this.clearValue();
        }
    }

    /**
     * Clears the value for the date picker
     */
    clearValue() {
        this.setState({
            selectedDay: '',
        });
    }

    /**
     * Handles client side validations before sending to the parent
     * @param {string} selectedDay Date value coming from the text field
     */
    fieldChange(selectedDay) {
        const { name, updateValue, onDateChange } = this.props;
        console.log("fieldChange", selectedDay);
        
        let selectedDateTime = undefined;
        if (!!selectedDay && moment(selectedDay).isValid()){
            
            let selectedTime = this.props.defaultValue || "12:00";
    
            const date = moment(selectedDay).format(DATE_FMT);
            var timeAndDate = moment(date + " " + selectedTime, DATE_TIME_FMT);
    
            this.setState({
                selectedDay: date,
                selectedDateTime: timeAndDate,
            });
            
            selectedDateTime = timeAndDate.format(DATE_TIME_FMT);
        }
        else{
            this.clearValue();
        }
      
        onDateChange(selectedDateTime);
        updateValue(name, selectedDateTime);  
    }

    timeChange(e){
        const { name, updateValue, onDateChange } = this.props;
        const selectedTime = e.target.value;
        let date=this.state.selectedDay;

        var timeAndDate = moment(date + " " + selectedTime, DATE_TIME_FMT);
        
        this.setState({
            selectedDateTime: timeAndDate,
        }); 
        let selectedDateTime = timeAndDate.format(DATE_TIME_FMT);
        onDateChange(selectedDateTime);
        updateValue(name, selectedDateTime);  
    }

    isEarlierThanEndLimit(timeValue, endLimit, lastValue) {
		var timeValueIsEarlier = moment(timeValue, 'HH:mm').diff(moment(endLimit, 'HH:mm')) < 0
		var timeValueIsLaterThanLastValue = lastValue === undefined ? true : moment(lastValue, 'HH:mm').diff(moment(timeValue, 'HH:mm')) < 0
		return timeValueIsEarlier && timeValueIsLaterThanLastValue;
	}

    
    // parseDate(str, format, locale) {
    //     console.log("parseDate", str, format);
    //     const m = moment(str, format);
    //     if (!m.isValid())
    //         return undefined;
        
    //     return moment(str, format).toDate();
    // }
  
    // formatDate(date, format, locale) {
    //     return moment(date).format(format);
    // }

    renderTimeSelector(){
        let timeValue = this.props.beginLimit || "01:00";
		let lastValue;
        let endLimit = this.props.endLimit || "23:59";
		let step = this.props.step || 15;
        let defaultValue = this.props.defaultValue || "00:00:00";
        var options = [];
        options[0] = <option key={''} value={''}>00:00:00</option>
		options.push(<option key={timeValue} value={timeValue}>{timeValue}</option>);

        while ( this.isEarlierThanEndLimit(timeValue, endLimit, lastValue) ) {
                lastValue = timeValue;
                
        timeValue = moment(timeValue, 'HH:mm').add(step, 'minutes').format('HH:mm');
        options.push(<option key={timeValue} value={timeValue}>{timeValue}</option>)
        }
        return (
            <select defaultValue={defaultValue} onChange={this.timeChange} disabled={!!!this.state.selectedDay}>
                {options}
            </select>
        );
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const {
            label,
            name,
            additionalClass,
            isRequired,
            hideLabel,
            placeholder,
            stableSelector,
            hideBeforeToday,
            readOnly,
            inputCls,
            isError,
        } = this.props;
        
        const { id, selectedDay } = this.state;
        const attr = {
            name,
            id,
            format: DATE_FMT,
            value: selectedDay,
            placeholder,
            onDayChange: this.fieldChange,
            readOnly,
        };
        if (isRequired) {
            attr.required = 'required';
        }
        if (hideBeforeToday) {
            attr.dayPickerProps = {
                disabledDays: {
                    before: new Date(),
                },
            };
        }

        let cssContainer="";    
        if (this.props.allowTime){
            cssContainer = "imc-formfield--date-time-container"
        }
        let dayPickerCss =  `${inputCls} ${isError ? ' imc-field--error' : ''}`;
        let customCss = {container:"imc-formfield--datepicker-calendar-container", overlayWrapper:"imc-formfield--datepicker-calendar-wrapper", overlay:""}
        return (
            <>
            <div className={`imc-vr--xsmall imc-formfield imc-content ${hideLabel ? 'imc-visuallyhide' : ''}`}>
                <label htmlFor={id} data-xpath={`${stableSelector}Label`}>
                    {label}{isRequired ? '*' : ''}
                </label>
            </div>
            <div className={`imc-formfield imc-content ${cssContainer} ${additionalClass}`} data-xpath={`${stableSelector}Container`}>
                <DayPickerInput
                    {...attr}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    inputProps={{className:dayPickerCss, readOnly:true}}
                    classNames={customCss}
                    data-xpath={stableSelector}
                />
                {this.props.allowTime &&
                <div className="imc-formfield-time">
                    {this.renderTimeSelector()}
                </div>
                }
            </div>
            </>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{label: *, name: *, updateValue: shim, additionalClass: shim}}
 */
DatePickerField.propTypes = {
    id: PropTypes.string, // Override for ID of the object
    label: PropTypes.string.isRequired, // Label for the text field
    name: PropTypes.string.isRequired, // Content that expands or collapses
    value: PropTypes.string, // Populates the value if available
    updateValue: PropTypes.func, // method to update the value in the parent
    additionalClass: PropTypes.string, // Additional class to include as part of the wrapper
    isRequired: PropTypes.bool, // Is a required field
    errorEmpty: PropTypes.string, // Error message for an empty field
    hideLabel: PropTypes.bool, // Flag to visually hide the label (still available for screen readers)
    placeholder: PropTypes.string, // Placeholder text
    stableSelector: PropTypes.string, // Stable Selector
    hideBeforeToday: PropTypes.bool, // Flag to hide dates before today
    readOnly: PropTypes.bool, // Flag to user not able to manually enter date
    onDateChange: PropTypes.func,
    inputCls: PropTypes.string,
    allowTime: PropTypes.bool,
    isError: PropTypes.bool, // Flag if field has error
};

/**
 * @property defaultProps
 * @type {{updateValue: (function()), additionalClass: string}}
 */
DatePickerField.defaultProps = {
    id: '',
    value: '',
    updateValue: () => {},
    onDateChange: () => {},
    additionalClass: '',
    isRequired: false,
    errorEmpty: 'Field is empty',
    hideLabel: false,
    placeholder: '',
    stableSelector: 'form.datepicker',
    hideBeforeToday: true,
    readOnly: false,
    inputCls: '',
    isError: false,
    allowTime: true
};

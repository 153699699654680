/**
 * Returns numbers/alpha only when passing in the string
 * @param {string} str String to convert
 * @returns {string} Returned alphanumeric string
 */
export function alphanumeric(str) {
    return str.replace(/[^\w\s]|_/gi, '');
}

/**
 * Returns numbers/alpha only, plus allows periods and dashes
 * @param {string} str String to convert
 * @returns {string} Returned alphanumeric string
 */
export function alphanumericPlus(str) {
    return str.replace(/[^0-9A-Za-z. \-]/g, '');
}

import {
    serialize
} from 'utils/serialize';
//import { getAPIEndpoint } from 'utils/getapiendpoint';
import {
    filterquerymodifier
} from 'utils/filterquerymodifier';
import {
    getSitecoreApiHost,
    getSitecoreApiKey
} from '../AppGlobals';
import UserUtility from '../utils/userutility';
/**
 * API key name
 * @type {string}
 */
const filtersEndpointKey = 'filtersResourceUrl';

/**
 * Fallback endpoint if the key does not exist
 * @type {string}
 */
const endpointFallback = '/site/api/articles';

/**
 * Videos API
 */
export default class FiltersAPI {
    /**
     * Constructor
     */
    constructor() {
        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         */
        this._apiKey = filtersEndpointKey;
        this.getAlphabets = this.getAlphabets.bind(this);
        this.getFilters = this.getFilters.bind(this);
    }

    /**
     * Gets search results for images
     * @param {array} tags array
     * @param {string} pagenum of current page
     * @returns {Promise.<T>} Promise object that returns the SearchArticlesAPI results
     */
    getAlphabets(term, type) {
        var url = `${getSitecoreApiHost()}/imc-api/v2/exhibitors/getAlphabets?sc_apikey=${getSitecoreApiKey()}`;
        if(term!=null && term!=""){
            url+=`&q=`+term;
        }
        if(type!=null && type!=""){
            url+=`&type=`+type;
        }
        
        return (
            fetch(url, {
                    method: 'GET',
                    headers :new Headers({
                        'Channel' : UserUtility.getChannelData().name,
                    })})
            .then(response => response.json())
            .catch((err) => {
                // todo: Error Handling
                console.log('handle err here', err);
            })
        );
    }

    getTypeOptions() {
        return (
            fetch(`${getSitecoreApiHost()}/imc-api/v2/search/getTypes?sc_apikey=${getSitecoreApiKey()}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }})
            .then(response => response.json())
            .catch((err) => {
                // todo: Error Handling
                console.log('handle err here', err);
            })
        );
    }

    getSortOptions() {
        return (
            fetch(`${getSitecoreApiHost()}/imc-api/v2/search/getSortOptions?sc_apikey=${getSitecoreApiKey()}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }})
            .then(response => response.json())
            .catch((err) => {
                // todo: Error Handling
                console.log('handle err here', err);
            })
        );
    }

    /**
     * Gets a list of available based on key
     * @param {string} filterKey key used to find associated results
     * @param { string } exhibitId exhibitor or line ID
     * @param { boolean } isLine determines if the item is a line
     * @returns {Promise.<T>} Promise object that returns the FilterAPI results
     */
    getFilters(filterKey, exhibitId = null, isLine = false) {
        const endpointURL = `${getSitecoreApiHost()}/imc-api/v1/search/filters`;
        
        const queryObject = {
            tab: filterKey,
            sc_apikey: getSitecoreApiKey(),
        };
        if (exhibitId) {
            if (isLine) {
                queryObject.lineId = exhibitId;
            } else {
                queryObject.id = exhibitId;
            }
        }

        const querystring = serialize(queryObject);
        return (
            fetch(`${endpointURL}?${querystring}`)
            .then(response => response.json())
            .catch((err) => {
                // todo: Error Handling
                console.log('handle err here', err);
            })
        );
    }

}
import {
    SET_SHOWROOM_CONTACT_INFO,
    SET_SHOWROOM_CONTACT_INFO_ERR,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {
    title: '',
    socialIcons: [],
    mailingAddress: '',
    phone: '',
    directoryContact: '',
    showLoading: true,
};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function digitalShowroomContactInfoReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_SHOWROOM_CONTACT_INFO:
            return Object.assign({}, state, {
                title: action.title,
                socialIcons: action.socialIcons,
                mailingAddress: action.mailingAddress,
                phone: action.phone,
                directoryContact: action.directoryContact,
                showLoading: true
            });
        case SET_SHOWROOM_CONTACT_INFO_ERR:
            return Object.assign({}, state, {
                title: initialState.title,
                socialIcons: initialState.socialIcons,
                mailingAddress: initialState.mailingAddress,
                phone: initialState.phone,
                directoryContact: initialState.directoryContact,
                errCode: action.status || 404,
            });
        default:
            return state;
    }
}
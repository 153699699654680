import pushDataLayer from '../dataLayer';
/**
 * Base class for promotions events
 */
class InteractionEvent{
    
    constructor() {
    }
    
    static pushMarketPlannerEvent(action, label){
        InteractionEvent.pushEvent("market planner", action, label);
    }

    static pushEvent(category, action, label){
        const EVENT_NAME = "interactionEvent";
        const data = {
            event: EVENT_NAME,
            eventCategory: category,
            eventAction: action,
            eventLabel: label 
        };
        pushDataLayer(data);
    }
}

export default InteractionEvent;

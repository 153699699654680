// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Module dependencies
import { uniqueid } from 'utils/uniqueid';

/**
 * Component for displaying a Checkbox Field module and maintaining its state
 */
export default class Checkbox extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            id: uniqueid('checkbox-'),
            isChecked: this.props.checked,
            errorEmpty: this.props.errorEmpty,
        };
        this.fieldChange = this.fieldChange.bind(this);
    };
    /**
     * Updates the state
     * @param {object} nextProps Updated props
     */
    componentWillReceiveProps(nextProps) {

        if (nextProps.checked !== this.state.isChecked || nextProps.checked !== this.props.checked) {
this.setState({
                id: uniqueid('checkbox-'),
                isChecked: nextProps.checked,
                errorEmpty: nextProps.errorEmpty,
            });
        }
    }

    /**
     * Handles client side validations before sending to the parent
     * @param {string} value String checked coming from the Checked field
     */
    fieldChange(value) {
        const { name, updateValue } = this.props;
        const final = value;
        this.setState({
            isChecked: final,
        });
        updateValue(name, final);
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const {
            label,
            type,
            name,
            additionalClass,
            labelClass,
            isRequired,
            stableSelector,
            value,
            checkboxClasses,
            isDisabled,
        } = this.props;

        const { id } = this.state;
        const attr = {
            id,
            name,
            value,
            type,
            checked: this.state.isChecked,
            onChange: e => this.fieldChange(e.target.checked),
            disabled: isDisabled,
        };
        if (isRequired) {
            attr.required = 'required';
        }
        return (
            <div
                className={`imc-formfield imc-content ${additionalClass}`}
                data-xpath={`${stableSelector}Container`}
            >
                <input type="checkbox"  className={`imc-checkbox ${checkboxClasses}`} {...attr} data-xpath={stableSelector} />
                <label
                    htmlFor={id}
                    className={`imc-checkbox--label ${labelClass}`}
                    data-xpath={`${stableSelector}label`}
                    dangerouslySetInnerHTML={{ __html: label }}
                />
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{label: *, name: *, type: shim, updateValue: shim, isRequired: shim, additionalClass: shim}}
 */
Checkbox.propTypes = {
    checkboxClasses: PropTypes.string,
    label: PropTypes.string.isRequired, // Label for the text field
    name: PropTypes.string.isRequired, // Content that expands or collapses
    type: PropTypes.string, // text|email
    value: PropTypes.string, // Populates the value if available
    updateValue: PropTypes.func, // method to update the value in the parent
    isRequired: PropTypes.bool, // determines if expanded by default
    errorEmpty: PropTypes.string, // Error message for an empty field
    additionalClass: PropTypes.string, // Additional class to include as part of the wrapper,
    labelClass: PropTypes.string, // Additional class for label
    stableSelector: PropTypes.string, // Stable Selector
    checked: PropTypes.bool, // determines if checked by default
    isDisabled: PropTypes.bool,
};

/**
 * @property defaultProps
 * @type {{type: string, updateValue: (function()), isRequired: boolean, additionalClass: string}}
 */
Checkbox.defaultProps = {
    checkboxClasses: '',
    type: 'checkbox',
    value: '',
    checked: false,
    updateValue: () => {},
    isRequired: false,
    errorEmpty: 'Field is empty',
    additionalClass: '',
    labelClass: '',
    stableSelector: 'form.checkbox',
    isDisabled: false,
};

/**
 * Returns the fields as props
 * @param {object} props
 * @returns {object} transformed prop including fields
 */
export function transformFieldsToProps(props) {

    var newProps = {
        ...props
    };
    Object.keys(props.fields).forEach((key) => {
        
        newProps[key] = props.fields[key]?.value;
    });
    return newProps;
}
import FiltersAPI from '../../../api/filters';
import * as types from './actionTypes';

/**
 * Function to return a getFilters dispatcher
 * @param { string } filterKey key results were fetched for
 * @param { object } filterAPIResponse response returned from API
 * @param { function } dispatch function used to dispatch SET action
 */
export function setFilters(filterKey, filterAPIResponse, dispatch) {
    const { status, filters, sortOptions } = filterAPIResponse || {};
    if (status === 200) {
        dispatch({
            type: types.SET_PRODUCT_FILTERS,
            filters,
        });
        dispatch({
            type: types.SET_PRODUCT_SORT,
            sortOptions,
        });
    } else {
        // handle errors here
        dispatch({
            type: types.SET_PRODUCT_FILTERS_ERR,
            status,
        });
    }
}

/**
 * Function to return a getFilters dispatcher
 * @param { string } filterKey fetch available filters for this value
 * @param { string } exhibitId exhibitor or line ID
 * @param { boolean } isLine determines if the item is a line
 * @returns {Function} Dispater used to getFilter options from API
 */
export function getFilters(filterKey, exhibitId, isLine) {
    return dispatch => (
        FiltersAPI
            .getFilters(filterKey, exhibitId, isLine)
            .then((response) => {
                setFilters(filterKey, response, dispatch);
            })
    );
}

import { getSpecificCookie } from 'utils/cookiemonster';
import UserUtility from '../../utils/userutility';
/**
 * String used to signify that an API endpoint
 * does not requre authorization
 */
//const OPEN_SIGNIFIER = '/open/';
const OPEN_SIGNIFIER = '/';

/**
 * Signifies the key used to get auth_token from
 * userInfo window variable
 */
const AUTH_TOKEN_KEY = 'access_token';

/**
 * AuthFetch
 */
class AuthFetch {

    /**
     * Auth Fetch Example
     */
    constructor() {
        this.authFetch = this.authFetch.bind(this);
    }

    /**
     * Makes a fetch call with authentication tokens in tow
     * if the call requires it. Logic is based on the URL
     * containing "/open/" or not
     * @param {string} url url to make request to
     * @param {object} fetchParams Object of params necessary for request
     * @returns {Promise} Promise that wraps fetch
     */
    authFetch(url, fetchParams = {}) {
        return new Promise((resolve) => {
            
            this._authToken = getSpecificCookie(AUTH_TOKEN_KEY); // todo: remove "this" and convert to a static function

            let authToken;
            const requiresAuth = url.indexOf(OPEN_SIGNIFIER) === -1;
            const _fetchParams = { ...fetchParams };
            const cookieToken = getSpecificCookie(AUTH_TOKEN_KEY);
            const userInfo = UserUtility.getUserData();
            const auth_token = userInfo.authToken;
            // if (!cookieToken || cookieToken.length === 0) {
            //     authToken = window.userInfo[AUTH_TOKEN_KEY] || '';
            // } else {
            //     authToken = cookieToken;
            // }
            if (!cookieToken || cookieToken.length === 0) {
                authToken = auth_token || '';
            } else {
                authToken = cookieToken;
            }
            if (requiresAuth && authToken.length > 0) {
                _fetchParams.credentials = 'include';
                if (_fetchParams.headers) {
                    _fetchParams.headers.Authorization = `Bearer ${authToken}`;
                } else {
                    _fetchParams.headers = {
                        Authorization: `Bearer ${authToken}`,
                    };
                }
            }
            resolve(fetch(url, _fetchParams));
        }).then((response) => {
            if (!response.ok) {
                //throw Error(response.statusText)
                console.error('AuthFetch Error ', url, response.statusText);
            }
            return response;
        });
    }
}
export default AuthFetch;

// Library dependencies
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {generateEventICSFileLink} from '../../../api/events';

/**
 * Component for displaying a AddToCalendar and maintaining its state
 */
export default class AddToCalendar extends React.Component {
    /**
     * @method constructor
     * @description Constructs the component
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            icsFileLink: '',
        };
        this.getCalendarLink = this.getCalendarLink.bind(this);
    }

    componentDidMount() {
        this.getCalendarLink()
    }

    getCalendarLink() {
        const icsFileLink = generateEventICSFileLink(this.props.eventId);
        this.setState({ icsFileLink })
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        return (
            <a
                className="imc-addtocalendar imc-content--small imc-eventdetail__box--type imc-eventdetail__type--color"
                role="button"
                title={this.props.calendarLabel}
                href={this.state.icsFileLink}
            >
                {this.props.hasIcon && <span className="imc-icon-Calendar"></span>}
                <span className={classNames("imc-type--body-1-link", {
                    "imc-selectableicon__label": this.props.hasIcon
                })}
                >{this.props.calendarLabel}</span>
            </a>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{items}}
 */
AddToCalendar.propTypes = {
    calendarLabel: PropTypes.string, // Label
    eventId: PropTypes.string, // Event Id
    hasIcon: PropTypes.bool, // boolean describing if you want icon to display
};

/**
 * @property defaultProps
 * @type {{valueComments: string}}
 */
AddToCalendar.defaultProps = {
    calendarLabel: 'Add to Calendar ',
    hasIcon: true,
};

import React, { Component } from 'react'

export default class CheckBox extends Component {
    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            isChecked: props.fieldData.field.model.value,
            errorMsg: ''
        };
        this.handleOnChange = this.handleOnChange.bind(this);
    }


    /**
     * Handles client side validations before sending to the parent
     * @param {string} value String checked coming from the Checked field
     */
    handleOnChange(field, fieldValue, callback) {
        const { isChecked } = this.state;
        var errorMessages = [];
        // custom client validation logic here
        if (field.model.required && !isChecked === false) {
            errorMessages.push(field.model.title + " is required");
            this.setState({
                errorMsg: field.model.title + " is required"
            })
        }
        this.setState({
            isChecked: !isChecked,
        });
        callback(field.valueField.name, !isChecked, !isChecked, errorMessages);
    }

    render() {
        const { fieldData } = this.props;
        const { isChecked } = this.state;
        const data = fieldData.field.model;
        return (
            <div className={'imc-vr--xlarge'}>
                <div
                    className={'imc-formfield imc-content'}
                >
                    <input 
                        type="checkbox"
                        id={data.itemId}
                        value={isChecked}
                        className={'imc-checkbox'}
                        name={data.name}
                        onChange={(e) => this.handleOnChange(fieldData.field, e.target, fieldData.onChange)}
                    />
                    <label
                        htmlFor={data.itemId}
                        className={'imc-checkbox--label'}
                    >{data.title}</label>
                </div>
                {this.state.errorMsg !== '' && !isChecked &&
                    <div className={'imc-vr--xsmall'}>
                        <p className={'imc-type--error'}>{data.title + " is required"}</p>
                    </div>
                }
            </div>
        )
    }
}

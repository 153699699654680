import React, { Component } from 'react';
import ReactModal from 'react-modal';
import ImcDataLayer from 'utils/datalayer';
/**
 * Component for displaying a Optin modal module and maintaining its state
 */
export default class Optinmodal extends Component {

    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            optinModalProductCategoryOption: [],
            loading: false,
            showMessage: false,
            showSuccess: false,
        };
        this.renderOptinForm = this.renderOptinForm.bind(this);
        this.renderSuccess = this.renderSuccess.bind(this);
        this.resetComponent = this.resetComponent.bind(this);
    }

    componentDidMount(){
        
        setTimeout(()=>{
            //find the signup form
            var form = null;
            for (var i=0; i<document.forms.length; i++){
                var action = document.forms[i].action;
                if (action.includes("formbuilder")){
                    form = document.forms[i];
                    break;
                }
            }
    
            if (!form)
                return;

            var sType="";
            //binds onclick for the radio buttons (attendee/exhibitor)
            [...form.elements].filter(i=>i.type=="radio").forEach(
                el=>{
                    el.onclick = (e)=>{
                        if (e.target.checked)   {//sets the value of the selected radio button
                            sType=e.target.value;
                        }
                    }
                }) ;

            const props = this.props;
            form.onsubmit = (e)=>{
                ImcDataLayer.pushInteractionEvent("newsletter", "success", sType);
            };

        }, 1000);

    }
        
    
    /**
     * Reset
     */
    resetComponent() {
        this.setState({
            showMessage: false,
            showSuccess: false,
        });
        this.props.handleCloseModal();
    }

    successRendered=false;
    /**
     * @method renderOptinForm
     * @description this will Return Optin Form
     * @returns {*} Rendered component
     */
    renderSuccess() {
        if (!this.successRendered){
            this.successRendered = true;
            ImcDataLayer.pushInteractionEvent("newsletter", "success", );
        }

        const { messageSuccess, messageError } = this.props;
        const { showSuccess } = this.state;
        return (
            <p className="imc-content">
                { (showSuccess) ? messageSuccess : messageError }
            </p>
        );
    }
    /**
     * @method renderOptinForm
     * @description this will Return Optin Form
     * @returns {*} Rendered component
     */
    renderOptinForm() {
        const {
            form
        } = this.props;
        return (
            <div>
                {form}
            </div>
        );
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const {
            showModal,
        } = this.props;
        const { loading, showSuccess } = this.state;
        const modalClass = (showSuccess) ? 'imc-modal--box-small' : '';
        return (
            <div>
                <ReactModal
                    isOpen={showModal}
                    contentLabel="Opt In overlay form"
                    onRequestClose={this.handleCloseModal}
                    overlayClassName="imc-modal--overlay"
                    className={`imc-modal--box ${modalClass} imc-optinmodalclosebutton`}
                    ariaHideApp={false}
                >
                    <div className={'imc-section imc-section--padded imc-vr--xlarge imc-modal--content ' +
                    'imc-modal--padding-large'}
                    >
                        {(!showSuccess) ? this.renderOptinForm() : this.renderSuccess()}
                    </div>
                    <button className="imc-modal--close imc-button--modal-close imc-button--modal-close--no-text imc-button--round" onClick={this.resetComponent}></button>
                    {/* <Loading showLoading={loading} /> */}
                </ReactModal>
            </div>
        );
    }
}
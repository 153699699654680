// Local dependencies
import { serialize } from 'utils/serialize';
// import AuthFetch from 'utils/authfetch';

// const _authFetch = new AuthFetch();

/**
 * Form Submit API
 */
export default class FormSubmitAPI {
    /**
     * Submits the form
     * @param {string} url Action URL for the query
     * @param {object} data Form data as an object
     * @param {string} contentType Content type of data in submission
     * @returns {Promise.<T>} Promise object that returns the FormSubmitAPI results
     */
    static submit(url, data, contentType = 'text/plain;charset=UTF-8') {
        if (contentType) {
            let payload;
            if (contentType === 'application/x-www-form-urlencoded') {
                payload = serialize(data);
            } else {
                payload = JSON.stringify(data);
            }
            // return _authFetch.authFetch(url, {
            //     method: 'post',
            //     body: payload,
            //     headers: {
            //         'Content-Type': contentType,
            //     },
            // })
            //     .then(response => response.json());
        }
        const formData = new FormData();
        Object.keys(data).forEach((fieldName) => {
            if (data[fieldName] !== undefined &&
                    !(data[fieldName].constructor === File && data[fieldName].name === '')) {
                formData.append(fieldName, data[fieldName]);
            }
        });
        // return _authFetch.authFetch(url, {
        //     method: 'POST',
        //     body: formData,
        // })
        //         .then(response => response.json());
    }
}

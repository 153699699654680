export default function TrackUserActivity (onUserAction: () => void, inactivityTimeout: number = 0) {
    let timeout: ReturnType<typeof setTimeout>;
    let wasTabInactive = false;

    function resetTimer() {
        clearTimeout(timeout);
        timeout = setTimeout(handleInactivity, inactivityTimeout);
    }

    function handleInactivity() {
        wasTabInactive = true;
    }

    function handleVisibilityChange() {
        if (document.visibilityState === 'visible') {
            onUserActionAndReset()
        } else {
            clearTimeout(timeout);
            wasTabInactive = true;
        }
    }

    function handleWindowBlur() {
        clearTimeout(timeout);
        wasTabInactive = true;
    }
  
    function handleWindowFocus() {
        onUserActionAndReset();
    }
    
    function onUserActionAndReset() {
        if (document.visibilityState === 'visible' && document.hasFocus()) {
            if (wasTabInactive) {
                onUserAction();
            }
            resetTimer();
            wasTabInactive = false;
        }
    }

    function addEventListeners() {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("blur", handleWindowBlur);
        window.addEventListener("focus", handleWindowFocus);
        document.onmousemove = onUserActionAndReset;
        document.onkeydown = onUserActionAndReset;
        document.ontouchstart = onUserActionAndReset;
        document.onclick = onUserActionAndReset;
        document.onscroll = onUserActionAndReset;
    }

    function removeEventListeners() {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        window.removeEventListener("blur", handleWindowBlur);
        window.removeEventListener("focus", handleWindowFocus);
        document.onmousemove = null;
        document.onkeydown = null;
        document.ontouchstart = null;
        document.onclick = null;
        document.onscroll = null;
    }

    return {
        startTracking() {
            if (document.visibilityState !== 'visible' || !document.hasFocus()) {
                wasTabInactive = true;
            }
            addEventListeners();
            resetTimer();
        },
        stopTracking() {
            removeEventListeners();
            clearTimeout(timeout);
        }
    };
}
import { replaceparams } from '../replaceparams';

/**
 * Resets the pagination and returns the new history object
 * @param {object} match Match object from withRouter
 * @param {object} location Location object from withRouter
 * @returns {*} History object
 */
export function resetPagination(match, location) {
    if (match && match.params && match.params.pagenum) {
        const updatedParams = {
            ...match.params,
            pagenum: '',
        };
        return {
            ...location,
            pathname: replaceparams(match.path, updatedParams),
        };
    }
    return {};
}

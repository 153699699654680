import {
    GET_ALLSPECIALS_PRODUCTS_SUCCESS,
    GET_ALLSPECIALS_PRODUCTS_FAIL,
    SHOW_LOADING, END_LOADING
} from './actionTypes';

import ExhibitorsAPI from '../../../api/exhibitor.js';
import mock from './mock.json';
import { getDictionaryValue } from 'utils/dictionary';
const _exhibitorAPI = new ExhibitorsAPI();

export function fetchSpecials(pageNum, pageSize, pageId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
        });

        return _exhibitorAPI.getAllSpecials(pageNum, pageSize, pageId).then((response) => {
            dispatch({
                type: GET_ALLSPECIALS_PRODUCTS_SUCCESS,
                specials: response,
                exhibitors: response.length,
                count: response.length,
            });
            dispatch({
                type: END_LOADING,
            });

        }).catch(err => {
            dispatch({
                type: GET_ALLSPECIALS_PRODUCTS_FAIL,
                err: err,
                count: 0,
            });
            dispatch({
                type: END_LOADING,
            });
        })
            ;
    };
}
/**
 * Initial state for the store
 * @type {{}}
 */
export const InitialState = {
    exhibitorSpecials: {
        isLoading: false,
        specials: [],
        specialsCount: 0,
        specialsUpToDate: false,
        error: false
    },
};

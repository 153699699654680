import UserUtility from 'utils/userutility';
import {
    SHOW_LOADING,
    SET_PROFILE_DATA,
    CHANGE_COMPANY_NAME,
    RESET_PASSWORD,
    GET_ALL_PRODUCT_INTEREST,
    UPDATE_PRODUCT_INTERESTS,
    GET_ALL_ATTENDEE_TYPES,
    UPDATE_ATTENDEE_TYPE,
    UPDATE_NAME,
    UPDATE_ZIP_CODE,
    GET_ALL_COMPANY_TYPES,
    UPDATE_COMPANY_TYPES,
    UPDATE_COMPANY_NAME,
    ADD_NEW_EMAIL,
    DELETE_EMAIL,
    SET_EMAIL_AS_DEFAULT,
    GET_NEXT_MARKET_REGISTER_NOW_BUTTON,
    GET_FUTURE_MARKET_LINK_REGISTRATION_BUTTON,
    UPDATE_FAIL,
    LINK_MY_MARKET_REGISTRATION,
    ASSOCIATE_EXHIBITOR
} from '../actions/actionTypes';


const initialState = {
    data: undefined,
    showLoading: true,
};


export default function prodsReducer(state = initialState, action) {
    switch (action.type) {
    
    case SET_PROFILE_DATA:{
        //set profile info to be available for gtm tracking
        UserUtility.setUserProfileData(action.profile);

        return Object.assign({}, state, {
            profile: action.profile,
            showLoading: false
        });
    }

    case GET_ALL_ATTENDEE_TYPES:
        return Object.assign({}, state, {
            attendeeTypes: action.attendeeTypes?action.attendeeTypes.attendeeTypes:null,
            showLoading: false
        });

    case GET_ALL_COMPANY_TYPES:
        return Object.assign({}, state, {
            companyTypes: action.companyTypes?action.companyTypes.companyTypes:null,
            showLoading: false
        });

    case UPDATE_ATTENDEE_TYPE:
        return { ...state};

    case GET_ALL_PRODUCT_INTEREST:
        return Object.assign({}, state, {
            productInterest: action.productInterest,
            showLoading: false
        });

    case GET_NEXT_MARKET_REGISTER_NOW_BUTTON:
        return Object.assign({}, state, {
            nextMarketRegistration: action.nextMarketRegistration,
            showLoading: false
        });

    case GET_FUTURE_MARKET_LINK_REGISTRATION_BUTTON:
        return Object.assign({}, state, {
            futureMarkets: action.futureMarkets,
            showLoading: false
        });

    case DELETE_EMAIL:
        return { ...state};

    case SET_EMAIL_AS_DEFAULT:
        return { ...state};

    case ADD_NEW_EMAIL:
        return { ...state};

    case UPDATE_NAME:
        return { ...state};

    case UPDATE_ZIP_CODE:
        return { ...state};

    case UPDATE_COMPANY_NAME:
        return { ...state};
    
    case UPDATE_PRODUCT_INTERESTS:
        return { ...state};

    case ASSOCIATE_EXHIBITOR:
        return { ...state};

    case SHOW_LOADING:
        return Object.assign({}, state, {
            showLoading: action.status,
        });

    case UPDATE_FAIL:
        return Object.assign({}, state, {
            showLoading: action.status,
        });    

    default:
        return state;
    }
}
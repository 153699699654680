import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// reducers
import imageGalleryReducer from './reducers/imageDirectoryReducer';
// action imports
import * as dataActions from './actions/dataActions';


import { createAppState } from 'modules/redux-app-state';
import { decode } from 'utils/querystring';

import Pagination from 'modules/search/Pagination.jsx';
import ImageCardComponent from '../Image-Card-Component'
import { getDictionaryValue } from 'utils/dictionary/index';



const imageGalleryState = createAppState();

const defaultProps = {
  dataActions: {
    fetchVideos: () => { },
  },
  searchQuery: null,
  defaultView: null,
  errCode: null,
  hideFilters: true,
  location: {
    search: true,
  },
  filterKey: 'articles',
  tagKey: 'tags',
  pageSize: 20,
  pagenumber: 1,
  totalPages: 0,
};
const propTypes = {
  dataActions: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  defaultView: PropTypes.object,
  filterKey: PropTypes.string,
  tagKey: PropTypes.string,
};

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    imageGalleryState.reducerRegistry.register({ imageGalleryReducer });
    this.boundFetchData = this.fetchData.bind(this);
    this.renderCards = this.renderCards.bind(this);
    let pageNum = qs.parse(this.props.router.location.search, { ignoreQueryPrefix: true }).page || 0;
    this.state = {
      currentPage: pageNum,

    };
  }

  /**
   * Kick off bound fetch data
   */
  componentDidMount() {
    const { router : {location} } = this.props;
    this._fetchingInfo = true;
    this.boundFetchData(qs.parse(location.search), 0);
  }

  /**
 * Updates state with new props
 * @param {object} nextProps Incoming props
 */
  componentWillReceiveProps(nextProps) {
    if (nextProps.router.location !== this.props.router.location) {
      let pageNum = qs.parse(nextprops.router.location.search, { ignoreQueryPrefix: true }).page || 0;
      this.state = {
        currentPage: pageNum,

      };
      this.boundFetchData(qs.parse(nextprops.router.location.search), this.state.currentPage);
    }
  }

  /**
   * Fetch the article data
   * @param { string } queryObj term used to conduct search for
   * @param { string } pagenum of new page
   * @param { string } pageSize number of items
   */
  fetchData(queryObj, pagenum) {
    let filters = [];
    const { filterKey, tagKey } = this.props;
    const { dataSourceIds } =this.props.fields;

    const size = this.props.sitecoreContext.route.fields.size.value || this.props.pageSize;
    const tabFilters = decode(queryObj[filterKey]);
    if (tabFilters && tabFilters[tagKey]) {
      filters = tabFilters[tagKey];
    }

    const { match } = this.props;
    const pagenumber = pagenum || this.state.currentPage;
    this.props.dataActions.fetchImages(filters, pagenumber, size, !!dataSourceIds?dataSourceIds.value:null);
  }

  renderCards() {
    const { images } = this.props;
    return images.map((card, index) => {
      const props = {
        isApiProps: true,
        ...card
      };
      return (
        <ImageCardComponent {...props} key={index} />
      );
    })
  }

  renderPagination() {
    const { count } = this.props;
    const size = this.props.sitecoreContext.route.fields.size.value || this.props.pageSize;

    if (count > size) {
      const integerPart = parseInt(count / size)
      if (integerPart < (count / size)) {
        this.totalPages = integerPart + 1;
      } else {
        this.totalPages = integerPart;
      }
    } else {
      this.totalPages = 1;
    }
    // alert(this.totalPages);
    return (
      <Pagination
        totalPages={this.totalPages}
        paginationContainerId={this.pagenumber}
      />
    );
  }
  render() {
    const { images } = this.props;
    return (
      <div className="imc-gallery imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge imc-searchresults">
        <div className="imc-vr--xxxlarge imc-landingpage__result-container imc-section imc-section--full-width">
          {(typeof images === 'undefined' || images.length === 0)
            ? <span className="imc-type--title-3-ui">{getDictionaryValue('imagesNotFound', 'Images are not available. Please try again soon.')}</span>
            : <>
              <div
                className="imc-vr--xxlarge imc-landingpage__image-results imc-gallery imc-gallery--image-gallery imc-gallery--1-5 imc-gallery--horizontal-padding">
                {this.renderCards()}
              </div>
              {this.renderPagination()}
            </>
          }
        </div>
      </div>
    );
  }
}

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {
  if (state.imageGalleryReducer)
    return {
      resultsUpToDate: state.imageGalleryReducer.resultsUpToDate,
      images: state.imageGalleryReducer.images,
      count: state.imageGalleryReducer.count,
      numResults: state.imageGalleryReducer.numResults,
      showLoading: state.imageGalleryReducer.showLoading,
      errCode: state.imageGalleryReducer.errCode,
    }
  else {
    return {
      resultsUpToDate: state.resultsUpToDate,
      images: state.images,
      count: state.count,
      numResults: state.numResults,
      showLoading: state.showLoading,
      errCode: state.errCode,
    };
  }
}

/**
* Maps dispatch to props for connect
* @param {function} dispatch Dispatcher
* @returns {object} Action creators
*/
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

ImageGallery.propTypes = propTypes;
ImageGallery.defaultProps = defaultProps;

export default withSitecoreContext()(withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ImageGallery)));



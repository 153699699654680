/**
 * Updates articles
 * @type {string}
 */
export const SET_ARTICLES = 'SET_ARTICLES';

/**
 * Action to setting updated articles count
 * * @type {string}
 */
export const SET_ARTICLE_COUNT = 'SET_ARTICLE_COUNT';

/**
 * Action to set error on articles fetch failure
 * @type {string}
 */
export const SET_ARTICLES_ERR = 'SET_ARTICLES_ERR';

/**
 * Used for updates when new search is ran, etc
 * marking any current results as out of date
 * @type {string}
 */
export const RUN_SEARCH = 'RUN_SEARCH';

/**
 * Show Results Tabs
 * @type {string}
 */
export const SHOW_LOADING = 'SHOW_LOADING';

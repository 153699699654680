import {getSitecoreApiHost} from '../../AppGlobals';
/**
 * Returns the API url from apiConfig
 * or throw error if endpoint is not available in config
 * @param {string} endPointKey endpoint key
 * @param {boolean} isIDM whether or not the api should be take from the IDM url
 * @param {boolean} appendSlug Determines if the baseUrlSlug should be prepended
 * @param {string} fallback Fallback endpoint to use if the endpoint key is not found
 * @returns {string} api endpoint from config
 */
export function getAPIEndpoint(endPointKey, isIDM = false, appendSlug = false, fallback = '') {
    if(typeof window.baseAPIUrl === 'undefined')
        initWindowGlobals();
    const baseURL = isIDM ? window.baseIdmAPIUrl : window.baseAPIUrl;
    const slug = appendSlug ? window.common.baseUrlSlug : '';
    const endpoint = window.apiConfig && window.apiConfig[endPointKey];
    
    if (!baseURL || !endpoint) {
        return `${baseURL}${slug}${fallback}`;
    }
    return `${baseURL}${slug}${endpoint}`;
}


function initWindowGlobals() {
    window.baseIdmAPIUrl = getSitecoreApiHost();
    window.baseAPIUrl = getSitecoreApiHost();
    window.common = {
        EventDetailOGType: "Event Detail",
        addToListAriaLabel: "Add to List",
        addToListlabel: "Add to List",
        addToNote: "Add Notes",
        articleNoResultText: "Joe Testing LVM No results for $1 found in Articles T",
        articleOGType: "Article",
        baseUrlSlug: "/site",
        blogOGType: "Blog",
        categoryDetailOGType: "Category detail",
        contactExhibitor: "Contact",
        contentLandingOGType: "Content Landing",
        defaultPageDescription: "las vegas market",
        defaultPageKeywords: "Las Vegas Market",
        descAccountNotVerified: "Las Vegas Market | Account Not Verified",
        descCreateAccountError: "Las Vegas Market | Create Account Error",
        descCreateAccountSuccess: "Las Vegas Market |Create Account Success",
        descErrorResetPassword: "Las Vegas Market | Error Reset Password",
        descExhbitorDirectory: "With a simple search, sort and filter of our Exhibitor Directory",
        descForgotPassword: "Las Vegas Market | Forgot Password",
        descForgotPasswordSuccess: "Las Vegas Market | Forgot Password Success",
        descLVDCDirectory: "With a simple search, sort and filter of our LVDC Directory",
        descMarketPlanner: "Las Vegas Market | Your very own planner to organise yourself during the market",
        descResetPassword: "Las Vegas Market | Reset Password",
        descSearchResult: "Las Vegas Market | Find out million of products from thousand plus exhibitors",
        descShowSpecials: "The best at-Market-only Specials on top lines and categories are available directly from Exhibitors at Las Vegas Market",
        eventLandingOGType: "Event landing",
        eventsNoResultText: "No results for $1 found in Events & Seminars",
        exhibitorDirectoryPageUrl: "/exhibitor/exhibitor-directory",
        exhibitorNoResultText: "No results for $1 found test",
        exhibitorOGType: "Exhibitor Detail",
        fallbackImagePath150: "/site/binaries/content/gallery/lasvegasmarket/icons-and-logos/150-150.png",
        floorPlanOGType: "Floor plan",
        footerLogo: "LVM footer Logo",
        footerLogoDesc: "This is LVM logo in footer",
        homePageTitle: "Home",
        homePageUrl: "/homepage",
        imageUrlSlug: "/site/binaries",
        infoNoResultText: "No results for $1 found in Informations",
        informationOGType: "Information",
        instagramDocPathForArticlePage: "_authoring/instagram/lasvegasmarket",
        lasvegasmarketAccountVerifyErrorUrl: "/createaccount-error",
        lasvegasmarketAccountVerifyErrorUrlAlreadyVerified: "/account-already-verified",
        lasvegasmarketAccountVerifySuccessUrl: "/createaccount-success",
        lasvegasmarketPassResetErrorUrl: "/forgotpassword",
        lasvegasmarketPassResetErrorUrlAlreadyVerified: "/resetpasswordsuccess",
        lasvegasmarketPassResetSuccessUrl: "/resetpassword",
        learnMoreAriaLabel: "Learn More About",
        learnMoreLabel: "Learn More",
        logoAltText: "Las Vegas Market",
        logoImageUrl: "/site/binaries/content/gallery/LasVegasMarket/lvm_logo.png",
        lvdcDesignSalonOGType: "LVDC design salon",
        lvdcExhibitorNoResultText: "No results for $1 found in LVDC Exhibitors",
        mediaAndPressLandingOGType: "Media And Press Landing",
        newsOGType: "News",
        ogType: "article",
        placeholderImagePath: "/site/binaries/content/gallery/LasVegasMarket/icons-and-logos/1-1-logo.png",
        placeholderImageProduct: "/site/binaries/content/gallery/LasVegasMarket/icons-and-logos/400-400.png",
        productOGType: "Product Detail",
        productsTabSuffix: "/products",
        searchExhibitorTabUrl: "/search/exhibitors",
        seoTitleSuffix: "| Las Vegas Market",
        siteUrlSlug: "",
        socialShareLabel: "Share:",
        titleAccountNotVerified: "Las Vegas Market | Account Not Verified",
        titleCreateAccountError: "Las Vegas Market | Create Account Error",
        titleCreateAccountSuccess: "Las Vegas Market |Create Account Success",
        titleErrorResetPassword: "Las Vegas Market |Error Reset Password",
        titleExhbitorDirectory: "Las Vegas Market | Exhibitor Directory",
        titleForgotPassword: "Las Vegas Market | Forgot Password",
        titleForgotPasswordSuccess: "Las Vegas Market | Forgot Password Success",
        titleLVDCDirectory: "Las Vegas Market | LVDC Directory",
        titleMarketPlanner: "Las Vegas Market | Market Planner",
        titleResetPassword: "Las Vegas Market | Reset Password",
        titleSearchResult: "Las Vegas Market | Search Results",
        titleShowSpecials: "Las Vegas Market | Show Specials for the Upcoming Las Vegas Market",
    }
    window.apiConfig = {
        addItemToList: "/imc-api-idm/api/v1/marketplanner/list/additem",
        adminAccountCreationPasswordResetApi: "/imc-api-idm/api/v1/admin/changepassword",
        adminApiForgotPasswordUrl: "/imc-api-idm/api/v1/admin/open/forgotpassword",
        adminApiMarketDatesUrl: "/imc-api-idm/api/v1/admin/market",
        adminDeleteUsers: "/imc-api-idm/api/v1/admin/{accountId}/deleteuser",
        apiAddNoteUrl: "/imc-api-idm/api/v1/lvm/addnote",
        apiArticleResourceUrl: "/search-api/article/search",
        apiArticleSearchId: "/search-api/article",
        apiCatalogSearchId: "/search-api/catalog",
        apiCompanyidRedirectUrl: "/createaccount",
        apiCopyUserListItem: "/imc-api-idm/api/v1/marketplanner/list/copylistitem",
        apiCreateAccountAddUrl: "/imc-api-idm/api/v1/lvm/open/account",
        apiCreateAccountCountryUrl: "/imc-api-idm/api/v1/lvm/open/country",
        apiCreateAccountDropdownUrl: "/imc-api-idm/api/v1/lvm/open/account/dropdown",
        apiCreateAccountProductsUrl: "/imc-api-idm/api/v1/lvm/open/account/$1/available",
        apiCreateAccountVerifyUrl: "/imc-api-idm/api/v1/lvm/account/verify/$1",
        apiDownloadPDF: "/site/api/downloadpdf",
        apiEventResourceUrl: "/search-api/event/search",
        apiEventSearchId: "/search-api/event",
        apiExhibitorDirectorySearch: "/site/api/exhibitorDirectorySearchDocument",
        apiExhibitorResourceUrl: "/imc-api-idm/api/v1/exhibitor/{accountId}/directorydetails",
        apiExhibitorResourceUrlOpen: "/imc-api-idm/api/v1/exhibitor/open/{accountId}/directorydetails",
        apiExhibitorResourceUrlSearch: "/search-api/exhibitor/",
        apiExhibitorShowSpecialsUrl: "/imc-api-idm/api/v1/lvm/open/showspecial/$1/exhibitor",
        apiFeaturedEventResourceUrl: "/search-api/event/featured?size=12&from=0",
        apiGetAccounInfoFromEmailId: "/imc-api-idm/api/v1/accounts/{hash}/account",
        apiGetAccountInfoUrl: "/imc-api-idm/api/v1/lvm/$1/accountinformation",
        apiGetAccountUrl: "/imc-api-idm/api/v1/lvm/{accountId}/accountinformation",
        apiGetAllEventsInSlotsUrl: "/search-api/event/allEventsInSlots",
        apiGetCompanyidRedirectUrl: "/createaccount",
        apiGetExhibitorCatalogsUrl: "/search-api/exhibitor/$1/productcatalogs",
        apiGetExhibitorCategoryCountUrl: "/search-api/search/exhibitor/$1",
        apiGetExhibitorProductCategories: "/imc-api-idm/api/v1/lvm/{accountId}/{exhibitorId}/contactexhibitor",
        apiGetExhibitorProductLinesUrl: "/search-api/exhibitor/$1/productlines",
        apiGetFloorPlanUrl: "/api/floorplan",
        apiGetNoteUrl:  "/imc-api/market-planner/v1/note/information/?accountId={accountId}",
        apiImagesResourceUrl: "/site/api/imagegallery",
        apiLVMResendVerification: "/imc-api-idm/api/v1/lvm/open/account/resend/verification",
        apiLVMlasvegasmarketAccountVerify: "/imc-api-idm/api/v1/lvm/open/account/verify/{hash}",
        apiLVMlasvegasmarketPassReset: "/imc-api-idm/api/v1/lvm/open/resetpassword/{hash}",
        apiLineResourceUrl: "/search-api/line/",
        apiLineSearchId: "/search-api/line",
        apiLineShowSpecialsUrl: "/imc-api-idm/api/v1/lvm/open/showspecial/$1/$2/line",
        apiLvmGetAccountInfo: "/imc-api-idm/api/v1/token/{email}/account",
        apiMoveUserListItem: "/imc-api-idm/api/v1/marketplanner/list/movelistitem",
        apiNewsletterSubscription: "/imc-api-idm/api/v1/lvm/open/newslettersubscription",
        apiPortalAddImcAdminUser: "/imc-api-idm/api/v1/admin/create",
        apiPortalAdminGETInformationUpdate: "/imc-api-idm/api/v1/admin/{accountId}/account",
        apiPortalAdminPOSTInformationUpdate: "/imc-api-idm/api/v1/admin/account/update",
        apiPortalAdminPOSTShowSpecialApproved: "/imc-api-idm/api/v1/admin/showspecial/approve",
        apiPortalAdminPOSTShowSpecialRejected: "/imc-api-idm/api/v1/admin/showspecial/reject",
        apiPortalAdminPOSTShowSpecialUpdate: "/imc-api-idm/api/v1/admin/showspecial/update",
        apiPortalCorporateDetailOnloadResourceUrl: "/imc-api-idm/api/v1/exhibitor/{organizationId}/corporatedetails",
        apiPortalCreateAccountAddUserUrl: "/imc-api-idm/api/v1/exhibitor/sendinvites",
        apiPortalCreateAccountOnloadResourceUrl: "/imc-api-idm/api/v1/accounts/open/{hash}/create",
        apiPortalCreateAccountSubmitUrl: "/imc-api-idm/api/v1/accounts/open",
        apiPortalDirectoryDetailOnloadResourceUrl: "/imc-api-idm/api/v1/exhibitor/{id}/directorydetails",
        apiPortalDirectoryDetailSubmitResourceUrl: "/imc-api-idm/api/v1/exhibitor/directorydetails",
        apiPortalFetchLeadsReportsResourceUrl: "/imc-api-idm/api/v1/exhibitor/{organizationId}/leadgenerationreport/enhanced",
        apiPortalGETLeadGen: "/imc-api-idm/api/v1/exhibitor/report/leadgeneration/{organizationId}/download/enhanced",
        apiPortalGETShowSpecialsRejected: "/imc-api-idm/api/v1/exhibitor/showspecial/{showSpecialId}/rejected",
        apiPortalGetAccountInfo: "/imc-api-idm/api/v1/token/{id}",
        apiPortalGetCompanyidSubmitUrl: "/imc-api-idm/api/v1/exhibitor/open/{emailId}/companyid",
        apiPortalInviteExhibitorUser: "/imc-api-idm/api/v1/exhibitor/open/companyid",
        apiPortalPostPreviewDirectoryUrl: "/imc-api-idm/api/v1/exhibitor/directorydetails/preview",
        apiPortalexhibitorportalAccountVerify: "/imc-api-idm/api/v1/exhibitor/open/account/verify/{hash}",
        apiPortalexhibitorportalPassReset: "/imc-api-idm/api/v1/exhibitor/open/resetpassword/{hash}",
        apiProductCategory: "/imc-api-idm/api/v1/lvm/open/productcategory",
        apiProductResourceUrl: "/search-api/product/",
        apiProductSearchId: "/search-api/product",
        apiRecommendEventResourceUrl: "/search-api/event/recommendEvent",
        apiResendVerification: "/imc-api-idm/api/v1/accounts/open/resend/verification",
        apiSaveSearchResults: "/imc-api/market-planner/v1/saved/search",
        apiSearchExhibitorDetail: "/search-api/exhibitor/{accountId}",
        apiUpdateCredentialInfoUrl: "/imc-api-idm/api/v1/lvm/account/update/profile",
        apiUpdateNoteUrl: "/imc-api-idm/api/v1/lvm/updatenote",
        apiUpdatePersonalInfoUrl: "/imc-api-idm/api/v1/lvm/account/update/personalinfo",
        apiVideoGallery: "/site/api/videogallery",
        approveShowSpecial: "/api/v1/admin/showspecial/approve",
        contactExhibitorUrl: "/imc-api-idm/api/v1/lvm/contactexhibitor",
        contactUsUrl: "/imc-api-idm/api/v1/lvm/open/contactus",
        createDocList: "/api/createDocList",
        defaultAdminPublishEnvIp: "dev-admin.imcinternal.com",
        defaultPortalPublishEnvIp: "dev-portal.imcinternal.com",
        deleteItemFromList: "/imc-api-idm/api/v1/marketplanner/list/item",
        devAdminPublishEnvIp: "dev-admin.imcinternal.com",
        devPortalPublishEnvIp: "dev-portal.imcinternal.com",
        docListAPIUrl: "/api/documentList",
        downloadAllShowSpecialUrl: "/imc-api-idm/api/v1/lvm/open/showspecial/download",
        duplicateUserList: "/imc-api-idm/api/v1/marketplanner/list/duplicatelist",
        editShowSpecialsRow: "/api/v1/admin/showspecial/update",
        exhibitorPasswordResetApi: "/imc-api-idm/api/v1/exhibitor/open/resetpassword",
        exhibitorProductSearchUrl: "/search-api/exhibitor/{id}/products",
        exhibitorShowSpecialsGetApi: "/imc-api-idm/api/v1/exhibitor/showspecial/{accountId}",
        exhibitorShowSpecialsPostApi: "/imc-api-idm/api/v1/exhibitor/showspecial",
        getAllShowSpecialUrl: "/imc-api-idm/api/v1/lvm/open/showspecial",
        getApprovedShowSpecial: "/api/v1/admin/showspecial/status/approved",
        getCurrentMarket: "/imc-api-idm/api/v1/market/open/current",
        getEulaContent: "/imc-api-idm/api/v1/eulaContent",
        getPastMarketUserLists: "/imc-api-idm/api/v1/marketplanner/list/pastmarket",
        getPendingShowSpecial: "/api/v1/admin/showspecial/status/pending",
        getRejectedShowSpecial: "/api/v1/admin/showspecial/status/rejected",
        getSavedCatalogsForExhibitor: "/imc-api-idm/api/v1/marketplanner/list/exhibitor/catalogs",
        getSavedCatalogsForLine: "/imc-api-idm/api/v1/marketplanner/list/line/catalogs",
        getSavedEvents: "/imc-api-idm/api/v1/marketplanner/list/events",
        getSavedExhibitors: "/imc-api-idm/api/v1/marketplanner/list/exhibitors",
        getSavedProducts: "/imc-api-idm/api/v1/marketplanner/list/products",
        getSavedProductsForExhibitor: "/imc-api-idm/api/v1/marketplanner/list/exhibitor/products",
        getSavedProductsForLine: "/imc-api-idm/api/v1/marketplanner/list/line/products",
        getUserContentIds: "/api/v1/marketplanner/list/fetchusersavedids/$1",
        getUserListContent: "/imc-api-idm/api/v1/marketplanner/list/fetchlistcontents/$1",
        getUserListIds: "/imc-api-idm/api/v1/marketplanner/list/itemId/itemType",
        // getUserListIds: "/imc-api/market-planner/v1/lists/type/items",
        getUserLists: "/imc-api-idm/api/v1/marketplanner/list",
        inquiriesUrl: "/imc-api-idm/api/v1/lvm/$1/enquirylist",
        lvmApiChangePassword: "/imc-api-idm/api/v1/lvm/changepassword",
        lvmApiForgotPasswordUrl: "/imc-api-idm/api/v1/lvm/open/forgotpassword",
        lvmApiResetPassword: "/imc-api-idm/api/v1/lvm/open/resetpassword",
        onCorporateBoardingRedirectedUrl: "/account-settings/corporate",
        portalAPIAdminGetShowSpecialApproved: "/imc-api-idm/api/v1/admin/showspecial/status/approved",
        portalAPIAdminGetShowSpecialPending: "/imc-api-idm/api/v1/admin/showspecial/status/pending",
        portalAPIAdminGetShowSpecialRejected: "/imc-api-idm/api/v1/admin/showspecial/status/rejected",
        portalAPIAdminPOSTShowSpecialApproved: "/imc-api-idm/api/v1/admin/showspecial/approve",
        portalAPIAdminReportsExhibitorDirectory: "/imc-api-idm/api/v1/report/exhibitor-directory/download",
        portalAPIAdminReportsExhibitorLines: "/imc-api-idm/api/v1/report/exhibitor/lines/download",
        portalAPIAdminReportsMarketPlanner: "/imc-api-idm/api/v1/report/marketplanner-report/download",
        portalAPIAdminReportsNewsletter: "/imc-api-idm/api/v1/report/newsletter-report/download",
        portalAPIExhibitorGETCompanyDescription: "/imc-api-idm/api/v1/exhibitor/{organizationId}/companydescription",
        portalAPIExhibitorGETSocialMedia: "/imc-api-idm/api/v1/exhibitor/{organizationId}/socialmedia",
        portalAPIExhibitorPOSTCompanyDescription: "/imc-api-idm/api/v1/exhibitor/companydescription",
        portalAPIExhibitorPOSTSocialMedia: "/imc-api-idm/api/v1/exhibitor/socialmedia",
        portalApiAdminPortalAdduser: "/imc-api-idm/api/v1/imc/create",
        portalApiCheckCompanyId: "/imc-api-idm/api/v1/exhibitor/open/companyid",
        portalApiForgotPasswordUrl: "/imc-api-idm/api/v1/exhibitor/open/forgotpassword",
        portalApiGetAddImages: "/imc-api-idm/api/v1/exhibitor/{organizationId}/image",
        portalApiGetAddPreferencesUrl: "/imc-api-idm/api/v1/exhibitor/{organizationid}/preferences",
        portalApiGetContactInfoUrl: "/imc-api-idm/api/v1/exhibitor/accountsetting/{organizationId}/contactinformation",
        portalApiGetMyInformationUrl: "/imc-api-idm/api/v1/exhibitor/{id}/usermanagement",
        portalApiGetProductCategoryUrl: "/imc-api-idm/api/v1/exhibitor/{organizationid}/productcategorytags",
        portalApiGetSortFilterCriterias: "/imc-api-idm/api/v1/admin/filterandsortvalues",
        portalApiGetUserInformationUrl: "/imc-api-idm/api/v1/exhibitor/{accountId}/usermanagement",
        portalApiGetUsersAdmin: "/imc-api-idm/api/v1/admin/searchuser",
        portalApiPostAddImages: "/imc-api-idm/api/v1/exhibitor/upload/image",
        portalApiPostAddPreferencesUrl: "/imc-api-idm/api/v1/exhibitor/preferences",
        portalApiPostChangePasswordUrl: "/imc-api-idm/api/v1/exhibitor/changepassword",
        portalApiPostContactInfoUrl: "/imc-api-idm/api/v1/exhibitor/contactinformation",
        portalApiPostMyInformationUrl: "/imc-api-idm/api/v1/exhibitor/usermanagement",
        portalApiPostProductCategoryUrl: "/imc-api-idm/api/v1/exhibitor/productcategorytags",
        portalApiUpdateEmail: "/imc-api-idm/api/v1/admin/exhibitor/account/update",
        portalUserInviteRedirectedUrl: "/createaccount",
        prodAdminPublishEnvIp: "admin.lasvegasmarket.com",
        prodPortalPublishEnvIp: "portal.lasvegasmarket.com",
        qaAdminPublishEnvIp: "qa-admin.imcinternal.com",
        qaPortalPublishEnvIp: "qa-portal.imcinternal.com",
        rejectShowSpecial: "/api/v1/admin/showspecial/reject",
        requestPersonalShopperFormUrl: "/imc-api-idm/api/v1/lvm/open/personalshopperform",
        saveUserListDetail: "/imc-api-idm/api/v1/marketplanner/list/create",
        savedSearchUrl: "/imc-api-idm/api/v1/lvm/search/savesearch",
        searchApiAllCount: "/search-api/search/all/count",
        searchApiCount: "/search-api/search/count",
        searchApiDownloadExhibitors: "/search-api/download/downloadExhibitors",
        searchApiDownloadInformation: "/search-api/download/downloadInformation",
        searchApiEvents: "/search-api/event/search",
        searchApiExhibitor: "/search-api/exhibitor/search",
        searchApiFilters: "/search-api/filters",
        searchApiInformation: "/search-api/information/search",
        searchApiJumpTo: "/search-api/exhibitor/getalphabets",
        searchApiSave: "/search-api/save/",
        searchReIndexURL: "/search-api/webContentJobLauncher.html",
        searchTypeAhead: "/search-api/search/suggestions",
        shareuserlist: "/imc-api-idm/api/v1/marketplanner/list/shareUserList",
        signOutApiTriggerUrl: "/idp/logout.jsp",
        signOutApiUrl: "http://ewrazorfish/webcm/logout.do",
        signOutSuccessUrl: "/logout",
        stageAdminPublishEnvIp: "admin.lasvegasmkt.com",
        stagePortalPublishEnvIp: "portal.lasvegasmkt.com",
        updateEulaVersionLvm: "/imc-api-idm/api/v1/lvm/eula/account/update",
        updateEulaVersionPortal: "/imc-api-idm/api/v1/exhibitor/eula/account/update",
        updateUserList: "/imc-api-idm/api/v1/marketplanner/list/updatelist",
        updateUserListItem: "/imc-api-idm/api/v1/marketplanner/list/updatelistitem",
    }
    window.sitemapUrls = {
        articleDetailPageUrl: "/articledetailpage",
        catalogDetailUrl: "/pdfview?catalogId=$1",
        eventDetailPageUrl: "/plan/events",
        exhibitorDetailPageUrl: "/exhibitor/$1",
        exhibitorDetailWithCatalogUrl: "/exhibitor/$1/catalogs",
        findArticles: "/search/articles?q=",
        findEvents: "/plan/events/event-seminar-landing",
        findExhibitors: "/exhibitor/exhibitor-directory",
        lineAndExhibitorInformationUrl: "/404",
        lineDetailPageUrl: "/exhibitor/$1/line/$2",
        lineProductDetailUrl: "/exhibitor/$1/line/$2/prod/$3",
        marketAppUrl: "/las-vegas-market-app",
        pdfViewerUrl: "/404",
        productDetailUrl: "/exhibitor/$1/prod/$2",
        productinformationOverviewUrl: "/404",
    }
}


// Library dependencies
import React, { Component } from 'react';
import { Provider } from 'react-redux';

// Platform dependencies
import { createAppState } from 'modules/redux-app-state';

// Module dependencies
import FormComponent from './FormConnected';
import form from '../reducers/formReducer';

/**
 * @property appState
 * @description An AppState object to manage the reducer and store state of the application
 */
const appState = createAppState();

/**
 * Component for displaying a TextField module and maintaining its state
 */
export default class Form extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };

        // Adding search reducers to the store
        appState.reducerRegistry.register({ form });
    }

    /**
     * @method render
     * @description Renders the connected DOM element
     * @returns {*} Rendered connected component
     */
    render() {
        return (
            <Provider store={appState.store}>
                <FormComponent {...this.props} />
            </Provider>
        );
    }
}

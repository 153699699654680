import { analytics } from '../helpers/analytics';

export const toggleDrawerState = (status?: boolean) => {
    return async (dispatch: any, getState: any) => {
        const state = getState();
        const plan = state.marketPlanReducer;
        const isDrawerOpen = status !== undefined ? status : !plan.isDrawerOpen;
        dispatch({
            type: 'TOGGLE_DRAWER',
            isDrawerOpen,
        });
        if (isDrawerOpen && plan.loaded) {
            let track = (plan.isRegistered) ? "Plan + Open Preview, Market Plan" : "Plan + Open Preview, Non-Registered";
            analytics().track(track, {
                contact_alt_id: (plan.isRegistered) ? plan.contactAltId : undefined,
                market_id:  `${plan.market.marketId}`,
                market_name: plan.market.displayName,
                market_channel_id: plan.channelID,
            });
        }
    };
};
import React from "react";
import {
  Image,
  Placeholder,
  isEditorActive
} from "@sitecore-jss/sitecore-jss-react";


const ContentSectionWithImageBackground = ({ fields, rendering }) => {
  if (!fields) {
    if (isEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    }
    return (
      <h1 className="alarm">
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }

  let backgroundImage = fields["Background Image"]
    ? fields["Background Image"].value
    : "";
  let horizontalContentPosition = fields["Internal Content Horizontal Position"]
    ? fields["Internal Content Horizontal Position"].value
    : "";
  let verticalContentPosition = fields["Internal Content Vertical Position"]
    ? fields["Internal Content Vertical Position"].value
    : "";
  let contentBackgroundColor = fields["Internal Content Background Color"]
    ? fields["Internal Content Background Color"].value
    : "";

  return (
    <div className="root-content-section-block">
      <Image className="allign-image-content" field={backgroundImage} />
      <div className='content-section-with-image-content'>
        <div className={`content-section-inner-content ${horizontalContentPosition} ${verticalContentPosition} ${contentBackgroundColor}-background-color`}>
          <Placeholder name="jss-content-section" rendering={rendering} />
        </div>
      </div>
    </div>
  );
};
export default ContentSectionWithImageBackground;

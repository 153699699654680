import React, { Component } from 'react';
import ScriptTag from 'react-script-tag';

class BroadstreetAdStatic extends Component {
    constructor(props) {
        super(props);

        this.setAd = this.setAd.bind(this);
    }

    setAd(){
        if ('broadstreet' in global) {
            global.broadstreet.watch({ networkId: this.props.fields.networkId.value });
        }
    }
    
    render() {
        return (
            <div>
                <ScriptTag onLoad={this.setAd} src="https://cdn.broadstreetads.com/init-2.min.js" />
                <broadstreet-zone zone-id={this.props.fields.zoneId.value}></broadstreet-zone>
            </div>
        );
    }
}
export default BroadstreetAdStatic;

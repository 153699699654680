// Library dependencies
import React, { Component, Children } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// Local dependencies
// import { checkemail } from 'utils/checkemail';
// import { checkmultipleemail } from 'utils/checkmultipleemail';
// import { checkminlength } from 'utils/checkminlength';
// import { checkcharacters } from 'utils/checkcharacters';
// import { checknumbers } from 'utils/checknumbers';
// import { checkstate } from 'utils/checkstate';
import { uniqueid } from 'utils/uniqueid';
// import { checkurl } from '../../../utils/checkurl';
import { checkValidZipCode } from '../../../utils/checkzipcodes';

// module dependencies
import * as formActions from '../actions/formActions';

/**
 * Component for displaying a TextField module and maintaining its state
 */
export class FormConnected extends Component {

    /**
     * Renders the loading layer
     * @param {string} error Covert if HTML exist in error
     * @returns {XML} Loading layer
     */
    static errorMarkup(error) {
        return { __html: error };
    }

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        const errors = [];
        const { showError, messageError, asyncFields } = this.props;
        if (showError) {
            errors.push(messageError);
        }

        this.state = {
            fields: asyncFields,
            valid: false,
            submitted: false,
            loading: false,
            errors,
            scrollToError: false,
            errorFields: {},
        };
        this.fields = {}; // stores the static values for the corresponding field names
        this.fileSize = {}; // stores file size corresponding file type field names
        this.fileExtension = {}; // stores file extension corresponding file type field names
        this.fileDimension = {}; // stores required file dimension corresponding file type field names
        this.populateFields = this.populateFields.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.addField = this.addField.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderSuccess = this.renderSuccess.bind(this);
        this.showGenericError = this.showGenericError.bind(this);
        this.updateFields = this.updateFields.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.checkForClearFormFlag = this.checkForClearFormFlag.bind(this);
    }

    /**
     * Adds in the field values when the component mounts
     */
    componentDidMount() {
        this.populateFields();
    }

    /**
     * Updates the field values
     * @param {object} nextProps Updated props
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.asyncFields !== this.props.asyncFields) {
            this.setState({
                fields: Object.assign({}, this.state.fields, nextProps.asyncFields),
            });
        }
        if (this.props !== nextProps && nextProps.customErrorHandler) {
            setTimeout(() => this.checkValues(), 200);
        }
        this.checkForClearFormFlag();
    }

    /**
     * Clears out the form fields if it is a successful POST
     */
    componentDidUpdate() {
        const { loading, errors, scrollToError } = this.state;
        const { status } = this.props.form;
        if (loading) {
            if (status === 'success') {
                this.clearFields();
            } else if (status === 'fail') {
                this.showGenericError();
            } else if (status) {
                this.showCustomError();
            }
        }

        if (errors.length > 0 && scrollToError) {
            this.scrollToTop();
        }
    }

    /**
     * Scroll to top if any error exist in fields
     */
    scrollToTop() {
        this.errorElement.focus();
        this.setState({
            scrollToError: false,
        });
    }

    /**
     * Clears out the form fields
     */
    clearFields() {
        const updatedFields = {};
        Object.keys(this.fields).forEach((key) => {
            updatedFields[key] = '';
        });
        this.setState({
            fields: Object.assign({}, this.state.fields, updatedFields),
            errors: [],
            submitted: false,
            loading: false,
            scrollToError: false,
        });
    }

    /**
     * Adds the generic error to the list of errors to show
     */
    showGenericError() {
        const { messageError } = this.props;
        this.setState({
            errors: [messageError],
            loading: false,
        });
    }

    /**
     * Updates the fields in the state when the component is mounted
     */
    populateFields() {
        const updatedFields = {};
        Object.keys(this.fields).forEach((key) => {
            updatedFields[key] = this.fields[key].value;
        });
        this.setState({
            fields: Object.assign({}, this.state.fields, updatedFields),
        });
    }

    /**
     * Updates the tracked value
     * @param {string} name Name to update
     * @param {string} value Updated value
     * @param {string} fileSize File size in case of file input field
     * @param {string} fileExtension File extension in case of file input field
     * @param {array} fileDimension Required file dimension in case of file input field
     */
    updateValue(name, value, fileSize, fileExtension, fileDimension) {
        const fields = Object.assign({}, this.state.fields);
        fields[name] = value;
        if (fileSize) {
            this.fileSize[name] = fileSize;
        }
        if (fileExtension) {
            this.fileExtension[name] = fileExtension;
        }
        if (fileDimension) {
            this.fileDimension[name] = fileDimension;
        }
        this.setState({
            fields,
        });
    }

    /**
     * Method to be passed to child to add its input fields to be monitored by the Form component
     * @param {object} child JSX component
     */
    updateFields(child) {
        this.addField(child);
    }

    /**
     * Method to explicitly clear Form fileds based on a Flag set at Form component level
     */
    checkForClearFormFlag() {
        const { clearFormFields } = this.props;
        if (clearFormFields) {
            this.clearFields();
        }
    }

    /**
     * Adds a field to be monitored by the Form component
     * @param {object} child JSX component
     */
    addField(child) {
        const { name, isRequired, type, errorEmpty, errorInvalid, value, errorMinLength, minLength,
            errorInvalidChars, isValidChars, errorMismatch, matchField, fieldToCheckForValue, errorFileExtension,
            errorMaxSize, allowedFileTypes, maxFileSize, dimensionMin, dimensionMax, aspectRatioMin, aspectRatioMax,
            errorDimension, compareField, errorMatch, isMultipleEmail, customErrorMsg, errorMatched, notMatchField,
            isValidUrl, isMultipleUrls, startsWith, errorStartsWith, isValidNum, errorInvalidUSZipCode,
            errorInvalidCAZipCode,
            zipCodeType, countryType, customValidation, customValidationErrorMsg } =
            child.props;
        this.fields[name] = {
            isRequired,
            type,
            errorEmpty,
            errorInvalid,
            value,
            errorMinLength,
            minLength,
            errorInvalidChars,
            isValidChars,
            isValidNum,
            errorMismatch,
            matchField,
            fieldToCheckForValue,
            errorFileExtension,
            errorMaxSize,
            allowedFileTypes,
            maxFileSize,
            dimensionMin,
            dimensionMax,
            aspectRatioMin,
            aspectRatioMax,
            customErrorMsg,
            errorDimension,
            compareField,
            errorMatch,
            isMultipleEmail,
            errorMatched,
            notMatchField,
            isValidUrl,
            isMultipleUrls,
            startsWith,
            errorStartsWith,
            errorInvalidUSZipCode,
            zipCodeType,
            errorInvalidCAZipCode,
            countryType,
            customValidation,
            customValidationErrorMsg
        };
    }

    /**
     * Recursively clones the children in the Form component
     * @param {object} children Children to recurse through
     * @returns {*} Final cloned children
     */
    recursiveCloneChildren(children) {
        return Children.map(children, (child) => {
            let childProps = {};
            if (!child)
                return;

            if (React.isValidElement(child) && child.props) {
                // Adding updateValue method to input fields
                if (child.props.name) {
                    childProps = {
                        updateValue: this.updateValue,
                        value: this.state.fields[child.props.name],
                        updateFields: this.updateFields,
                        formFields: this.state.fields,
                        isError: this.state.errorFields[child.props.name],
                    };
                    this.addField(child);
                }

                // Adding submitClick functionality
                if (child.props.submitClick) {
                    childProps = {
                        submitClick: this.submitClick,
                    };
                }
            }
            if (child.props && child.props.children && typeof child.props.children === 'object') {
                childProps.children = this.recursiveCloneChildren(child.props.children);
            }
            return React.cloneElement(child, childProps);
        });
    }

    /**
     * Updates the submit flag to show the required classes if necessary
     * @param {object} e event object
     */
    submitClick(e) {
        const code = e ? e.keyCode || e.which : undefined;
        if (code === undefined || code === 13 || code === 32) {
            this.setState({
                submitted: true,
                scrollToError: true,
            });
            this.checkValues();
        }
    }

    /**
     * Checks the values to see if they pass validations
     */
    checkValues() {
        const errors = [];
        const errorFields = {};
        const { getErrorsList, errorNetwork } = this.props;
        const { fields } = this.state;
        Object.entries(this.fields).forEach(([name, item]) => {
            const value = fields[name];
            errorFields[name] = false;

            /**
             * function to check if the value is an array of length 0
             * @param {object} arr event object
             * @return {number} length
             */
            function emptyArrayCheck(arr) {
                if (Array.isArray(arr)) {
                    const isEmptyArray = arr.length ? 0 : 1;
                    return isEmptyArray;
                }
                return 0;
            }

            const isEmpty = (value === undefined || (typeof value === 'string' && value.trim() === '') ||
                value === 0 || emptyArrayCheck(value));

            // check required fields
            if (item.isRequired) {
                // Check if the field is empty
                if (isEmpty) {
                    errors.push(item.errorEmpty);
                    errorFields[name] = true;
                }
            }

            // // check valid email
            // if (item.type === 'email' && !isEmpty) {
            //     if (!checkemail(value)) {
            //         errors.push(item.errorInvalid);
            //         errorFields[name] = true;
            //     }
            // }
            // check if checkbox is required and the value is false
            if (item.type === 'checkbox' && item.isRequired) {
                if (!value && !isEmpty) {
                    errors.push(item.errorEmpty);
                    errorFields[name] = true;
                }
            }

            // check valid multiple email
            // if (item.isMultipleEmail === 'true' && !isEmpty) {
            //     const multipleEmailCheck = checkmultipleemail(value);
            //     if (!multipleEmailCheck.isValid) {
            //         errors.push(`${item.errorInvalid}: ${multipleEmailCheck.invalidEmailList.toString()}`);
            //         errorFields[name] = true;
            //     }
            // }
            // // check min length
            // if (item.minLength) {
            //     if (!isEmpty && !checkminlength(value, item.minLength)) {
            //         errors.push(item.errorMinLength);
            //         errorFields[name] = true;
            //     }
            // }
            // check characters
            // if (item.isValidChars) {
            //     if (!checkcharacters(value)) {
            //         errors.push(item.errorInvalidChars);
            //         errorFields[name] = true;
            //     }
            // }

            // // check numbers
            // if (!isEmpty && item.isValidNum) {
            //     if (!checknumbers(value)) {
            //         errors.push(item.errorInvalidChars);
            //         errorFields[name] = true;
            //     }
            // }

            if (!!item.customValidation){
                
                if (!item.customValidation(fields, name)){
                    errors.push(item.customValidationErrorMsg);
                    errorFields[name] = true;   
                }
            }

            // check custom error message
            if (item.customErrorMsg !== '' && typeof item.customErrorMsg !== 'undefined') {
                errors.push(item.customErrorMsg);
                errorFields[name] = true;
            }

            // match field
            if (item.matchField) {
                if (value !== fields[item.matchField] && (!item.isRequired || (item.isRequired && !isEmpty))) {
                    errors.push(item.errorMismatch);
                    errorFields[name] = true;
                }
            }

            // match field
            if (item.notMatchField) {
                if (value === fields[item.notMatchField]) {
                    errors.push(item.errorMatched);
                    errorFields[name] = true;
                }
            }

            // check linked field for value
            if (item.fieldToCheckForValue) {
                if (isEmpty && fields[item.fieldToCheckForValue]) {
                    errors.push(item.errorEmpty);
                    errorFields[name] = true;
                }
            }
            // check conditional field for value
            // if (item.countryType && checkstate(fields[item.countryType])) {
            //     if (isEmpty && fields[item.countryType]) {
            //         errors.push(item.errorEmpty);
            //         errorFields[name] = true;
            //     }
            // }

            // check file
            if (item.type === 'file' && value) {
                if (item.maxFileSize && this.fileSize[name] > item.maxFileSize) {
                    errors.push(item.errorMaxSize);
                    errorFields[name] = true;
                }
                if (item.allowedFileTypes &&
                    item.allowedFileTypes.split(',').indexOf(this.fileExtension[name]) === -1) {
                    errors.push(item.errorFileExtension);
                    errorFields[name] = true;
                }
                if (item.dimensionMin.length && value.type.match('^image/')) {
                    const [minWidth, minHeight] = item.dimensionMin;
                    const [maxWidth, maxHeight] = item.dimensionMax;
                    const minAspectRatio = item.aspectRatioMin;
                    const maxAspectRatio = item.aspectRatioMax;
                    const currentWidth = this.fileDimension[name][0];
                    const currentHeight = this.fileDimension[name][1];
                    const currentAspectRatio = currentWidth / currentHeight;
                    // Validate the File Height and Width.
                    if (minWidth && (minWidth > currentWidth || maxWidth < currentWidth)) {
                        errors.push(item.errorDimension);
                        errorFields[name] = true;
                    } else if (minHeight && (minHeight > currentHeight || maxHeight < currentHeight)) {
                        errors.push(item.errorDimension);
                        errorFields[name] = true;
                    } else if (minAspectRatio &&
                        (minAspectRatio > currentAspectRatio || maxAspectRatio < currentAspectRatio)) {
                        errors.push(item.errorDimension);
                        errorFields[name] = true;
                    }
                }
            }
            // Same field value Error
            if (item.compareField) {
                if (!isEmpty && value === fields[item.compareField]) {
                    errors.push(item.errorMatch);
                    errorFields[name] = true;
                }
            }
            // To check if the string is a valid url and validation incase user is putting multiple URLs
            // if (item.isValidUrl && !isEmpty) {
            //     const isValidUrl = (item.isMultipleUrls) ? value.split(',').every(checkurl) : checkurl(value);
            //     if (!isValidUrl) {
            //         errors.push(item.errorInvalid);
            //         errorFields[name] = true;
            //     }
            // }
            // To check if the string mets the starting with criteria
            if (item.startsWith && !isEmpty) {
                let isInvalidStarting;
                if (item.isMultipleUrls) {
                    value.split(',').map((val) => {
                        const invalidStarting = item.startsWith.split(',')
                            .every(startingCriteriaStr => !val.toLowerCase().trim().startsWith(startingCriteriaStr));

                        if (invalidStarting) {
                            isInvalidStarting = true;
                        }
                        return null;
                    });
                } else {
                    isInvalidStarting = item.startsWith.split(',')
                            .every(startingCriteriaStr => !value.toLowerCase().startsWith(startingCriteriaStr));
                }
                if (isInvalidStarting) {
                    errors.push(item.errorStartsWith);
                    errorFields[name] = true;
                }
            }
            if (item.zipCodeType && !checkValidZipCode(value, fields[item.zipCodeType])) {
                if (fields[item.zipCodeType] === '307') {
                    errors.push(item.errorInvalidUSZipCode);
                    errorFields[name] = true;
                }
                if (fields[item.zipCodeType] === '116') {
                    errors.push(item.errorInvalidCAZipCode);
                    errorFields[name] = true;
                }
            }
        });
        if (navigator.onLine === false) {
            errors.push(errorNetwork);
        }
        this.setState({
            errors,
            valid: (errors.length === 0),
            errorFields,
        });
        getErrorsList(errors);
    }

    /**
     * Handles the submit of the form
     * @param {object} e Event object
     */
    handleSubmit(e) {
        const { useAjax, actionUrl, customSubmit, contentType } = this.props;
        const { valid, fields } = this.state;
        const { submitForm } = this.props.actions;
        if (useAjax && valid && customSubmit === null) {
            e.preventDefault();
            this.setState({
                loading: true,
            });
            submitForm(actionUrl, fields, contentType);
        } else if (!valid) {
            e.preventDefault();
        } else if (customSubmit !== null && valid) { // Faking the submission with field validation
            e.preventDefault();
            customSubmit(fields);
        } else {
            this.setState({
                loading: true,
            });
        }
    }
    /**
     * Custom error messages handling
     */
    showCustomError() {
        const { customStatus, form } = this.props;
        this.setState({
            errors: customStatus(form),
            loading: false,
        });
    }

    /**
     * Renders the success message
     * @returns {*} Success message JSX
     */
    renderSuccess() {
        const { messageSuccess, showSuccess, form, customSuccessHandler } = this.props;
        if (customSuccessHandler) {
            customSuccessHandler(form);
        } else if (showSuccess || form.status === 'success') {
            return (
                <div className="imc-vr--large">
                    <ul className="imc-content imc-content--success">
                        <li>
                            {messageSuccess}
                        </li>
                    </ul>
                </div>
            );
        }
        return null;
    }

    /**
     * Renders the list of errors if they exist
     * @returns {*} Error JSX
     */
    renderErrors() {
        const { errorAdditionalClass } = this.props;
        const { errors } = this.state;
        if (errors.length > 0) {
            const errorContent = [];
            errors.forEach((error) => {
                errorContent.push(
                    <li
                        key={uniqueid('error')}
                        className={errorAdditionalClass}
                        dangerouslySetInnerHTML={FormConnected.errorMarkup(error)}
                    />,
                );
            });
            return (
                <div className="imc-vr--large">
                    <ul
                        className="imc-content imc-content--error"
                        tabIndex={-1}
                        ref={(element) => { this.errorElement = element; }}
                    >
                        {errorContent}
                    </ul>
                </div>
            );
        }
        return null;
    }

    /**
     * Renders the loading layer
     * @returns {XML} Loading layer
     */
    renderLoading() {
        const { loading } = this.state;
        const loadingClass = loading ? 'imc-loading--active' : '';

        return (
            <div className={`imc-loading ${loadingClass}`} aria-hidden="true">
                <svg width="200" height="200">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#loading" />
                </svg>
            </div>
        );
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { actionUrl, children } = this.props;
        const { submitted, errors } = this.state;
        return (
            <form
                ref={(node) => { this.theForm = node; }}
                action={actionUrl}
                method="post"
                onSubmit={this.handleSubmit}
                className={submitted ? 'imc-form--submitted' : ''}
                noValidate
            >
                {this.renderLoading()}
                {(errors.length === 0) && this.renderSuccess()}
                {this.renderErrors()}
                {this.recursiveCloneChildren(children)}
            </form>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{label: *, name: *, type: shim, updateValue: shim, isRequired: shim, additionalClass: shim}}
 */
FormConnected.propTypes = {
    form: PropTypes.object, // Form response
    actions: PropTypes.object, // Redux actions for the connected component
    actionUrl: PropTypes.string.isRequired, // Action URL for the form
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.object,
        PropTypes.node,
    ]).isRequired,
    useAjax: PropTypes.bool, // Flag to use either Ajax or Form Port
    customSubmit: PropTypes.func, // function to handle response when status is other than success or fail
    messageSuccess: PropTypes.string, // Success message text
    messageError: PropTypes.string, // Error message text
    showSuccess: PropTypes.bool, // Flag to show the success message
    showError: PropTypes.bool, // Flag to show the error message
    errorAdditionalClass: PropTypes.string, // Additional class for the errors
    customStatus: PropTypes.func, // function to handle response when status is other than success or fail
    customSuccessHandler: PropTypes.func, // function to custom handle submission
    asyncFields: PropTypes.object, // Object containing input fields name and their updated values
    contentType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]), // Content type of data in submission
    getErrorsList: PropTypes.func, // Method for getting list of error messages
    customErrorHandler: PropTypes.bool, // Prop to show custom error messages
    clearFormFields: PropTypes.bool, // Prop to trigger clear form fields explicitly
    errorNetwork: PropTypes.string, // Additional class for the network error
};

/**
 * @property defaultProps
 * @type {{errorAdditionalClass: string}}
 */
FormConnected.defaultProps = {
    actions: {},
    form: {
        status: '',
        message: '',
    },
    useAjax: false,
    customSubmit: null,
    errorAdditionalClass: '',
    showSuccess: false,
    showError: false,
    messageSuccess: 'Successfully Submitted',
    messageError: 'There was an error in your submission',
    customStatus: () => {},
    customSuccessHandler: null,
    asyncFields: {},
    contentType: 'text/plain;charset=UTF-8',
    getErrorsList: () => {},
    customErrorHandler: false,
    clearFormFields: false,
    errorNetwork: 'Sorry, there is currently not an internet connection. Please try again once you have a connection.',

};

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {
    return {
        form: state.form,
    };
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {{actions: *}} Action creators
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, formActions), dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormConnected);

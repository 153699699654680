/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_CONTACT_EXHIBITOR_INFO = 'SET_CONTACT_EXHIBITOR_INFO';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_CONTACT_EXHIBITOR_INFO_ERR = 'SET_CONTACT_EXHIBITOR_INFO_ERR';


/**
 * Show Loading
 * @type {string}
 */
export const SHOW_LOADING = 'SHOW_LOADING';

import { SEARCH_SAVED } from '../actions/actionTypes';
import { InitialState } from './initialState';

const initialState = {
    ...InitialState.search.searchSaved,
};

/**
 * Reducer for download search
 * @param {object} state State of the downloaded content
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function saveSearch(state = initialState, action) {
    switch (action.type) {
    case SEARCH_SAVED:
        return Object.assign({}, state, {
            searchSaved: action.saveSearch,
        });
    default:
        return state;
    }
}

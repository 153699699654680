/**
 * Constructs the basepath up to the route folder names
 * @returns {string} current basepath
 */
export function mobileScreenUtility() {
    /**
     * isMobileScreen
     * @return {boolean} flag
     */
    function isMobileScreen() {
        return (typeof window !== 'undefined' && window.innerWidth < 768);
    }

    /**
     * isTouchDevice
     * @return {boolean} flag
     */
    // function isTouchDevice() {
    //     let flag = false;
    //     if (typeof window !== 'undefined') {
    //         if ('ontouchstart' in window || navigator.maxTouchPoints) {
    //             flag = true;
    //         }
    //     }
    //     return flag;
    // }

    return isMobileScreen();
}

import React from 'react';
import {isEditorActive, Placeholder, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

type GlobalHeaderContainerProps = {
    fields?: any;
    rendering?: any;
    sitecoreContext: any;
}

const GlobalHeaderContainer: React.FC<GlobalHeaderContainerProps> = (props) => {

    return (
        <div className={`top-nav section-wrapper campus-bar${(isEditorActive()) ? ` sitecore-ee` : ``}`}>
            <section className={`imc-section imc-section--padded-none top-nav-wrapper${(props?.fields?.isFullWidth?.value == true) ? ` imc-section--full-bleed` : ``}`}>
                <div className="top-nav-campus">
                    <Placeholder name="global-header--top-left" rendering={props.rendering} />
                </div>
                <Placeholder name="global-header--top-right" rendering={props.rendering} isMenu={props?.fields?.isMenu.value} />
            </section>
        </div>
    );

}

export default withSitecoreContext()(GlobalHeaderContainer);

import React, { Component } from 'react'
import ReactModal from 'react-modal';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {withSitecoreRouter} from 'utils/withRouter';
import PropTypes from "prop-types";
import { createAppState } from "modules/redux-app-state";
import errorMessageReducer from "./reducers/errorMessageReducer";
// action imports
import * as actions from "./actions/actions";
const errorMessageState = createAppState();
class ErrorMessage extends Component {

    constructor(props) {
        super(props);  
        errorMessageState.reducerRegistry.register({
            errorMessageReducer
          });
		// if (!props) {
	    //     if (isEditorActive()) {
	    //         return <h1 className="alarm">Datasource isn't set.</h1>;
	    //     }
	    //     return (
	    //         <h1 className="alarm">
	    //             Data is not provided. Contact administrators, please.
	    //       </h1>
	    //     );
	    // }

	    this.state = { 	
            messages: [],
		};

		this.handleClosePopup = this.handleClosePopup.bind(this);
		this.handleOpenPopup = this.handleOpenPopup.bind(this);
    }

    
    handleOpenPopup() {
        //this.setState({ showAlertModal: true });
    }
    handleClosePopup() {
        this.props.actions.clearErrorMessage();
        //this.setState({ showAlertModal: false });
    }
    render() {
        const {messages} = this.props;
        
        return (
            <ReactModal
            isOpen={messages.length>0}
            ariaHideApp={false}
            overlayClassName="imc-modal--overlay"
            className="imc-modal--box imc-market-planner-quick-add-modal imc-modal--overlay imc-modal--box-height-auto"
        >
            <div className="imc-modal--content imc-section">
                <div
                    className={`imc-market-planner-quick-add-modal__content-box
                    imc-vr--large imc-vr--xlarge-desktop`}>
                    {messages.map(message=>{
                         return (<p className={`imc-errormessage--${message.errorLevel}`}>{message.text}</p>)
                    })}
                    <div className="imc-content imc-content--right"><button
                        className="imc-button--accent1-inverted imc-button--round imc-button " 
                        onClick={this.handleClosePopup}
                    >{'OK'}</button></div>
                </div>
            </div>
            <button
                className="imc-modal--close"
                onClick={this.handleClosePopup}
            >X</button>
        </ReactModal>
        )
    }
}

const defaultProps = {
    actions: {
      clearErrorMessage: () => { },
      setErrorMessage: () => { },
    },
    messages: [],
  };
  const propTypes = {
    actions: PropTypes.object,    
    messages: PropTypes.array
  };
  
  /**
   * Maps state to props for connect
  * @param {object} state State object
  * @returns {{ search: *}} State to props mapping
    */
  function mapStateToProps(state) {
    let _props = {};
    if (state.errorMessageReducer)
      Object.assign(_props, {
        messages: state.errorMessageReducer.messages
      });
    return _props;
  }
  
  /**
   * Maps dispatch to props for connect
   * @param {function} dispatch Dispatcher
   * @returns {object} Action creators
          */
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ ...actions}, dispatch),
    };
  }
  
  ErrorMessage.propTypes = propTypes;
  ErrorMessage.defaultProps = defaultProps;
  
  export default 
    withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorMessage)
  );


import {
    SET_ERROR_MESSAGE,
    CLEAR_ERROR_MESSAGE
} from './actionTypes';

/**
 * Action to display error popup
*/
export function setErrorMessage(message, errorLevel= 'ERROR') {
    return (dispatch) => {
        dispatch({
            type: SET_ERROR_MESSAGE,
            message: message,
            errorLevel: errorLevel
        });
    };
}


/**
 * Action to clear error message
 */
export function clearErrorMessage() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ERROR_MESSAGE
        });
    };
}



import React from 'react';
import { Image, ImageField }  from "@sitecore-jss/sitecore-jss-react";
import MediaCarouselComponent from '../Media-Carousel-Component'
import { Carousel, CarouselItem } from 'modules/carousel';

class FeaturedArticles extends React.Component {
	/**
	* @method contructor
	* @description Lifecycle method
	* @param {object} props incoming props
	*/
	constructor(props) {
		super(props);
		this.state = { data :[]};
	}

	renderCarouselItem(item, index) {
		var img = {};		
		//TO DO: verify this. If image.Src is empty for item - all the component throws an error. Added this check.
		if (item.ImageSrc){			
			img.value = { src: item.ImageSrc.replace("/sitecore/shell",""), alt: item.ImageAlt };
		}		

		return (
			<CarouselItem key={index}>
				<div className="imc-content--center imc-featured-events--display-flex" aria-hidden="false">
					<div className="imc-featured-events--box imc-featured-events--display-flex">
						<div className="imc-featured-events--box-content">
							<div className="imc-circle-image-text__image--mobile-nopadding imc-vr--small imc-vr--xlarge-desktop imc-featured-events--image">
								<a href={item.URL}>
								<Image field={img} />
								</a>
							</div>
							<a href={item.URL} class="imc-link imc-link--alt imc-content--full-width-block">
								<h4 className="imc-heading imc-heading--gamma imc-heading--alpha-desktop imc-heading--secondary imc-vr--small imc-heading--alt-darkred">
									{item.Title}
								</h4>
							</a>
						</div>
					</div>
				</div>
			</CarouselItem>
		)
	}

	render() {
		
		const { fields } = this.props;
		//TO DO: check here if datasourse.empty - show message in experience editor to add it.
		if (fields && fields.data && fields.data.datasource) {
			return (<div>
					<h1 className="imc-heading imc-heading--h2 imc-heading--h2-desktop imc-heading--center imc-vr--xlarge-desktop">Featured Articles</h1>
					{
						 <Carousel carouselColumns={3}>
							{fields.data.datasource.articles.multilist.map((item, index) => (
								this.renderCarouselItem({ImageSrc:item.thumbnail.src, ImageAlt:item.thumbnail.alt, URL: item.URL, Title: item.title == null ? '':item.title.value}, index)
							))}
						</Carousel>
					}

				</div>)
		}
		else {
			return (
				<div>
					<h1 className="imc-heading imc-heading--h2 imc-heading--h2-desktop imc-heading--center imc-vr--xlarge-desktop">Featured Articles</h1>
					{
						this.state && this.state.data && <Carousel carouselColumns={3}>
							{this.state.data.map((item, index) => (
								this.renderCarouselItem(item, index)
							))}
						</Carousel>
					}

				</div>
			)
		}
	}
}

export default FeaturedArticles;
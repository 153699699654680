/**
 * Updates videos
 * @type {string}
 */
export const SET_VIDEOS = 'SET_VIDEOS';

/**
 * Action to setting updated video count
 * * @type {string}
 */
export const SET_VIDEO_COUNT = 'SET_VIDEO_COUNT';

/**
 * Action to set error on videos fetch failure
 * @type {string}
 */
export const SET_VIDEOS_ERR = 'SET_VIDEOS_ERR';

/**
 * Used for updates when new search is ran, etc
 * marking any current results as out of date
 * @type {string}
 */
export const RUN_SEARCH = 'RUN_SEARCH';

/**
 * Show Results Tabs
 * @type {string}
 */
export const SHOW_LOADING = 'SHOW_LOADING';

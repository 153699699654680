import React from 'react';
import { Placeholder, withSitecoreContext, isEditorActive } from '@sitecore-jss/sitecore-jss-react';

class AccordionSectionComponent extends React.Component {

  constructor(props) {
    super(props);
    if (!props) {
      if (isEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    this.state = {
      currentIndex: -1,
      currentUid: this.props.currentUid
    };
    // set aliases
    this.updateCurrentIndex = this.updateCurrentIndex.bind(this);
    this.updateStateChild = this.updateStateChild.bind(this);
    this.updateDefaultOpen = this.updateDefaultOpen.bind(this);
    this.addSection = this.addSection.bind(this);
    this.updateUid = this.updateUid.bind(this);

    this.props.function.bind(this);

    var list = [];

    this.props.rendering.placeholders['imc-accordion-section-placeholder'].map((item, index) =>
      list.push(this.createIndexUidMap(item.uid, index))
    );
    this.list = list;

  }

  updateCalledFromDropdownAS(flag) {
    this.props.updateCalledFromDropdownASP(false);
  }


  createIndexUidMap(uid, index) {
    var map = {}
    map.uid = uid;
    map.index = index;
    return map;
  }
  addSection(section, toggleCallback) {
    this.props.function(section, toggleCallback);
  }

  updateUid(uid) {
    this.setState({
      currentUid: uid
    });
    this.props.updateCurrentUid(uid);

  }

  addCategory = () => this.props.function;
      /**
      * Updates the Accordion state from the prop
      */
  componentWillMount() {
    const { currentIndex } = this.state;
    this.updateCurrentIndex(currentIndex);
    if (typeof window == 'undefined' || !window.Sitecore) {
      if (!this.props.isSection) {
        this.props.function(this.props.fields.categoryTitle.value, this.props.rendering.uid);
      }
    }
  }


  /**
   * Updates the expanded state from the prop
   * @param {object} prevProps Previous props
   */
  componentDidUpdate(prevProps) {
    if (prevProps.defaultOpen !== this.props.defaultOpen) {
      // this.updateDefaultOpen();
    }
  }


  /**
   * Updates the expanded state based on the updated prop
   */
  updateDefaultOpen() {
    const { defaultOpen } = this.props;
    this.setState({
      currentIndex: defaultOpen,
    });
  }

  /**
   * @method updateCurrentIndex
   * @description Sets the state with the current index
   * @param {Number} index The index number of the carousel item to select
   * @param {object=} e Event object
   */
  updateCurrentIndex(index) {

    this.setState({
      currentIndex: index,
    });


  }
  /**
   * @method updateStateChild
   * @description updating Which accordion open and scroll
   * @param {object=} event Event object
   */
  updateStateChild(event) {
    this.setState({ currentIndex: event.target.value });
  }

  render() {
    const { fields, rendering } = this.props;
    if (!this.props.isSection && this.props.isCategory && this.props.currentCategoryOptionUid == this.props.rendering.uid) {
      if (this.props.calledFromDropdown) {
        this.state.currentIndex = 0;
      }
    } else if (!this.props.isSection && this.props.isCategory && this.props.currentCategoryOptionUid != this.props.rendering.uid) {
      if (this.props.calledFromDropdown) {
        this.state.currentIndex = -1;
      }
    }
    if (typeof window !== 'undefined' && window.Sitecore) {
      return (<div>
        <div className="imc-accordion imc-vr--titan imc-category--heading">
          <div>
            <div className="imc-category-title">
              <span className="imc-heading--alt2 imc-heading--alpha imc-heading--giga-desktop"><h2>{fields.categoryTitle.value}</h2></span>
            </div>
          </div>
          <div className="imc-expand-collapse">
            <Placeholder name="imc-accordion-section-placeholder" rendering={rendering} indexList={this.list} currentIndex={this.state.currentIndex} function={(index) => this.updateCurrentIndex(index)} />
          </div>
        </div>
      </div>);
    } else {
      return (
        <div>
          {!this.props.isSection &&
            <div className="imc-accordion imc-vr--titan imc-category--heading">
              <div>
                <div className="imc-category-title">
                  <span className="imc-heading--alt2 imc-heading--alpha imc-heading--giga-desktop"><h2>{fields.categoryTitle.value} </h2></span>
                </div>
              </div>
              <Placeholder name="imc-accordion-section-placeholder" isCategory={this.props.isCategory} calledFromDropdown={this.props.calledFromDropdown} rendering={rendering} indexList={this.list} currentIndex={this.state.currentIndex} function={(index) => this.updateCurrentIndex(index)} updateCalledFromDropdownAC={(flag) => this.updateCalledFromDropdownAS(flag)} />
            </div>
          }
          {this.props.isSection &&
            <div className="imc-expand-collapse">
              <Placeholder name="imc-accordion-section-placeholder" currentUid={this.props.currentUid} rendering={rendering} isSection={this.props.isSection} indexList={this.list} currentIndex={this.state.currentIndex} addSection={(section, toggleCallback) => this.addSection(section, toggleCallback)} updateUid={(uid) => this.updateUid(uid)} function={(index) => this.updateCurrentIndex(index)} />
            </div>
          }
        </div>
      )
    }
  }
}

export default withSitecoreContext()(AccordionSectionComponent);


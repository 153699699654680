import {
    SET_SHOWROOM_LINES_OVERVIEW,
    SET_SHOWROOM_LINES_OVERVIEW_ERR,
    SHOW_LOADING,
    SET_SHOWROOM_LINES_OVERVIEW_ORDER
} from '../actions/actionTypes';
const initialState = {
    lines: [],
    // shopZioURL: '',
    linesCount: 0,
    showLoading: true,
    order: ''
};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function digitalShowroomLinesOverviewReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_SHOWROOM_LINES_OVERVIEW:
            return Object.assign({}, state, {
                lines: action.lines,
                // shopZioURL: action.imcdiUrl,
                linesCount: action.linesCount,
                unfilteredLinesCount: action.filtered? state.unfilteredLinesCount: action.linesCount,
                showLoading: true
            });
        case SET_SHOWROOM_LINES_OVERVIEW_ERR:
            return Object.assign({}, state, {
                lines: initialState.lines,
                // shopZioURL: initialState.imcdiUrl,
                linesCount: initialState.linesCount,
                errCode: action.status || 404,
            });
        case SET_SHOWROOM_LINES_OVERVIEW_ORDER:
            return Object.assign({}, state, {
                order: action.order
            });
        default:
            return state;
    }
}
/**
 * Action to set Info on new error
 * @type {string}
 */
export const SET_ERROR_MESSAGE= 'SET_ERROR_MESSAGE';

/**
 * Action to set clear state
 * @type {string}
 */
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';

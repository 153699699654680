import React from 'react';
import { Placeholder, withSitecoreContext, isEditorActive } from '@sitecore-jss/sitecore-jss-react';

class AccordionModuleComponent extends React.Component {

  constructor(props) {
    super(props);
    if (!props) {
      if (isEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    this.showDropdown = false;
    this.addCategory = this.addCategory.bind(this);
    this.addSection = this.addSection.bind(this);
    if (this.props.fields.contentType.url.includes('Category')) {
      this.showDropdown = true;
    }
    this.isSection = false;
    if (this.props.fields.contentType.url.includes('Section')) {
      this.isSection = true;
    }
    this.updateCurrentAccordionIndex = this.updateCurrentAccordionIndex.bind(this);
    this.updateStateChild = this.updateStateChild.bind(this);
    this.state = {
      options: [],
      update: 0,
      currentUid: '',
      currentCategoryOptionUid: '',
      calledFromDropdown: false
    };
    this.dropdown = [];
    this.select = React.createRef();
    this.sections = [];
    this.currentUid = '';
  }

  updateCalledFromDropdownAM(flag) {
    this.setState({
      calledFromDropdown: flag,
    });
  }

  createOption(item) {
    var option = document.createElement("option");
    option.text = item.title;
    option.value = item.uid;
    this.dropdown.add(option);
  }
  componentDidMount() {
    if (this.showDropdown) {
      this.dropdown = this.select.current;
      this.state.options.map((item) =>
        this.createOption(item)
      );
    }
  }
  addCategory(title, uid) {

    var category = new Object();
    category.title = title;
    category.uid = uid;

    this.state.options.push(category);
    this.state.update = this.state.update + 1;

  }

  addSection(section, toggleCallback) {
    //this.sections.push(<a class="imc-gallery__item_section imc-link imc-link--alt-darkred imc-heading imc-heading--beta" onClick={toggleCallback} > {section} </a>)
    this.sections.push(<a class="imc-gallery__item imc-link imc-link--alt-darkred imc-heading imc-heading--beta" onClick={toggleCallback} > {section} </a>)

    this.setState({ update: this.state.update + 1 });
  }

  updateCurrentUid(uid) {
    this.setState({
      currentUid: uid,
    });
  }

  componentWillReceiveProps(nextProps) {

  }
  componentDidUpdate(prevProps) {

  }
  /**
    * @method updateCurrentAccordionIndex
    * @description Sets the state with the current index
    * @param {object=} e Event object
    */
  updateCurrentAccordionIndex(e) {

    this.setState({
      currentIndex: e.target.selectedIndex - 1,
      currentCategoryOptionUid: e.target.options[e.target.selectedIndex].value,
      calledFromDropdown: true,
    });
  }
  /**
     * @method updateStateChild
     * @description updating Which accordion open and scroll
     * @param {object=} event Event object
     */
  updateStateChild(event) {
    this.setState({ currentIndex: event.target.selectedIndex });
  }

  render() {

    const { fields, rendering } = this.props;
    if (typeof window !== 'undefined' && window.Sitecore) {
      return (
        <div className="imc-section imc-section--padded">
          <h2 className="imc-vr--large imc-vr--small-desktop imc-heading--alt imc-heading imc-heading--alpha imc-heading--omni-desktop">{fields.title.value}</h2>
          <p className="imc-heading imc-heading--gamma-beta imc-heading--alt imc-vr--xlarge imc-vr--xxxlarge-desktop">{fields.description.value}</p>

          <div data-accordion-group="" className="imc-accordion-group" data-dropdown-label="Select a Category" data-dropdown-firstoption="Select Category">
            <div data-reactroot="" className="imc-accordion-group--container"><div className="imc-vr--jumbo imc-vr--titan-desktop">
              {this.showDropdown &&
                <div className="imc-formfield imc-dropdown imc-content imc-accordion-group--dropdown-box" data-xpath="accordion.dropdownContainer">
                  <div className="imc-vr--small ">
                    <label htmlFor="dropdown" data-xpath="accordion.dropdownLabel" className="imc-accordion-group--label">Select a Category</label>
                  </div>
                  <select ref={this.select} onChange={e => this.updateCurrentAccordionIndex(e)} id="dropdown" data-xpath="accordion.dropdown" className="imc-accordion-group--dropdown imc-content"><option>Select Category</option>
                  </select>
                </div>
              }
              {this.isSection &&
                <div className="imc-accordion ">
                  <div className="imc-accordion-nav imc-vr--jumbo imc-vr--titan-desktop">
                    <div className="imc-gallery imc-gallery--1-3 imc-gallery--1-3--right-1-gutter imc-gallery--align-flex-start">
                      {this.sections}
                    </div>
                  </div>
                </div>
              }
            </div>
              {!this.isSection &&
                <Placeholder name="imc-accordion-module-placeholder" rendering={rendering} isSection={this.isSection} function={(category) => this.addCategory(category)} />
              }
              {this.isSection &&
                <Placeholder name="imc-accordion-module-placeholder" rendering={rendering} isSection={this.isSection} function={(section) => this.addSection(section)} />
              }
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="imc-section imc-section--padded">
          <h2 className="imc-vr--large imc-vr--small-desktop imc-heading--alt imc-heading imc-heading--alpha imc-heading--omni-desktop">{fields.title.value}</h2>
          <p className="imc-heading imc-heading--gamma-beta imc-heading--alt imc-vr--xlarge imc-vr--xxxlarge-desktop">{fields.description.value}</p>

          {this.showDropdown &&
            <div data-accordion-group="" className="imc-accordion-group" data-dropdown-label="Select a Category" data-dropdown-firstoption="Select Category">
              <div data-reactroot="" className="imc-accordion-group--container">
                <div className="imc-vr--jumbo imc-vr--titan-desktop">
                  <div className="imc-formfield imc-dropdown imc-content imc-accordion-group--dropdown-box" data-xpath="accordion.dropdownContainer">
                    <div className="imc-vr--small ">
                      <label htmlFor="dropdown" data-xpath="accordion.dropdownLabel" className="imc-accordion-group--label">Select a Category</label>
                    </div>
                    <select ref={this.select} onChange={e => this.updateCurrentAccordionIndex(e)} id="dropdown" data-xpath="accordion.dropdown" className="imc-accordion-group--dropdown imc-content"><option>Select Category</option>

                    </select>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.isSection &&
            <div data-accordion="" data-additional-class="" data-default-open="-1" data-open-all="false" data-navigation-link="true">
              <div data-reactroot className="imc-accordion ">
                <div className="imc-accordion-nav imc-vr--jumbo imc-vr--titan-desktop">
                  <div className="imc-gallery imc-gallery--1-3 imc-gallery--1-3--right-1-gutter imc-gallery--align-flex-start">
                    {this.sections}
                  </div>
                </div>
              </div>
            </div>
          }

          {!this.isSection &&
            <Placeholder name="imc-accordion-module-placeholder" calledFromDropdown={this.state.calledFromDropdown} isCategory={this.showDropdown} rendering={rendering} isSection={this.isSection} function={(category, uid) => this.addCategory(category, uid)} currentCategoryOptionUid={this.state.currentCategoryOptionUid} updateCalledFromDropdownASP={(flag) => this.updateCalledFromDropdownAM(flag)} />
          }
          {this.isSection &&
            <Placeholder name="imc-accordion-module-placeholder" rendering={rendering} currentUid={this.state.currentUid} isSection={this.isSection} function={(section, toggleCallback) => this.addSection(section, toggleCallback)} updateCurrentUid={(uid) => this.updateCurrentUid(uid)} />
          }
        </div>

      )
    }
  }
}



export default withSitecoreContext()(AccordionModuleComponent);

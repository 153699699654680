import React from 'react';
import Section from "./Section.jsx";
import { sortAscElements } from "../utils";
import { getDictionaryValue } from 'utils/dictionary';


class Pavilion extends React.Component {

    render() {
        const { id, icon, backgroundColor, fontColor, name, sections } = this.props;
        const orderedSections = sections.sort(sortAscElements);
        return (
            <div className="imc-campus-view--pavilion" id={id}>
                <div className="imc-campus-view--pavilion-title">{name.value}</div>
                {orderedSections.map((floor, index) => {
                    return <Section icon={icon} defaultBackgroundColor={backgroundColor} defaultFontColor={fontColor} {...floor.fields} key={index} pavilionName={name.value} ></Section>
                })}
            </div>
        )
    }
}

export default Pavilion;

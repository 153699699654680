import React from 'react';
import ReactModal from 'react-modal';
import { withSitecoreContext, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';
import { Loading } from 'modules/loading';
// reducers
import savedSearchesReducer from './reducers/savedSearchesReducer';
// action imports
import * as dataActions from './actions/dataActions';
import { createAppState } from 'modules/redux-app-state';
import { getDictionaryValue } from 'utils/dictionary/index.js';

import moment from 'moment';

const  MySavedSearchesState = createAppState();

class MySavedSearchesComponent extends React.Component {

  constructor(props) {
    super(props);

    MySavedSearchesState.reducerRegistry.register({ savedSearchesReducer });
    
    if (!props) {
      if (isEditorActive()) {
          return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
          <h1 className="alarm">
              Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    this.state = { 
      savedSearches : [],
      nextMarket: null,
      deleted: false,
    };

    this.loadSavedSearchData = this.loadSavedSearchData.bind(this);
    this.renderSavedSearchesItems = this.renderSavedSearchesItems.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.renderConfirm = this.renderConfirm.bind(this);
    this.deleteSavedSearch = this.deleteSavedSearch.bind(this);
    this.selectToDelete = this.selectToDelete.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
  }
  
  componentWillMount() {
     
  }

  /**
   * Kick off bound fetch data
   */
  componentDidMount() {
    this.loadSavedSearchData();
  }

  /**
   * Updates state with new props
   * @param {object} nextProps Incoming props
   */
  componentWillReceiveProps(nextProps) {
    if(this.props.data){
      if(this.props.data.savedSearch.length !== nextProps.data.savedSearch.length){
        this.setState({deleted:false});
      }
    }
    // let ss = this.props.data.savedSearch;
  }

  componentDidUpdate() {
    this.loadSavedSearchData();
  }

  deleteSavedSearch(){
    this.props.dataActions.deleteSavedSearch(this.state.selectedSavedSearchId);
    this.loadSavedSearchData();
    this.handleClosePopup();
    this.setState({deleted: true})
  }

  selectToDelete(savedSearchId){
    this.setState({selectedSavedSearchId: savedSearchId});
  }

  handleClosePopup(){
    this.setState({selectedSavedSearchId: null});
  }

  loadSavedSearchData(){
    if (!isEditorActive()) {
      this.props.dataActions.fetchSavedSearches();
    }else{
      this.props.dataActions.fetchMockSavedSearches();
    }
  }

  renderConfirm(){
    const { selectedSavedSearchId } = this.state;
    const { searchName } = this.props;
    return (
        <ReactModal
            isOpen={selectedSavedSearchId!=null}
            overlayClassName="imc-modal--overlay"
            className="imc-modal--box imc-market-planner-quick-add-modal imc-modal--overlay imc-modal--box-height-auto"
        >
            <div className="imc-modal--content imc-section">
                <div
                    className={`imc-market-planner-quick-add-modal__content-box
                    imc-vr--large imc-vr--xlarge-desktop`}
                >
                    <h2 className="imc-heading--giga imc-heading--secondary imc-vr--large">
                        Are you sure you want to delete <strong>{searchName}</strong> Saved Search?
                    </h2>
                    <button
                        className="imc-market-planner-quick-add-modal__submit-btn imc-button"
                        onClick={this.deleteSavedSearch}
                    >{'Confirm'}</button>
                </div>
            </div>
            <button
                className="imc-modal--close"
                onClick={this.handleClosePopup}
            >X</button>
        </ReactModal>
    );
  }
  
  renderItem(item) {
      return (
          <li key={item.savedSearchId}>
              <div className="imc-market-planner-list_row_title imc-content--alpha"> 
                  <a className="imc-link imc-link--alt-darkred imc-content--display-flex  imc-content--display-flex-center" href={`/search/exhibitors?`+item.searchTerm}>
                      {item.searchName}
                  </a>
              </div>
              <div className='imc-market-planner-list_row_right_column imc-content--epsilon'>
                  <span className='imc-type--title-5-ui-link imc-type--color-primary-medium imc-market-planner-list_right_item'>Saved: {moment(item.createdDate,'YYYY-MM-DD[T]HH:mm:ss[Z]').format('MMMM DD, YYYY hh:mm')}</span>
                  <span className='imc-market-planner-list_right_item'>
                      <a className="imc-type--title-5-ui-link imc-type--color-primary-medium" onClick={() => this.selectToDelete(item.savedSearchId)} >
                          Delete
                      </a>
                  </span>
              </div>
          </li>
      )
  }

  renderSavedSearchesItems(){
    if (typeof this.props.data == 'undefined' || typeof this.props.data.savedSearch == 'undefined'){
      return null;
    }else{
      let savedSearches = this.props.data.savedSearch;
      if(savedSearches.length >0 ){
        return (
          savedSearches.map((item) => {
            return(this.renderItem(item));
          }));
      }
      else {
        return (
          <span>{getDictionaryValue('noSavedSearches','You don\'t have any saved searches.')}</span>
      );
      }

    }
  }

  render() {
    return(
    <div>
      <h3 className="imc-content--center-mobile imc-heading--giga-desktop imc-heading--kilo imc-heading imc-heading--headingh2 imc-vr--large imc-breakpoint-display--hide-mobile">{getDictionaryValue('mySavedSearches','My Saved Searches')}</h3>
      <ul className='imc-market-planner-list'>
        {this.renderSavedSearchesItems()}
        {this.renderConfirm()}
      </ul>
    <div className="spacer">&nbsp;</div>
    </div>
    )
  }

}

function mapStateToProps(state) {
  if (!!state.savedSearchesReducer){
    return {
      data: state.savedSearchesReducer.data,
      showLoading: state.savedSearchesReducer.showLoading,
      deleteSucess: state.savedSearchesReducer.deleteSucess,
    };
  }
  else {
    return {
      data: state.data,
      showLoading: state.showLoading,
      deleteSucess: state.deleteSucess,
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

export default withSitecoreContext()(withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(MySavedSearchesComponent)));
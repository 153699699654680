import {
    SHOW_LOADING,
    SET_ARTICLES,
    SET_ARTICLE_COUNT,
    SET_ARTICLES_ERR,
} from './actionTypes';
import ArticlesAPI from 'api/search/articles';


/**
 * Instantiates the Images API
 * @type {SearchImagesAPI}
 * @private
 */
const _searchArticles = new ArticlesAPI();

/**
 * Action to get videos
 * @param {array} tags List of tags to pass into the API
 * @param {string} pagenum of page numbers
 * @returns {function(*)} Promise object with fetch to the SearchVideos
 */
export function fetchArticles(tags = [], pagenum, pagesize, bucket) {
    
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _searchArticles.getArticles(tags, pagenum, pagesize, bucket).then((response) => {
            const { Articles, Total } = response || {};
            // TO-DO Remove this, they need to send a status :|
            const status = 200;
            if (status === 200) {
               
                dispatch({
                    type: SET_ARTICLES,
                    articles: Articles,
                    count: Total,
                });
                dispatch({
                    type: SET_ARTICLE_COUNT,
                    count: Total,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_ARTICLES_ERR,
                    status,
                });
                dispatch({
                    type: SET_ARTICLE_COUNT,
                    count: Total,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}



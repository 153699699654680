
import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import {newComponent, contentSectionComponentRendering, richTextRendering, flexGridRendering, imcCtaRendering, standaloneImageRendering, returnMediaSection, returnRichTextHeading, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'
import ContentSectionComponent from '../Content-Section-Component';

interface HeroParenthesisWrapperProps {
    fields?: any;
}

const HeroParenthesisWrapper: React.FC<HeroParenthesisWrapperProps> = ({fields}) => {
    const backgroundSection = contentSectionComponentRendering();
    let gridWrapper = flexGridRendering();
    let contentSection = contentColumnSectionRendering(fields);
    let parenthesisColor = (fields?.accentColor?.value) ? fields.accentColor.value : "primary-medium";

    // ------------------------ //
    // build background section //
    // ------------------------ //
    backgroundSection.fields.backgroundColor = newComponent(fields.backgroundColor);
    backgroundSection.fields.backgroundImage = newComponent(fields.image);
    backgroundSection.fields.styles = {}
    if (fields?.videoFile?.value?.href) {
        backgroundSection.fields.videoFile = {
            value: {
                src: fields.videoFile.value.href,
                description: fields.videoFile.value.description
            }
        };
    }
    if (fields?.backgroundColor?.value || fields?.backgroundOpacity?.value || fields?.backgroundOverride?.value) {
        backgroundSection.fields.backgroundColor.value += ' imc-hero__overlay';
        if (fields?.backgroundColor?.value) {
            backgroundSection.fields.backgroundColor.value += ` imc-hero__overlay--${fields.backgroundColor.value}`;
        }
        if (fields?.backgroundOpacity?.value) {
            backgroundSection.fields.styles["--opacity"] = `${fields.backgroundOpacity.value}%`;
        }
        if (fields?.backgroundOverride?.value) {
            backgroundSection.fields.styles["--overlay"] = `${fields.backgroundOverride.value}`;
        }
    }
    if (fields.minHeight?.length > 0) {
        fields.minHeight.forEach((height?: any) => {
            if (height.fields?.className?.value) {
                if (height.fields.className.value.includes("CLASS-")) {
                    backgroundSection.fields.backgroundColor.value += ` imc-hero__min-height${height.fields.className.value.split("CLASS")[1]}`;
                } else {
                    backgroundSection.fields.styles["minHeight"] = `${height.fields.className.value}`;
                }
            }
        });
    }
    backgroundSection.fields.paddingTop.value = 'xlarge';
    backgroundSection.fields.paddingBottom.value = 'xlarge';
    backgroundSection.fields.paddingLeft.value = 'xlarge';
    backgroundSection.fields.paddingRight.value = 'xlarge';
    backgroundSection.fields.backgroundColor.value += ` am-hero`;
    // ------------------------ //

    // ------------------------ //
    // -- build grid wrapper -- //
    // ------------------------ //
    gridWrapper.params = {
        "columnCount": "{\"className\":{\"value\":\"2\"}}",
        "totalColumns": "{\"className\":{\"value\":\"1\"}}",
        "twoColumnWidths": "{\"className\":{\"value\":\"80-20\"}}",
        "padding": "{\"className\":{\"value\":\"imc-gallery--no-padded\"}}",
        "horizontalAlignment": "{\"className\":{\"value\":\"imc-gallery--justify-center\"}}",
        "verticalAlignment": "{\"className\":{\"value\":\"imc-gallery--align-flex-stretch\"}}",
        "mobileColumnCount": "{\"className\":{\"value\":\"1\"}}",
        "tabletColumnCount": "{\"className\":{\"value\":\"2\"}}"
    };

    // ------------------------ //

    // ------------------------ //
    // build  content  section  //
    // ------------------------ //
    fields.headingExtraClasses.unshift({
        fields: {
            className: {
                value: "HEADING-1"
            }
        }
    });
    let spreadLines = false;
    if (fields?.headingExtraClasses?.length > 0) {
        fields.headingExtraClasses.forEach((c?: any) => {
            if (c.fields?.className?.value.includes("andmore-width-") || c.fields?.className?.value.includes("andmore-line-")) {
                fields.headingExtraClasses.unshift( {
                    fields: {
                        className: {
                            value: "andmore-width-desktop-75 andmore-width-tablet-75 andmore-width-mobile-100"
                        }
                    }
                });
            }
        });
    }
    fields.secondaryHeadingExtraClasses.unshift( {
        fields: {
            className: {
                value: "HEADING-2"
            }
        }
    });
    contentSection = contentColumnSectionRendering(fields);
    contentSection.fields.backgroundColor = {
        "value": `boxy am-wrapper am-pare am-pare-${parenthesisColor} hero-am-pare pare-hide-mobile`
    };
    contentSection.fields.extraClass = {
        fields: {
            className: {
                value: "CONTENT inner-center"
            }
        }
    };
    if (fields?.accentOverride?.value) {
        contentSection.fields.styles = {
            "--pare": `${fields.accentOverride.value}`
        }
    }
    contentSection.fields.paddingTop.value = 'none';
    contentSection.fields.paddingBottom.value = 'none';
    contentSection.fields.paddingLeft.value = 'none';
    contentSection.fields.paddingRight.value = 'none';
    // ------------------------ //

    gridWrapper.placeholders['imc-grid-column-one-placeholder'].push(contentSection);
    backgroundSection.placeholders['imc-jss-content-section'].push(gridWrapper);

    return (
        <ContentSectionComponent {...backgroundSection} rendering={backgroundSection} />
    
    );
};

export default HeroParenthesisWrapper;

import React from 'react';

//Utils
import { uniqueid } from 'utils/uniqueid';

export default class SearchBarButton extends React.Component {

    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.toogleMenu = this.toogleMenu.bind(this);
        this.isOpen = this.isOpen.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.id = uniqueid('searchBarButton-');
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) & this.isOpen()) {
            this.toogleMenu();
        }
    }

    isOpen() {
        return this.props.openerId === this.id;
    }
    toogleMenu(close=false) {
        const { closeOtherMenus } = this.props;
        closeOtherMenus((this.isOpen() || close) ? '' : this.id);
    }


}

/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_SHOWROOM_EVENTS_OVERVIEW = 'SET_SHOWROOM_EVENTS_OVERVIEW';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_SHOWROOM_EVENTS_OVERVIEW_ERR = 'SET_SHOWROOM_EVENTS_OVERVIEW_ERR';

/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_EXHIBITOR_DETAIL_EVENTS_OVERVIEW = 'SET_EXHIBITOR_DETAIL_EVENTS_OVERVIEW';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_EXHIBITOR_DETAIL_EVENTS_OVERVIEW_ERR = 'SET_EXHIBITOR_DETAIL_EVENTS_OVERVIEW_ERR';

/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_LINE_DETAIL_EVENTS_OVERVIEW = 'SET_LINE_DETAIL_EVENTS_OVERVIEW';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_LINE_DETAIL_EVENTS_OVERVIEW_ERR = 'SET_LINE_DETAIL_EVENTS_OVERVIEW_ERR';


/**
 * Show Loading
 * @type {string}
 */
export const SHOW_LOADING = 'SHOW_LOADING';

import { DOWNLOAD_SEARCH } from '../actions/actionTypes';
import { InitialState } from './initialState';

const initialState = {
    ...InitialState.search.download,
};

/**
 * Reducer for download search
 * @param {object} state State of the downloaded content
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function download(state = initialState, action) {
    switch (action.type) {
    case DOWNLOAD_SEARCH:
        return Object.assign({}, state, {
            download: action.download,
        });
    default:
        return state;
    }
}

import React from 'react';
import {  isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Link from '../Link';
import withTooltip from 'modules/withTooltip';
class ImcCta extends React.Component {

    render() {
        const { fields, rendering } = this.props;
        if (isEditorActive()) { // Experience editor view
            if (!fields) {
                return <h1 className="alarm">Datasource isn't set.{JSON.stringify(this.props)}</h1>;
            }
            else if (!fields.ctaClass) {

                return <h1 className="alarm">No ctaClass selected</h1>;
            }
            else {
                
                const ctaClass = `imc-button imc-button--${(!!fields.ctaClass?fields.ctaClass.fields.className.value:'primary')}  ${(!!fields.textClass && fields.textClass.fields.className.value != "" ? " imc-heading--" + fields.textClass.fields.className.value : " ")}  ${(!!fields.extraClass  && fields.extraClass.fields.className.value != "" ? " " + fields.extraClass.fields.className.value : "")}`;
                return ( <>
                    {fields.link && 
                        <Link field={fields.link} target={(fields.link.value.linktype === 'internal') ? '' : fields.link.value.target} href={fields.link.value.href} className={ctaClass}>
                        
                    </Link>}
                    {!!!fields.link && 
                        <a href="#">
                            WARNING: NO LINK SPECIFIED
                        </a>
                    }</>
                )
            }
        }
        else { //Live view
            if (!fields) {
                return (
                    <h1 className="alarm">
                        Data is not provided. Contact administrators, please.
                     </h1>
                );
            }
            else { 
                const ctaClass = `imc-button imc-button--${(!!fields.ctaClass ? fields.ctaClass.fields.className.value : 'primary')}  ${(!!fields.textClass && fields.textClass.fields.className.value != "" ? " imc-heading--" + fields.textClass.fields.className.value : " ")}  ${(!!fields.extraClass && fields.extraClass.fields.className.value != "" ? " " + fields.extraClass.fields.className.value : "")}`;
                return (  <>
                    {fields.link && 
                        <Link field={fields.link} target={(fields.link.value.linktype === 'internal') ? '' : fields.link.value.target} href={fields.link.value.href} className={ctaClass} title={fields.link.value.text}>
                         
                        </Link>
                    }
                    {!!!fields.link && 
                        <a href="#">
                           
                        </a>
                    }

                    </>
                )
            }
        }
    }
}


export default withTooltip(ImcCta);
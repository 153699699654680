/**
 * @method getFloorPlanURL
 * @returns {string} /exhibitor/[exhibitorId]
 */
export function getFloorPlanURL(buildingName, floorNumber, showRoom) {
    return !!showRoom ? `/Market Map/building/${buildingName}/floor/${floorNumber}?showroom=${showRoom}` : `/Market Map/building/${buildingName}/floor/${floorNumber}`;
}

export function getBooth(buildingName, floorNumber, showRoom) {
    let channel = window?.channel?.name;
    if (buildingName?.toLowerCase()?.includes("expo")) buildingName = "E-";
    if (channel && buildingName && floorNumber && showRoom) {
        if (channel === 'atlanta-apparel' || channel === 'atlanta-market' || channel === 'americasmart') {
            if (showRoom.split(" ").length <= 1) {
                return `B${buildingName.replace("Building ","").replace(/^0+/, '')} ${`${floorNumber}`.replace(/^0+/, '')}-${showRoom.replace(/^0+/, '')}`.replace(/\(.*\)/g, '');
                // .replace(`${floorNumber.replace(/^0+/, '')}-${floorNumber.replace(/^0+/, '')}`, `${floorNumber.replace(/^0+/, '')}-`).replace(`${floorNumber.replace(/^0+/, '')}-0`, `${floorNumber.replace(/^0+/, '')}-${floorNumber.replace(/^0+/, '')}0`)
            }
        } else {
            if (parseInt(showRoom[0])) {
                return `${buildingName.replace("Building ","").replace(/^0+/, '')}${showRoom.replace(/^0+/, '')}`.replace(/\(.*\)/g, '');
            }
        }
    }
    return showRoom;
}
import React from 'react';
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate } from "react-router";
import {withSitecoreRouter} from 'utils/withRouter';
import PropTypes from "prop-types"

// reducers
import digitalShowroomProductDetailReducer from "./reducers/productDetailReducer";
// action imports
import * as dataActions from "./actions/dataActions";

//modules
import { createAppState } from "modules/redux-app-state";

//components
import ProductDetailHero from "./components/ProductDetailHero.jsx";
import ProductDetailBasicInfo from "./components/ProductDetailBasicInfo.jsx";
import RelatedProductsOverview from './components/RelatedProductsOverview';
import Link from '../Link';
//utils
import { getExhibitorURL, getExhibitorLineURL, locationIsLine } from "utils/exhibitor";
import { getDictionaryValue } from 'utils/dictionary';


const digitalShowroomState = createAppState();
const PRODUCT_PAGE_URL = 'prod';
const GUID_CONTST_LENGTH = 36;

class DigitalShowroomProductDetail extends React.Component {
  constructor(props) {
    super(props);
    digitalShowroomState.reducerRegistry.register({
      digitalShowroomProductDetailReducer
    });
    this.fetchData = this.fetchData.bind(this);

    const { sitecoreContext } = this.props;
    if (typeof sitecoreContext !== 'undefined') {
      if (typeof sitecoreContext.product !== 'undefined') {
        this.exhibitorId = sitecoreContext.product.exhibitId;
        this.productId = sitecoreContext.product.productId;

        this.lineId = 'lineProduct' in sitecoreContext ? sitecoreContext.lineProduct.lineId : null;
      }
    }
    this.state = {
      showModalGallery: false,
      productId: this.productId,
    }
  }

  /**
 * Kick off bound fetch data
 */
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (typeof document != 'undefined') {
      if (!!this.props.productDetail.productTitle && this.props.productDetail.productTitle != '')
        document.title = document.title.replace("{productName}", this.props.productDetail.productTitle);
    }

    // Fetch data again when navigating to a new instance of the same component
    // Expects GUIDs to be 36 characters
    if (window) {
      const url = window.location.href;
      const isValidURL = url.includes(PRODUCT_PAGE_URL);
      if (isValidURL) {
        const newProductId = window?.location?.href?.split('/').pop();
        if (newProductId.length === GUID_CONTST_LENGTH && this.state.productId !== newProductId) {
          this.setState({ productId: newProductId }, this.fetchData);
        }
      }
    }
  }

  /**
   * Fetch the article data
   * @param { string } queryObj term used to conduct search for
   */
  fetchData(id = this.state.productId) {
    if (this.state.productId)
      this.props.dataActions.getDigitalShowroomProductsDetail([id]);
  }

  getShownByName(productDetail) {
    return
  }

  render() {
    const { productDetail, actions } = this.props;
    const isLine = locationIsLine(this.props.router.location); //this.lineId !== null;
    if (this.props.errCode === 404)
      return <Navigate to='/exhibitor-not-exist' />
    return <>
      <div>
        <ProductDetailHero
          productDetail={productDetail}
          lineId={this.lineId}
          exhibitorId={this.exhibitorId}
          productId={this.state.productId}
          actions={actions} />
        <ProductDetailBasicInfo
          productDetail={productDetail}
        />
        {(Array.isArray(productDetail.productCategories) && productDetail.productCategories.length > 0) &&
          <RelatedProductsOverview
            lineId={this.lineId}
            exhibitorId={this.exhibitorId}
            productCategories={productDetail.productCategories}
          />
        }
        {
          ((isLine && productDetail.lineName) || (!isLine && productDetail.companyName)) &&
          <div className="imc-product-details--section">
            <div className="imc-section imc-section--width-100-percent">
              {
                (isLine) &&
                <h5>{getDictionaryValue('productBy', 'Product By')}&nbsp;
                  <Link href={getExhibitorLineURL(this.exhibitorId, productDetail.lineId)} className="imc-link imc-type--title-3">
                    {productDetail.lineName}
                  </Link>&nbsp;
                  {getDictionaryValue('shownBy', 'Shown By')}&nbsp;
                  <Link href={getExhibitorURL(this.exhibitorId)} className="imc-link imc-type--title-3 imc-margin--left--none">
                    {productDetail.companyName}
                  </Link>
                </h5>
              }
              {(!isLine) &&
                <h5>{getDictionaryValue('productShownBy', 'Product Shown By')}&nbsp;
                  <Link href={getExhibitorURL(this.exhibitorId)} className="imc-link imc-type--title-3">
                    {productDetail.companyName}
                  </Link>
                </h5>
              }
            </div>
          </div>
        }
      </div>
    </>
  }
}

const defaultProps = {
  dataActions: {
    getDigitalShowroomProductsDetail: () => { },
  },
  showLoading: false,
  productDetail: {},
};
const propTypes = {
  dataActions: PropTypes.object,
  showLoading: PropTypes.bool,
  productDetail: PropTypes.object,
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
  if (state.digitalShowroomProductDetailReducer) {
    return {
      showLoading: state.digitalShowroomProductDetailReducer.showLoading,
      productDetail: state.digitalShowroomProductDetailReducer.productDetail,
      errCode: state.digitalShowroomProductDetailReducer.errCode,
    };
  }
  else {
    return {
      showLoading: state.showLoading,
      productDetail: state.productDetail,
      errCode: state.errCode,
    };
  }
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

DigitalShowroomProductDetail.propTypes = propTypes;
DigitalShowroomProductDetail.defaultProps = defaultProps;

export default withSitecoreContext()(
  withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomProductDetail))
);


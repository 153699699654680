// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import Link from '../../Link'; 

import NavigationMobileTier3 from './NavigationMobileTier3.jsx';
/**
 * Component for displaying a navigation mobile item (tier 2) and maintaining its state
 */
export default class NavigationMobileTier2 extends Component {

    /**
     * @method constructor
     * @description On instantiation dynamically sets the tier 2 navigation item
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.setTier1 = this.setTier1.bind(this);
        this.setTier2 = this.setTier2.bind(this);
        this.renderTiers = this.renderTiers.bind(this);
        this.toogleTier3 = this.toogleTier3.bind(this);
    }

    /**
     * Unsets the tiers when the menu is closed
     * @param {object} prevProps Previous props
     */
    componentDidUpdate(prevProps) {
        // Focuses on tier 2 when a tier 1 item is selected
        if (this.props.translateX !== prevProps.translateX &&
            this.props.currentTier1 !== -1 &&
            this.props.currentTier2 === -1 &&
            this.tier2overview) {
            setTimeout(() => {
                if (this.props.eventType !== 'click') {
                    this.tier2overview.focus();
                }
            }, 500);
        }
    }

    /**
     * Sets the current tier1 item
     * @param {object} e Event object
     * @param {number} idx Tier 1 index number
     */
    setTier1(e, idx) {
        const code = e.keyCode || e.which;

        if (code === undefined || code === 13 || code === 32) {
            this.props.setTier1(idx, e.type);
        }
    }

    /**
     * Sets the current tier2 item
     * @param {object} e Event object
     * @param {number} idx Tier 2 index number
     */
    setTier2(e, idx) {
        const code = e.keyCode || e.which;

        if (code === undefined || code === 13 || code === 32) {
            this.props.setTier2(idx, e.type);
        }
    }
    findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }

    toogleTier3(event, tier3Id) {
        this.findAncestor(event.target, 'imc-navigation-mobile__2tier').classList.toggle("open");
        document.getElementById(tier3Id).classList.toggle("show");
    }

    /**
     * Renders the tier 2 level
     * @returns {*} Tier 2 JSX
     */
    renderTiers() {
        const tier2 = [];

        [].forEach.call(this.props.items, (item2tier, key) => {
            const tier3Class = (!!item2tier.navigation3st && item2tier.navigation3st.length) ? "imc-navigation-mobile-has3tier" : "";
            tier2.push(
                <div className={`imc-navigation-mobile__tierItem imc-navigation-mobile__2tier ${tier3Class}`} key={key}>
                    <div className="imc-navigation-mobile__2tierContainer">
                        <Link onClick={() => this.props.closeMenu()} className="imc-link imc-link--invert imc-navigation-mobile__tier2Link" field={item2tier.link} />
                        {item2tier.image.value.src &&
                            <Link className="imc-navigation-mobile-2tier-imageLink" field={item2tier.link} onClick={() => this.props.closeMenu()} >
                                <Image field={item2tier.image.value} className="imc-image--responsive imc-navigation-mobile-2tier-image" />
                            </Link>
                        }
                        {item2tier.description && item2tier.description.value !== "" &&
                            <div className="imc-navigation-mobile-2tier-description"><Text field={item2tier.description}></Text></div>
                        }
                    </div>
                    {
                        item2tier.navigation3st.length > 0 &&
                        <>
                            <button className="imc-navigation-mobile-3tier__toogle" onClick={(e) => { this.toogleTier3(e, `imc-navigation-mobile-3tier${key}`) }}>
                                <svg width="24" height="24">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconExpandArrow"></use>
                                </svg>
                            </button>
                            <div className="imc-navigation-mobile-3tier collapse" id={`imc-navigation-mobile-3tier${key}`}>
                                <NavigationMobileTier3
                                    ref={(node) => {
                                        this.tier3 = node;
                                    }}
                                    id={this.tier3id}
                                    tier3Content={item2tier.navigation3st}
                                    closeMenu={this.props.closeMenu}
                                />
                            </div>
                        </>
                    }
                </div>,
            );
        });
        return tier2;
    }

    /**
     * @method render
     * @description Renders the Mobile Navigation DOM element
     * @returns {*} Rendered component
     */
    render() {
        return (
            <div
                className="imc-navigation-mobile__tierContainer imc-navigation-mobile__tierContainer--tier2"
                style={{
                    flexBasis: this.props.windowWidth,
                }}
            >
                <div
                    key="back"
                    className="imc-navigation-mobile__tierItem"
                >
                    <button
                        role="menuitem"
                        tabIndex={0}
                        className="imc-navigation-mobile__back imc-link--icon"
                        onClick={e => this.setTier1(e, -1)}
                        onKeyDown={e => this.setTier1(e, -1)}
                    >
                        <svg width="22" height="22" className="icon__arrow-left">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconForwardArrow"></use>
                        </svg>
                    </button>
                    <span className="imc-navigation-mobile__tierTitle imc-link--to-upper-case" 
                    dangerouslySetInnerHTML={{ __html: this.props.backLabel }}>
                    </span>
                </div>
                {
                    this.props.currentTier1 !== -1 &&
                    <div className="imc-navigation-mobile__areaScroll"
                        style={{
                            height: this.props.scrollHeight,
                        }}
                    >
                        {this.renderTiers()}
                    </div>
                }
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{items}}
 */
NavigationMobileTier2.propTypes = {
    currentTier1: PropTypes.number.isRequired, // Current selected tier 1
    currentTier2: PropTypes.number.isRequired, // Current selected tier 1
    setTier1: PropTypes.func.isRequired, // Function to set the tier 2 item
    setTier2: PropTypes.func.isRequired, // Function to set the tier 2 item
    items: PropTypes.array.isRequired, // Array of items
    backLabel: PropTypes.string, // Label for Back button
    windowWidth: PropTypes.number, // Current window width
    translateX: PropTypes.string, // Current translateX (for focus detection)
    eventType: PropTypes.string, // Event type of how the menu is being navigated
    scrollHeight: PropTypes.number,

};

/**
 * @property defaultProps
 */
NavigationMobileTier2.defaultProps = {
    backLabel: '',
    windowWidth: '768px',
    translateX: '0px',
    eventType: 'click',
};

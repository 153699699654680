import React from 'react'
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import { ReactComponent as CloseIcon } from '../../assets/svg/las-vegas-market/close.svg';

GenericModal.propTypes = {
  children: PropTypes.any,
  containerCSSClass: PropTypes.string,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  acceptButtonTitle: PropTypes.string,
  denyButtonTitle: PropTypes.string,
  title: PropTypes.string,
}

function GenericModal({
  children,
  containerCSSClass,
  onClose,
  onAccept,
  acceptButtonTitle,
  denyButtonTitle,
  title,
}) {
  return (
    <div className="genericModal--popup">
      <div
        className={`genericModal--container ${containerCSSClass}`}
      >
        <div className="genericModal--childContainer">
          <div className='genericModal--titleContainer'>
            <h2 className="genericModal--title">{title}</h2>
            <CloseIcon
              className="genericModal--close"
              onClick={() => onClose()}
            />
          </div>
          <div className='genericModal--contentContainer'>
            <div className='genericModal--text'>
              {children}
            </div>
            <div className='genericModal--buttons'>
              {denyButtonTitle && (
                <Button
                  name={denyButtonTitle}
                  displayName={denyButtonTitle}
                  onClick={() => onClose()}
                  cssClass="imc-button imc-button--primary-inverted"
                />
              )}
              {acceptButtonTitle && (
                <Button
                  name={acceptButtonTitle}
                  displayName={acceptButtonTitle}
                  onClick={() => onAccept()}
                  cssClass="imc-button"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

GenericModal.defaultProps = {
  children: <></>,
  onClose: () => { },
  onAccept: () => { },
  acceptButtonTitle: "",
  denyButtonTitle: "",
  containerCSSClass: "",
  title: "",
}


export default GenericModal
export const GET_FLOOR_PLAN_MOCK = {
    "building": {
        "name": "Building A",
        "address": "475 S Grand Central Pkwy #1615\r\nLas Vegas, NV 89106",
        "floor": {
            "number": "2",
            "name": "Furniture",
            "pdfLink": "/-/media/ABD673EA8734427A9CE611BF57085D1E.ashx",
            "useWem": false,
            "wemUrl": ""
        },
        "nextFloor": {
            "number": "3"
        },
        "previousFloor": {
            "number": "1"
        }
    }
};
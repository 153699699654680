/**
 * Initial state for the store
 * @type {{}}
 */
export const InitialState = {
    form: {
        status: '',
        message: '',
    },
};

import React from 'react';
import PropTypes from 'prop-types';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import FocusTrap from 'focus-trap-react';
import { decode, encode } from 'utils/querystring';
import qs from 'query-string';
//import SavedSearchModal from '../Saved-Search-Modal';
import {withSitecoreRouter} from 'utils/withRouter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dataActions from '../../modules/exhibitors/actions/dataActions';
import * as searchActions from '../../modules/search/actions/searchActions';
import UserUtility from '../../utils/userutility';
import { createAppState } from 'modules/redux-app-state';
import search from "modules/search/reducers/searchReducer";
/**
 * @property appState
 * @description An AppState object to manage the reducer and store state of the application
 */
const appState = createAppState();

const EXHIBITOR = 'exhibitor';





const DESKTOP_VIEWPORT_WIDTH = 992;

const propTypes = {
    selectedFilterCounts: PropTypes.array,
    filterKey: PropTypes.string,
    toggleFiltersOpen: PropTypes.func,
    filtersOnly: PropTypes.bool,
    searchTypesLabel: PropTypes.string,
    closeLabel: PropTypes.string,
    savedSearchLabel: PropTypes.string,
    saveCurrentSearchButtonLabel: PropTypes.string,
    savedSearches: PropTypes.object,
    siteMapUrlLabels: PropTypes.object,
    dataActions: PropTypes.object,
    actions: PropTypes.object,
    saveSearchModal: PropTypes.bool,
    labels: PropTypes.object,
    savedSearchLabelSuffix: PropTypes.string,
    savedSearchEmptyMessage: PropTypes.string,
    savedSearchLogin: PropTypes.string,
    savedSearchLoginSaveSuffix: PropTypes.string,
    savedSearchLoginViewSuffix: PropTypes.string,
    searchingLabel: PropTypes.string,
    searchingMobileLabel: PropTypes.string,
    searchTypeBoth: PropTypes.string,
    searchTypeExhibitors: PropTypes.string,
    searchTypeProducts: PropTypes.string,
    setSearchType: PropTypes.func,
    query: PropTypes.string,
    filtersLabel: PropTypes.string,
    appliedFiltersLabel: PropTypes.string,
};

const defaultProps = {
    selectedFilterCounts: [],
    filterKey: '',
    toggleFiltersOpen: null,
    filtersOnly: false,
    dataActions: {
        getSavedsearches: () => {},
    },
    closeLabel: 'Close',
    savedSearchLabel: 'My Saved Searches',
    savedSearchLabelSuffix: '- Click to Close',
    saveCurrentSearchButtonLabel: 'Save Current Search',
    savedSearchEmptyMessage: 'You Have no Saved Searches',
    savedSearchLogin: 'Login',
    savedSearchLoginSaveSuffix: 'to Save a Search',
    savedSearchLoginViewSuffix: 'to view your Saved Searches',
    searchingLabel: 'Searching:',
    searchingMobileLabel: 'Searching',
    searchTypeBoth: 'Exhibitor & Product Info',
    searchTypesLabel: 'Select Search Types',
    searchTypeExhibitors: 'Exhibitor Info Only',
    searchTypeProducts: 'Product Info Only',
    savedSearches: {"count":2,"savedSearch":[{"searchTerm":"q=Chairs","createdDate":"16-10-2019:15:37:57","savedSearchId":200,"accountId":21335,"searchName":"Chair Search","searchContext":"exhibitor"},{"searchTerm":"q=Infinity%20Massage%20Chairs","createdDate":"20-02-2020:12:58:43","savedSearchId":261,"accountId":21335,"searchName":"test af","searchContext":"exhibitor"}]},
    siteMapUrlLabels: {},
    setSearchType: null,
    query: '',
    filtersLabel: 'Filters',
    appliedFiltersLabel: '$1 Filters Applied',
    actions: {
        saveSearch: () => {},
    },
    saveSearchModal: false,
    labels: {
        searchResults: {
            saveSearchCta: 'Save Search',
            downloadSearchResults: 'Download',
            saveSearchHelp: 'Save Search Help',
            saveSearchDescription: 'Save Search Description',
            saveSearchName: 'Search Name:',
            saveSearchResults: 'Save',
            emailSearchResults: 'Email',
        },
        account: {
            loginUrl: '/login',
        },
    },
};

/**
 * Given a saved search optimized for mobile, wrap the search in exhibitor
 * for use in LVM
 * @param {*} queryIn Incoming search query from saved search
 * @returns {string} A query string for use in LVM
 */
function calculateQueryString(queryIn) {
    const queryObject = decode(queryIn);
    const { type, q, exactmatch, ...otherFilters } = queryObject;
    let writableObject = {};
    if (otherFilters[EXHIBITOR]) {
        writableObject = {
            ...otherFilters,
        };
    } else {
        writableObject[EXHIBITOR] = encode(otherFilters);
    }
    if (q) {
        writableObject.q = q;
    }
    if (type) {
        writableObject.type = type;
    }
    if (exactmatch) {
        writableObject.exactmatch = exactmatch;
    }

    return qs.stringify(writableObject);
}

class SaveSearches extends React.Component {
	/**
     * Initializes the history
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
		if (!props) {
	        if (isEditorActive()) {
	            return <h1 className="alarm">Datasource isn't set.</h1>;
	        }
	        return (
	        <h1 className="alarm">
	                Data is not provided. Contact administrators, please.
	          </h1>
	        );
        }
        
        appState.reducerRegistry.register({
            search
        });

        const queryObj = typeof window !== "undefined"? qs.parse(window.location.search): '';
        this.state = {
            isOpen: false,
            isFilterOpen: false,
            searchType: queryObj.type && queryObj.type.length ? queryObj.type : '',
            saveSearchModal:false,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleFilterOpen = this.handleFilterOpen.bind(this);
        this.openSaveSearchModal = this.openSaveSearchModal.bind(this);
        this.closeSaveSearchModal = this.closeSaveSearchModal.bind(this);
        this.saveSearch = this.saveSearch.bind(this);
        this.setSearchType = this.setSearchType.bind(this);
        this.toggleFiltersOpen = this.toggleFiltersOpen.bind(this);
    }
    /**
     * Set the search type (exhibitor and product, exhibitor only, product only).
     * @param {obj} event The click event from the search type button.
     */
    setSearchType(event) {
        const { setSearchType, query } = this.props;
        const type = event.target.getAttribute('data-type');
        this.setState({
            searchType: type,
        }, () => {
            setSearchType(query, type);
        });
        this.setState({
            isFilterOpen: false,
        });
    }

    /**
     * Toggle the filters open/closed (for mobile view).
     */
    toggleFiltersOpen() {
        this.props.toggleFiltersOpen();
    }
    /**
     * Handle menu open/close.
     */
    handleOpen() {
        
        if (this.state.isOpen) {
            document.removeEventListener('mousedown', this.handleClick.bind(this), false);
        } else {
            document.addEventListener('mousedown', this.handleClick.bind(this), false);
        }
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }
    /**
     * Handle menu open/close.
     */
    handleFilterOpen() {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }
    /**
     * Handle clicks exterior to the open menu, close the menu when the occur.
     * @param {obj} event The document click event.
     */
    handleClick(event) {
        if (!this.node.contains(event.target)) {
            this.setState({
                isOpen: false,
                isFilterOpen: false,
            });
        }
    }
    /**
     * Save search action
     */
    closeSaveSearchModal() {
        // this.setState({
        //     isOpen: false,
        //     saveSearchModal:false
        // });
        this.props.actions.closeSaveSearchModal();
    }
    /**
     * Save search action
     */
    openSaveSearchModal() {
        
        this.setState({
            isOpen: false,
            // saveSearchModal:true
        });
        this.props.actions.openSaveSearchModal();
    }
    /**
     * Save search action
     * @param { string } searchName  name of saved search
     */
    saveSearch(searchName) {
        
        const userInfo = UserUtility.getUserData();
        this.props.actions.saveSearch(searchName, EXHIBITOR).then(() => {
            this.props.dataActions.getSavedsearches(userInfo.accountId);
        });
    }
    /**
     * Render the saved search area.
     * @returns {XML} Rendered component
     */
    renderContents() {
        const { labels, savedSearches, savedSearchEmptyMessage,
                savedSearchLogin, savedSearchLoginViewSuffix } = this.props;
        var href = typeof window !== "undefined"?`${labels.account.loginUrl}?referrerUrl=${window.location.href}`:''
        return (<div>
            {savedSearches.count > 0 &&
            savedSearches.savedSearch.map((item, index) => this.renderSearchItem(item, index))}
            {(!savedSearches.count && typeof window !== "undefined" && window.userInfo.loggedIn) &&
            <div className="imc-savedsearchbar__items__dropdown__contents__noitems">
                {savedSearchEmptyMessage}
            </div>}
            {typeof window !== "undefined" && !window.userInfo.loggedIn &&
            <div className="imc-savedsearchbar__items__dropdown__contents__noitems">
                <a
                    className="imc-savedsearchbar__items__dropdown__contents__noitems__login"
                    aria-label={savedSearchLogin}
                    href={href}
                >
                    {savedSearchLogin}
                </a>
                {` ${savedSearchLoginViewSuffix}`}
            </div>}
        </div>);
    }
    /**
     * Render a single saved search item.
     * @param {obj} item The item to render
     * @param {num} index The index of the item to render
     * @returns {XML} Rendered component
     */
    renderSearchItem(item, index) {
        const { siteMapUrlLabels } = this.props;
        const siteMainURL = typeof window !== "undefined"?`${window.location.origin}`:'';
        const redirectUrl = `${siteMainURL}${siteMapUrlLabels.findExhibitors}`;
        const queryString = calculateQueryString(item.searchTerm);
        return (<a
            href={`${redirectUrl}?${queryString}`}
            aria-label={item.searchName}
            key={index}
            data-id={item.savedSearchId}
            className="imc-savedsearchbar__items__dropdown__contents__item"
        >
            {item.searchName}
        </a>);
    }
    /**
     * Renders the JSX view
     * @returns {XML} Rendered component
     */
    render() {
        const { savedSearchLabel, saveCurrentSearchButtonLabel, savedSearches,
            selectedFilterCounts, filterKey, filtersLabel, appliedFiltersLabel,
            savedSearchLabelSuffix, savedSearchLogin, savedSearchLoginSaveSuffix, filtersOnly,
            closeLabel } = this.props;
        const openClass = this.state.isOpen ? 'imc-savedsearchbar__items__dropdown__open' : '';
        const queryObj = typeof window !== "undefined"?qs.parse(window.location.search):'';
        const isSaveEnabled =
            ((queryObj.q && queryObj.q.length) || (queryObj.exhibitor && queryObj.exhibitor.length))
            && (typeof window !== "undefined" && window.userInfo.loggedIn);
        const mainButtonLabel = this.state.isOpen ? `${savedSearchLabel} ${savedSearchLabelSuffix}` : savedSearchLabel; 
        const hideLabelClass = (typeof window !== "undefined" && window.innerWidth < DESKTOP_VIEWPORT_WIDTH) ? 'imc-visuallyhide' : '';
        const filtersOnlyClass = filtersOnly ? 'imc-savedsearchbar--filtersonly' : '';
        const filterCount = selectedFilterCounts[filterKey] && selectedFilterCounts[filterKey] ?
            selectedFilterCounts[filterKey] : 0;
        filterCount > 0 ? appliedFiltersLabel.replace('$1', filterCount) : filtersLabel;
        if (typeof window !== "undefined" && (this.state.isOpen || this.state.isFilterOpen) && window.innerWidth < DESKTOP_VIEWPORT_WIDTH) {
            document.body.classList.add('imc-filter__mobile-filter-body-no-scroll');
        } else {
          
        }
        var href = typeof window !== "undefined"?`${this.props.labels.account.loginUrl}?referrerUrl=${window.location.href}`:''
        return (
            <div className={`imc-savedsearchbar ${filtersOnlyClass}`}>
  
                <FocusTrap
                    className="imc-savedsearchbar__items"
                    active={this.state.isOpen}
                >
                    <button
                        className="imc-savedsearchbar__items__button"
                        onClick={this.handleOpen}
                        aria-label={mainButtonLabel}
                    >
                        <span className={`imc-savedsearchbar__items__button__label  ${hideLabelClass}`}>
                            {savedSearchLabel}
                        </span>
                        <svg
                            className="imc-savedsearchbar__items__button__icon"
                            width="18"
                            height="18"
                            role="img"
                            aria-labelledby="my-saved-search-title"
                        >
                            <title id="my-saved-search-title">{savedSearchLabel}</title>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#saved-search" />
                        </svg>
                    </button>
                    <div
                        className={`imc-savedsearchbar__items__dropdown ${openClass}`}
                        ref={(node) => { this.node = node; }}
                    >
                        <div className="imc-savedsearchbar__items__dropdown__closebar">
                            <button
                                className="imc-savedsearchbar__items__dropdown__closebar__button"
                                onClick={this.handleOpen}
                                aria-label={closeLabel}
                            >
                                {closeLabel}
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => this.openSaveSearchModal()}
                                disabled={!isSaveEnabled}
                                className="imc-button imc-savedsearchbar__items__dropdown__save"
                                aria-label={saveCurrentSearchButtonLabel}
                            >
                                {saveCurrentSearchButtonLabel}
                            </button>
                        </div>
                        {typeof window !== "undefined" && !window.userInfo.loggedIn &&
                        <div className="imc-savedsearchbar__items__dropdown__loginmessage">
                            <a
                                className="imc-savedsearchbar__items__dropdown__loginmessage__login"
                                aria-label={savedSearchLogin}
                                href={href}
                            >
                                {savedSearchLogin}
                            </a>
                            {` ${savedSearchLoginSaveSuffix}`}
                        </div>}
                        <div className="imc-savedsearchbar__items__dropdown__label">
                            {savedSearchLabel}
                        </div>
                        <div className="imc-savedsearchbar__items__dropdown__contents">
                            {this.renderContents(savedSearches)}
                        </div>
                    </div>
                </FocusTrap>
               
            </div>
        );
    }
}

SaveSearches.propTypes = propTypes;
SaveSearches.defaultProps = defaultProps;

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {
    if  (state.search) {
        return {
            saveSearchModal: state.search.saveSearchModal 
        }
    } else {
        return {
            saveSearchModal: false
        }
    }
}

// export default SaveSearches;
/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {{actions: *}} Action creators
 */
function mapDispatchToProps(dispatch) {
    return {
        dataActions: bindActionCreators({ ...dataActions }, dispatch),
        actions: bindActionCreators(Object.assign({}, searchActions), dispatch),
    };
}

export { SaveSearches as UnwrappedSavedSearchBar };
export default withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(SaveSearches));
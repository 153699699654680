// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component for displaying a CarouselItem within a Carousel component,
 * handling display logic to show the carousel item content when the item is active
 * @returns {Element} Rendered carousel item
 */
export default class CarouselItem extends Component {
    /**
     * @method constructor
     * @description On instantiation renders the carousel item
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            displayNone: false,
            active: props.isActive,
        };

        this.setDisplay = this.setDisplay.bind(this);
    }

    /**
     * Sets the displayNone flag upon component mount
     */
    componentDidMount() {
        this.setDisplay(!this.props.isActive);
    }

    /**
     * Adds a timeout to set the display to none for accessibility
     * @param {object} prevProps Previous props
     */
    componentDidUpdate(prevProps) {
        const { isActive } = this.props;
        if (isActive !== prevProps.isActive) {
            this.setDisplay();
        }
    }

    /**
     * Sets the displayNone and active flag
     */
    setDisplay() {
        const { isActive } = this.props;
        if (isActive) {
            setTimeout(() => {
                this.setState({
                    displayNone: false,
                });
            }, 50);
            setTimeout(() => {
                this.setState({
                    active: true,
                });
            }, 100);
        } else {
            setTimeout(() => {
                this.setState({
                    active: false,
                });
            }, 100);
            setTimeout(() => {
                this.setState({
                    displayNone: true,
                });
            }, 800);
        }
    }

    /**
     * @method render
     * @description Renders the CarouselItem DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { isActive, children, suppressDesktop, carouselColumns } = this.props;
        const { displayNone, active } = this.state;
        let carouselColumnsClass;
        let flexClass = '';
        const activeClass = active && !suppressDesktop ? 'imc-carousel__item--active' : '';
        const displayNoneClass = displayNone && !suppressDesktop && !active ? 'imc-hide' : '';
        const carouselClass = suppressDesktop ? 'imc-gallery__item imc-gallery__item--center'
            : 'imc-carousel__item imc-gallery__item imc-gallery__item--center';
        carouselColumnsClass = carouselClass;
        if (carouselColumns > 1) {
            carouselColumnsClass = 'imc-gallery__item imc-gallery__item--center';
            flexClass = 'imc-featured-events--display-flex';
        }
        return (
            <div
                className={`${carouselColumnsClass} ${activeClass} ${displayNoneClass} ${flexClass}`}
                aria-hidden={!isActive && !suppressDesktop}
                data-xpath="carousel.item"
            >
                <div className={`imc-carousel__content ${flexClass}`}>{children}</div>
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{children, isActive: (*)}}
 */
CarouselItem.propTypes = {
    children: PropTypes.object, // The children contents of the component
    isActive: PropTypes.bool, // Indicator for whether the CarouselItem is active
    suppressDesktop: PropTypes.bool, // Indicator to suppress carousel functionality
    carouselColumns: PropTypes.number, // Indicator to suppress carousel functionality
};

/**
 * defaultProps
 * @type {{isActive: boolean}}
 */
CarouselItem.defaultProps = {
    children: [],
    isActive: false,
    suppressDesktop: false,
    carouselColumns: 1,
};

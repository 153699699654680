/**
 * Inserts the SVG response into the DOM
 * @param {string} response SVG response string
 * @private
 */
function _insertSVG(response) {
    const div = document.createElement('div');
    div.setAttribute('class', 'imc-svg-sprite');
    div.innerHTML = response;
    document.body.insertBefore(div, document.body.childNodes[0]);
}

/**
 * Creates an ajax request to asyncronously load the sprites
 */
export function loadSvgSprite(siteSlug) {
    //we may need to implement a static url for getting this. So far I'm not sure if it is really needed as I can see the icons in the FE 
    if(typeof (process.env.PUBLIC_URL) =='undefined')
        return;
    fetch(`${(process.env.PUBLIC_URL!==undefined?process.env.PUBLIC_URL:'/dist/las-vegas-market')}/assets/${siteSlug}/defs.svg`)
        .then(response => response.text())
        .then(_insertSVG);
}

import * as types from './actionTypes';
import ExhibitorProductAPI from '../api/ExhibitorProductAPI';

/**
 * Initialize the SearchCountAPI
 * @type {SearchCountAPI}
 * @private
 */
const _products = new ExhibitorProductAPI();

/**
 * Function to mark product data out of date
* @returns {Function} function to dispatch action
 */
export function setExhibitorCategoriesProductsOutOfDate() {
    return (dispatch) => {
        dispatch({
            type: types.SET_EXHIBITORCATEGORIES_PRODUCTS_OUT_DATE,
        });
    };
}

/**
 * Action to get the lists for the user
 * @param {string} exhibitId of current exhibitor
 * @param {bool} isLine  is Line page
 * @param {string} pagenum of current page
 * @returns {Function} Promise object with fetch to the ListsAPI
 */
export function getProducts(exhibitId, isLine, pagenum) {
    return function returnLists(dispatch) {
        return _products.getProducts(exhibitId, isLine, pagenum)
            .then((response) => {
                const { products, count } = response;
                const countObj =
                    {
                        products: count,
                    };
                dispatch({
                    type: types.GET_EXHIBITORCATEGORIES_COUNT_SUCCESS,
                    payload: countObj,
                });
                dispatch({
                    type: types.GET_EXHIBITORCATEGORIES_PRODUCTS_SUCCESS,
                    products,
                    count,
                });
                return products;
            });
    };
}

/**
 * Action to get the lists for the user
 * @param {string} exhibitId of current exhibitor
 * @param {bool} isLine  is Line page
 * @param {string} pagenum of current page
 * @returns {Function} Promise object with fetch to the ListsAPI
 */
export function getFeaturedProducts(exhibitId, isLine, pagenum) {
    return function returnLists(dispatch) {
        return _products.getProducts(exhibitId, isLine, pagenum)
            .then((response) => {
                const { products, count } = response;
                const countObj =
                    {
                        products: count,
                    };
                dispatch({
                    type: types.GET_EXHIBITORCATEGORIES_COUNT_SUCCESS,
                    payload: countObj,
                });
                dispatch({
                    type: types.GET_EXHIBITORCATEGORIES_PRODUCTS_SUCCESS,
                    products,
                    count,
                });
                return products;
            });
    };
}

import pushDataLayer from '../../dataLayer';
import ProductEvent from "./productBaseEvent";

class ProductClicks extends ProductEvent{

    constructor() {
        super();
        this.page = "";
        this.push = this.push.bind(this);
    }

    /**
     * Sets the page in which the product click took place
     * @param {string} page the page where the product click took place
     */
    setPage(page){
        this.page = page;
    }
    /**
     * Puhses recorded data to GTM datalayer
     */
    push(){
        if (this.products.length==0)
            return;

        if (!this.page){
            console.warn ("Data Layer - Product Click - Page was not set up");
        }

        const EVENT_NAME = "productClick";
        const data = {
            event: EVENT_NAME,
            ecommerce: {
              currencyCode: "USD",                   
              click: {
                  actionField:{list: this.page},
                  products: this.products
                }
            }
        }
        
        pushDataLayer(data);
    }
}

export default ProductClicks;
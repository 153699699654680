import {
    SET_VIEW_MODE, SET_GROUPED
} from '../actions/actionTypes';

const initialState = {
    viewMode: "List",
    isGrouped: true
};


export default function listDisplayReducer(state = initialState, action) {
    switch (action.type) {
    
    case SET_VIEW_MODE:
        return Object.assign({}, state, {
            viewMode: action.value
        });
    case SET_GROUPED:
        return Object.assign({}, state, {
            isGrouped: action.isGrouped
        });
        
    default:
        return state;
    }
}

/**
 * Remove keys from object with null or empty values
 * @param {object} dirtyObj object that needs to be cleaned
 * @returns {object} cleaned object
 */
export function cleanObjKeys(dirtyObj) {
    const cleanObj = {};
    Object.keys(dirtyObj).forEach((key) => {
        const selectedFilters = dirtyObj[key];

        if (selectedFilters && !(selectedFilters.length === 0)) {
            cleanObj[key] = selectedFilters;
        }
    });

    return cleanObj;
}


import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import SocialButton from "../Social-Button";
//import "./SocialButtonList.css";

const SocialButtonList = props => (
  <>
    <Text
      field={props.fields.title}
      tag="h4"
      className="imc-heading imc-heading--alpha imc-heading--invert imc-vr--large"
    />
    <div className="imc-optin-social">
      {(props.fields.list || []).map((item, i) => (
        <SocialButton
          key={i}
          image={item.fields.image}
          link={item.fields.link}
        />
      ))}
    </div>
  </>
);

export default SocialButtonList;

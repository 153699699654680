import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Link from '../Link';

const ActivitiesCardComponent = (props) => {
  const { sitecoreContext, account, fields } = props;
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  if (typeof fields === 'undefined') {
    if (isEditing) {
      return <h1 className='alarm'>Datasource isn't set.</h1>;
    }
    return (
      <h1 className='alarm'>
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }
  console.log(fields)
  return (
    <div className="imc-card imc-loading-relative">
      {fields.bookHotel.value &&
        <div className="imc-card-section">
          <Link className="imc-type--title-3 imc-content--display-flex  imc-content--display-flex-center" field={fields.bookHotel.value}>
            {fields.bookHotel.value.text}
            <svg width="16" height="16" className="imc-content--display-flex--right" >
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconGo"></use>
            </svg>
          </Link>
        </div>
      }
      {fields.marketMaps.value &&
        <div className="imc-card-section">
          <Link className="imc-type--title-3 imc-content--display-flex  imc-content--display-flex-center" field={fields.marketMaps.value}>
            {fields.marketMaps.value.text}
            <svg width="16" height="16" className="imc-content--display-flex--right" >
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconGo"></use>
            </svg>
          </Link>
        </div>
      }
      {fields.exhibitorDirectory.value &&
        <div className="imc-card-section">
          <Link className="imc-type--title-3 imc-content--display-flex  imc-content--display-flex-center" field={fields.exhibitorDirectory.value}>
            {fields.exhibitorDirectory.value.text}
            <svg width="16" height="16" className="imc-content--display-flex--right" >
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconGo"></use>
            </svg>
          </Link>
        </div>
      }
      {fields.savedSearches.value &&
        <div className="imc-card-section">
          <Link className="imc-type--title-3 imc-content--display-flex  imc-content--display-flex-center" field={fields.savedSearches.value}>
            {fields.savedSearches.value.text}
            <svg width="16" height="16" className="imc-content--display-flex--right" >
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconGo"></use>
            </svg>
          </Link>
        </div>
      }
      {fields.myInquires.value &&
        <div className="imc-card-section">
          <Link className="imc-type--title-3 imc-content--display-flex  imc-content--display-flex-center" field={fields.myInquires.value}>
            {fields.myInquires.value.text}
            <svg width="16" height="16" className="imc-content--display-flex--right" >
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconGo"></use>
            </svg>
          </Link>
        </div>
      }
      <div className="imc-card-section"></div>
    </div>
  )
};

export default withSitecoreContext()(ActivitiesCardComponent);

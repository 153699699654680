import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { format, isWithinInterval } from 'date-fns';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import { isMobile } from "react-device-detect";
import {withSitecoreRouter} from 'utils/withRouter';

import CalendarComponent from 'modules/calendar/Calendar';
import EventCard from './components/EventCard';
import { ReactComponent as NoResultsImage } from '../../assets/svg/common/noResults.svg';
import {getEventTypes} from '../../api/events';

const EVENT_TYPE = {
    IMC: "IMC",
    EXHIBITOR: "Exhibitor",
}

class EventList extends PureComponent {
    static propTypes = {
        fields: PropTypes.object,
    };

    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            allEvents: [],
            labels: this.props.fields,
            currentlySelectedEvents: [],
            eventType: EVENT_TYPE.IMC,
            filters: {},
            isLoading: true,
            selectedCalendarDate: null,
            searchText: "",
            modalOpen: false,
            exhibitorEvents: [],
            IMCEvents: [],
            showClearSearch: {}
        };

        this.efficientlyParseEventItemsByOwner = this.efficientlyParseEventItemsByOwner.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.formatConsistentDate = this.formatConsistentDate.bind(this);
        this.renderAllItemsByTime = this.renderAllItemsByTime.bind(this);
        this.checkHashChange = this.checkHashChange.bind(this);
        this.renderEventCard = this.renderEventCard.bind(this);
        this.onClickDay = this.onClickDay.bind(this);
        this.onResetDate = this.onResetDate.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onTabSelect = this.onTabSelect.bind(this);
        this.renderCalendar = this.renderCalendar.bind(this);
        this.onToggleModal = this.onToggleModal.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.renderNoResultsMsg = this.renderNoResultsMsg.bind(this);
    }


    componentDidMount() {
        this.getEvents()
    }
    componentWillReceiveProps() {
        this.checkHashChange();
    }

    async getEvents() {
        const events = await getEventTypes();
        const { exhibitorEvents, IMCEvents } = this.efficientlyParseEventItemsByOwner(events.data);

        let currentEvents = IMCEvents;
        let tab = EVENT_TYPE.IMC

        if ((typeof window !== 'undefined') && window.location && window.location.hash && window.location.hash.replace("#","").toLowerCase() == "exhibitor") {
            currentEvents = exhibitorEvents;
            tab = EVENT_TYPE.EXHIBITOR;
        }

        this.setState({
            count: events.count,
            currentlySelectedEvents: currentEvents,
            allEvents: events.data,
            exhibitorEvents,
            IMCEvents,
            isLoading: false,
            eventType: tab,
        });
    }

    checkHashChange() {
        const { exhibitorEvents, IMCEvents } = this.efficientlyParseEventItemsByOwner(this.state.allEvents);

        let currentEvents = IMCEvents;
        let tab = EVENT_TYPE.IMC

        if ((typeof window !== 'undefined') && window.location && window.location.hash && window.location.hash.replace("#","").toLowerCase() == "exhibitor") {
            currentEvents = exhibitorEvents;
            tab = EVENT_TYPE.EXHIBITOR;
        }

        this.setState({
            currentlySelectedEvents: currentEvents,
            isLoading: false,
            eventType: tab,
        });
    }

    renderEventCard(cardItem) {
        const { learnMoreLabel } = this.props?.fields;

        return (
          <EventCard
            key={`cardItem${cardItem.id}`}
            eventData={cardItem}
            startDate={cardItem.startDateTime ? this.formatConsistentDate(new Date(cardItem.startDateTime)) : null}
            endDate={cardItem.endDateTime ? this.formatConsistentDate(new Date(cardItem.endDateTime)) : null}
            learnMoreLabel={learnMoreLabel}
          />
        );
    }

    renderNoResultsMsg() {
        return (
            <div className='event-card--svg-container'>
                <NoResultsImage className='event-card--svg' />
                <p>Sorry, no results found.</p>
            </div>
        )
    }

    renderAllItemsByTime() {
        const { currentlySelectedEvents, eventType, selectedCalendarDate, searchText } = this.state;

        const formattedEvents = {};

        if (currentlySelectedEvents.length < 1) {
            return this.renderNoResultsMsg();
        }

        currentlySelectedEvents.sort((a, b) => {
            return new Date(a.startDateTime) -  new Date(b.startDateTime);
        }).forEach((eventItem) => {
            let shouldAddToResults = false;
            if (selectedCalendarDate) {
                const selected = new Date(selectedCalendarDate);
                const dateFrom = new Date(eventItem.formattedDate);
                const dateTo = new Date(eventItem.formattedEndDate);
                let isBetween = false;

                if (dateFrom && dateTo) {
                    isBetween = isWithinInterval(selected, {
                        start: dateFrom,
                        end: dateTo
                    })
                }

                shouldAddToResults = isBetween
                  || selected === dateFrom
                  || selected === dateTo;
            }

            if (searchText.length > 0) {
                shouldAddToResults = eventItem.title.toLowerCase().includes(searchText.toLowerCase());
            }

            if (searchText.length == 0 && !selectedCalendarDate) {
                shouldAddToResults = true;
            }

            if (eventType === EVENT_TYPE.IMC && !eventItem?.isIMCEvent) {
                return;
            }

            if (eventType === EVENT_TYPE.EXHIBITOR && !eventItem?.isExhibitorEvent) {
                return;
            }

            if (!shouldAddToResults) {
                return;
            }

            const selectedDate = selectedCalendarDate ? selectedCalendarDate : eventItem.formattedDate;
            if (!formattedEvents[selectedDate]) {
                formattedEvents[selectedDate] = {
                    events: []
                }
            }
            formattedEvents[selectedDate].events.push(eventItem);
        });

        const props = Object.keys(formattedEvents)
        const cardResults = props.map((groupDate) => {
            const events = formattedEvents[groupDate].events;
            return(<div
              className='event-date--container'
              key={`eventItem${groupDate}`}
            >
                <p className='event-date--item'>{`${groupDate} | ${events.length} Events`}</p>
                {events.map(eventItem =>
                  <div className='event-card--multi-card-container'>
                      {this.renderEventCard(eventItem)}
                  </div>
                )}
            </div>);
        });
        return cardResults.length > 0 ? cardResults : this.renderNoResultsMsg();
    }

    // Runs once when component mounts to avoid multiple nested loop operations
    efficientlyParseEventItemsByOwner(events) {
        let exhibitorEvents = [];
        let IMCEvents = [];

        events.map((item) => {
            const isImcEvent = item.eventOwner === EVENT_TYPE.IMC;
            const isExhibitorEvent = item.eventOwner == EVENT_TYPE.EXHIBITOR;

            const event = {
                eventDate: item.formattedDate,
                isIMCEvent: isImcEvent,
                isExhibitorEvent: isExhibitorEvent,
                  ...item
            };

            if (event.isIMCEvent){
                IMCEvents.push(event);
            }

            if(event.isExhibitorEvent){
                exhibitorEvents.push(event);
            }
        })

        return { exhibitorEvents, IMCEvents };
    }

    formatConsistentDate(date) {
        const day = format(date, 'dd');
        const dayOfWeek = format(date, 'eeee');
        const month = format(date, 'LLLL');
        const year = format(date, 'yyyy');

        return `${dayOfWeek}, ${month} ${day}, ${year}`
    }

    onClickDay(date) {
        this.setState({ selectedCalendarDate: date })
    }

    onResetDate() {
        this.setState({ selectedCalendarDate: null })
    }

    onSearchChange(text) {
        this.setState({ searchText: text?.target?.value })
    }

    onClearSearch() {
        this.setState({ searchText: "" })
    }

    onTabSelect(tab) {
        const { exhibitorEvents, IMCEvents, router } = this.state;
        let currentlySelectedEvents;

        if (tab === EVENT_TYPE.IMC) {
            currentlySelectedEvents = IMCEvents;
            if ((typeof window !== 'undefined') && window.location && window.location.hash) {
                window.history.pushState("", document.title, window.location.pathname);
            }
        }

        if (tab === EVENT_TYPE.EXHIBITOR) {
            currentlySelectedEvents = exhibitorEvents;
            if ((typeof window !== 'undefined') && window.location) {
                window.location.hash = "#exhibitor"
            }
        }

        this.setState({
            eventType: tab,
            currentlySelectedEvents,
            searchText: ""
        })
    }

    onToggleModal() {
        this.setState({ modalOpen: !this.state.modalOpen })
    }

    renderCalendar() {
        const { currentlySelectedEvents, modalOpen } = this.state;

        if (isMobile) {
            return (
                <ReactModal
                    isOpen={modalOpen}
                    overlayClassName="imc-modal--overlay"
                    className="imc-campus-view--modal event-card--modal-centered"
                >
                    <CalendarComponent
                        events={currentlySelectedEvents}
                        onClickDay={this.onClickDay}
                        formatDate={this.formatConsistentDate}
                        onResetDate={this.onResetDate}
                    />
                    <div>
                        <button
                            className="imc-button"
                            onClick={this.onToggleModal}
                        >
                            Close
                        </button>
                    </div>

                </ReactModal>
            )
        }
        return (
            <CalendarComponent
                events={currentlySelectedEvents}
                onClickDay={this.onClickDay}
                formatDate={this.formatConsistentDate}
                onResetDate={this.onResetDate}
            />
        )
    }

    render() {
        const { eventType, isLoading, currentlySelectedEvents } = this.state;
        const svgClass = 'enabled';
        const { showClearSearch } = this.props.fields;
        let hideSubmitButton = true;
        let disabled = false;

        if (currentlySelectedEvents.length <= 0) {
            disabled = true;
        }

        return (
            <div className="event-list imc-loading__anchor">
                {isLoading && <p>Loading...</p>}
                {!isLoading && (
                    <>
                        <h4>All Events</h4>
                        {/* Tabs */}
                        <div className='event-card--tab'>
                            <p
                                onClick={() => this.onTabSelect(EVENT_TYPE.IMC)}
                                className={classNames('event-card--tab--item', {
                                    'event-card--tab--selected': eventType === EVENT_TYPE.IMC
                                })}
                            >Events</p>
                            <p
                                onClick={() => this.onTabSelect(EVENT_TYPE.EXHIBITOR)}
                                className={classNames('event-card--tab--item', {
                                    'event-card--tab--selected': eventType === EVENT_TYPE.EXHIBITOR
                                })}
                            >Exhibitor Events</p>
                        </div>
                        {/* Search */}
                        <div className='event-card--cal-btn-container'>
                            <div
                                className='imc-searchform--row event-card--search'
                            >
                                <input
                                    className='imc-searchform--global imc-searchform--input imc-searchform--input'
                                    type="text"
                                    value={this.state.searchText}
                                    name="EventSearch"
                                    placeholder="Search"
                                    onChange={this.onSearchChange}
                                    ref={this.searchInput}
                                    disabled={disabled}
                                />

                                {showClearSearch?.value &&
                                    <button
                                        type="button"
                                        disabled={false}
                                        onClick={this.onClearSearch}
                                        aria-label={'Clear Search'}
                                        className={`imc-searchform--button--clear
                            ${hideSubmitButton ? 'imc-searchform--button--clear--floorplan--hidesubmit' : ''}`}
                                    >
                                        <svg
                                            className={svgClass}
                                            width="10"
                                            height="10"
                                            role="img"
                                            aria-labelledby="clear-search-title clear-search-desc"
                                        >
                                            <title id="clear-search-title">{'Clear Search'}</title>
                                            <desc id="clear-search-desc">{'Clear the Search Form'}</desc>
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-close" />
                                        </svg>
                                    </button>
                                }
                                <button
                                    className='imc-searchform--button--search'
                                >
                                    <svg
                                        width="16"
                                        height="16"
                                        role="img"
                                        aria-labelledby="btn-search-title btn-search-desc"
                                    >
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-search-v3" />
                                    </svg>
                                </button>
                            </div>
                            {isMobile && <button
                                className="imc-button event-card--btn"
                                onClick={this.onToggleModal}
                            >
                                Select Date
                            </button>}
                        </div>
                        {/* Calendar */}
                        <div className='event-card--cal-card-container'>
                            <div>
                                {this.renderCalendar()}
                            </div>
                            {/* Cards */}
                            <div className='event-card--all-container'>
                                {this.renderAllItemsByTime()}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}
export default withSitecoreRouter(EventList);

import {
    SET_EXHIBITOR_DETAIL,
    SET_EXHIBITOR_DETAIL_ERR,
    SHOW_LOADING,
} from './actionTypes';
import ExhibitorsAPI from 'api/exhibitor';

/**
 * Instantiates the Exhibitors API
 * @type {ExhibitorsAPI}
 * @private
 */
const _exhibitorsApi = new ExhibitorsAPI();



/**
 * Action to get Digital Showroom Exhibitor Details
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getExhibitorDetail(exhibitorId, pageId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getExhibitorDetail(exhibitorId, pageId).then((response) => {
            if (response && Array.isArray(response.data) && response.data.length === 1) {
                const exhibitor = response.data[0];
                dispatch({
                    type: SET_EXHIBITOR_DETAIL,
                    exhibitor: exhibitor,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_EXHIBITOR_DETAIL_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}
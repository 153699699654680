import {
    GET_EXHIBITORCATEGORIES_PRODUCTS_SUCCESS,
    GET_EXHIBITORCATEGORIES_PRODUCTS_FAIL,
    GET_EXHIBITORCATEGORIES_COUNT_SUCCESS,
    GET_EXHIBITORCATEGORIES_FEATURED_COUNT_SUCCESS,
    GET_EXHIBITORCATEGORIES_FEATURED_PRODUCTS_FAIL,
    GET_EXHIBITORCATEGORIES_FEATURED_PRODUCTS_SUCCESS } from './actionTypes';

import ExhibitorProductAPI from '../api/ExhibitorProductAPI';

const _searchProducts = new ExhibitorProductAPI();

/**
 * Action to get exhibitors related to privided search term
 * @param {string} query Query to run
 * @param {object} filters Object of selected filters
 * @param {string} exhibitId of current exhibitor
 * @param {bool} isLine  is Line page
 * @param {string} pagenum of current page
 * @returns {Function} Promise object with fetch to the SearchExhibitors
 */
export function fetchProducts(query, filters = {}, exhibitId, isLine, pagenum) {
    return (dispatch) => {
        return _searchProducts.getFilterProducts(query, filters, exhibitId, isLine, pagenum).then((response) => {
            const { status, products, count } = response;
            if (status === 200) {
                dispatch({
                    type: GET_EXHIBITORCATEGORIES_PRODUCTS_SUCCESS,
                    products,
                    count,
                });
                const countObj =
                    {
                        products: count,
                    };
                dispatch({
                    type: GET_EXHIBITORCATEGORIES_COUNT_SUCCESS,
                    payload: countObj,
                });
            } else {
                dispatch({
                    type: GET_EXHIBITORCATEGORIES_PRODUCTS_FAIL,
                    err: status,
                });
                const countObj =
                    {
                        products: 0,
                    };
                dispatch({
                    type: GET_EXHIBITORCATEGORIES_COUNT_SUCCESS,
                    payload: countObj,
                });
            }
        });
    };
}

/**
 * Action to get exhibitors related to privided search term
 * @param {string} query Query to run
 * @param {object} filters Object of selected filters
 * @param {string} exhibitId of current exhibitor
 * @param {bool} isLine  is Line page
 * @param {string} pagenum of current page
 * @returns {Function} Promise object with fetch to the SearchExhibitors
 */
export function fetchFeaturedProducts(query, filters = {}, exhibitId, isLine, pagenum) {
    return (dispatch) => {
        return _searchProducts.getFeaturedProducts(query, filters, exhibitId, isLine, pagenum).then((response) => {
            const { status = 200, products, count } = response;
            if (status === 200) {
                dispatch({
                    type: GET_EXHIBITORCATEGORIES_FEATURED_PRODUCTS_SUCCESS,
                    featuredProducts: products,
                    count,
                });
                const countObj =
                    {
                        featuredProducts: count,
                    };
                dispatch({
                    type: GET_EXHIBITORCATEGORIES_FEATURED_COUNT_SUCCESS,
                    payload: countObj,
                });
            } else {
                dispatch({
                    type: GET_EXHIBITORCATEGORIES_FEATURED_PRODUCTS_FAIL,
                    err: status,
                });
                const countObj =
                    {
                        featuredProducts: 0,
                    };
                dispatch({
                    type: GET_EXHIBITORCATEGORIES_FEATURED_COUNT_SUCCESS,
                    payload: countObj,
                });
            }
        });
    };
}

import React from 'react';
import { isEditorActive, Placeholder, Image } from '@sitecore-jss/sitecore-jss-react';

class ContentSectionComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    render () {
    
        if (!this.props.fields) {
            if (isEditorActive()) {
            return <h1 className="alarm">Datasource isn't set.</h1>;
            }
            return (
            <h1 className="alarm">
                Data is not provided. Contact administrators, please.
            </h1>
            );
        }

        var editorClasses = "imc-section--inner-content";
        if(isEditorActive()){
            editorClasses = "imc-section--inner-content imc-section--inner-content-ee"
        }
        let backgroundImage = "";
        if (this.props.fields.backgroundImage) {
            backgroundImage = this.props.fields.backgroundImage.value
            backgroundImage.width = null;
            backgroundImage.height = null;
        }

        let backgroundVideo = "";
        if (this.props.fields.videoFile && this.props.fields.videoFile.value && this.props.fields.videoFile.value.src) {
            backgroundVideo =  this.props.fields.videoFile.value.src;
        }
    
        let backgroundColor = this.props.fields.backgroundColor
            ? this.props.fields.backgroundColor.value
            : "default";
        let horizontalContentPosition = this.props.fields.internalContentHorizontalPosition
            ? this.props.fields.internalContentHorizontalPosition.value
            : "";
        let verticalContentPosition = this.props.fields.internalContentVerticalPosition
            ? this.props.fields.internalContentVerticalPosition.value
            : "";
        let contentBackgroundColor = this.props.fields.internalContentBackgroundColor
            ? this.props.fields.internalContentBackgroundColor.value
            : "";
        // Padding
        let paddingTop =  !!this.props.fields.paddingTop && this.props.fields.paddingTop.value!==""
            ? "imc-section--padded-top-" + this.props.fields.paddingTop.value
            : "imc-section--padded-top-xlarge";
        let paddingBottom = !!this.props.fields.paddingBottom && this.props.fields.paddingBottom.value!==""
            ? "imc-section--padded-bottom-" + this.props.fields.paddingBottom.value
            : "imc-section--padded-bottom-xlarge";
        let paddingLeft = !!this.props.fields.paddingLeft && this.props.fields.paddingLeft.value!==""
            ? "imc-section--padded-left-" + this.props.fields.paddingLeft.value
            : "imc-section--padded-left-xlarge";
        let paddingRight = !!this.props.fields.paddingRight && this.props.fields.paddingRight.value!==""
            ? "imc-section--padded-right-" + this.props.fields.paddingRight.value
            : "imc-section--padded-right-xlarge";
        
        // Padding Desktop
        let paddingTopDesktop = !!this.props.fields.paddingTopDesktop && this.props.fields.paddingTopDesktop.value!==""
            ? "imc-section--padded-top-desktop-" + this.props.fields.paddingTopDesktop.value
            : "";
        let paddingBottomDesktop = !!this.props.fields.paddingBottomDesktop && this.props.fields.paddingBottomDesktop.value!==""
            ? "imc-section--padded-bottom-desktop-" + this.props.fields.paddingBottomDesktop.value
            : "";
        let paddingLeftDesktop = !!this.props.fields.paddingLeftDesktop && this.props.fields.paddingLeftDesktop.value!==""
            ? "imc-section--padded-left-desktop-" + this.props.fields.paddingLeftDesktop.value
            : "";
        let paddingRightDesktop = !!this.props.fields.paddingRightDesktop && this.props.fields.paddingRightDesktop.value!==""
            ? "imc-section--padded-right-desktop-" + this.props.fields.paddingRightDesktop.value
            : "";


        // Margin
        let marginTop = !!this.props.fields.marginTop && this.props.fields.marginTop.value!==""
            ? "imc-section--margin-top-" + this.props.fields.marginTop.value
            : "imc-section--margin-top-none";
        let marginBottom = !!this.props.fields.marginBottom && this.props.fields.marginBottom.value!==""
            ? "imc-section--margin-bottom-" + this.props.fields.marginBottom.value
            : "imc-section--margin-bottom-none";
        let marginLeft = !!this.props.fields.marginLeft && this.props.fields.marginLeft.value!==""
            ? "imc-section--margin-left-" + this.props.fields.marginLeft.value
            : "imc-section--margin-left-none";
        let marginRight = !!this.props.fields.marginRight && this.props.fields.marginRight.value!==""
            ? "imc-section--margin-right-" + this.props.fields.marginRight.value
            : "";

        // Margin Desktop
        let marginTopDesktop = !!this.props.fields.marginTopDesktop && this.props.fields.marginTopDesktop.value!==""
            ? "imc-section--margin-top-desktop-" + this.props.fields.marginTopDesktop.value
            : "";
        let marginBottomDesktop = !!this.props.fields.marginBottomDesktop && this.props.fields.marginBottomDesktop.value!==""
            ? "imc-section--margin-bottom-desktop-" + this.props.fields.marginBottomDesktop.value
            : "";
        let marginLeftDesktop = !!this.props.fields.marginLeftDesktop && this.props.fields.marginLeftDesktop.value!==""
            ? "imc-section--margin-left-desktop-" + this.props.fields.marginLeftDesktop.value
            : "";
        let marginRightDesktop = !!this.props.fields.marginRightDesktop && this.props.fields.marginRightDesktop.value!==""
            ? "imc-section--margin-right-desktop-" + this.props.fields.marginRightDesktop.value
            : "";
        let extraClass = this.props.fields.extraClass? this.props.fields.extraClass.fields.className.value : "";

        let innerStyles = {};
        if (this?.props?.fields?.styles) {
            let props = this.props;
            Object.keys(props.fields.styles).forEach(function(key) {
                let c = `${props.fields.styles[key]}`;
                if (key.includes("--")) {
                    innerStyles[key] = c;
                }
                if (key.includes("background")) {
                    innerStyles[key] = c;
                }
            });
        }

        return( 
        <div className={`section-wrapper imc-section--${backgroundColor}  imc-section--relative`} style={(this.props.fields.styles) ? this.props.fields.styles : {}}>
            {!backgroundVideo && backgroundImage.src && backgroundImage.src.indexOf("default_image.svg")<0 && <Image className="imc-image--responsive align-image-content imc-section--background-media" field={backgroundImage} />}
            {backgroundVideo && <video className="imc-section--background-media" playsInline autoPlay muted loop src={backgroundVideo} poster={(backgroundImage.src) ? backgroundImage.src : ''}  alt={(backgroundImage.alt) ? backgroundImage.alt : ''}></video>}
            <section className={`imc-section imc-section--relative imc-content--center imc-section--${backgroundColor} ${extraClass} ${paddingTop} ${paddingBottom} ${paddingLeft} ${paddingRight} ${paddingTopDesktop} ${paddingBottomDesktop} ${paddingLeftDesktop} ${paddingRightDesktop}`}  style={(innerStyles) ? innerStyles : {}}>
                <div className={`${marginTop} ${marginBottom} ${marginLeft} ${marginRight} ${marginTopDesktop} ${marginBottomDesktop} ${marginLeftDesktop} ${marginRightDesktop}`}>
                    <div className='imc-section--content-with-image'>
                        <div className={`${editorClasses} imc-section--align-${horizontalContentPosition} ${verticalContentPosition} imc-section--${contentBackgroundColor} imc-content--center-mobile`}>
                        {
                            horizontalContentPosition == 'center-hero' &&
                            <div className={`imc-hero-module--box  imc-hero-module--box--position imc-hero-module--box--padding imc-section--padded imc-section imc-section--full-width-mobile imc-section--${contentBackgroundColor}`}>
                                <Placeholder name="imc-jss-content-section" rendering={this.props.rendering} />
                            </div>
                        }
                        {
                            horizontalContentPosition !== 'center-hero' && 
                            <Placeholder name="imc-jss-content-section" rendering={this.props.rendering} />
                        }
                        </div>
                    </div>
                </div>
            </section>
        </div>
        );
    }
}
export default ContentSectionComponent;

// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobileScreenUtility } from 'utils/mobileScreenUtility';
import debounce from 'lodash/debounce';

// Local dependencies
import CarouselNav from './CarouselNav';

/**
 * Component for displaying a carousel module and maintaining its state
 *
 * Ex:
 * <Carousel>
 *     <CarouselItem>
 *         <div>Carousel contents...</div>
 *     </CarouselItem>
 *     <CarouselItem>
 *         <div>Carousel contents...</div>
 *     </CarouselItem>
 * </Carousel>
 */
export default class Carousel extends Component {

    /**
     * @method constructor
     * @description On instantiation dynamically sets the moduleName based on the `carouselId` and
     * creates aliases to methods
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 0,
            suppressDesktop: false,
            isDesktop: false,
            onMobile: false,
        };

        // set aliases
        this.setActiveItem = this.setActiveItem.bind(this);
        this.setPreviousItem = this.setPreviousItem.bind(this);
        this.setNextItem = this.setNextItem.bind(this);
        this.renderNav = this.renderNav.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.setSuppressDesktop = debounce(this.setSuppressDesktop.bind(this));
        this.checkDeviceWidth = debounce(this.checkDeviceWidth.bind(this));
        this.mapSlide = this.mapSlide.bind(this);
    }

    /**
     * @method componentWillMount
     * @description When the component is mounting register the active item
     */
    componentDidMount() {
        this.state.currentIndex = 0;
        if (this.props.desktopGallery && typeof window !== 'undefined') {
            window.addEventListener('resize', this.setSuppressDesktop);
            this.setSuppressDesktop();
        }
        if (this.props.carouselColumns > 1 && typeof window!='=undefined') {
            window.addEventListener('resize', this.checkDeviceWidth);
            this.checkDeviceWidth();
        }
    }

    /**
     * @method setActiveItem
     * @description Sets the state with the current index
     * @param {Number} carouselIndex The index number of the carousel item to select
     * @param {object=} e Event object
     */
    setActiveItem(carouselIndex, e) {
        const code = e ? e.keyCode || e.which : undefined;
        if (code === undefined || code === 13 || code === 32) {
            this.setState({
                currentIndex: carouselIndex,
            });
        }
    }

    /**
     * Determine if the carousel should be suppressed based on breakpoint
     */
    setSuppressDesktop() {
        const { desktopGallery } = this.props;
        const isDesktop = !mobileScreenUtility();
        const suppress = desktopGallery && isDesktop;
        this.setState({
            suppressDesktop: suppress,
            isDesktop,
        });
    }


    /**
     * @method setPreviousItem
     * @description Goes to the previous carousel item if not at index 0
     * @param {object} e Event object
     */
    setPreviousItem(e) {
        const code = e ? e.keyCode || e.which : undefined;
        if (code === undefined || code === 13 || code === 32) {
            const { carouselLength, carouselColumns } = this.props;
            const { currentIndex, isDesktop } = this.state;
            const carouselSlides = carouselLength || this.props.children.length;
            let itemLength = carouselSlides;
            if (isDesktop && carouselColumns > 1) {
                itemLength = Math.ceil(itemLength / carouselColumns);
            }
            const prevItem = (currentIndex === 0) ? itemLength - 1 : currentIndex - 1;

            this.setState({
                currentIndex: prevItem,
            });
        }
    }

    /**
     * @method setNextItem
     * @description Goes to the next carousel item if not at the end
     * @param {object} e Event object
     */
    setNextItem(e) {
        const code = e ? e.keyCode || e.which : undefined;
        if (code === undefined || code === 13 || code === 32) {
            const { carouselLength, carouselColumns } = this.props;
            const { currentIndex, isDesktop } = this.state;
            const carouselSlides = carouselLength || this.props.children.length;
            let itemLength = carouselSlides;
            if (isDesktop && carouselColumns > 1) {
                itemLength = Math.ceil(itemLength / carouselColumns);
            }
            const nextItem = (currentIndex === itemLength - 1) ? 0 : currentIndex + 1;

            this.setState({
                currentIndex: nextItem,
            });
        }
    }

    /**
     * @method mapSlide
     * @description Mapping to different viewed of carousel on page like 1-1, 1-2 and 1-3
     * @returns {Element} Rendered Carousel Items
     */
    mapSlide() {
        const { children, carouselColumns, showMode } = this.props;
        const { suppressDesktop, currentIndex, isDesktop } = this.state;
        let carouselItems = [];
        if (carouselColumns > 1 && isDesktop === true) {
            // only render active carousel items
            children.forEach((item, index) => {
                let isActive = false;
                if (showMode) {
                    isActive = showMode;
                } else {
                    isActive = (currentIndex * carouselColumns <= index &&
                        (currentIndex * carouselColumns) + (carouselColumns - 1) >= index);
                }

                if (isActive) {
                    const slide = React.cloneElement(item, {
                        isActive: (currentIndex * carouselColumns <= index &&
                            (currentIndex * carouselColumns) + (carouselColumns - 1) >= index),
                        suppressDesktop,
                    });
                    carouselItems.push(slide);
                }
            });
        } else {
            carouselItems = children.map((item, index) => (
                (React.cloneElement(item, {
                    isActive: index === currentIndex,
                    suppressDesktop,
                }))
            ));
        }
        return carouselItems;
    }

    /**
     * @method checkDeviceWidth
     * @description Checking of view port device width is LVM
     */
    checkDeviceWidth() {
        const isDesktop = !mobileScreenUtility();

        this.setState({
            isDesktop,
        });
    }

    /**
     * @method renderNav
     * @description Parses through the child CarouselItems and renders Meatball components for each
     * @param {int} carouselSlides number of slides in carousel
     * @returns {Element} Rendered Carousel meatballs
     */
    renderNav(carouselSlides) {
        const { carouselType, carouselColumns } = this.props;
        const { currentIndex, suppressDesktop, isDesktop } = this.state;

        if (!suppressDesktop) {
            return (
                <CarouselNav
                    onPrev={this.setPreviousItem}
                    onNext={this.setNextItem}
                    onSelect={this.setActiveItem}
                    currentIndex={currentIndex}
                    carouselLength={carouselSlides}
                    carouselType={carouselType}
                    isDesktop={isDesktop}
                    carouselColumns={carouselColumns}
                    totalPages={Math.ceil(this.props.children.length / this.props.carouselColumns)}
                />
            );
        }
        return null;
    }

    /**
     * @method renderItems
     * @description Parses through the child CarouselItems and clones the components and sets new properties based
     * on the current carousel state/properties
     * @returns {Element} Rendered Carousel Items
     */
    renderItems() {
        const { galleryColumns, carouselColumns } = this.props;
        const { suppressDesktop } = this.state;
        let carouselColumnsWrapperClass;
        const wrapperClass = suppressDesktop ? `imc-gallery imc-gallery--1-${galleryColumns}` : 'imc-carousel__body';
        carouselColumnsWrapperClass = wrapperClass;
        if (carouselColumns > 1) {
            carouselColumnsWrapperClass = `imc-gallery imc-gallery--1-${carouselColumns} 
            imc-gallery--1-3--right-1-gutter imc-carousel__body imc-featured-events--gallery`;
        }
        return (
            <div
                data-imc-carousel-body=""
                className={carouselColumnsWrapperClass}
                aria-live="polite"
                data-xpath="carousel.containerInner"
            >
                {this.mapSlide()}
            </div>
        );
    }

    /**
     * @method render
     * @description Renders Carsouel
     * @returns {Element} Rendered Carousel
     */
    render() {
        const { carouselType, carouselColumns, carouselLength } = this.props;
        const { suppressDesktop } = this.state;
        const carouselSlides = carouselLength || this.props.children.length;
        const wrapperClass = suppressDesktop ? '' : `imc-carousel imc-carousel--${carouselType}`;
        const { isDesktop } = this.state;
        let navBallDisplay = false;

        if (carouselSlides > 1) {
            navBallDisplay = true;
        }
        if (isDesktop && carouselColumns > 1) { // feature events details
            if (carouselSlides > carouselColumns) {
                navBallDisplay = true;
            } else {
                navBallDisplay = false;
            }
        }
        return (
            <div
                className={wrapperClass}
                role="region"
                data-xpath="carousel.container"
            >
                {this.renderItems()}
                {navBallDisplay ? this.renderNav(carouselSlides) : null }
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{carouselType, carouselDotNavigation, carouselLength, navType}}
 */
Carousel.propTypes = {
    // Determines the type of carousel to load (full|notification)
    carouselType: PropTypes.oneOf(['full', 'notification']),
    carouselLength: PropTypes.number, // Number of items in the carousel,
    children: PropTypes.arrayOf(PropTypes.object),
    desktopGallery: PropTypes.bool, // Determines if desktop will show as a gallery view
    showMode: PropTypes.bool, // Determines if desktop will show as a gallery view
    galleryColumns: PropTypes.number, // Determines number of columns for the gallery view
    carouselColumns: PropTypes.number, // Determines number of columns for the gallery view
};

/**
 * @property defaultProps
 * @type {{
 * carouselType: string,
 * carouselDotNavigation: boolean,
 * carouselLength: number,
 * navType: string
 * }}
 */
Carousel.defaultProps = {
    carouselType: 'full',
    carouselLength: null,
    children: [],
    desktopGallery: false,
    showMode: false,
    galleryColumns: 2,
    carouselColumns: 1,
};

import { Text } from '@sitecore-jss/sitecore-jss-react';
import SubmitButton from '../../../modules/formelements/components/SubmitButton';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Checkbox } from '../../../modules/formelements';
import qs from 'qs';
import '../styles.css';
import {
    channelOptOutCancelUnsubscribeUrl,
    channelOptOutUnsubscribeUrl,
    vipOptOutCancelUnsubscribeUrl,
    vipOptOutUnsubscribeUrl,
    fetchRequest,
    preferencesSubscribesUrl,
} from '../api';
import { SuccessErrorMessage } from './SuccessErrorMessage';
import { getDictionaryValue } from '../../../utils/dictionary';

const vipLabel = `Don't send me VIP Designer newsletters`;

const getCategories = (categories, condition = null) => {
    if (!categories || !categories.length) {
        return [];
    }
    const items = [];
    categories.map((item) =>
        item.subscriptionOptions.forEach((option) =>
            items.push({
                checked: condition ? condition.isChecked : option.checked,
                optInListId: option.optInListId,
            })
        )
    );
    return items;
};

export const PreferencesForm = (props) => {
    const {
        subTitle,
        categories,
        unsubscribeLabel,
        unsubscribeDescription,
        isChannelOptOut,
        channelOptOutListId,
        contactId,
        errorMessage,
        successMessage,
        isGlobalOptOut,
        isVipOptOut,
        vipListId,
        messageId,
    } = props;
    const [message, setMessage] = useState({
        isSuccess: false,
        isError: false,
        message: '',
    });

    const location = useLocation();
    const queryParams = qs.parse(location.search, {depth: 1, ignoreQueryPrefix: true});
    const isVip = queryParams.vip;

    const [isUnsubscribeOnChannel, setUnsubscribeOnChannel] = useState(
        isChannelOptOut || isGlobalOptOut
    );
    const [isVipUnsubscribe, setVipUnsubscribed] = useState(
        isVipOptOut || isGlobalOptOut
    );

    const [settings, setSettings] = useState(
        categories && categories.length > 0
            ? categories.map((category) => {
                return {
                    title: category.title,
                    subscriptionOptions: category.subscriptionOptions.map(
                        (option) => {
                            return {
                                ...option,
                                disabled:
                                    isChannelOptOut ||
                                    isGlobalOptOut
                            };
                        }
                    ),
                };
            })
            : []
    );
    const [selectedCategories, setSelectedCategories] = useState(
        getCategories(categories)
    );
    const getList = useCallback(() => {
        const list = [];
        if (settings && settings.length > 0) {
            settings.forEach((item) =>
                item.subscriptionOptions.forEach((option) =>
                    list.push(option.optInListId)
                )
            );
        }
        return list;
    }, [categories]);

    useEffect(() => setUnsubscribeOnChannel(isGlobalOptOut || isChannelOptOut), [
        isGlobalOptOut,
        isChannelOptOut,
    ]);

    useEffect(() => setVipUnsubscribed(isGlobalOptOut || isVipOptOut), [
        isGlobalOptOut,
        isVipOptOut,
    ]);

    useEffect(
        () =>
            setSettings((prevState) => {
                return (
                    prevState &&
                    prevState.length > 0 &&
                    prevState.map((setting) => {
                        const requestOptions =
                            (setting.subscriptionOptions &&
                                setting.subscriptionOptions.length > 0 &&
                                setting.subscriptionOptions.map((item) => {
                                    item.disabled =
                                        isChannelOptOut || isGlobalOptOut;
                                    return item;
                                })) ||
                            null;
                        return {
                            title: setting.title,
                            subscriptionOptions: requestOptions,
                        };
                    })
                );
            }),
        [isChannelOptOut, isGlobalOptOut]
    );

    const handleUnsubscribe = (name, isChecked) => {
        updateSettingsState();
        if (isChecked) {
            setSelectedCategories(
                getCategories(categories, {isChecked: false})
            );
        }
        if (name === 'vip') {
            setVipUnsubscribed(isChecked);
            return
        }
        setUnsubscribeOnChannel(isChecked);
    };

    const handleChangeCategory = (name, value) => {
        setSelectedCategories((prevState) => {
            const newState = prevState.map((item) => {
                if (item && item.optInListId && item.optInListId === name) {
                    item.checked = value;
                }
                return item;
            });
            return newState;
        });
        setSettings((prevState) => {
            return (
                prevState &&
                prevState.length > 0 &&
                prevState.map((setting) => {
                    const options =
                        (setting.subscriptionOptions &&
                            setting.subscriptionOptions.length > 0 &&
                            setting.subscriptionOptions.map((item) => {
                                if (
                                    item &&
                                    item.optInListId &&
                                    item.optInListId === name
                                ) {
                                    item.checked = value;
                                }
                                return item;
                            })) ||
                        null;
                    return {
                        title: setting.title,
                        subscriptionOptions: options,
                    };
                })
            );
        });
    };

    const updateSettingsState = () => {
        setSettings((prevState) => {
            return (
                prevState &&
                prevState.length > 0 &&
                prevState.map((setting) => {
                    const requestOptions =
                        (setting.subscriptionOptions &&
                            setting.subscriptionOptions.length > 0 &&
                            setting.subscriptionOptions.map((item) => {
                                if (isUnsubscribeOnChannel) {
                                    item.disabled = false;
                                } else {
                                    item.checked = false;
                                }
                                return item;
                            })) ||
                        null;
                    return {
                        title: setting.title,
                        subscriptionOptions: requestOptions,
                    };
                })
            );
        });
    }

    async function handleNotSuccessResponse(response) {
        let description = '';
        if (response && response.status && response.status === 500) {
            const data = await response.json();
            description = data && data.message ? data.message : '';
        }
        setMessage({
            isError: true,
            message: errorMessage,
            description,
            isSuccess: false,
        });

        return console.error('Something went wrong...');
    }

    const sendVipSubscriptionRequest = async () => {
        const vipUnsubscribeUrl = isVipUnsubscribe ? vipOptOutUnsubscribeUrl : vipOptOutCancelUnsubscribeUrl;
        const vipUnsubscribeRequest = {
            contactId,
            vipListId,
            messageId
        }

        const response = await fetchRequest(vipUnsubscribeRequest)(vipUnsubscribeUrl);

        if (!response || !response.ok) {
            await handleNotSuccessResponse(response);
            setVipUnsubscribed(isVipUnsubscribe);
            return true;
        }
        return true;
    }

    const handleSubmit = async () => {
        setMessage({isError: false, message: '', isSuccess: false});
        const unsubscribeChannelUrl = isUnsubscribeOnChannel
            ? channelOptOutUnsubscribeUrl
            : channelOptOutCancelUnsubscribeUrl;

        if (isVip) {
            const isCompletedSuccessfully= await sendVipSubscriptionRequest();
            if (!isCompletedSuccessfully)
                return ;
        }

        const unsubscribeChannelRequestData = {
            optOutListId: channelOptOutListId,
            contactId,
            messageId: messageId,
        };
        unsubscribeChannelRequestData.channelAllOptInListIds =  getList();

        const response = await fetchRequest(unsubscribeChannelRequestData)(unsubscribeChannelUrl);
        if (!response || !response.ok) {
            await handleNotSuccessResponse(response);

            if (isUnsubscribeOnChannel) {
                setUnsubscribeOnChannel(false);
            }
            updateSettingsState();

            return;
        }

        if (!isUnsubscribeOnChannel) {
            const response = await fetchRequest({
                contactId,
                preferences: selectedCategories,
            })(preferencesSubscribesUrl);

            if (!response || !response.ok) {
                let description = '';
                if (response && response.status && response.status === 500) {
                    const data = await response.json();
                    description = data && data.message ? data.message : '';
                }
                return setMessage({
                    isError: true,
                    message: errorMessage,
                    description,
                    isSuccess: false,
                });
            }
        }
        setMessage({
            isError: false,
            message: successMessage,
            isSuccess: true,
        });
    };

    const vipCheckbox = (
        <>
            <Checkbox
                additionalClass='imc-type--title-3-ui imc-type--color-neutral-heavy-medium imc-padding--top--xsmall imc-preferences-label'
                checkboxClasses='imc-checkbox--small'
                name='vip'
                label={vipLabel}
                checked={isVipUnsubscribe}
                updateValue={handleUnsubscribe}
                isDisabled={isGlobalOptOut}
            />
        </>
    );

    const renderCategories = useMemo(() => {
        if (!settings || !settings.length) {
            return;
        }
        return (
            <div
                className={
                    'imc-content--divider imc-padding--bottom--medium imc-margin--bottom--medium'
                }
            >
                {settings.map((category, index) => (
                    <React.Fragment key={index}>
                        <Text
                            tag={'div'}
                            className={`imc-preferences-title imc-padding--bottom--medium`}
                            field={{value: category.title}}
                        />
                        <div className={'imc-vr--medium'}>
                            {category.subscriptionOptions &&
                            category.subscriptionOptions.length > 0 &&
                            category.subscriptionOptions.map(
                                (item, index) => {
                                    const listName = item['title'];
                                    const listId = item['optInListId'];
                                    return (
                                        <Checkbox
                                            label={listName}
                                            name={listId}
                                            value={listId}
                                            stableSelector={`${listId}.${listName
                                                .split(' ')
                                                .join('')
                                                .toLowerCase()}`}
                                            additionalClass='imc-vr--medium'
                                            labelClass='imc-type--title-3-ui imc-preferences-label'
                                            checkboxClasses={
                                                'imc-checkbox--without-symbol'
                                            }
                                            updateValue={
                                                handleChangeCategory
                                            }
                                            checked={item.checked}
                                            key={`checkbox-item-${listId}`}
                                            isDisabled={!!item.disabled}
                                        />
                                    );
                                }
                            )}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        );
    }, [settings]);

    const renderUnsubscribeBlock = () => (
        <div className={'imc-refuse-block'}>
            <div className={'imc-refuse-block--text'}>
                <Checkbox
                    additionalClass='imc-type--title-3-ui imc-type--color-neutral-heavy-medium imc-padding--top--xsmall imc-preferences-label'
                    checkboxClasses='imc-checkbox--small'
                    name='unsubscribe'
                    label={unsubscribeLabel}
                    checked={isUnsubscribeOnChannel}
                    updateValue={handleUnsubscribe}
                    isDisabled={isGlobalOptOut}
                />
                <Text
                    tag='div'
                    field={{value: unsubscribeDescription}}
                    className={'imc-note imc-padding--top--xsmall'}
                />

                {isVip ? vipCheckbox : null}
            </div>
            <SubmitButton
                labelSubmit={getDictionaryValue(
                    'channelPreferenceCenter_Save',
                    'Save'
                )}
                additionalClass={`imc-preferences-button ${
                    isGlobalOptOut ||  (selectedCategories.filter(x=>x.checked==true).length == 0 && !isUnsubscribeOnChannel) ? 'imc-preferences-button-disabled' : ''
                }`}
                submitClick={handleSubmit}
                isDisabled={isGlobalOptOut || ( selectedCategories.filter(x=>x.checked==true).length == 0 && !isUnsubscribeOnChannel)}
            /><SuccessErrorMessage status={message}/>
        </div>
    );

    return (
        <section
            className='imc-padding--left--large imc-padding--right--large imc-padding--top--large imc-padding--bottom--large imc-preferences--container'>
            
            <Text
                tag='div'
                field={{value: subTitle}}
                className={
                    'imc-type--title-3-ui imc-padding--bottom--medium imc--preferences-subtitle'
                }
            />
            {renderCategories}
            {renderUnsubscribeBlock()}
            
        </section>
    );
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tag } from '../../../modules/cardtags';

const propTypes = {
    imagePath: PropTypes.string,
    videoTitle: PropTypes.string,
    keys: PropTypes.array,
    description: PropTypes.string,
    tagClick: PropTypes.func,
    expandClick: PropTypes.func,
    expandedView: PropTypes.bool,
    imageObject: PropTypes.object,
    altText: PropTypes.string,
    title: PropTypes.string,
    filename: PropTypes.string,
};
const defaultProps = {
    imagePath: null,
    videoTitle: '',
    keys: [],
    description: '',
    altText: '',
    expandedView: false,
    imageObject: {},
    title: '',
    filename: '',
    tagClick: () => { },
    expandClick: () => { },
};

/**
 * Blog Teaser Component
 */
export default class ImageTeaser extends Component {
    /**
     * @method contructor
     * @description Lifecycle method
     * @param {object} props incoming props
     */
    constructor(props) {
        super(props);
        this.renderCardTags = this.renderCardTags.bind(this);
    }
    /**
     * Render Card Tags
     * @returns {JSX} Card tags
     */
    renderCardTags() {
        const { keys, videoTitle, tagClick } = this.props;
        if (keys && keys.length > 0) {
            return (
                <ul className="imc-blogteaser__tags">
                    {keys.map(tagItem => (<Tag
                        key={`tag-${videoTitle}-${tagItem}`}
                        className="imc-blogteaser__tag"
                        tag={tagItem}
                        clickEvent={() => tagClick(tagItem)}
                    />))}
                </ul>
            );
        }

        return null;
    }


    /**
     * Render Method
     * @returns {JSX} Blog Teaser component
     */
    render() {
        var { altText, description, imagePath, title, expandedView, expandClick, imageObject, filename } = this.props;
        const classes = 'imc-articlecard__body imc-imageteaser__body';
        if (!imagePath)
            imagePath='';
        const wrapperClass = expandedView ?
            'imc-articlecard--border imc-articlecard imc-gallery__item imc-imageteaser imc-imageteaser--wide' :
            'imc-articlecard--border imc-articlecard imc-gallery__item imc-imageteaser ';
        return (
            <div className={wrapperClass}>
                <div className={`${classes}`}>
                    {!expandedView
                        ?
                        <div
                            className="imc-imageteaser__image-container"
                             style={{
                                 backgroundImage:'url("' + imagePath.replace('/-/media', '/-/jssmedia').replace("/sitecore/shell","")+'")'
                            }}
                        />
                        : <img
                            className="imc-imageteaser__image"
                            src={imagePath.replace('/-/media', '/-/jssmedia').replace("/sitecore/shell","")}
                            alt={altText}
                        />
                    }
                    <div className="imc-imageteaser__action-bar">
                        <a href={imagePath.replace('/-/media', '/-/jssmedia').replace("/sitecore/shell","")} target="_blank" download={filename}>
                            <svg width="20" height="20">
                            <polygon points="9.13 8.08 9.13 4.25 7.13 4.25 7.13 8.08 5.7 7.31 4.75 9.07 8.13 10.88 11.51 9.07 10.56 7.31 9.13 8.08"/><path d="M13.23,4.25A5.18,5.18,0,0,0,3,5,4.63,4.63,0,0,0,4.44,14v0H11v0a5.16,5.16,0,0,0,2.19-9.73ZM10.95,12l-.37,0H5l-.42,0A2.63,2.63,0,0,1,3.69,6.9l1.23-.48L5,5.1a3.18,3.18,0,0,1,6.31-.49l.17,1,.85.45a3.16,3.16,0,0,1-1.34,6Z"/>
                            </svg>
                        </a>
                        {!expandedView && (
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.setExpanded(true);
                                }}
                            >
                                <svg width="20" height="20">
                                <polygon points="10.75 8 10.75 6 9 6 9 4.25 7 4.25 7 6 5.25 6 5.25 8 7 8 7 9.75 9 9.75 9 8 10.75 8"/><polygon points="10.75 0 10.75 2 14 2 14 5.25 16 5.25 16 0 10.75 0"/><polygon points="14 12 10.75 12 10.75 14 16 14 16 8.75 14 8.75 14 12"/><polygon points="2 2 5.25 2 5.25 0 0 0 0 5.25 2 5.25 2 2"/><polygon points="2 8.75 0 8.75 0 14 5.25 14 5.25 12 2 12 2 8.75"/>
                                </svg>
                            </button>
                        )}
                        
                    </div>
                    <div className="imc-articlecard__image-description imc-imageteaser__description">
                        <div className="imc-articlecard__title imc-imageteaser__header">
                            <h2 className="imc-heading imc-heading--alpha">{title}</h2>
                        </div>
                        <div
                            className={'imc-articlecard__teasertext ' +
                                'imc-imageteaser__teasertext imc-content imc-content--zeta'}
                        >
                            {/* TODO: Be sure that you really need this field here.  */}
                            <p>{description}</p>
                        </div>
                        {
                            this.renderCardTags()
                        }
                    </div>
                </div>
            </div>
        );
    }
}

ImageTeaser.propTypes = propTypes;
ImageTeaser.defaultProps = defaultProps;


// Library dependencies
// import { bindActionCreators } from 'redux';
import { Form, SubmitButton, TextArea, TextField } from 'modules/formelements';
// import { SingleLineText, RadioField, DropDown, CheckBox, Button, TextField, Section, SingleLineEmail} from '../components/formelements';
import { Loading } from 'modules/loading';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { getDictionaryValue } from 'utils/dictionary/index';
import submitEmail from '../api/emailQueryApi';
// import * as myInformationAction from '../../marketplanner/actions/myInformationAction';
// import * as loaderAction from '../../marketplanner/actions/loaderAction';
/**
 * EmailListModal
 * @param {function} props dat
 * @returns {element} EditableCta
 */
class EmailQueryModal extends Component {
    /**
     * EmailListModal
     * @param {object} email error messages from response
     * @returns {*} Rendered component
     */
    static validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }
    /**
     * @method constructor
     * @description
     * By default sets the state
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            errorMessages: '',
            showError: false,
            loading: false,
            showSuccess: false,
            messageSuccess: '',
            name: '',
        };

        this.customSubmit = this.customSubmit.bind(this);
        this.renderSuccess = this.renderSuccess.bind(this);
        this.closeEmailModal = this.closeEmailModal.bind(this);
    }

    /**
     * Fetches data in case not available
     */
    componentDidMount() {
        // const { actions } = this.props;
        // const accountId = window.userInfo.accountId;
        // if (organizationName === '') {
        //     actions.startLoader(true);
        //     actions.getMyInformation(accountId);
        // }
    }
    /**
     * EmailListModal
     * @param {object} fields error messages from response
     * @returns {boolean} EditableCta
     */
    customSubmit(fields) {
        const name = fields.name;
        const email = fields.email;
        const emailIds = fields.Recipients;
        const emailIdsArr = fields.Recipients.split(',');
        const message = fields.PersonalMessage;
        const query = this.props.query;
        const exhibitorId = this.props.exhibitorId;
        this.setState({
            loading: true,
        });
        if (emailIdsArr.length > 20) {
            this.setState({
                errorMessages: 'Maximum 20 email ids are allowed',
                showError: true,
                loading: false,
                showSuccess: false,
                messageSuccess: '',
            });
            return false;
        }
        for (let i = 0; i < emailIdsArr.length; i++) {
            if (!EmailQueryModal.validateEmail(emailIdsArr[i])) {
                this.setState({
                    errorMessages: 'Please enter valid email address',
                    showError: true,
                    loading: false,
                    showSuccess: false,
                    messageSuccess: '',
                });
                return false;
            }
        }


        let data = null;

        if (!!exhibitorId) {
            data = {
                to: emailIds,
                cc: email,
                userName: name,
                subject: 'Exhibitor List',
                body: message,
                query: query,
                exhibitorId: exhibitorId
            };
        } else {
            data = {
                to: emailIds,
                cc: email,
                userName: name,
                subject: 'Exhibitor List',
                body: message,
                query: query
            };
        }



        this.submitForm(data, query);
        return true;
    }

    /**
     * EmailListModal
     * @param {object} data error messages from response
     * @param {string} query Query to be parsed
     */
    submitForm(data, query) {
        let actionUrlPost;
        if (query === '') {
            actionUrlPost = `/search-api/mail/searchEmail?`;
        } else {
            actionUrlPost = `/search-api/mail/searchEmail?`;
        }
        submitEmail(actionUrlPost, data)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        errorMessages: '',
                        showError: false,
                        loading: false,
                        showSuccess: true,
                        messageSuccess: 'Email sent successfully',
                    });
                    this.closeEmailModal();
                } else {
                    this.setState({
                        errorMessages: 'Unable to send email',
                        showError: true,
                        loading: false,
                        showSuccess: false,
                        messageSuccess: '',
                    });
                }
            });
    }
    /**
     * @method closeEmailModal
     * @description close the email modal and turn state false
     */
    closeEmailModal() {
        this.setState({
            showError: false,
            showSuccess: false,
        });

        this.props.closePopup();
    }
    /**
     * @method renderError
     * @description this will Return Error component
     * @param {object} message error messages from response
     * @returns {*} Rendered component
     */
    renderSuccess() {
        if (this.state.showSuccess) {
            return (
                <p className="imc-content imc-content--success imc-vr--xxxlarge">
                    {this.state.messageSuccess}
                </p>
            );
        }
        return (
            <div />
        );
    }
    /**
     * @method renderError
     * @description this will Return Error component
     * @param {object} message error messages from response
     * @returns {*} Rendered component
     */
    renderError() {
        if (this.state.showError) {
            return (
                <p className="imc-content imc-content--error imc-vr--medium">
                    {this.state.errorMessages}
                </p>
            );
        }
        return (
            <div />
        );
    }
    /**
     * EmailListModal
     * @returns {element} EditableCta
     */
    render() {
        const { showError, showSuccess } = this.state;
        const { listHeading } = this.props;

        return (
            <ReactModal
                isOpen={this.props.showModal}
                overlayClassName="imc-modal--overlay"
                className="imc-modal--box  imc-modal--box-height-auto"
            >
                <Loading showLoading={this.state.loading} />

                <div className="imc-modal--content">
                    <h2 className="imc-heading--box-modal">
                        {getDictionaryValue("emailResult", 'Email Results')}
                    </h2>
                    <div className="imc-padding--left--large imc-padding--right--large imc-padding--top--large imc-padding--bottom--large">
                        {
                            showError && this.renderError()
                        }
                        {
                            showSuccess && this.renderSuccess()
                        }
                        <Form
                            actionUrl="#"
                            messageError=""
                            customSubmit={this.customSubmit}
                        >

                            <div>

                                <div className="imc-vr--medium">
                                    <TextField
                                        label={getDictionaryValue("emailName", 'Name')}
                                        type="text"
                                        name="name"
                                        isRequired
                                        errorEmpty='Invalid Name'
                                        value={this.state.name}
                                    />
                                </div>
                                <div className="imc-vr--medium">
                                    <TextField
                                        label={getDictionaryValue("yourMail", 'Your Email')}
                                        type="email"
                                        name="email"
                                        isRequired
                                        errorEmpty='Invalid Email'

                                    />
                                </div>
                                <div className="imc-vr--medium">
                                    <TextArea
                                        label={getDictionaryValue("recipientAddress", 'Recipient Email Address')}
                                        rows={2}
                                        type="text"
                                        name="Recipients"
                                        isRequired
                                        errorEmpty='Invalid Recipient'
                                    />
                                </div>
                                <div className="imc-vr--medium">
                                    <TextArea
                                        label={getDictionaryValue("comments", 'Comments/Message')}
                                        rows={2}
                                        type="text"
                                        name="PersonalMessage"
                                    />
                                </div>
                                <div className="imc-content--full-width imc-content--right">
                                    <SubmitButton
                                        labelSubmit={getDictionaryValue('send', 'Send')}
                                        additionalClass="imc-button--secondary-inverted imc-content- imc-savesearchmodal__submit"
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <button
                    className={'imc-modal--close imc-button--modal-close imc-button--round'}
                    onClick={this.closeEmailModal}
                >
                    {getDictionaryValue('close', 'Close')}</button>
            </ReactModal>
        );
    }
}

/**
 * @property defaultProps
 * @type {{sideNavLink: string}}
 */
EmailQueryModal.propTypes = {
    showModal: PropTypes.bool,
    closePopup: PropTypes.func,
    listHeading: PropTypes.string,
    query: PropTypes.string,
    exhibitorId: PropTypes.number

};

/**
 * @property defaultProps
 * @type {{applicationProperties: {}}}
 */
EmailQueryModal.defaultProps = {
    showModal: true,
    closePopup: () => { },
    listHeading: 'Email Results',
    query: '',
    exhibitorId: 0

};

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {{actions: *}} Action creators
 */
// function mapDispatchToProps(dispatch) {
//     return {
//         actions: bindActionCreators(Object.assign({}, myInformationAction, loaderAction), dispatch),
//     };
// }

// export default EmailQueryModal;
export default EmailQueryModal;


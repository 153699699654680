import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';

import API from '../../api/exhibitor';
import AlphabetNavigation from './components/AlphabetNavigation';
import ExhibitorList from './components/ExhibitorList';
import exhibitors from 'modules/searchresults/routes/exhibitors';

const ALL_CHARACTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '#'];

class ExhibitorAZDirectory extends PureComponent {
  static propTypes = {
    fields: PropTypes.object,
  }

  constructor(props) {
    super(props);

    if (!props) {
      if (isEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    this.state = {
      exhibitors: [],
      invalidCharacters: [],
      selectedCharacter: 'A',
      resultsCountString: '',
      loading: false,
      noData: false,
    };
    this.api = new API();

    this.createResultsCountString = this.createResultsCountString.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.onChangeCharacter = this.onChangeCharacter.bind(this);
    this.onLoadExhibitors = this.onLoadExhibitors.bind(this);
    this.onLoadInvalidChars = this.onLoadInvalidChars.bind(this);
  }

  componentDidMount() {
    this.loadInitialData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCharacter } = this.state;
    if (prevState.selectedCharacter !== selectedCharacter) {
      this.onLoadExhibitors(selectedCharacter);
    }
  }

  async loadInitialData() {
    await this.onLoadInvalidChars();
    if (this.state.noData) return;

    const firstViableChar = ALL_CHARACTERS.find((char) => !this.state.invalidCharacters.includes(char)) || 'A';
    this.onChangeCharacter(firstViableChar)
    this.onLoadExhibitors(firstViableChar);
  }

  async onLoadInvalidChars() {
    const data = await this.api.getInvalidCharactersAzDirectory();
    const invalidCharacters = data.invalidLetters.map((char) => char.toUpperCase());
    this.setState({ invalidCharacters })

    if (invalidCharacters.length === ALL_CHARACTERS.length) {
      this.setState({ noData: true })
    }
  }

  async onLoadExhibitors(selectedCharacter = this.state.selectedCharacter) {
    let exhibitorsData = [];

    this.setState({ loading: true })
    if (selectedCharacter === '#') {
      selectedCharacter = '%23'
    }

    try {
      exhibitorsData = await this.api.getExhibitorsDirectoryList(selectedCharacter)
      exhibitorsData = exhibitorsData?.items.sort((a, b) => {
        let fa = a?.name.toLowerCase(),
          fb = b?.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    } catch (err) {
      console.log('Error', err)
    }
    const resultsCountString = this.createResultsCountString(exhibitorsData);
    this.setState({ exhibitors: exhibitorsData, resultsCountString, loading: false })
  }

  onChangeCharacter(selectedCharacter) {
    this.setState({ selectedCharacter })
  }

  createResultsCountString(exhibitors) {
    const { selectedCharacter } = this.state;
    return this.props.fields?.Matching?.value?.replace('{0}', exhibitors?.length).replace('{1}', selectedCharacter);
  }

  render() {
    const { fields } = this.props;
    const { exhibitors, invalidCharacters, selectedCharacter, resultsCountString, loading, noData } = this.state;

    return (
      <div className='az-directory--container'>
        <h1 class="imc-type--title-1">{fields?.Title?.value}</h1>
        <p className='az-directory--matching imc-type--body-3'>{resultsCountString}</p>
        <AlphabetNavigation invalidCharacters={invalidCharacters} selectedCharacter={selectedCharacter} onChangeCharacter={this.onChangeCharacter} loading={loading} />
        <ExhibitorList exhibitors={exhibitors} loading={loading} noData={noData} />
      </div >
    )
  }
};

export default ExhibitorAZDirectory;

import React, { Component } from 'react'

export default class TextField extends Component {
    render() {
        const { fieldData } = this.props;
        const data = fieldData.field.model;
        let text;

        // different html element options you can set in sitecore admin
        if(data.htmlTag === 'h1'){
            text = <h1 className={data.cssClass}>{data.text}</h1>
        }
        if(data.htmlTag === 'h2'){
            text = <h2 className={data.cssClass}>{data.text}</h2>
        }
        if(data.htmlTag === 'h3'){
            text = <h3 className={data.cssClass}>{data.text}</h3>
        }
        if(data.htmlTag === 'h4'){
            text = <h4 className={data.cssClass}>{data.text}</h4>
        }
        if(data.htmlTag === 'h5'){
            text = <h5 className={data.cssClass}>{data.text}</h5>
        }
        if(data.htmlTag === 'h6'){
            text = <h6 className={data.cssClass}>{data.text}</h6>
        }
        if(data.htmlTag === 'p'){
            text = <p className={data.cssClass}>{data.text}</p>
        }
        if(data.htmlTag === 'span'){
            text = <span className={data.cssClass}>{data.text}</span>
        }
        if(data.htmlTag === 'label'){
            text = <label className={data.cssClass}>{data.text}</label>
        }
        return (
            <div>
                {text}
            </div>
        )
    }
}

export const GET_MY_INFO_MOCK =  {
        accountId: "9090acd1-f5fd-445e-931f-32ff79e93b67",
        userType: "DigitalIdentity",
        name: "",
        organizationName: "",
        attendeeType: "Buyer",
        interiorDesigner: "no",
        primaryProductCategory: null,
        productInterests: [
        ],
        organizationType: "Commercial Real Estate",
        secondaryProductCategories: null,
        //OldValues
        primaryCategoryOfInterest: 'empty',
        interests: {
        'furniture': [],
        'gifts': [],
        'homeDecor': [],
        },
    };
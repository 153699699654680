import { getAPIEndpoint } from 'utils/getapiendpoint';
import {
    getSitecoreApiHost,
    getSitecoreApiKey
} from '../../../AppGlobals';
import UserUtility from 'utils/userutility';
import {filterquerymodifier} from '../../../utils/filterquerymodifier';
/**
 * Search API for getting search results
 */
export default class TypeAheadSearchAPI {
    /**
     * Constructor
     */
    constructor() {
        this.getResults = this.getResults.bind(this);
    }

    /**
     * Converts a JSON object to a serialized string
     * @param {object} obj Object to convert
     * @returns {string} Serialized string
     * @private
     */
    static _serialize(obj) {

        return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${
            encodeURIComponent(obj[key])}`).join('&');
    }

    /**
     * Gets search results
     * @param {string} query Query to run
     * @returns {Promise.<T>} Promise object that returns the TypeAheadSearchAPI results
     */
    getResults(query, exhibitorId, pageId=null) {
        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         */
        //Encode query
        query = encodeURIComponent(query);
        const pageIdParam = pageId ? `&SearchPage=${pageId}` : '';
        this._serviceUrl = getAPIEndpoint('searchTypeAhead');
        const preparedQuery = filterquerymodifier(query);
        const exhibitor = exhibitorId ? `&exhibitorId=${exhibitorId}` : '';
        const url = `${getSitecoreApiHost()}/imc-api/v2/search/suggestions?term=${preparedQuery}&sc_apikey=${getSitecoreApiKey()}${exhibitor}${pageIdParam}`; //`https://www.lasvegasmarket.com/search-api/search/suggestions?${TypeAheadSearchAPI._serialize(data)}`;

        return (
            fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Channel': UserUtility.getChannelData().name,
                }})
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }
    /**
     * Get Destination Url
     * @param {object} valueObj value Object containing details
     * @returns {Promise.<T>} Promise object that returns the search API results
     */
    getDestinationUrl(valueObj) {
        switch (valueObj.type) {
        case 'event': {
            const eventUrl = getAPIEndpoint('apiEventSearchId');
            this._serviceUrl = `${eventUrl}/${valueObj.id}`;
            break;
        }
        case 'article': {
            const articleUrl = getAPIEndpoint('apiArticleSearchId');
            this._serviceUrl = `${articleUrl}/${valueObj.id}`;
            break;
        }
        case 'line': {
            const lineUrl = getAPIEndpoint('apiLineSearchId');
            this._serviceUrl = `${lineUrl}/${valueObj.id}`;
            break;
        }
        case 'lineproduct': {
            const lineUrl = getAPIEndpoint('apiProductResourceUrl');
            this._serviceUrl = `${lineUrl}${valueObj.id}`;
            break;
        }
        default:
            break;
        }
        return fetch(this._serviceUrl)
            .then(response => response.json());
    }
}

// Packages
import React from 'react'
import PropTypes from 'prop-types'

// Image failure state
const noImage = process.env.PUBLIC_URL + '/assets/images/175-175.png';

function Image({
  alt = null,
  className = null,
  src,
  title = null,
  imageWidth,
  imageHeight,
}) {
  const formatImageSource = () => {
    if (!src) {
      return noImage
    }

    const cleanSource = src.replace("?width=", "")
    if (imageWidth || imageHeight) {
      return `${cleanSource}?width=${imageWidth}&height=${imageHeight}`;
    }

    return cleanSource;
  }

  return (
    <img
      className={className}
      style={{ width: imageWidth, height: imageHeight }}
      src={formatImageSource()}
      alt={alt}
      title={title}
      onError={(e) => { e.target.onerror = null; e.target.src = noImage; }}
    />
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
}

export default Image;
import {
    SHOW_LOADING,
    SET_IMAGES,
    SET_IMAGES_COUNT,
    SET_IMAGE_ERR,
} from './actionTypes';
import ImagesAPI from 'api/search/images';


/**
 * Instantiates the Images API
 * @type {SearchImagesAPI}
 * @private
 */
const _searchImages = new ImagesAPI();



/**
 * Action to get images
 * @param {array} tags List of tags to pass into the API
 * @param {string} pagenum of page numbers
 * @returns {function(*)} Promise object with fetch to the SearchVideos
 */
export function fetchImages(tags = [], pagenum, pagesize, datasourceIds) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _searchImages.getImages(tags, pagenum, pagesize, datasourceIds).then((response) => {
            const imageResponse = response || [];
            const status = 200;
            if (status === 200) {
                dispatch({
                    type: SET_IMAGES,
                    images: imageResponse.itemsList,
                    count:imageResponse.totalCount,
                });
                dispatch({
                    type: SET_IMAGES_COUNT,
                    count: imageResponse.totalCount,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_IMAGE_ERR,
                    status,
                });
                dispatch({
                    type: SET_IMAGES_COUNT,
                    count: imageResponse.totalCount,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}

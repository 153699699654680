// Internal
import { getSitecoreApiHost, getSitecoreApiKey } from '../../../AppGlobals';
import { Environment } from '../../../Environment';
import UserUtility from 'utils/userutility';

//Component
import { showToastPromise } from '../components/ToastNotification';
import { RemoveFromPlanInterface, ShowroomInterface } from '../reducers/interface';
import userId from './localUserId';
import { toggleDrawerState } from './toggleDrawer';
import { analytics } from '../helpers/analytics';
import { handleError } from '../helpers/errorHandlers';

const removeShowroomSuccess = (showroom: ShowroomInterface, lineGuid?: string) => ({
    type: 'REMOVE_SHOWROOM_SUCCESS',
    showroom,
    lineGuid
});

export const removeFromPlan = (Item: ShowroomInterface, plan: any, lineGuid?: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: 'SET_LOADING_STATE',
            isLoading: true,
            lineGuid: lineGuid,
            exhibitorId: Item.exhibitorId
        });
        let line = undefined;
        if (lineGuid) {
            line = Item.lines?.find((line: any) => line.lineGuid === lineGuid);
        }
        try {
            const requestBody: RemoveFromPlanInterface = {
                marketPlanItemId: Item.marketPlanItemId
            };
            if (lineGuid) requestBody.lineGuid = lineGuid;

            const headers = {"Content-Type": "application/json"}

            const promise: Promise<string> = new Promise((resolve, reject) => {
                fetch(
                    `${getSitecoreApiHost()}/imc-api/market-planner/v1/delete?sc_apikey=${getSitecoreApiKey()}&${Environment.isHot ? `contactAltId=${userId}` : ``}`,
                    {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(requestBody)
                    },
                ).then(response => {
                    if (!response.ok) {
                        handleError(
                            response,
                            (Environment.isHot) ? () => dispatch(toggleDrawerState(true)) : () => UserUtility.redirectToLogin()
                        )
                        throw new Error('Network response was not ok');
                    } else {
                        dispatch(removeShowroomSuccess(Item, lineGuid));
                        dispatch({
                            type: 'SET_LOADING_STATE',
                            isLoading: false,
                            lineGuid: lineGuid,
                            exhibitorId: Item.exhibitorId
                        });
                        if (lineGuid) {
                            analytics().track('Plan Item + Remove Line from Plan', {
                                contact_alt_id: plan.contactAltId,
                                market_id: `${plan.market.marketId}`,
                                market_display_name: plan.market.displayName,
                                market_channel_name: plan.market.channelName,
                                market_channel_id: plan.channelID,
                                market_event_code: plan.market.eventCode,
                                market_season: plan.market.season,
                                market_year: `${plan.market.marketId}`,
                                market_name: plan.market.name,
                                exhibitor_name: Item.showroomName,
                                exhibitor_core_brand_id: Item.exhibitorCoreBrandId,
                                exhibitor_rds_id: Item.exhibitorId,
                                manufacturing_line_name: (Item.lines) ? Item.lines[0].name : undefined,
                                manufacturing_line_rds_id: lineGuid,
                            });
                        } else {
                            analytics().track('Plan Item + Remove Showroom from Plan', {
                                contact_alt_id: plan.contactAltId,
                                market_id: `${plan.market.marketId}`,
                                market_display_name: plan.market.displayName,
                                market_channel_name: plan.market.channelName,
                                market_channel_id: plan.channelID,
                                market_event_code: plan.market.eventCode,
                                market_season: plan.market.season,
                                market_year: `${plan.market.marketId}`,
                                market_name: plan.market.name,
                                exhibitor_name: Item.showroomName,
                                exhibitor_core_brand_id: Item.exhibitorCoreBrandId,
                                exhibitor_rds_id: Item.exhibitorId,
                            });
                        }
                        resolve('Success!')
                    }
                })
                .catch(error => {
                    console.log(`Error deleting from plan: ${error}`);
                    dispatch({
                        type: 'SET_LOADING_STATE',
                        isLoading: false,
                        lineGuid: lineGuid,
                        exhibitorId: Item.exhibitorId
                    });
                    toggleDrawerState(false);
                    reject('Error!');
                });
            });
            showToastPromise(promise, Item, plan, 'removed', 'removalerror', line);
        } catch (e) {
            console.log(e);
            dispatch({
                type: 'SET_LOADING_STATE',
                isLoading: false,
                lineGuid: lineGuid,
                exhibitorId: Item.exhibitorId
            });
            toggleDrawerState(false);
        }
    };
};
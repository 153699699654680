import React from 'react'
import PropTypes from 'prop-types'

function ArrowButton(props) {
  const forward = <svg className='alpha-nav--button' onClick={() => props.onClickButton('forward')} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g id="Group_133" data-name="Group 133" transform="translate(-1642 -420)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="24" cy="24" r="24" transform="translate(1642 420)" fill="#ececec" />
      <g id="Icon_-_Expand_Arrow" data-name="Icon - Expand Arrow" transform="translate(1656 456.572) rotate(-90)">
        <rect id="Rectangle_358" data-name="Rectangle 358" width="24" height="24" transform="translate(-0.428)" fill="none" />
        <g id="Group_132" data-name="Group 132" transform="translate(0 5.893)">
          <path id="Path_39" data-name="Path 39" d="M11.82,12.512.441,2.09A1.238,1.238,0,0,1,2.116.267l9.7,8.912L21.543.285a1.238,1.238,0,1,1,1.676,1.823Z" transform="translate(-0.044 0.056)" fill="#758488" />
        </g>
      </g>
    </g>
  </svg>

  const back = <svg className='alpha-nav--button' onClick={() => props.onClickButton('back')} id="Group_134" data-name="Group 134" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <circle id="Ellipse_1" data-name="Ellipse 1" cx="24" cy="24" r="24" fill="#ececec" />
    <g id="Icon_-_Expand_Arrow" data-name="Icon - Expand Arrow" transform="translate(10 37) rotate(-90)">
      <rect id="Rectangle_358" data-name="Rectangle 358" width="24" height="24" fill="none" />
      <g id="Group_132" data-name="Group 132" transform="translate(0.428 5.539)">
        <path id="Path_39" data-name="Path 39" d="M11.777,0,.4,10.422a1.238,1.238,0,1,0,1.676,1.823l9.7-8.912L21.5,12.227A1.238,1.238,0,1,0,23.175,10.4Z" fill="#758488" />
      </g>
    </g>
  </svg>

  return (
    <>
      {props.forward && !props.back && forward}
      {props.back && !props.forward && back}
    </>
  )
}

ArrowButton.propTypes = {
  forward: PropTypes.bool,
  back: PropTypes.bool,
  onClickButton: PropTypes.func.isRequired,
}

export default ArrowButton


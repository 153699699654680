// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {withSitecoreRouter} from 'utils/withRouter';

//Local import
import ContactExhibitorModal from './ContactExhibitorModal';
// utils
import { renderDesktopMobileVariations } from 'utils/dictionary';
import UserUtility from 'utils/userutility';
import { productCategoriesToArray } from 'utils/exhibitor';

// reducers
import ContactExhibitorReducer from "../reducers";
// action
import * as dataActions from "../actions/dataActions";
// modules
import { createAppState } from "modules/redux-app-state";
import ImcDataLayer from 'utils/datalayer';

const contactExhibitorState = createAppState();

/**
 * Component for displaying a OptIn module and maintaining its state
 */
class ContactExhibitor extends Component {

    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        contactExhibitorState.reducerRegistry.register({
            ContactExhibitorReducer
        });

        this.state = {
            showModal: false,
            errors: [],
            companyName: ''
        };
        this.fetchData = this.fetchData.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!!this.state.showModal && !!this.state.companyName && (prevState.companyName != this.state.companyName)) {
            //open modal is clicked and company name is avilable
            ImcDataLayer.pushInteractionEvent("digital showroom", "contact exhibitor", this.state.companyName);
        }
    }

    /**
   * Fetch the article data
   * @param { string } queryObj term used to conduct search for
   */
    fetchData() {
        const pageId = this.props.sitecoreContext.route.itemId;
        this.props.dataActions.getContactExhibitorInfo(this.props.exhibitorId, pageId);
    }

    /**
     * @method handleOpenModal
     * @param {object} e click event
     * @description this will open the modal window
     */
    handleOpenModal(e) {
        e.preventDefault();
        e.stopPropagation();
        this.fetchData();
        const { onPreClick, exhibitor } = this.props;
        this.setState({ showModal: true, companyName: exhibitor.companyName });

        if (typeof onPreClick !== 'undefined') {
            // onPreClick();
        }
    }

    /**
     * @method handleCloseModal
     * @description this will Close the modal window
     */
    handleCloseModal() {
        this.setState({ showModal: false, companyName: '' });
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { showModal } = this.state;
        const { extraClass, exhibitor, iconOnly, alternativeLayout, buttonLabel, title } = this.props;
        const userInfo = UserUtility.getUserData();
        const mainCss = !!alternativeLayout ? "imc-button--contact-exhibitor-alt" : "imc-button--contact-exhibitor";
        const btnLabel = !!buttonLabel ? buttonLabel.trim() : renderDesktopMobileVariations('contactExhibitor', 'Contact Exhibitor');
        const data = UserUtility.isLoggedIn() ? {
            userName: userInfo.name,
            email: userInfo.email,
            organizationId: userInfo.organizationId,
            accountId: userInfo.accountId,
        } : {};

        return (
            <div
                className={iconOnly ? 'imc-content--inline-block imc-content--center' : 'contact-exhibitor-wrapper'}
            >
                <button
                    className={`imc-button--contact-exhibitor ${extraClass} ${mainCss} ${iconOnly ? 'imc-button--icon-only' : 'imc-button'}`}
                    onClick={this.handleOpenModal}
                    title={title}
                >
                    {!iconOnly && btnLabel}
                </button>
                <ContactExhibitorModal
                    showModal={showModal}
                    closeModal={this.handleCloseModal}
                    exhibitor={exhibitor}
                    {...this.props}
                    {...data}
                />
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{data: *, labels: *}}
 */
ContactExhibitor.propTypes = {
    exhibitorId: PropTypes.any,
    extraClass: PropTypes.string,
    iconOnly: PropTypes.bool,
    exhibitor: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {{data: object, labels: object}}
 */
ContactExhibitor.defaultProps = {
    exhibitorId: '',
    exhibitorName: '',
    extraClass: '',
    iconOnly: false,
    exhibitor: {
        companyName: '',
        productCategories: [],
        contactInfo: {},
        socialMedia: {},
    },
    title: "",
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
    if (state.ContactExhibitorReducer)
        return {
            exhibitor: {
                companyName: state.ContactExhibitorReducer.companyName,
                productCategories: productCategoriesToArray(state.ContactExhibitorReducer.productCategories),
                contactInfo: state.ContactExhibitorReducer.contactInfo,
                socialMedia: state.ContactExhibitorReducer.socialMedia,
            }
        };
    else {
        return {
            exhibitor: {
                companyName: state.companyName,
                productCategories: state.productCategories,
                contactInfo: state.contactInfo,
                socialMedia: state.socialMedia,
            }
        };
    }
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
    return {
        dataActions: bindActionCreators({ ...dataActions }, dispatch),
    };
}

export default withSitecoreContext()(
    withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ContactExhibitor))
);

// Third-Party
import React, { useEffect, useRef } from 'react';
import { Placeholder, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade, EffectFlip, EffectCoverflow } from 'swiper';
import FlexGrid from '../Flex-Grid';
import { flexGridRendering } from 'utils/buildRenderingParameters';

SwiperCore.use([Autoplay, EffectFade, EffectFlip, EffectCoverflow]);

// ---------------------- //
// define props interface //
// ---------------------- //
type CarouselComponentProps = {
    params: any;
    rendering: any;
};

interface SwiperOptions {
    ref: any;  // Replace 'any' with the correct type for swiperRef
    slidesPerView: number;
    breakpoints: any;  // Replace 'any' with the correct type for breakpoints
    navigation?: {
        nextEl: string;
        prevEl: string;
    };
    pagination?: {
        clickable: boolean;
    };
    loop?: boolean;
    loopedSlides?: number;
    autoplay?: {
        delay: number;
        disableOnInteraction?: boolean;
    };
    allowTouchMove?: boolean;
    direction?: any;
    autoHeight?: boolean;
    effect?: string;
}

interface ContainerStyles {
        "--swiper-navigation-color"?: string;
        "--swiper-pagination-color"?: string;
        "--swiper-pagination-inactivecolor"?: string;
        "--swiper-pagination-bullet-opacity"?: string;
        "--swiper-pagination-bullet-inactive-color"?: string;
        "--swiper-pagination-bullet-inactive-opacity"?: string;
        "--swiper-navigation-sides-offset"?: string;
        "--swiper-pagination-bottom"?: string | undefined;
}
// ---------------------- //

// ------------------ //
// FlexGrid Component //
// ------------------ //
const CarouselComponent: React.FC<CarouselComponentProps> = (props) => {

    const swiperRef = useRef<any>(null);
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, [loaded]);
    
    // ----------- //
    // render JSX  //
    // ----------- //

    // handle rendering before params selected in Experience Editor
    if (!props || !props.params || !props.rendering) {
        return (
            <div>
                <strong>Loading Rendering Parameters</strong>
                <br />
                <svg width="100" height="100"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#loading" /></svg>
            </div>
        );
    }

    const padding = (props?.params?.padding) ? JSON.parse(props.params.padding).className.value : '0px';
    const paginationSize = (props?.params?.paginationType) ? JSON.parse(props.params.paginationType).className.value : '0px';
    const paginationPosition = (props?.params?.paginationPosition) ? JSON.parse(props.params.paginationPosition).className.value : '8px';
    const hasPagination = paginationSize !== '0px';
    const arrowSize = (props?.params?.arrowType) ? JSON.parse(props.params.arrowType).className.value : '0px';
    const arrowPosition = (props?.params?.arrowPosition) ? JSON.parse(props.params.arrowPosition).className.value : '0px';
    const mobile = (props?.params?.mobile) ? JSON.parse(props.params.mobile).className.value : '1';
    const tablet = (props?.params?.tablet) ? JSON.parse(props.params.tablet).className.value : '2';
    const desktop = (props?.params?.desktop) ? JSON.parse(props.params.desktop).className.value : '3';

    const breakpoints = {
        '0': {slidesPerView: mobile, spaceBetween: padding.replace('px', '')},
        '600': {slidesPerView: tablet, spaceBetween: padding.replace('px', '')},
        '991': {slidesPerView: desktop, spaceBetween: padding.replace('px', '')},
        '1366': {slidesPerView: desktop, spaceBetween: padding.replace('px', '')},
    };

    const totalSlides = (props.params.totalSlides && JSON.parse(props.params.totalSlides).className.value) ? parseInt(JSON.parse(props.params.totalSlides).className.value) : parseInt(JSON.parse(props.params.columnCount).className.value);
    const numbers = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];

    let options: SwiperOptions = {
        ref: swiperRef,
        slidesPerView: 1,
        breakpoints: breakpoints,
        allowTouchMove: false
    };

    if (props?.params?.paginationType && paginationSize != '0px') {
        options.pagination = {
            clickable: true,
        }
    }
    if (props?.params?.arrowType) {
        options.navigation = {
            nextEl: `.swiper-button-next-${props.rendering.uid}`,
            prevEl: `.swiper-button-prev-${props.rendering.uid}`
        };
    }

    if (props?.params?.autoplay > 0) {
        options.autoplay = {
            delay: parseInt(props.params.autoplay) * 1000,
            disableOnInteraction: true,
        };
    }

    if (props?.params?.loop == 1 && (totalSlides / 2) >= desktop) {
        options.loop = true;
    }

    if (props?.params?.draggable == 1) {
        options.allowTouchMove = true;
    }

    if (props?.params?.vertical == 1) {
        options.direction = 'vertical';
        options.autoHeight = true;
    }

    if (props?.params?.effect) {
        options.effect = JSON.parse(props.params.effect).className.value;
    }

    let containerStyles: ContainerStyles = {};

    if (props?.params?.paginationActiveColor) {
        containerStyles["--swiper-pagination-color"] = props.params.paginationActiveColor;
    }
    if (props?.params?.paginationColor) {
        containerStyles["--swiper-pagination-inactivecolor"] = props.params.paginationColor;
    }

    if (props?.params?.arrowColor) {
        containerStyles["--swiper-navigation-color"] = props.params.arrowColor;
    }

    if (props?.params.inactiveOpacity) {
        containerStyles["--swiper-pagination-bullet-inactive-opacity"] = props.params.inactiveOpacity;
    }

    containerStyles["--swiper-navigation-sides-offset"] = arrowPosition;
    // containerStyles["--swiper-pagination-bottom"] = paginationPosition;

    let extraClasses = (props.params.extraOptions && JSON.parse(props.params.extraOptions).className) ? ' ' + JSON.parse(props.params.extraOptions).className.value : '';
    extraClasses += (props.params.extraOptions && JSON.parse(props.params.extraOptions).classes) ? ' ' + JSON.parse(props.params.extraOptions).classes.map((c?: any) => {
        return c.className.value;
    }).join(' ') : '';

    if (loaded && !(props.params.displayAsGrid && isEditorActive())) {
        return (
            <div
                className={`swiper-andmore ${extraClasses} padding-${padding} ${(hasPagination) ? 'has-pagination' : ''} pagination-${paginationSize} ${(paginationPosition.includes('-')) ? `pagination-position-${paginationPosition}` : ``} navigation-${arrowSize} ${(props?.params?.vertical == 1) ? 'vertical' : ''}`}
                style={containerStyles as React.CSSProperties}
            >
                <Swiper
                    {...options}
                >
                    {/* ------------------------------ */}
                    {/* loop through the placeholders  */}
                    {/*     and get  their items       */}
                    {/*     and placeholder name       */}
                    {/* without any integer characters */}
                    {/* ------------------------------ */}
                    {Array(totalSlides).fill(undefined).map((item, index) => {
                        let placeholder = {
                            name: '',
                            data: [],
                        }
                        let characters = (index + 1).toString();
                        numbers.forEach((number, integer) => {
                            let regex = new RegExp(integer.toString(), 'g')
                            characters = characters.replace(regex, number);
                        });
                        placeholder.name = `imc-grid-column-${characters}-placeholder`;
                        placeholder.data = props.rendering.placeholders[`imc-grid-column-${characters}-placeholder`]
                        return placeholder;
                    }).map((placeholder) => {
                        // ---------------------------- //
                        // if a grid column placeholder //
                        //     then don't wrap with     //
                        //    additional grid column    //
                        // ---------------------------- //
                        if (props.rendering.placeholders[placeholder.name] && props.rendering.placeholders[placeholder.name].length == 1 && props.rendering.placeholders[placeholder.name][0].componentName == "Grid-Column-Component") {
                            return (
                                <Placeholder name={placeholder.name} rendering={props.rendering} />
                            );
                        }
                        // ---------------------------- //
                        //  same condition but for EE   //
                        // ---------------------------- //
                        if (isEditorActive() && props.rendering.placeholders[placeholder.name] && props.rendering.placeholders[placeholder.name].length == 5 && props.rendering.placeholders[placeholder.name][3].componentName == "Grid-Column-Component") {
                            return (
                                <Placeholder name={placeholder.name} rendering={props.rendering} />
                            );
                        }
                        // ---------------------------- //
                        //  default placeholder column  //
                        // ---------------------------- //
                        return (
                            <SwiperSlide
                                key={placeholder.name}
                                className=" imc-content-nav-container--column">
                                <Placeholder name={placeholder.name} rendering={props.rendering} />
                            </SwiperSlide>
                        );
                        // ---------------------------- //
                    })}
                </Swiper>
                {(props?.params?.arrowType && arrowSize != '0px') && <div className={`swiper-button-prev swiper-button-prev-${props.rendering.uid}`}></div>}
                {(props?.params?.arrowType && arrowSize != '0px') && <div className={`swiper-button-next swiper-button-next-${props.rendering.uid}`}></div>}
            </div>
        );
    } else {
        let grid = flexGridRendering();
        grid.params.totalColumns = `{\"className\":{\"value\":\"${totalSlides}\"}}`;
        grid.params.columnCount = `{\"className\":{\"value\":\"${desktop}\"}}`;
        grid.params.tabletColumnCount = `{\"className\":{\"value\":\"${tablet}\"}}`;
        grid.params.mobileColumCount = `{\"className\":{\"value\":\"${mobile}\"}}`;
        grid.params.padding = (padding && parseInt(padding.replace("px","")) > 0) ? "{\"className\":{\"value\":\"imc-gallery--xlarge-padded\"}}" : "";
        grid.params.horizontalAlignment = "{\"className\":{\"value\":\"imc-gallery--justify-center\"}}";
        grid.params.verticalAlignment = "{\"className\":{\"value\":\"imc-gallery--align-flex-stretch\"}}";
        grid.params.extraClasses = `{\"classes\": [{\"className\":{\"value\":\" ${(isEditorActive()) ? '' : 'swiper-grid'} amc-grid \"}}]}`
        return (
            <div
                className={`swiper-andmore ${extraClasses} padding-${padding} ${(hasPagination) ? 'has-pagination' : ''} pagination-${paginationSize} ${(paginationPosition.includes('-')) ? `pagination-position-${paginationPosition}` : ``} navigation-${arrowSize} ${(props?.params?.vertical == 1) ? 'vertical' : ''}`}
                style={containerStyles as React.CSSProperties}
            >
                <div className={'swiper'}>
                    <div>
                        <FlexGrid params={grid.params} rendering={props.rendering} />
                    </div>
                    {(hasPagination) &&
                    <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                        {Array.from({ length: totalSlides }).map((_, index) => (
                            <span
                                key={index}
                                className={`swiper-pagination-bullet ${index === 0 ? 'swiper-pagination-bullet-active' : ''}`}
                            ></span>
                        ))}
                    </div>}
                    {(props?.params?.arrowType && arrowSize != '0px') && <div className={`swiper-button-prev swiper-button-prev-${props.rendering.uid}`}></div>}
                    {(props?.params?.arrowType && arrowSize != '0px') && <div className={`swiper-button-next swiper-button-next-${props.rendering.uid}`}></div>}
                </div>
            </div>
        );

    }
    // ----------- //

}
// ------------------ //

export default CarouselComponent;
/**
 * Success for getting quick add modal list
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_PRODUCTS_SUCCESS = 'GET_EXHIBITORCATEGORIES_PRODUCTS_SUCCESS';

/**
 * Failure for getting the lists
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_PRODUCTS_FAIL = 'GET_EXHIBITORCATEGORIES_PRODUCTS_FAIL';

/**
 * Success for getting quick add modal list
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_FEATURED_PRODUCTS_SUCCESS = 'GET_EXHIBITORCATEGORIES_FEATURED_PRODUCTS_SUCCESS';

/**
 * Failure for getting the lists
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_FEATURED_PRODUCTS_FAIL = 'GET_EXHIBITORCATEGORIES_FEATURED_PRODUCTS_FAIL';

/**
 * Failure for getting the lists
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_FEATURED_COUNT_SUCCESS = 'GET_EXHIBITORCATEGORIES_FEATURED_COUNT_SUCCESS';

/**
 * Success for Adding to List
 * @type {string}
 */
export const ADD_EXHIBITORCATEGORIES_PRODUCTS_SUCCESS = 'ADD_EXHIBITORCATEGORIES_PRODUCTS_SUCCESS';

/**
 * Success for Adding to List
 * @type {string}
 */
export const ADD_EXHIBITORCATEGORIES_PRODUCTS_FAIL = 'ADD_EXHIBITORCATEGORIES_PRODUCTS_FAIL';

/**
 * Success for getting quick add modal list
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_CATALOGS_SUCCESS = 'GET_EXHIBITORCATEGORIES_CATALOGS_SUCCESS';

/**
 * Failure for getting the lists
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_CATALOGS_FAIL = 'GET_EXHIBITORCATEGORIES_CATALOGS_FAIL';

/**
 * Success for Adding to List
 * @type {string}
 */
export const ADD_EXHIBITORCATEGORIES_CATALOGS_SUCCESS = 'ADD_EXHIBITORCATEGORIES_CATALOGS_SUCCESS';

/**
 * Success for Adding to List
 * @type {string}
 */
export const ADD_EXHIBITORCATEGORIES_CATALOGS_FAIL = 'ADD_EXHIBITORCATEGORIES_CATALOGS_FAIL';

/**
 * Success for getting quick add modal list
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_MANUFACTURINGLINES_SUCCESS = 'GET_EXHIBITORCATEGORIES_MANUFACTURINGLINES_SUCCESS';

/**
 * Failure for getting the lists
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_MANUFACTURINGLINES_FAIL = 'GET_EXHIBITORCATEGORIES_MANUFACTURINGLINES_FAIL';

/**
 * Success for Adding to List
 * @type {string}
 */
export const ADD_EXHIBITORCATEGORIES_MANUFACTURINGLINES_SUCCESS = 'ADD_EXHIBITORCATEGORIES_MANUFACTURINGLINES_SUCCESS';

/**
 * Success for Adding to List
 * @type {string}
 */
export const ADD_EXHIBITORCATEGORIES_MANUFACTURINGLINES_FAIL = 'ADD_EXHIBITORCATEGORIES_MANUFACTURINGLINES_FAIL';
/**
 * Loads the count for the tabs
 * @type {string}
 */
export const GET_EXHIBITORCATEGORIES_COUNT_SUCCESS = 'GET_EXHIBITORCATEGORIES_COUNT_SUCCESS';


/**
 * Loads the my information tab data
 */
export const GET_EXHIBITORCATEGORIES_MY_INFORMATION_SUCCESS = 'GET_EXHIBITORCATEGORIES_MY_INFORMATION_SUCCESS';

/**
 * Loads the my information tab data
 */
export const GET_EXHIBITORCATEGORIES_MY_INFORMATION_FAIL = 'GET_EXHIBITORCATEGORIES_MY_INFORMATION_FAIL';

/**
 * Set Filters Relevant to exhibitors & products
 * * @type {string}
 */
export const SET_PRODUCT_FILTERS = 'SET_PRODUCT_FILTERS';

/**
 * Set Filters Relevant to exhibitors & products
 * * @type {string}
 */
export const SET_PRODUCT_FILTERS_ERR = 'SET_PRODUCT_FILTERS_ERR';

/**
 * Action to get filters for exhibitors & products
 * * @type {string}
 */
export const GET_PRODUCT_FILTERS = 'GET_PRODUCT_FILTERS';

/**
 * Action to set sort options for products
 * @type {string}
 */
export const SET_PRODUCT_SORT = 'SET_PRODUCT_SORT';

/**
 * Action to set exhibitor category products out of date
 */
export const SET_EXHIBITORCATEGORIES_PRODUCTS_OUT_DATE = 'SET_EXHIBITORCATEGORIES_PRODUCTS_OUT_DATE';

/**
 * Action to get manufacturing lines pending status
 */
export const GET_EXHIBITORCATEGORIES_MANUFACTURINGLINES_PENDING = 'GET_EXHIBITORCATEGORIES_MANUFACTURINGLINES_PENDING';

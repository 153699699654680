import {
    SET_PRODUCT_DETAIL,
    SET_PRODUCT_DETAIL_ERR,
    SHOW_LOADING
} from './actionTypes';
import ExhibitorsAPI from 'api/exhibitor';
import ImcDataLayer from 'utils/datalayer';


/**
 * Instantiates the Exhibitors API
 * @type {ExhibitorsAPI}
 * @private
 */
const _exhibitorsApi = new ExhibitorsAPI();


function GTMPushProductDetail(p){
    //datalayer prodDetailView
    const dl = new ImcDataLayer();
    dl.productDetailView.add (p.productTitle, p.productId, p.lineName, p.productCategories, p.exhibitorName, p.exhibitorId, !!p.shopZioUrl, 'Product Detail', 0);
    dl.productDetailView.push();
}

/**
 * Action to get Digital Showroom Products Detail
 * @param {array} productIds Array with Product Ids
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getDigitalShowroomProductsDetail(productIds) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getDigitalShowroomProductsDetail(productIds).then((response) => {
            if (response && Array.isArray(response.data) && response.data.length > 0) {
                const productDetail = {
                    productId: response.data[0].productId,
                    shopZioUrl: response.data[0].shopZioUrl,
                    productTitle: response.data[0].productTitle,
                    productDescription: response.data[0].productDescription,
                    productCategories: response.data[0].productCategoryTags,
                    images: response.data[0].images,
                    companyName: response.data[0].companyDetails?response.data[0].companyDetails.companyName:'',
                    lineName: response.data[0].lineName,
                    lineId: response.data[0].lineId,
                    exhibitorId: response.data[0].exhibitorId,
                    exhibitorName: response.data[0].exhibitorName,
                };
                GTMPushProductDetail(productDetail);

                dispatch({
                    type: SET_PRODUCT_DETAIL,
                    productDetail: productDetail,
                    status: true,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_PRODUCT_DETAIL_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        }).catch((err) =>{
            dispatch({
                type: SET_PRODUCT_DETAIL_ERR,
                status: false,
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    };
}

import AuthFetch from 'utils/authfetch';
import UserUtility from 'utils/userutility'
const _authFetch = new AuthFetch();
/**
 * Returns object containing field name and value
 * @param {string} url API endpoint
 * @returns {array} Array returned containing field names and values
 */
export function getExhibitorProductCategories(url) {
    return _authFetch.authFetch(url)
        .then(response => response.json());
}

/**
 * Posts data for contacting Exhibitor
 * @param {string} url API endpoint
 * @param {object} fields API endpoint
 * @returns {object} Object returned containing field names and values
 */
export function submitFormData(url, fields) {
    return _authFetch.authFetch(url, {
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify(fields),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'channel':  UserUtility.getChannelData().name,
            'clientId': 'TODO: Remove this.' 
        }
    });
}

import React, {Component} from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
export default class TabComponent extends Component {

    /**
     * @method constructor
     * @description On instantiation dynamically sets the moduleName based on the `mediaGalleryId` and
     * creates aliases to methods
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
    }

	/**
     * @method renderItems
     * @description Parses through the child CarouselItems and clones the components and sets new properties based
     * on the current carousel state/properties
     * @returns {Element} Rendered Carousel Items
     */
    renderBodies() {
        const { children } = this.props;
        const { currentIndex } = this.state;

        return (
            <div
                className="imc-tabs__body imc-section"
                aria-live="polite"
                data-xpath="tabs.containerInner"
            >
            	<Placeholder name='imc-tab-content' rendering={this.props.rendering}/>
            </div>
        );
    }

    render(){
        let item;
    	if(this.props.currentIndex == this.props.rendering.uid){
    		item = <div
                className="imc-tabs__body imc-section"
                aria-live="polite"
                data-xpath="tabs.containerInner"
            >
            	<Placeholder name='imc-tab-content' rendering={this.props.rendering}/>
            </div>
    	}
    	return(
    		<div>
    		{item}
    		</div>
    	)
    }
}
/**
 * Module for configuring redux reducers for an AppState
 */

// Dependencies
import { combineReducers } from 'redux';

/**
 * @method configureReducers
 * @description Creates a combined redux reducer and wires third-party middleware
 *
 * @param {Object} reducers Collection of reducer objects to be used in redux store
 * @returns {*} Combined reducers
 */
export default function configureReducers(reducers) {
    return combineReducers({
        ...reducers,
    });
}

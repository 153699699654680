import React, {Component} from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
export default class TabbedNavigationComponent extends Component {

	/**
     * @method constructor
     * @description On instantiation dynamically sets the moduleName based on the `mediaGalleryId` and
     * creates aliases to methods
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            currentIndex: null,
        };

        if(this.state.currentIndex===null){
        	this.state.currentIndex = this.props.rendering.placeholders["imc-tabbed-navigation-content"][0].dataSource;
        }

        this.setActiveItem = this.setActiveItem.bind(this);
    }

    /**
	 * @method setActiveItem
	 * @description Sets the state with the current index
	 * @param {Number} index The index number of the carousel item to select
	 * @param {object=} e Event object
	 */
	setActiveItem(index, e) {
	    const code = e ? e.keyCode || e.which : undefined;
	    if (code === undefined || code === 13 || code === 32) {
	        this.setState({
	            currentIndex: index,
	        });
	    }
	}

    /**
	 * Renders a single tab item
	 * @param {number} index tab index
	 * @param {string} heading Heading text
	 * @param {function} onSelect Method to call when selected
	 * @param {boolean} active Flag to determine active or inactive tab
	 * @returns {XML} Tab markup
	 */
	renderTab(index, fields, onSelect, active) {
		let title;
    	if(fields != null && fields.title !=null){
    		title = fields.title.value;
    	}
	    if (active) {
	        return (
	            <div
	                key={index}
	                className="imc-tabs-nav__item imc-tabs-nav__item--active imc-content--beta"
	            >
	                {title}
	            </div>
	        );
	    }
	    return (
	        <a
	            key={index}
	            role="button"
	            tabIndex={0}
	            className="imc-tabs-nav__item imc-link imc-link--alt imc-content--beta"
	            onClick={() => onSelect(index)}
	            onKeyDown={e => onSelect(index, e)}
	            data-xpath="tab.heading"
	        >{title}</a>
	    );
	}

    /**
     * @method renderItems
     * @description Parses through the child CarouselItems and clones the components and sets new properties based
     * on the current carousel state/properties
     * @returns {Element} Rendered Carousel Items
     */
    renderTabs() {
        return (
        	<div
	            className="imc-tabs-nav"
	            aria-label="tab controls"
	            data-xpath="tab.controls"
	        >
	        	{this.props.rendering.placeholders["imc-tabbed-navigation-content"].map((item, index) =>(
	            	this.renderTab(item.dataSource, item.fields, this.setActiveItem, item.dataSource === this.state.currentIndex)
	            ))}
			</div>
        );
    }

	render(){
		return(
			<div className="imc-section">
				<div className="imc-section imc-section--alt-gray-light imc-tabs-nav__container imc-section">
					{this.renderTabs()}
			    </div>
			    <Placeholder name='imc-tabbed-navigation-content' rendering={this.props.rendering} currentIndex={this.state.currentIndex}/>
		    </div>
		)
	}
}

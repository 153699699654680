import { Environment } from './Environment';

export function getGlobalData() {
    return window.app;
}

export function getSitecoreApiKey() {
    return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_KEY : getGlobalData().sitecoreApiKey;
}

export function getGtmEnvironmentName() {
    return Environment.isServer ? Environment.reactAppProcessEnv.GTM_ENVIRONMENT_NAME : getGlobalData().gtmEnvironmentName;
}

export function getSitecoreApiHost() {

    //hack - replaced getGlobalData().sitecoreApiHost with new function to use window.origin.
    // return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost();
    //return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost();
    //hack# added if statement in order to avoid local development 404 error in pages.
    return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost();
}
export function getSitecoreLayoutApiHost() {

    //hack - replaced getGlobalData().sitecoreApiHost with new function to use window.origin.
    // return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost();
    //return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost();
    //hack# added if statement in order to avoid local development 404 error in pages.
    return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost();
}

export function getSitecoreDefaultLanguage() {
    return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_DEFAULT_LANGUAGE : getGlobalData()?.sitecoreDefaultLanguage;
}
export function getSitecoreReactApiHost() {
    try {
        if (typeof window !== "undefined" && !(window.location.origin.indexOf('localhost') > -1))
            return window.location.origin;
        else
            return getGlobalData().sitecoreApiHost;
    }
    catch
    {
        return getGlobalData().sitecoreApiHost;
    }
}

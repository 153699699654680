import React from 'react';
import { isEditorActive, } from "@sitecore-jss/sitecore-jss-react";
import { getDictionaryValue } from 'utils/dictionary/index.js';
import Link from '../Link';


class BuildingNavigatorComponent extends React.Component {
  searchURL = getDictionaryValue("searchUrl", "/Search");
  
  constructor(props) {
    super(props);
    
    this.getLinkUrl = this.getLinkUrl.bind(this);
  }
  
    getRangeFloors(range){
        const floors = range.split('-');
        const start= parseInt(floors[0].trim());
        const end = parseInt(floors.length>1?floors[1].trim():start);
        let arr = [];
        
        for (var i=start; i<=end; i++){
          arr.push(i);
        }
        return arr;
    } 

    //?exhibitors=Locations=Building A.1|Building A.2|Building A.3|Building B.1|Building B.4
    getLinkUrl(building){
      let params = `Locations=`;
      let separator = "%7C";

        building.floorsTitle.split(',').forEach(floor => {
          const floors = this.getRangeFloors(floor);
          floors.forEach(f=>{
            params += `${building.name.replace(" ","+")}.${f}${separator}`;
          });
        });
      
      let encodedParams = encodeURI(params);
      //encodedParams = encodeURIComponent(encodedParams);

      const url = `${this.searchURL}?exhibitors=${encodeURIComponent(params)}`;
      return url;
    }

  render(){
    const {title, buildings} = this.props.fields;
      return (
        <div className="buidingNavigator-container">
            <h3>{title}</h3>
            
              <ul className="imc-buildingNavigator-building-list imc-list imc-list--type-disc">
              {
                buildings.map(b=>{
                    let linkUrl=this.getLinkUrl(b);
                    return  <li className="imc-list__item imc-buildingNavigator--item" key={b.id}>
                        <Link className="imc-type--body-1-link" href={linkUrl}>{b.name}: </Link>
                        <Link className="imc-type--body-2-ui-link" href={linkUrl}>Floors {b.floorsTitle}</Link>
                      </li>
                      }
                )
              }
              </ul>
            
        </div>
      );
  }
}


export default BuildingNavigatorComponent;

import React from 'react';

const SearchBar = (props) => (
  <div>
    <h1>Search-Bar Component</h1>
    <p>{JSON.stringify(props)}</p>
  </div>
);

export default SearchBar;

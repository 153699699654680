import React from 'react';
import {withSitecoreRouter} from 'utils/withRouter';
import { encode, decode } from 'utils/querystring';

class DefineSearchIntent extends React.Component {
	/**
	* @method contructor
	* @description Lifecycle method
	* @param {object} props incoming props
	*/
	constructor(props) {
		super(props);
		this.renderOptions = this.renderOptions.bind(this);
		this.changeType = this.changeType.bind(this);
		this.state = {
			value: ''
		};

	}
	componentDidMount() {
		const searchParams = new URLSearchParams(typeof window != "undefined" ? window.location.search : null);
		let type = "";
		if (Object.hasOwn(searchParams, 'type')) {
			type = searchParams.type;
			this.setState({ value: type });
		}
	}

    componentDidUpdate(lastProps) {
        if (lastProps.router.location !== this.props.router.location) {
            let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
            let type = "";
            if (Object.hasOwn(searchParams, 'type')) {
                type = searchParams.type;
                this.setState({ value: type });
            } else {
                this.setState({ value: ""});
            }
        }
    }

	changeType(event) {
		this.setState({ value: event.target.value });
		let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
		if (event.target.value == "") {
			delete searchParams.type;
		} else {
			if (Object.hasOwn(searchParams, 'type')) {
				searchParams.type = event.target.value;
			} else {
				searchParams.type = event.target.value;
			}

		}
		if(Object.hasOwn(searchParams, 'page')){
			delete searchParams.page;
		}
		const router = this.props.router;
		router.navigate({  pathname: this.props.router.location.pathname,
			search: encode(searchParams, false, false)});

	}
	renderOptions() {
		const { options } = this.props;
		if (Array.isArray(options))
			return (
				<>
					{options.map((item, index) => (
						<option key={index} value={item.key}>{item.value}</option>
					))}
				</>
			)
	}


	render() {
		return (
			<div className="imc-formfield imc-dropdown  imc-searchform--dropdown ">
				<select id="dropdown-sort-search" name="Search Type" onChange={this.changeType} value={this.state.value}>
					{this.renderOptions()}
				</select>
			</div>
		);
	}
}

export default withSitecoreRouter(DefineSearchIntent);

/**
 * Initializes the search results
 * @type {string}
 */
export const TYPEAHEADSEARCH_INIT = 'TYPEAHEADSEARCH_INIT';

/**
 * Loads the search results from a successful search
 * @type {string}
 */
export const TYPEAHEADSEARCH_SUCCESS = 'TYPEAHEADSEARCH_SUCCESS';

/**
 * Failed call for search results
 * @type {string}
 */
export const TYPEAHEADSEARCH_FAIL = 'TYPEAHEADSEARCH_FAIL';

/**
 * Failed call for search results
 * @type {string}
 */
export const TYPEAHEADSEARCH_RESET = 'TYPEAHEADSEARCH_RESET';

/**
 * Sets the current query in the store
 * @type {string}
 */
export const TYPEAHEADSEARCH_UPDATE_CURRENT_QUERY = 'TYPEAHEADSEARCHUPDATE_CURRENT_QUERY';

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// reducers
import articlesDirectoryReducer from './reducers/articleDirectoryReducer';
// action imports
import * as dataActions from './actions/dataActions';
import { createAppState } from 'modules/redux-app-state';
import  Pagination   from 'modules/search/Pagination.jsx';
import { decode } from 'utils/querystring';

import ArticleCardComponent from '../Article-Card-Component'

const ArticleGalleryState = createAppState();

  const defaultProps = {
    dataActions: {
      fetchArticles: () => { },
    },
    searchQuery: null,
    defaultView: null,
    errCode: null,
    hideFilters: true,
    location: {
      search: true,
    },
    filterKey: 'articles',
    tagKey: 'tags',
    pageSize: 20,
    pagenumber: 1,
    totalPages : 0,
  };
  const propTypes = {
    dataActions: PropTypes.object,
    router: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    defaultView: PropTypes.object,
    filterKey: PropTypes.string,
    tagKey: PropTypes.string,
  };

  class ArticleList extends React.Component {
    constructor(props) {
      super(props);
      ArticleGalleryState.reducerRegistry.register({ articlesDirectoryReducer });
      this.boundFetchData = this.fetchData.bind(this);
      this.renderCards = this.renderCards.bind(this);
      let pageNum = qs.parse(this.props.router.location.search, { ignoreQueryPrefix: true }).page || 0;
      this.bucket = this.props.fields.targetItemBucket.id;
      this.state = {
        currentPage: pageNum,

    };
    }

    /**
     * Kick off bound fetch data
     */
    componentDidMount() {
      const { router: {location} } = this.props;
      this._fetchingInfo = true;
      this.boundFetchData(qs.parse(location.search), 0,this.bucket);
    }


    /**
   * Updates state with new props
   * @param {object} nextProps Incoming props
   */
    componentDidUpdate(prevProps) {
      if (prevProps.router.location !== this.props.router.location) {

        let pageNum = qs.parse(this.props.router.location.search, { ignoreQueryPrefix: true }).page || 0;
        this.setState = ({
          currentPage: pageNum,

        });
        this.boundFetchData(qs.parse(this.props.router.location.search), pageNum, this.bucket);

      }
    }


    /**
     * Fetch the article data
     * @param { string } queryObj term used to conduct search for
     * @param { string } pagenum of new page
     * @param { string } pageSize number of items
     */
    fetchData(queryObj, pagenum, bucket) {
      let filters = [];
      const { filterKey, tagKey } = this.props;
      const  size  = this.props.sitecoreContext.route.fields.size.value || this.props.pageSize;
      const tabFilters = decode(queryObj[filterKey]);
      if (tabFilters && tabFilters[tagKey]) {
        filters = tabFilters[tagKey];
      }

      const { match } = this.props;

       this.pagenumber = pagenum || this.state.currentPage;
      this.props.dataActions.fetchArticles(filters, this.pagenumber, size, bucket);
    }

    renderContent(){
      const { articles } = this.props;
      if (typeof articles == 'undefined')
        return null;
      if(articles.length > 0){
        return(
          <div className="imc-vr--xxxlarge imc-landingpage__result-container  imc-section imc-section--full-width">
            <div
              className="imc-vr--xxlarge imc-landingpage__image-results imc-gallery imc-gallery--image-gallery imc-gallery--1-5">
              {this.renderCards()}
            </div>
            {this.renderPagination()}
        </div>
        )
      }
      return null;
    }
    renderMessage(){
      const { articles } = this.props;
      if (typeof articles == 'undefined')
        return null;
      if(articles.length == 0){
        return(
          <h3 class="imc-content">No Articles are matching your search. Let's try removing some Topic filters.</h3>
        )
      }
      return null;
    }
    renderCards() {
      const { articles } = this.props;
      if (typeof articles == 'undefined')
        return null;
      return articles.map((card, index) => {
        const props = {
          ...card
        };
        return (
          <ArticleCardComponent {...props} key={index} />
        );
      })
    }

    renderPagination(){
      const { count } = this.props;
      const  size  = this.props.sitecoreContext.route.fields.size.value || this.props.pageSize;

      if(count > size){
       const integerPart = parseInt(count/size)
       if(integerPart <(count/size)){
        this.totalPages = integerPart + 1;
       }else{
        this.totalPages = integerPart;
       }
      }else{
       this.totalPages = 1;
      }
      return (
        <Pagination
            totalPages={this.totalPages}
            paginationContainerId={`searchContainer`}
        />
      );
    }

    render() {
      return (
        <div className="imc-gallery imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge imc-searchresults">
          {this.renderMessage()}
          {this.renderContent()}
        </div>
      );
    }
  }
  /**
   * Maps state to props for connect
   * @param {object} state State object
   * @returns {{search: *}} State to props mapping
   */
  function mapStateToProps(state) {
    if (state.articlesDirectoryReducer)
      return {
        resultsUpToDate: state.articlesDirectoryReducer.resultsUpToDate,
        articles: state.articlesDirectoryReducer.articles,
        count: state.articlesDirectoryReducer.count,
        numResults: state.articlesDirectoryReducer.numResults,
        showLoading: state.articlesDirectoryReducer.showLoading,
        errCode: state.articlesDirectoryReducer.errCode,
      }
    else {
      return {
        resultsUpToDate: state.resultsUpToDate,
        articles: state.articles,
        count: state.count,
        numResults: state.numResults,
        showLoading: state.showLoading,
        errCode: state.errCode,
      };
    }
  }

  /**
  * Maps dispatch to props for connect
  * @param {function} dispatch Dispatcher
  * @returns {object} Action creators
  */
  function mapDispatchToProps(dispatch) {
    return {
      dataActions: bindActionCreators({ ...dataActions }, dispatch),
    };
  }


  ArticleList.propTypes = propTypes;
  ArticleList.defaultProps = defaultProps;


export default withSitecoreContext()(withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ArticleList)));



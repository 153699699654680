import React, { Component } from 'react';
import {  Text, isEditorActive, withSitecoreContext, Image, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import BradcrumbAPI from './Api/Breadcrumb.js';
import {withSitecoreRouter} from 'utils/withRouter';
import { getSitecoreApiHost, getSitecoreApiKey } from '../../AppGlobals';
import Link from '../Link';
class BreadcrumbsComponent extends Component {
  constructor(props) {
    super(props);
    //  TODO GET PAGE ID
    // alert(props.sitecoreContext.itemId);

    var itemId = props.sitecoreContext.itemId;

    var exhibitId = null;
    var lineId = null;
    var productId=null;
    //TODO: This is very wrong. We have different property structure for the same data depending on the page we are on.
    if(props.sitecoreContext.product)
    {
        productId = props.sitecoreContext.product.productId;
        exhibitId = props.sitecoreContext.product.exhibitId;
    }
    if(props.sitecoreContext.lineProduct)
    {
      lineId = props.sitecoreContext.lineProduct.lineId;
    }
    if(props.sitecoreContext.exhibitor)
    {
      exhibitId = props.sitecoreContext.exhibitor.id;
    }



    itemId = '{' + itemId.toUpperCase() + '}';
    // this.setState({pageId : '{'+itemId.toUpperCase()+'}'});
    this.state = { data: [], pageId: itemId.toUpperCase(), exibitId: exhibitId, lineId: lineId, productId:productId};
    //this.fetchData(itemId, exhibitId, lineId, productId);
  }

  componentDidMount()
  {
    var itemId = this.props.sitecoreContext.itemId;
    var exhibitId = null;
    var lineId = null;
    var productId=null;
    //TODO: This is very wrong. We have different property structure for the same data depending on the page we are on.
    if(this.props.sitecoreContext.product)
    {
        productId = this.props.sitecoreContext.product.productId;
        exhibitId = this.props.sitecoreContext.product.exhibitId;
    }
    if(this.props.sitecoreContext.lineProduct)
    {
      lineId = this.props.sitecoreContext.lineProduct.lineId;
    }
    if(this.props.sitecoreContext.exhibitor)
    {
      exhibitId = this.props.sitecoreContext.exhibitor.id;
    }

    itemId = '{' + itemId.toUpperCase() + '}';
    // this.setState({pageId : '{'+itemId.toUpperCase()+'}'});
    this.setState({ data: [], pageId: itemId.toUpperCase(), exhibitId: exhibitId, lineId: lineId, productId:productId});
    this.fetchData(itemId, exhibitId, lineId, productId);


  }

  fetchData(pageId, exhibitId, lineId, productId)
  {

    fetch(`${getSitecoreApiHost()}/imc-api/v1/navigation/breadcrumbs?pageId=${pageId}${!!exhibitId?`&exibitId=`+exhibitId:``}${!!lineId?`&lineId=`+lineId:``}${!!productId?`&productId=`+productId:``}&sc_apikey=${getSitecoreApiKey()}`)
      .then(response => response.json())
      .then(data => {
        this.setState({ data: data })
        //console.log(data)
      })
      .catch(err => console.error(this.props.url, err.toString()))
  }


  componentDidUpdate(prevProps)
  {

    if ((prevProps.sitecoreContext.itemId !== this.props.sitecoreContext.itemId)
    || (this.props.sitecoreContext.exhibitor && this.props.sitecoreContext.exhibitor.id !== this.state.exhibitId)
    || (this.props.sitecoreContext.lineProduct && this.props.sitecoreContext.lineProduct.lineId !== this.state.lineId)
    || (this.props.sitecoreContext.product && this.props.sitecoreContext.product.productId !== this.state.productId))
    {
      var itemId = this.props.sitecoreContext.itemId;
        var exhibitId = null;
        var lineId = null;
        var productId=null;
        //TODO: This is very wrong. We have different property structure for the same data depending on the page we are on.
        if(this.props.sitecoreContext.product)
        {
            productId = this.props.sitecoreContext.product.productId;
            exhibitId = this.props.sitecoreContext.product.exhibitId;
        }
        if(this.props.sitecoreContext.lineProduct)
        {
          lineId = this.props.sitecoreContext.lineProduct.lineId;
        }
        if(this.props.sitecoreContext.exhibitor)
        {
          exhibitId = this.props.sitecoreContext.exhibitor.id;
        }


        itemId = '{' + itemId.toUpperCase() + '}';
        // this.setState({pageId : '{'+itemId.toUpperCase()+'}'});
        this.setState({ data: [], pageId: itemId.toUpperCase(), exhibitId: exhibitId, lineId: lineId, productId:productId});
        this.fetchData(itemId, exhibitId, lineId, productId);
      }


  }

  /**
       * @method render
       * @description Renders the DOM element
       * @returns {*} Rendered component
       */
  render() {
    if (this.state.data.length === 0 && this.props.sitecoreContext.pageEditing)
      return (
        <section className="imc-section imc-breakpoint-display--hide-mobile imc-show-print imc-breadcrumb-wrapper">
          <div className="imc-gallery imc-gallery--65-35 breadcrumbs__round">
            <div className="imc-gallery__item">
              <ul className="imc-breadcrumb" data-xpath="breadcrumb.links">
                <li>
                  <Link href='/' className="imc-link imc-link--alt-darkred" data-xpath="breadcrumb.link">Home</Link>
                </li>
              </ul>
            </div>
          </div>
        </section>);

    return (
      <section className="imc-section imc-breakpoint-display--hide-mobile imc-show-print imc-breadcrumb-wrapper">
        {this.state.data.length !== 0 &&
          <div className="imc-gallery imc-gallery--65-35 breadcrumbs__round">
            <div>
              <ul className="imc-breadcrumb" data-xpath="breadcrumb.links">
                <li>
                  <Link href='/' className="imc-link imc-link--alt-darkred" data-xpath="breadcrumb.link">Home</Link>

                </li>
                {this.state.data.map((breadcrumb, i) => (

                  <li data-xpath="breadcrumb.active.link"  key={i}>
                    {this.state.pageId == breadcrumb.Id &&
                      <a data-xpath="breadcrumb.link">{breadcrumb.PageTitle}</a>
                    }
                    {this.state.pageId !== breadcrumb.Id && breadcrumb.IsActive &&
                      <Link href={breadcrumb.Path} className="imc-link imc-link--alt-darkred" data-xpath="breadcrumb.link">{breadcrumb.PageTitle}</Link>
                    }
                    { this.state.pageId !== breadcrumb.Id && !breadcrumb.IsActive &&
                      <a data-xpath="breadcrumb.link" className="">{breadcrumb.PageTitle}</a>
                    }
                  </li>

                ))}
              </ul>
            </div>
          </div>
        }
      </section>

    );
  }

};


export default withSitecoreRouter(withSitecoreContext()(BreadcrumbsComponent));

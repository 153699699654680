import React, { useEffect, useState } from "react";
import "../styles.css";

export const SuccessErrorMessage = ({ status }) => {
  const { message, isError, isSuccess, description = "" } = status || {};

  const [isHide, setHide] = useState(false);

  useEffect(() => {
    setHide(false);
    if (!status) {
      return;
    }

    const timeout = setTimeout(() => {
      setHide(true);
    }, 30000);

    return () => {
      clearTimeout(timeout);
    };
  }, [status]);

  return message && (isError || isSuccess) && !isHide ? (
    <div
      className={`${"imc-vr--large imc-success-error-container"} ${isError &&
        "imc-error-block"}`}
    >
      <div
        className={`imc-content ${
          isSuccess ? "imc-content--success" : "imc-content--error"
        }`}
      >
        {" "+ message}
      </div>
      {description && <div className={`imc-content`}>{description}</div>}
    </div>
  ) : null;
};

import React, { Component } from 'react'

export default class index extends Component {
    render() {
        if (this.props.text)
            return (
                <div className={`alert-box ${ this.props.extraClass? this.props.extraClass:''}`}>
                    {this.props.text}
                </div>
            )
        return null;
    }
}

/**
 * Module for creating a redux store for maintaining application state
 *
 * This module follows the redux methodology of using one store per redux
 * application and enforces a singleton pattern when instantiating an appState
 * module so that each app will receive the same instance when creating their stores
 */

// Dependencies
import configureStore from './store/configureStore';
import rootReducer from './reducers/rootReducer';
import ReducerRegistry from './reducers/ReducerRegistry';
import configureReducers from './reducers/configureReducers';

/**
 * @property
 * Stores the instance created from
 */
let instance;

/**
 * Class representing an AppState containing a redux store and reducerRegistry for dynamically
 * registering reducers to store
 *
 * @returns {Object} singleton instance of AppState
 */
class AppState {
    /**
     * Creates the appstate instance that will get exported to components with providers that need it
     * @param {object=} initialState Initial state if provided
     * @returns {AppState|*} AppState instance
     */
    constructor() {
        if (instance) {
            /* if (initialState) {
                AppState.addInitialState(initialState);
            } */
            return instance;
        }

        // set the instance to instantiated AppState
        instance = this;

        // set initial root reducer and expose ReducerRegistry for dynamically adding reducers
        this.reducerRegistry = new ReducerRegistry({ root: rootReducer });

        // configure redux store with initial root reducers
        this.store = configureStore(configureReducers(this.reducerRegistry.getReducers()));

        // Reconfigure the store's reducer when the reducer registry is changed - we
        // depend on this for loading reducers via code splitting and for hot
        // reloading reducer modules.
        this.reducerRegistry.setChangeListener((reducers) => {
            this.store.replaceReducer(configureReducers(reducers));
        });
    }

    /**
     * Adds the initial state to the store
     * @param {object} initialState Initial state object to add to the store. This is dispatched in.
     */
    static addInitialState(initialState) {
        const action = Object.assign({}, initialState, {
            type: 'INIT_STATE',
        });
        instance.store.dispatch(action);
    }
}

/**
 * @method createAppState
 * @description Creates an instance of AppState
 * @param {object=} initialState Apply an initial state for the store if provided
 *
 * @returns {AppState} Appstate instance
 */
function createAppState(initialState) {
    return new AppState(initialState);
}

/**
 * Exports factory method for creating an AppState
 */
export default createAppState;

export const initialState = {
    contactAltId: '123',
    channelID: 1,
    market: {
        marketId: 156,
        channelName: "Las Vegas Market",
        channelShortName: "lvm",
        name: "2024 Summer Las Vegas Market",
        season: "Summer",
        year: 2024,
        displayName: "Las Vegas Market Summer 2024",
        eventCode: "LVMS0824",
        from: "2024-07-28T00:00:00Z",
        to: "2024-08-01T00:00:00Z",
        categories: [
            "Furniture",
            "Home Décor",
            "Gift"
        ]
    },
    activitySummary: {
        showroomsCount: 0,
        showroomVisitsCount: 0,
        photosCount: 0,
    },
    showrooms: [],
    isRegistered: false,
    loading: [],
    loaded: false,
    errors: null,
    isFetching: false,
}

// export const testingState = {
//     contactAltId: '123',
//     channelID: 1,
//     market: {
//         name: 'ANDMORE MARKET',
//         eventCode: 1,
//         marketId: 12,
//         from: '2024-06-13',
//         to: '2024-06-17',
//     },
//     activitySummary: {
//         showroomsCount: 14,
//         photosCount: 23,
//     },
//     showrooms: [
//         {
//             showroomName: `Zuo Modern Contemporary Inc.`,
//             shownBy: null,
//             lastVisited: null,
//             photosCount: 0,
//             note: null,
//             exhibitorCoreBrandId: `66500`,
//             id: null,
//         },
//         {
//             showroomName: `Marissas Mailboxes`,
//             shownBy: `Tripp's Tents`,
//             lastVisited: '2024-07-22',
//             photosCount: 23,
//             note: true,
//             exhibitorCoreBrandId: `83906`,
//             id: 'e6e47bb5-c652-8793-d958-10ea0e83ce51',
//         },
//         {
//             showroomName: `Ivystone`,
//             shownBy: null,
//             lastVisited: '2024-07-21',
//             photosCount: 13,
//             note: null,
//             exhibitorCoreBrandId: `65958`,
//             id: null,
//         },
//         {
//             showroomName: `NEST, The New Era Sales Team`,
//             shownBy: null,
//             lastVisited: '1988-03-09',
//             photosCount: 213,
//             note: null,
//             exhibitorCoreBrandId: `66124`,
//             id: null,
//         },
//         {
//             showroomName: `Tripp's Tents`,
//             shownBy: null,
//             lastVisited: null,
//             photosCount: 0,
//             note: null,
//             exhibitorCoreBrandId: `83906`,
//             id: null,
//         },
//     ],
//     loading: [],
// }
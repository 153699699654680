import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDictionaryValue } from 'utils/dictionary';

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{basename: *}}
 */
const propTypes = {
    content: PropTypes.string,
    showMore: PropTypes.string,
    seeLess: PropTypes.string,
};

/**
 * @property defaultProps
 * @type {{basename: string}}
 */
const defaultProps = {
    content: '',
    showMore: '... show more',
    seeLess: 'show less',
};

/**
 * @property CHARACTER_LIMIT
 * @type {number}
 */
const CHARACTER_LIMIT = 500;

/**
 * ReadMore component class
 * @constructor
 */
class ReadMore extends Component {

    /**
     * Initializes the history
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            showExpand: false,
            textArray: [],
            showButton: false,
        };
        this.showMore = this.showMore.bind(this);
    }

    /**
     * On component did mount, hide the characters over the limit
     */
    // componentDidMount() {

    //     this.setState({
    //         textArray,
    //         showButton: runningLimit > CHARACTER_LIMIT,
    //     });
    // }
    /**
     * Show the hidden text
     */
    showMore() {
        this.setState({
            showExpand: !this.state.showExpand,
        });
    }

    /**
     * Renders the Search wrapper/provider and connected react-router
     * @returns {XML} Search JSX wrapper
     */
    render() {
        const { showExpand } = this.state;
        const { seeMore, seeLess } = this.props;
        const { content } = this.props;
        const textArray = [];
        const splitContent = content.split("\n");
        let runningLimit = 0;
        let innerText = '';
        for (let i = 0; i < splitContent.length; i++) {
            innerText = splitContent[i];
            if (runningLimit < CHARACTER_LIMIT && runningLimit + innerText.length > CHARACTER_LIMIT) {
                let trimmedString = innerText.substr(0, CHARACTER_LIMIT - runningLimit);
                const restString =
                    innerText.substr(Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')), innerText.length);
                trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
                textArray.push({
                    beforeHide: trimmedString,
                    afterHide: restString,
                });
            } else if (runningLimit + innerText.length > CHARACTER_LIMIT) {
                textArray.push({
                    beforeHide: '',
                    afterHide: innerText,
                });
            } else {
                textArray.push({
                    beforeHide: innerText,
                    afterHide: '',
                });
            }
            runningLimit += innerText.length;
        }
        let showButton = runningLimit > CHARACTER_LIMIT;

        return (
            <div
                className={!showExpand ?
                    'imc-exhibitors__collapse-content' :
                    'imc-exhibitors__collapse-content imc-exhibitors__collapse-content--show'}
            >
                {textArray.map((obj, index) => ((obj.beforeHide && !showExpand) || showExpand) && (
                    <p
                        key={index}
                        className="imc-type--body-1"
                        data-xpath="exhibitorDetail.description"
                    >
                        {obj.beforeHide}{showExpand ? obj.afterHide : ''}
                    </p>
                ))}
                {showButton &&
                    <button
                        className="imc-exhibitors__read-more-button imc-type--body-1-link"
                        onClick={this.showMore}
                    >
                        {showExpand ? getDictionaryValue('showLess', 'Show less')  : getDictionaryValue('showMore', '...Show more') }
                    </button>}
            </div>
        );
    }
}

ReadMore.propTypes = propTypes;
ReadMore.defaultProps = defaultProps;

export default ReadMore;

// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link'; 

/**
 * Component for displaying a navigation mobile item (tier 3)
 * @param {number} currentTier2 Currently selected tier 2 item
 * @param {string} tier3Content Tier 3 content
 * @constructor
 */
export default class NavigationMobileTier3 extends Component {

    /**
     * @method render
     * @description Renders the Mobile Navigation Tier 3 DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { tier3Content } = this.props;
        console.log("TIER 3 CONTENT", tier3Content);
        return (
            tier3Content.map((item3tier, key) =>
                <Link field={item3tier.link}
                    onKeyDown={this.navigateTier3}
                    key={key}
                    className="imc-link imc-link--invert imc-navigation-mobile__tier3Link"
                    onClick={() => this.props.closeMenu()}
                ></Link>
            ));
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{currentTier2: *, tier3Content: shim}}
 */
NavigationMobileTier3.propTypes = {
    windowWidth: PropTypes.number, // Current window width
    translateX: PropTypes.string, // Current translateX (for focus detection)
};

/**
 * @property defaultProps
 * @type {{tier3Content: string}}
 */
NavigationMobileTier3.defaultProps = {
    tier3Content: [],
    windowWidth: 768,
    translateX: '0px',
};

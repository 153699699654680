import {SET_VIEW_MODE, SET_GROUPED} from './actionTypes';
//import PubSubService from "modules/lists/Services/pubsub";

export function setViewMode(mode) {

    //const pubsub = PubSubService.getInstance();
    //pubsub.emitViewModeChanged(mode);

    return (dispatch) => {
        dispatch({
            type: SET_VIEW_MODE,
            value: mode,
        });
               
    };
}

export function setGroupedByExhibitor(value) {

   // const pubsub = PubSubService.getInstance();
   // pubsub.emitIsGroupedChanged(value);

    return (dispatch) => {
        dispatch({
            type: SET_GROUPED,
            isGrouped: value,
        });
               
    };
}



import { serialize } from 'utils/serialize';
//import { getAPIEndpoint } from 'utils/getapiendpoint';
import { filterquerymodifier } from 'utils/filterquerymodifier';
import { getSitecoreApiHost, getSitecoreApiKey } from '../../AppGlobals';
/**
 * API key name
 * @type {string}
 */
const searchArticlesEndpointKey = 'apiArticlesResourceUrl';

/**
 * Fallback endpoint if the key does not exist
 * @type {string}
 */
const endpointFallback = '/site/api/articles';

/**
 * Videos API
 */
export default class SearchArticlesAPI {
    /**
     * Constructor
     */
    constructor() {
        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         */
        this._apiKey = searchArticlesEndpointKey;
        this.getArticles = this.getArticles.bind(this);
    }

    /**
     * Gets search results for images
     * @param {array} tags array
     * @param {string} pagenum of current page
     * @returns {Promise.<T>} Promise object that returns the SearchArticlesAPI results
     */
    getArticles(tags = [], pagenum = 0, pageSize, bucket) {
        let totalSkip = 0;
        if(pagenum > 1){
            totalSkip = (pagenum - 1) * pageSize;
        }
        const params = {
            take: pageSize,
            skip: totalSkip,
        };

        if (tags.length > 0) {
            params.tags = tags;
        }
        let querystring = serialize(params);
        querystring = filterquerymodifier(querystring);
        
        return (
            fetch(`${getSitecoreApiHost()}/imc-api/v1/cards/articles?sc_apikey=${getSitecoreApiKey()}&${querystring}&bucket=`+bucket)
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }
}

// Replaces broken image tag with the noImage backup
export function replaceBrokenImageLink(event) {
  const noImg = process.env.PUBLIC_URL + '/assets/images/175-175.png';
  event.target.src = noImg;
}

// Divides array and returns array of arrays
export function divideArrays(array = [], parts = 4) {
  if (array.length === 0) return;

  const arrCopy = array.slice();
  return new Array(Math.ceil(arrCopy.length / parts))
    .fill()
    .map(_ => arrCopy.splice(0, parts))
}

export function isMarketCtaShouldBeShown(propsWithContext) {
  if (propsWithContext.sitecoreContext){
    return !(propsWithContext.sitecoreContext?.jsssite?.hideMarketCta);
  }
  return true;
}

export function showMarketPlan(propsWithContext) {
  if (propsWithContext?.sitecoreContext){
    return !(propsWithContext.sitecoreContext?.jsssite?.hideMarketPlan);
  }
  return true;
}

export function segmentId() {
  if (window?.segmentId){
    return window.segmentId;
  }
  return 'q4i6gr7D0I3pKzUmNewGRFELlWAZauaO';
}

// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withSitecoreRouter} from 'utils/withRouter';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import qs from 'query-string';
import { encode, decode } from 'utils/querystring';
import { mobileScreenUtility } from 'utils/mobileScreenUtility';
import { replaceparams } from 'utils/replaceparams';
import { resetPagination } from 'utils/resetpagination';
import ImcDataLayer from 'utils/datalayer';

/**
 * clear page from the basepath
 * and replace url params with values
 * @param {object} match url match object
 * @returns {string} path with filled in param values
 */
function cleanedBasepath(match) {
    const updatedParams = {
        ...match.params,
        page: '',
    };
    return replaceparams(match.path, updatedParams);
}

/**
 * Component for displaying a Filter module and maintaining its state
 */
class Pagination extends Component {

    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        const { router } = props;
        const location = router.location;
        let basepath = location.pathname;
        const pageNum = qs.parse(location.search, { ignoreQueryPrefix: true }).page || 1;

        if (basepath === '') {
            basepath = '/';
        }

        this.state = {
            basepath,
            currentPage: pageNum,
            isMobile: mobileScreenUtility(),

        };

        this.renderPagination = this.renderPagination.bind(this);
        this.renderPaginationMobile = this.renderPaginationMobile.bind(this);
        this.onResize = this.onResize.bind(this);
        this.getPageNumber = this.getPageNumber.bind(this);
    }


    /**
     * Initialize resize event listener window
     */
    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        const cachedWidth = window.innerWidth;
        this.setState((state, props) => ({
            cachedWidth,
            ...state
        }));
        this._fetchingInfo = true;
    }

    /**
     * Scroll to top of specified container on page update
     * @param {object} prevProps incoming props
     */
    componentDidUpdate(prevProps) {
        const { match, paginationContainerId } = this.props;
        if (paginationContainerId) {
            const prevPage = this.getPageNumber(prevProps.router.location);
            const nextPage = this.getPageNumber(this.props.router.location);

            if (nextPage !== prevPage) {
                const container = document.getElementById(paginationContainerId);
                const headerHeight = (document.getElementsByClassName('imc-header__container-wrapper')[0]?.offsetHeight || 0) + 50;
                if (container && container.offsetTop) {
                    window.scrollTo(0, container.offsetTop - headerHeight);
                } else {
                    // throw new Error('Pagination Container Element provided to Pagination does not exist');
                }
            }
        } else {
            // window.scrollTo(0, this.paginationElem.parentElement.offsetTop);
        }
        if (prevProps.router.location !== this.props.router.location) {
            const pageNum = this.getPageNumber(this.props.router.location);
            this.setState({ currentPage: pageNum });
        }
    
        this.onResize();
    }

    /**
     * Remove event Listeners on window
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    getPageNumber = (location) => {
        return qs.parse(location.search, { ignoreQueryPrefix: true }).page || 1
    }

    /**
     * On Resize function
     */
    onResize() {
        const newWidth = window.innerWidth;
        if (newWidth !== this.state.cachedWidth) {
            let resizeTimer;
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                this.setState({
                    isMobile: mobileScreenUtility(),
                });
            }, 500);
        }
    }

    /**
     * Renders the pagination items
     * @returns {*} Rendered Pagination
     */
    renderPagination() {
        const { totalPages } = this.props;

        if (isNaN(totalPages) || totalPages < 2) {
            return null;
        }

        const currentPage = parseInt(this.state.currentPage, 10) || 1;
        const startNum = currentPage - 4 > 0 ? currentPage - 4 : 1;
        let endNum = currentPage + 5 < totalPages ? currentPage + 5 : totalPages;
        if (endNum < 10 && totalPages > endNum) {
            endNum = (totalPages > 10) ? 10 : totalPages;
        }
        const pageNumbers = [];
        for (let i = startNum; i <= endNum; i++) {
            if (currentPage === i) {
                if (endNum !== 1) {
                    pageNumbers.push(
                        <div
                            key={i}
                            className="imc-inline-divide__item imc-breakpoint-display--hide-mobile"
                            data-xpath="pagination.linkActive"
                        >
                            <strong>{i}</strong>
                        </div>,
                    );
                }
            } else {

                pageNumbers.push(
                    <div
                        key={i}
                        className="imc-inline-divide__item  imc-breakpoint-display--hide-mobile"
                        data-xpath="pagination.link"
                    >
                        <a className="imc-link " onClick={() => this.updateUrl(i)}>{i}</a>

                    </div>,
                );
            }
        }
        return pageNumbers;
    }

    /**
     * Renders the pagination items Mobile
     * @returns {*} Rendered Pagination
     */
    renderPaginationMobile() {
        const { match, totalPages, labelsPagination } = this.props;
        const currentPage = parseInt(this.state.currentPage, 10) || 1;

        return (
            <div
                className="imc-inline-divide__item imc-breakpoint-display--hide-desktop"
                data-xpath="pagination.linkActive"
                style={{ width: 'fit-content' }}
            >
                <div className="imc-content imc-content--alpha imc-content--invert pagination-button-color"  >
                    Page {currentPage} {(totalPages > 1) ? ` of ${totalPages}` : ''}
                </div>
            </div>
        );
    }



    /**
     * Renders the previous link if necessary
     * @returns {*} JSX for previous link
     */
    renderPrevious() {
        const { match, nonSearch} = this.props;
        const currentPage = parseInt(this.state.currentPage, 10) || 1;

        if (currentPage !== 1) {
            return (
                <div className="imc-inline-divide__item">
                    <a className={
                        `imc-link--caret-before
                            ${!this.state.isMobile ?
                            'imc-link imc-content--kilo ' :
                            'imc-link--caret-before-kilo'
                        }`
                    } onClick={() => this.updateUrl(currentPage - 1)} />{ }

                </div>
            );
        }
        return null;
    }

    /**
     * Renders the next link if necessary
     * @returns {*} JSX for previous link
     */
    renderNext() {
        const { match, totalPages, nonSearch, router } = this.props;
        let { basepath } = this.state;
        if (nonSearch) {
            // eslint-disable-next-line max-len
            basepath = cleanedBasepath(match);
        }
        const currentPage = parseInt(this.state.currentPage, 10) || 1;

        if (totalPages > 1 && currentPage < totalPages) {
            return (
                <div className="imc-inline-divide__item">
                    <a className={
                        `imc-link--caret-after 
                            ${!this.state.isMobile ?
                            'imc-link imc-content--kilo ' :
                            'imc-link--caret-after-kilo'
                        }`
                    }
                        onClick={() => this.updateUrl(currentPage + 1)} />{ }

                </div>
            );
        } else {
            return (
                <div className="imc-inline-divide__item">


                </div>
            )
        }
        return null;
    }

    updateUrl(page) {
        const { match, router, router : {location}, sitecoreContext } = this.props;

        const queryObject = decode(location.search);
        delete queryObject['page'];
        queryObject.page = page;
        const newHistory = resetPagination(match, location);
        const search = encode(queryObject, false, false);
        newHistory.search = search;
        newHistory.pathname = location.pathname;
        newHistory.page = page;

        const pageType = !!window ? window.pageType : this.props.router.location.pathname;
        ImcDataLayer.pushInteractionEvent("page interaction", "view next page", pageType);

        router.navigate(newHistory);

    }
    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        if (this.props.totalPages !== 0) {
            return (
                <div
                    className={'imc-pagination imc-content imc-content--center-mobile imc-content--right-desktop'}
                    data-xpath="pagination.container"
                    ref={(node) => { this.paginationElem = node; }}
                >
                    <div className="imc-inline-divide imc-inline-divide--noline">
                        {this.renderPrevious()}
                        {this.renderPagination()}{this.renderPaginationMobile()}
                        {this.renderNext()}
                    </div>
                </div>
            );
        }
        return null;
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{currentPage: *, totalPages: *, pageUpdated: *}}
 */
Pagination.propTypes = {
    router: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    totalPages: PropTypes.number,
    labelsPagination: PropTypes.object,
    nonSearch: PropTypes.bool,
    paginationContainerId: PropTypes.string,
};

/**
 * @property defaultProps
 * @type {{currentPage: number, totalPages: number, pageUpdated: null}}
 */
Pagination.defaultProps = {
    totalPages: 1,
    labelsPagination: {
        labelPrevious: 'Previous',
        labelNext: 'Next',
        labelPageMobile: 'Page',
        labelPageMobileOf: 'of',
    },
    nonSearch: false,
    match: {
        path: '',
        params: {},
    },
    paginationContainerId: null,
};



export { Pagination as UnWrappedPagination };
export default withSitecoreContext()(withSitecoreRouter((Pagination)));

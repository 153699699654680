/**
 * Returns string and limits length based on maxchars
 * @param {string} str String to check
 * @param {number} maxchars Number of characters to limit to
 * @returns {string} String returned up to the maximum characters
 */
export function maxlength(str, maxchars) {
    if (maxchars > 0) {
        return (str.length <= maxchars) ? str : str.substring(0, maxchars);
    }
    return str;
}

import {
    SHOW_LOADING,
    SET_PROFILE_DATA,
    CHANGE_COMPANY_NAME,
    RESET_PASSWORD,
    GET_ALL_PRODUCT_INTEREST,
    UPDATE_PRODUCT_INTERESTS,
    GET_ALL_ATTENDEE_TYPES,
    UPDATE_ATTENDEE_TYPE,
    UPDATE_NAME,
    UPDATE_ZIP_CODE,
    GET_ALL_COMPANY_TYPES,
    UPDATE_COMPANY_TYPES,
    UPDATE_COMPANY_NAME,
    ADD_NEW_EMAIL,
    DELETE_EMAIL,
    SET_EMAIL_AS_DEFAULT,
    GET_NEXT_MARKET_REGISTER_NOW_BUTTON,
    GET_FUTURE_MARKET_LINK_REGISTRATION_BUTTON,
    UPDATE_FAIL,
    LINK_MY_MARKET_REGISTRATION,
    ASSOCIATE_EXHIBITOR,
    DELETE_ACCOUNT,
} from './actionTypes';
import { DATA } from "./mockData";

import UserUtility from 'utils/userutility';
import UserProfileAPI from 'api/profile';
import ImcDataLayer from 'utils/datalayer';

const _api = new UserProfileAPI();

export function fetchMockProfile() {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        window.setTimeout(() => {
            dispatch({
                type: SET_PROFILE_DATA,
                profile: DATA,
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        }, 1000);
    };
}

export function fetchProfile() {
    const userInfo = UserUtility.getUserData();
    const channelInfo = UserUtility.getChannelData();

    let accountId = userInfo.accountId;
    let channel = channelInfo.name;
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.getUserProfile(accountId, channel).then((response) => {
            dispatch({
                type: SET_PROFILE_DATA,
                profile: response
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });

    }
}

export function setCompanyName(companyName) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.changeCompanyName(accountId, companyName).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "save Company Name");
            dispatch({
                type: CHANGE_COMPANY_NAME,
                updateCompanyNameResponse: response
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function resetPassword() {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.resetPassword(accountId).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Reset Password");
            if (response.ok) {
                dispatch({
                    type: RESET_PASSWORD,
                });
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function getAllProductInterest() {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.getAllProductInterest().then((response) => {
            dispatch({
                type: GET_ALL_PRODUCT_INTEREST,
                productInterest: response
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function updateProductInterests(categories) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.updateProductInterests(accountId, categories).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Update Products of Interest");
            if (response.ok) {
                dispatch({
                    type: UPDATE_PRODUCT_INTERESTS,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function getAllAttendeeTypes() {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.getAllAttendeeTypes().then((response) => {
            dispatch({
                type: GET_ALL_ATTENDEE_TYPES,
                attendeeTypes: response
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function updateAttendeeType(attendeeTypeKey, attendeeTypeValue) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.updateAttendeeType(accountId, attendeeTypeKey, attendeeTypeValue).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Update Atendee Type");
            if (response.ok) {
                dispatch({
                    type: UPDATE_ATTENDEE_TYPE,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }

            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function getAllCompanyTypes() {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.getAllCompanyTypes().then((response) => {
            dispatch({
                type: GET_ALL_COMPANY_TYPES,
                companyTypes: response
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function updateCompanyTypes(companyTypeKey, companyTypeValue) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.updateCompanyTypes(accountId, companyTypeKey, companyTypeValue).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Update Company Type");
            if (response.ok) {
                dispatch({
                    type: UPDATE_COMPANY_TYPES,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function updateZipCode(zipCode) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.updateZipCode(accountId, zipCode).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Update Zip");
            if (response.ok) {
                dispatch({
                    type: UPDATE_ZIP_CODE,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function updateCompanyName(companyName) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });


        return _api.updateCompanyName(accountId, companyName).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Update Company Name");
            if (response.ok) {
                dispatch({
                    type: UPDATE_COMPANY_NAME,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function addNewEmail(email) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.addNewEmail(accountId, email).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Add Email");
            if (response.ok) {
                dispatch({
                    type: ADD_NEW_EMAIL,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function deleteEmail(email) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.deleteEmail(accountId, email).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Delete Email");
            if (response.ok) {
                dispatch({
                    type: DELETE_EMAIL,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function setEmailAsDefault(email) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.setEmailAsDefault(accountId, email).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Set Default Email");
            if (response.ok) {
                dispatch({
                    type: SET_EMAIL_AS_DEFAULT,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function getNextMarketRegisterNowButton() {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.getNextMarketRegisterNowButton().then((response) => {
            dispatch({
                type: GET_NEXT_MARKET_REGISTER_NOW_BUTTON,
                nextMarketRegistration: response
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function getFutureMarketLinkRegistrationButton() {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.getFutureMarketLinkRegistrationButton().then((response) => {
            dispatch({
                type: GET_FUTURE_MARKET_LINK_REGISTRATION_BUTTON,
                futureMarkets: response
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function linkMyMarketEmail(email, marketId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.linkMyMarketEmail(email, marketId).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Link my Market Email");
            if (response.ok) {
                dispatch({
                    type: LINK_MY_MARKET_REGISTRATION,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function associateExhibitor(exhibitorTitle) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.associateExhibitor(exhibitorTitle).then((response) => {
            ImcDataLayer.pushMPInteraction("settings", "Associate Exhibitor");
            if (response.ok) {
                dispatch({
                    type: ASSOCIATE_EXHIBITOR,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function updateName(firstName, lastName) {
    const userInfo = UserUtility.getUserData();
    let accountId = userInfo.accountId;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.updateName(accountId, firstName, lastName).then((response) => {
            if (response.ok) {
                dispatch({
                    type: UPDATE_NAME,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}

export function deleteAccount() {
    const userInfo = UserUtility.getUserData();
    const userProfile = window.userProfile;
    const accountId = userInfo.accountId;
    const email = userProfile.preferredEmail;
    const name = `${userProfile.firstName} ${userProfile.lastName}`;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _api.deleteAccount(accountId, email, name).then((response) => {
            if (response.ok) {
                dispatch({
                    type: DELETE_ACCOUNT,
                });
                this.fetchProfile();
            } else {
                dispatch({
                    type: UPDATE_FAIL,
                });
            }
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    }
}
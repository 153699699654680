import React from 'react';
import { isEditorActive, Image, Link} from '@sitecore-jss/sitecore-jss-react';
import FloorPlanComponent from "../Floor-Plan-Component";

const SingleLink = ({ fields }) => {
  if (!fields) {
    if (isEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    }
    return (
      
      <FloorPlanComponent></FloorPlanComponent>
    );
  }else{
    fields.icon.value.src = fields.icon.value.src.replace('/-/media', '/-/jssmedia').replace("/sitecore/shell","");
  }
  let hasMobileCookie = false;
  if (typeof document !=="undefined"){
      hasMobileCookie = decodeURIComponent(document.cookie).includes("ImcMobileApp");
  }
  return (
   
    <div className="imc-section--margin-xsmall">
     {!hasMobileCookie && <a href={fields.link.value.href} target="_blank">
        <Image className="imc-content-breaker" field={fields.icon.value} />
      </a>}
      {hasMobileCookie && <a href={fields.link.value.href}>
        <Image className="imc-content-breaker" field={fields.icon.value} />
      </a>}
    </div>
  );
}
export default SingleLink;
// Library dependencies
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component for rendering a Submit button and
 * setting a callback for when the component is clicked
 * @returns {Element} Rendered submit button
 */
const SubmitButton = ({ labelSubmit, submitClick, additionalClass, isDisabled, xpath, baseButtonClass }) =>
    (
        <input
            type="submit"
            className={`${baseButtonClass} ${additionalClass}`}
            value={labelSubmit}
            onClick={submitClick}
            disabled={isDisabled}
            data-xpath={xpath}
            onKeyDown={e => submitClick(e)}
        />
    );

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{submitClick: (shim|boolean|*), labelSubmit: *}}
 */
SubmitButton.propTypes = {
    submitClick: PropTypes.func, // Callback handler to be called when clicking the submit button
    labelSubmit: PropTypes.string, // Label for the submit button
    additionalClass: PropTypes.string, // Additional class to apply to the button
    isDisabled: PropTypes.bool, // for disabling the buttonxs
    baseButtonClass: PropTypes.string,
    xpath: PropTypes.string,
};

/**
 * @property defaultProps
 * @type {{labelSubmit: string}}
 */
SubmitButton.defaultProps = {
    submitClick: () => {},
    labelSubmit: 'Submit',
    additionalClass: '',
    isDisabled: false,
    xpath: null,
    baseButtonClass: 'imc-button'
};

// Export the react component
export default SubmitButton;

import React, { Component } from 'react'

export default class RadioField extends Component {
     /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            value: props.fieldData.field.model.value,
            // errorEmpty: props.errorEmpty,
        };
        this.handleOnChange = this.handleOnChange.bind(this);
        this.renderGroupList = this.renderGroupList.bind(this);
    }

    
    /**
     * Renders the radio in group in the RadioField
     * @returns {*} RadioField
     */
    renderGroupList(items) {
        const { fieldData } = this.props;
        const field = fieldData.field.model;
        const valueField = fieldData.field.valueField;
        return items.map((item, key) => {
            return (
                <div className={'imc-formfield--radio-list-item imc-content--inline-block'} key={key}>
                    <input
                        type="radio"
                        className="imc-radio"
                        name={field.name}
                        id={'radio-'+key}
                        value={item.value}
                        checked={`${this.state.value === item.value ? 'checked' : ''}`}
                        onChange={(e) => this.handleOnChange(fieldData.field, e.target.value, fieldData.onChange)}
                        tabIndex="0"
                    />
                    <label
                        htmlFor={'radio-'+key}
                        className="imc-radio--label"
                    >
                        {item.text}
                    </label>
                </div>
            );
        });
    }

    /**
     * Renders data after mounted
     */
    componentDidMount() {
        const { fieldData } = this.props;
        const field = fieldData.field.model;
        const radioItems = field.items;
        radioItems.forEach(item => {
            if (item.selected === true) {
                this.setState({
                    value: item.value
                })
            }
        });
    }

    handleOnChange(field, fieldValue, callback) {
        var valid = true;
        var errorMessages = [];
        // custom client validation logic here
        if (field.model.required && !fieldValue) {
            valid = false;
            errorMessages.push(field.model.title + " is required");
        }
        this.setState({
            value: fieldValue,
        });
        callback(field.valueField.name, fieldValue, valid, errorMessages);
    }

    render() {
        const { fieldData } = this.props;
        const field = fieldData.field.model;
        const radioItems = field.items;
        const valueField = fieldData.field.valueField;
        return (
            <div className={'imc-vr--xlarge'}>
                <h3 className={'imc-heading imc-heading--gamma imc-vr--large'}>
                    {field.title}
                    <span className="imc-content--alt-darkred">
                        {field.required ? '*' : ''}
                    </span>
                </h3>
                <div className={'imc-formfield imc-radiofield imc-content'}>
                    {this.renderGroupList(radioItems)}
                </div>
            </div>
        )
    }
}

import * as types from '../actions/actionTypes';
import { InitialState } from './initialState';

/**
 * Reducer for form actions
 * @param {object} state State of the search
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function formReducer(state = InitialState.form, action) {
    switch (action.type) {
    case types.SUBMIT_SUCCESS:
        return Object.assign({}, state, action.form);

    case types.SUBMIT_FAIL:
        return Object.assign({}, state, InitialState.form, action.form);

    default:
        return state;
    }
}


import {
    getSitecoreApiHost,
    getSitecoreApiKey
} from '../AppGlobals';

const APIKEY_PLACEHOLDER = "[SC_APIKEY]";
const TOOLTIP_ID_PLACEHOLDER = "[TOOLTIP_ID]";
const GET_TOOLTIP_DATA_API = `/imc-api/v1/tooltip/get?sc_apikey=${APIKEY_PLACEHOLDER}&tooltipId=${TOOLTIP_ID_PLACEHOLDER}`;

export default class TooltipAPI {
    /**
     * Constructor
     */
    constructor() {
        /**
         * Stores the service URL for future fetches
         * @type {string}
         * @private
         * 
         */
        this.getTooltip = this.getTooltip.bind(this);
    }
    
    getTooltip(tooltipId) {
        let endpointWithParams = GET_TOOLTIP_DATA_API.replace(APIKEY_PLACEHOLDER, getSitecoreApiKey()).replace(TOOLTIP_ID_PLACEHOLDER,tooltipId);
        return (
            
            fetch(`${getSitecoreApiHost()}${endpointWithParams}`,
                    {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                        }
                    })
                    .then(response => response.json())
                    .catch((err) => {
                        // todo: Error Handling
                        console.log('handle err here', err);
                        return {};
                    })
        );
    }
}

import React from 'react';
import {isEditorActive, withSitecoreContext, Text} from '@sitecore-jss/sitecore-jss-react';
import {withSitecoreRouter} from 'utils/withRouter';
import ExhibitorApi from '../../api/exhibitor';
import ExhibitorAd from './components/exhibitorad.jsx';
import qs from 'query-string';
import {SEARCH_TYPE_EXHIBITOR, SEARCH_TYPE_PRODUCT, getFilterKeyFromPath} from 'utils/search'

class ExhibitorAdComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        const {fields} = this.props;
        //If there is a querystring, we have a search. This component should hide.
        this.updateVisibilityState(this.props);

        if (fields && !!fields.data && fields.data.model) {
            //If we got row, we need to grab each one of the exhibitors in there to fetch details and pass down to the components.
            var exhibitorIds = [];
            fields.data.model.rows.forEach((row, index) => {
                row.exhibitors.forEach((exhibitor, index) => {
                    if (exhibitor?.featureExhibitorsId?.data?.value) {
                        exhibitorIds.push(exhibitor.featureExhibitorsId.data.value);
                    }
                });
            })
            if (exhibitorIds.length > 0) this.fetchData(exhibitorIds);
        }
    }

    updateVisibilityState(props) {
        const qsParsed = qs.parse(props.router.location.search, {ignoreQueryPrefix: true});
        const query = qsParsed.q;
        const filterType = getFilterKeyFromPath(props.router.location.search) || this.props.router.location.pathname.split('/').pop();
        const filters = qsParsed[filterType] ? qs.parse(qsParsed[filterType]) : null;

        const visible = this.checkIfShouldShow(query, filters);
        this.setState({'visible': visible});
    }

    componentWillReceiveProps(nextProps) {
        this.updateVisibilityState(nextProps);
    }

    checkIfShouldShow(query, filters) {
        return ((typeof query === 'undefined') || query === null || query ==="")
            && ((typeof filters === 'undefined') || filters === null || Object.keys(filters).length === 0);
    }

    componentDidUpdate(lastProps) {
        if (lastProps.router.location !== this.props.router.location) {
            this.updateVisibilityState(this.props);
        }
    }

    fetchData(exhibitorIds) {
        const exhibitorApi = new ExhibitorApi();
        const pageId = this.props.sitecoreContext.route.itemId;
        exhibitorApi.getExhibitorDetail(exhibitorIds, pageId).then((response) => {
            this.setState({
                exhibitorData: response.data
            });
        })
    }

    render() {
        const {fields, rendering} = this.props;
        if (isEditorActive()) { // Experience editor view
            if (!fields) {
                return <h1 className="alarm">Datasource isn't set.{JSON.stringify(this.props)}</h1>;
            } else if (!fields.data) {
                return <h1 className="alarm">No data provided</h1>;
            }
        }
        return (<>
                {/*TODO: Verify design. No title or subheading defined in mock.  */
                    this.state.visible &&
                    <>
                        {fields.data.model.title && fields.data.model.title.data &&
                        <h3>{fields.data.model.title.data.value}</h3>
                        }
                        {fields.data.model.subtitle && fields.data.model.subtitle.data &&
                        <p className="imc-bosy">{fields.data.model.subtitle.data.value}</p>
                        }
                        {
                            (!!fields && !!fields.data && fields.data.model) && this.renderRows(fields.data.model.rows)
                        }
                    </>
                }
            </>
        );
    }

    renderRows(model) {
        return (model.map((row, index) => {

                return (
                    <div key={index}
                         className={`imc-gallery imc-landingpage__video-results imc-gallery--1-${Math.max(2, Math.min(row.exhibitors.length, 4))}   imc-gallery--center`}>
                        {
                            this.renderRow(row)
                        }
                    </div>)
            })
        );
    }

    renderRow(row) {
        const props = this.props;
        const exhibitorData = this.state.exhibitorData;
        return (row.exhibitors.map((exhibitor, index) => {
            return (
                <ExhibitorAd {...props} exhibitorData={exhibitorData} exhibitor={exhibitor} key={index}/>
            );
        }));
    }

}

let initialState = {
    exhibitorData: [],
    visible: false
}

export default withSitecoreContext()(withSitecoreRouter(ExhibitorAdComponent));

import pushDataLayer from '../dataLayer';
/**
 * Base class for promotions events
 */
class EntityEvent{
    
    constructor() {
    }

    static push(eventName, entityName, entity){
        const data = {
            event: eventName,
        };
        
        data[entityName] = entity;

        pushDataLayer(data);
    }
}

export default EntityEvent;

import React from 'react';
import { Filter } from 'modules/filter';
import {
  isEditorActive,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {withSitecoreRouter} from 'utils/withRouter';
import PropTypes from "prop-types";
import { createAppState } from "modules/redux-app-state";

import qs from 'query-string';
// reducers
import filterReducer from "./reducers/filtersReducer";
// action imports
import * as filterActions from "./actions/filterActions";

const resultsFilterState = createAppState();

class ResultsFilter extends React.Component {

  constructor(props) {
    super(props);

    resultsFilterState.reducerRegistry.register({
      filterReducer
    });
    this.fetchData = this.fetchData.bind(this);

    const { sitecoreContext } = this.props;
    if (typeof sitecoreContext !== 'undefined') {
      if (typeof sitecoreContext.exhibitor !== 'undefined')
        this.exhibitorId = sitecoreContext.exhibitor.id;
      this.pageId = sitecoreContext.itemId;
      //Removing based on UEP-568 feedback
      /*if(typeof sitecoreContext.taxonomyManagerFilters !== 'undefined')
        //this.filters = sitecoreContext.taxonomyManagerFilters;
        props.filterActions.setFilters(this.props.filterKey, sitecoreContext.taxonomyManagerFilters);*/

    }
    if(!isEditorActive())
      this.fetchData();
  }

  /**
   * Kick off bound fetch data
   */
  componentDidMount() {
    this.props.filterActions.setFilterKey(this.props.router.location.pathname.split('/').pop());
    this.setState({
      filterKey: this.props.router.location.pathname.split('/').pop()
    });
  }


  /**
 * Component Update
 */
  componentDidUpdate(prevProps) {
    const { router:{location} } = this.props;

    if (prevProps.router.location !== location) {
      this.props.filterActions.setFilterKey(location.pathname.split('/').pop());
      this.fetchData();
    };
  }


  /**
   * Fetch the article data
   * @param { string } queryObj term used to conduct search for
   */
  fetchData() {
    const term = qs.parse(this.props.router.location.search).q;
    const lineId = this.props.sitecoreContext.lineProduct?this.props.sitecoreContext.lineProduct.lineId: null;
    this.props.filterActions.getFilters(this.getFilterType(), this.exhibitorId, false, this.pageId, term, lineId);
  }

  getFilterType()
  {
     const type = this.props.router.location.pathname.toLowerCase().split('/').pop();
     return type.indexOf("directory")>0?"exhibitors":type;
  }
  render() {
    const {
      fields,
      filters,
      filterKey,
      router : {location} } = this.props;
    if (!fields) {
      if (isEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    return (<>{!!filters && <Filter
      // markDataOutOfDate={this.props.productActions.setExhibitorCategoriesProductsOutOfDate}
      filters={filters}
      filterKey={filterKey}
      resultTotal={0 /*productsCount*/}
      location={location}
    />}</>);
  }
}



/**
 * @property defaultProps
 * @type {{filters: *, filterActions: *, filterErr: *, filterKey: *,
  * productsCount: *, location: *}}
  */
const propTypes = {
  filters: PropTypes.object,
  filterActions: PropTypes.object,
  filterErr: PropTypes.number,
  filterKey: PropTypes.string,
  productsCount: PropTypes.number,
  location: PropTypes.object,
  pageId: PropTypes.string
};

/**
 * @property defaultProps
 * @type {{filters: {},
 * filterActions: {getFilters: defaultProps.filterActions.getFilters},
 * filterErr: null, filterKey: string, productsCount: number, location: {}
 */
const defaultProps = {
  filters: {},
  filterActions: {
    getFilters: () => { },
  },
  filterErr: null,
  filterKey: null,
  productsCount: 0,
  location: {},
};

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {

  if (state.filterReducer)
    return {
      filters: state.filterReducer.filters,
      filterErr: state.filterReducer.filterErr,
      filterKey: state.filterReducer.filterKey,
      pageId: state.pageId
      // productsCount: state.products.productsCount,
    };
  else {
    return {
      filters: state.filters,
      filterErr: state.filterErr,
      productsCount: state.productsCount,
      filterKey: state.filterKey,
      pageId: state.pageId
    };
  }
}

/**
* Maps dispatch to props for connect
* @param {function} dispatch Dispatcher
* @returns {object} Action creators
*/
function mapDispatchToProps(dispatch) {
  return {
    filterActions: bindActionCreators({ ...filterActions }, dispatch),
  };
}

ResultsFilter.propTypes = propTypes;
ResultsFilter.defaultProps = defaultProps;

export default withSitecoreContext()(
  withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ResultsFilter))
);

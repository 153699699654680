import React, { Component } from 'react'

export default class Button extends Component {
    render() {
        const { fieldData } = this.props;
        const data = fieldData.field.model;
        return (
            <input type="submit" className={data.cssClass} value={data.title} />
        )
    }
}

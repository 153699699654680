import {
    SET_SHOWROOM_CATALOGS_OVERVIEW,
    SET_SHOWROOM_CATALOGS_OVERVIEW_ERR,
    SHOW_LOADING,
    SET_EXHIBITOR_DETAIL_CATALOGS_OVERVIEW,
    SET_EXHIBITOR_DETAIL_CATALOGS_OVERVIEW_ERR,
    SET_LINE_DETAIL_CATALOGS_OVERVIEW,
    SET_LINE_DETAIL_CATALOGS_OVERVIEW_ERR,
} from './actionTypes';
import ExhibitorsAPI from 'api/exhibitor';

/**
 * Instantiates the Exhibitors API
 * @type {ExhibitorsAPI}
 * @private
 */
const _exhibitorsApi = new ExhibitorsAPI();



/**
 * Action to get Digital Showroom Info
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getDigitalShowroomCatalogsOverview(exhibitorId, pageId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getDigitalShowroomCatalogsOverview(exhibitorId, pageId).then((response) => {
            if (response) {
                dispatch({
                    type: SET_SHOWROOM_CATALOGS_OVERVIEW,
                    catalogs: response.data,
                    catalogsCount: response.count,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_SHOWROOM_CATALOGS_OVERVIEW_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}



// /**
//  * Action to get Digital Showroom Info
//  * @param {array} exhibitorId Id of Exhibitor
//  * @returns {function(*)} Promise object with fetch from showrooms api
//  */
export function getDigitalShowroomLineCatalogsOverview(lineId, pageId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getDigitalShowroomLineCatalogsOverview(lineId, pageId).then((response) => {
            if (response) {
                dispatch({
                    type: SET_SHOWROOM_CATALOGS_OVERVIEW,
                    catalogs: response.data,
                    catalogsCount: response.count,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_SHOWROOM_CATALOGS_OVERVIEW_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}




/**
 * Action to get Digital Showroom Exhibitor Details
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getExhibitorDetail(exhibitorId, pageId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getExhibitorDetail(exhibitorId, pageId).then((response) => {
            if (response && Array.isArray(response.data) && response.data.length === 1) {
                const exhibitor = response.data[0];
                dispatch({
                    type: SET_EXHIBITOR_DETAIL_CATALOGS_OVERVIEW,
                    shopZioURL: exhibitor.imcdiUrl,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_EXHIBITOR_DETAIL_CATALOGS_OVERVIEW_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        }).catch((err) =>{
            dispatch({
                type: SET_EXHIBITOR_DETAIL_CATALOGS_OVERVIEW_ERR,
                status: false,
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    };
}



/**
 * Action to get Digital Showroom Line Details
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getExhibitorLineDetail(lineId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getExhibitorLineDetail(lineId).then((response) => {
            if (response && Array.isArray(response.data) && response.data.length === 1) {
                const line = response.data[0];
                dispatch({
                    type: SET_LINE_DETAIL_CATALOGS_OVERVIEW,
                    shopZioURL: line.imcdiUrl,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_LINE_DETAIL_CATALOGS_OVERVIEW_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}


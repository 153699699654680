import { 
    SHOW_MAIN_NAV, 
    SET_INDEX,
    SET_VISIBILITY
} from './actionTypes';


/**
 * @param {string} typeId typeId of the item
 * @param {string} itemId itemId of the item
 * @param {string} notes itemId of the item
 * @returns {func} dispatch
 */
export function changeNavStatus(newStatus) {
    return function returnNavStatus(dispatch) {
        dispatch({ 
            type: SHOW_MAIN_NAV,
            val: newStatus
        });
    };
}


export function setIndex(mode) {
    return (dispatch) => {
        dispatch({
            type: SET_INDEX,
            value: mode,
        });
    };
}


export function setVisibility(mode) {
    return (dispatch) => {
        dispatch({
            type: SET_VISIBILITY,
            showTabs: mode,
        });
    };
}
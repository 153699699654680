import React from 'react';
import Link from '../Link'
import UserUtility from 'utils/userutility';
import { Environment } from '../../Environment';


interface UserMenuProps {
  items: any;
}

const UserMenu: React.FC<UserMenuProps> = ({ items }) => {

    const isLoggedIn = ((Environment.isHot != undefined) || UserUtility.isLoggedIn());
    const user = isLoggedIn ? UserUtility.getUserData() : {
        firstName: "George",
        lastName: "Burdell"
    };
    const name = (user && user.firstName && user.lastName) ? `${user.firstName} ${user.lastName.charAt(0)}.` : `George B.`;
    const renderedItems = items;

    const renderUserItem = (item: any, index: number) => {
        return (
            <li key={index} role="menuitem">
                {item.type?.name == "User Link" && 
                <Link className="top-nav-user-link" field={item.link.data} href={item.link.data.value.href} title={item.link.data.value.text}>
                    <span className="top-nav-user-heading">{item.heading.data.value}</span>
                </Link>}
                {(item.type?.name == "Sign In") && 
                <button onClick={(event) => UserUtility.redirectToLogin()} title={item.link.data.value.text} className="top-nav-user-link">
                    <span className="top-nav-user-heading">{item.heading.data.value}</span>
                </button>}
                {(item.type?.name == "Sign Out") && 
                <a href={item.link.data.value.href} title={item.link.data.value.text} className="top-nav-user-link">
                    <span className="top-nav-user-heading">{item.heading.data.value}</span>
                </a>}
            </li>
        );
    };
    
    return (
        <div className="top-nav-dropdown-menu">
            <div className="top-nav-dropdown-grid">
                <div className="top-nav-column">
                    <ul role="menu" aria-label={`Dropdown Menu for User Login and Logout`}>
                        {(isLoggedIn) && <li key={`username`} role="username">
                            <span className={`top-nav-user-userinfo`}>
                                <span className="top-nav-user-heading">{name}</span>
                            </span>
                        </li> }                       
                        {renderedItems.map((item: any, index: number) => renderUserItem(item, index))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default UserMenu;
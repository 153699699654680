import {
    SHOW_LOADING,
    GET_FLOOR_PLAN,
} from './actionTypes';
import {
    CLOSE_EX_MODAL 
} from '../../Meridian-Map-Component/actions/actionTypes';
import UserUtility from '../../../utils/userutility';
import {GET_FLOOR_PLAN_MOCK} from './mockData';
import FloorPlanAPI from 'api/floor-plan';

const _floorPlansApi = new FloorPlanAPI();
const channelInfo = {id: 2, name:'las-vegas-market'};

export function fetchFloorPlan(datasourceId, buildingName, floor) {
    //todo: read these parameters properly
    let channel = UserUtility.getChannelData().name;

    return (dispatch) => {
        dispatch({
            type: CLOSE_EX_MODAL,
        });
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _floorPlansApi.getFloorPlan(datasourceId, buildingName, floor, channel).then((response) => {
            
            dispatch({
                type: GET_FLOOR_PLAN,
                data: response
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    };
}

export function fetchMockFloorPlan() {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        window.setTimeout (()=>{
                dispatch({
                    type: GET_FLOOR_PLAN,
                    data: GET_FLOOR_PLAN_MOCK,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }, 1000);
    };
}


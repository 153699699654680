// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  Text, Image } from '@sitecore-jss/sitecore-jss-react';
import Link from '../../Link'; 
import ImcDataLayer from 'utils/datalayer';
// Local dependencies
import NavigationTier3 from './NavigationTier3.jsx';


/**
 * Component for displaying a navigation item (tier 2) and maintaining its state
 */
export default class NavigationTier2 extends Component {

    /**
     * @method constructor
     * @description On instantiation dynamically sets the tier 2 navigation items
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            currentTier2: 0,
            tier3Focus: false,
        };
        this.itemref = [];
        this.tier3 = [];
        this.tier3id = 'navigation-tier3';
        this.setCurrentTier2 = this.setCurrentTier2.bind(this);
        this.unsetCurrentTier2 = this.unsetCurrentTier2.bind(this);
        this.navigateTier2 = this.navigateTier2.bind(this);
        this.nextTier2 = this.nextTier2.bind(this);
        this.focusTier2 = this.focusTier2.bind(this);
        this.returnFocusTier2 = this.returnFocusTier2.bind(this);
    }

    /**
     * Resets the component if the current tier 1 item is not in focus
     * @param {object} prevProps Previous set of props
     */
    componentDidUpdate(prevProps) {
        if (this.props.currentTier1 !== prevProps.currentTier1) {
            if (this.props.currentTier1 !== this.props.currentIndex) {
                this.unsetCurrentTier2();
            }
        }
    }

    /**
     * Sets the currently focused tier 2 element
     * @param {number} index Index to set as the current tier 2 item
     */
    setCurrentTier2(index) {
        this.setState({
            currentTier2: index,
        });
    }

    /**
     * Unsets the currently focused tier 2 element
     */
    unsetCurrentTier2() {
        this.setState({
            currentTier2: -1,
        });
    }

    /**
     * Sets to the next tier 2 item
     */
    nextTier2() {
        const next = (this.state.currentTier2 < this.props.items.length - 1) ? this.state.currentTier2 + 1 : 0;
        this.setState({
            currentTier2: next,
        });
        this.itemref[next].focus();
    }

    /**
     * Sets the previous tier 1 item
     */
    previousTier2() {
        if (this.state.currentTier2 !== 0) {
            const prev = this.state.currentTier2 - 1;
            this.setState({
                currentTier2: prev,
            });
            if (this.itemref[prev]) {
                this.itemref[prev].focus();
            }
        } else if (this.state.currentTier2 === 0) {
            this.setState({
                currentTier2: -1,
            });
            this.props.focusTier1();
        }
    }

    /**
     * Navigates through the tier 2 items
     * @param {object} e Keydown event object
     */
    navigateTier2(e) {
        const code = e.keyCode || e.which;
        // up arrow
        if (code === 38) {
            e.preventDefault();
            this.previousTier2();
        }
        // down arrow
        if (code === 40) {
            e.preventDefault();
            this.nextTier2();
        }
        // right arrow
        if (code === 39 || (code === 9 && this.tier3.tier3ref.querySelectorAll('a').length > 0 && !e.shiftKey)) {
            if (this.tier3.tier3ref && this.props.items[this.state.currentTier2].item !== '') {
                this.tier3.tier3ref.focus();
                this.setState({
                    tier3Focus: true,
                });
            }
        }
    }

    /**
     * Focuses on the tier 2 item
     */
    focusTier2() {
        this.setState({
            tier3Focus: false,
        });
        if (this.state.currentTier2 + 1 <= this.props.items.length - 1) {
            this.nextTier2();
        } else {
            this.props.nextTier1();
        }
    }

    /**
     * Returns focus to the tier 2 item
     */
    returnFocusTier2() {
        if (this.itemref[this.state.currentTier2]) {
            this.itemref[this.state.currentTier2].focus();
        }
    }

    /**
     * @method render
     * @description Renders the ModelCard DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { items, currentTier1, currentIndex, fullWidth, tier1Offset, tier1Label, mouseOut } = this.props;
        const { currentTier2 } = this.state;

        if (items.length > 0) {
            const activeClass =
                currentTier1 === currentIndex ? 'imc-navigation__menu--active' : '';
            const fullWidthClass =
                fullWidth ? 'imc-navigation__menu--fullwidth' : '';
            const windowWidth = document.documentElement.clientWidth < 1366 ?
                document.documentElement.clientWidth : 1366;
            const width = !fullWidth ? `${windowWidth - tier1Offset}` : '100vw';
            const tier2attrs = {
                className: 'imc-navigation__tier2',
            };
            if (!fullWidth) {
                tier2attrs.style = {
                    width: `${Math.round(1366 * (7 / 24))}px`,
                };
            }
            return (
                <div
                    className={`imc-navigation__menu imc-content ${activeClass} ${fullWidthClass}`}
                    aria-label={`${tier1Label} Menu`}
                    aria-expanded={currentTier1 === currentIndex}
                    style={{
                        width: `${width}px`,
                    }}
                    data-xpath="navigation.menu"
                    onMouseLeave={mouseOut}
                >
                    {items.map((column, index) => {
                        return (
                            <div className="imc-navigation-col" key={index}>
                                {
                                    column.navigation2st.map((item2tier, key) =>
                                        <div className="imc-navigation-2tier" key={key}>
                                            {item2tier.link && !((item2tier.link.data.value.text == "" || !item2tier.link.data.value.text) && item2tier.image.data.value.src) && <Link onClick={()=>ImcDataLayer.pushInteractionEvent("navigation", "header", "tier2 - " + item2tier.nodeName)} className="imc-link imc-navigation__tier2Link" field={item2tier.link.data} /> }
                                            {item2tier.image.data.value.src &&
                                                <Link onClick={()=>ImcDataLayer.pushInteractionEvent("navigation", "header", "tier2 - " + item2tier.nodeName)} className="imc-navigation-2tier-imageLink" field={item2tier.link.data} >
                                                    <Image field={item2tier.image.data.value} className="imc-image--responsive imc-navigation-2tier-image" ></Image>
                                                </Link>
                                            }
                                            {item2tier.description.data && item2tier.description.data.value != "" &&
                                                <div className="imc-navigation-2tier-description"><Text field={item2tier.description.data}></Text></div>
                                            }
                                            {
                                                
                                                item2tier.navigation3st && item2tier.navigation3st.length > 0 &&
                                                <div className="imc-navigation-3tier">
                                                    <NavigationTier3
                                                        ref={(node) => {
                                                            this.tier3 = node;
                                                        }}
                                                        id={this.tier3id}
                                                        content={item2tier.navigation3st}
                                                        active={(currentTier2 !== -1)}
                                                        currentTier1={currentTier1}
                                                        focusTier2={() => this.focusTier2()}
                                                        fullWidth={fullWidth}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        );
                    },
                    )}
                </div>
            );
        }
        return null;
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{items}}
 */
NavigationTier2.propTypes = {
    tier1Label: PropTypes.string, // Label for Tier 1
    tier1Title: PropTypes.string, // Label for Tier 1
    currentIndex: PropTypes.number.isRequired, // current key
    items: PropTypes.array.isRequired, // Array of tier 2 items
    fullWidth: PropTypes.bool.isRequired, // Flag if the menu is full width
    currentTier1: PropTypes.number.isRequired, // Currently selected tier 1 item
    focusTier1: PropTypes.func, // Method to focus on the tier 1 parent element
    tier1Offset: PropTypes.number, // Offset of the tier 1 parent
    mouseOut: PropTypes.func,
    nextTier1: PropTypes.func.isRequired, // Function to set the next tier 1 item
};

/**
 * @property defaultProps
 * @type {{tier1Label: string}}
 */
NavigationTier2.defaultProps = {
    tier1Label: 'Primary',
    tier1Offset: 0,
    mouseOut: () => { },
    focusTier1: () => { },
};

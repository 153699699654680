/**
 * Cookie expiration date
 */
const COOKIE_EXPIRATION = 90;

/**
 * Gathers all cookies and returns them as an object.
 * @returns {object} Name/Value pair of cookies
 */
export function getCookies() {
    const cookies = {};
    const cookieSheet = document.cookie || '';
    const cookieJar = cookieSheet.split(';');
    let cookie;
    for (let cookieIndex = 0; cookieIndex < cookieJar.length; cookieIndex++) {
        cookie = cookieJar[cookieIndex].trim();
        const [name, value] = cookie.split('=');
        if (name && value) {
            cookies[name] = decodeURIComponent(value);
        }
    }
    return cookies;
}

/**
 * Sets a cookie given a name and value pair
 * @param {*} name Name of the cookie
 * @param {*} value Value of the cookie
 */
export function setCookie(name, value) {
    let expires = '';
    const date = new Date();
    date.setTime(date.getTime() + (COOKIE_EXPIRATION * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
    // document.cookie = `${name}=${value};domain=${window.location.hostname}${expires}; path=/`;
    document.cookie = `${name}=${value};domain=''${expires}; path=/`;
}

/**
 * Removes a cookie by setting the max age to a negative mumber
 * @param {*} name Name of the cookie to eat
 */
export function deleteCookie(name) {
    document.cookie = `${name}=; Max-Age=-99999999;`;
}

/**
 * Get specific cookie from the cookie object.
 * @param {string} cookieKey Key for desired cookie.
 * @returns {string} Value of a specific cookie
 */
export function getSpecificCookie(cookieKey) {
    const cookies = getCookies();
    return cookies[cookieKey] || '';
}

// window.getSpecificCookie = getSpecificCookie;
// window.setCookie = setCookie;

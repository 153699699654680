// Library dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Local dependencies
import CarouselMeatball from './CarouselMeatball';

/**
 * Renders the meatballs
 * @param {function} onSelect Method when meatball is selected
 * @param {number} currentIndex Currently active carousel item
 * @param {number} carouselLength slides in carousel
 * @param {boolen} isDesktop is display on destop or not
 * @param {number} carouselColumns of column to display on browser
 * @returns {Array} Array of meatballs
 */
function renderMeatballs(onSelect, currentIndex, carouselLength, isDesktop, carouselColumns) {
    const meatballs = [];
    let itemLength = carouselLength;
    if (isDesktop && carouselColumns > 1) {
        itemLength = Math.ceil(itemLength / carouselColumns);
    }

    for (let i = 0; i < itemLength; i++) {
        meatballs.push(
            <CarouselMeatball
                key={i}
                index={i}
                isActive={i === currentIndex}
                onSelect={onSelect}
            />,
        );
    }
    return meatballs;
}

/**
 * Component for rendering a Carousel navigation element and setting a callback for when the component is clicked
 * @param {boolen} isDesktop is display on destop or not
 * @param {number} carouselColumns of column to display on browser
 * @returns {Element} Rendered carousel navigation
 */
function CarouselNav({ onPrev, onNext, onSelect, carouselType, currentIndex, carouselLength, isDesktop, carouselColumns, totalPages}) {
    return (
        <div
            className="imc-carousel__meatballs"
            aria-label="carousel controls"
            data-xpath="carousel.controls"
        >
            <a
                role="button"
                tabIndex={0}
                className={(currentIndex == 0) ? `imc-carousel__nav-prev--${carouselType} inactive` : `imc-carousel__nav-prev--${carouselType}`}
                onClick={(currentIndex == 0) ? '' : onPrev}
                onKeyDown={(currentIndex == 0) ? '' : e => onPrev(e)}
                aria-label="Previous"
                data-xpath="carousel.previous"
            >&nbsp;</a>
            <div className="imc-carousel__meatball--wrap">
                {renderMeatballs(onSelect, currentIndex, carouselLength, isDesktop, carouselColumns)}
            </div>
            <a
                role="button"
                tabIndex={0}
                className={(currentIndex == totalPages - 1) ? `imc-carousel__nav-next--${carouselType} inactive` : `imc-carousel__nav-next--${carouselType}`}
                onClick={(currentIndex == totalPages - 1) ? '' : onNext}
                onKeyDown={(currentIndex == totalPages - 1) ? '' : e => onNext(e)}
                aria-label="Next"
                data-xpath="carousel.next"
            >&nbsp;</a>
        </div>
    );
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{header: *, isActive: *, onSelect: *}}
 */
CarouselNav.propTypes = {
    onPrev: PropTypes.func.isRequired, // Callback handler to be called when clicking the previous button
    onNext: PropTypes.func.isRequired, // Callback handler to be called when clicking the next button
    onSelect: PropTypes.func.isRequired, // Callback handler to be called when clicking a meatball item
    currentIndex: PropTypes.number.isRequired, // Currently active index
    carouselLength: PropTypes.number.isRequired, // Number of slides
    carouselType: PropTypes.string, // String, value of either 'full' or 'notification'
    isDesktop: PropTypes.bool,
    carouselColumns: PropTypes.number, // String, value of either 'full' or 'notification'
};

/**
 * @property defaultProps
 * @type {{navType: string}}
 */
CarouselNav.defaultProps = {
    carouselType: 'full',
    isDesktop: false,
    carouselColumns: 1,
};

// Export the react component
export default CarouselNav;

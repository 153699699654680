import {
    SET_SHOWROOM_CATALOGS_OVERVIEW,
    SET_SHOWROOM_CATALOGS_OVERVIEW_ERR,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {
    catalogs: [],
    // shopZioURL: '',
    catalogsCount: 0,
    showLoading: true,
};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function digitalShowroomCatalogsOverviewReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_SHOWROOM_CATALOGS_OVERVIEW:
            return Object.assign({}, state, {
                catalogs: action.catalogs,
                // shopZioURL: action.imcdiUrl,
                catalogsCount: action.catalogsCount,
                showLoading: true
            });
        case SET_SHOWROOM_CATALOGS_OVERVIEW_ERR:
            return Object.assign({}, state, {
                catalogs: initialState.catalogs,
                // shopZioURL: initialState.imcdiUrl,
                catalogsCount: initialState.catalogsCount,
                errCode: action.status || 404,
            });
        default:
            return state;
    }
}
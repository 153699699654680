


/**
 * Filters API
 * Get Filters relevant for provided category
 */
class BreadcrumbAPI {
    /**
     * Constructor
     */
    constructor() {
        
    }

    /**
     * Gets a list of available based on key
     * @param {string} filterKey key used to find associated results
     * @param { string } exhibitId exhibitor or line ID
     * @param { boolean } isLine determines if the item is a line
     * @returns {Promise.<T>} Promise object that returns the FilterAPI results
     */
    getBreadcrumbs(pageId) {
        // TODO REPLACE HOST NAME
        const url = 'http://imc_xp0.sc/imc-api/v1/navigation/breadcrumbs?pageId={FDB9FCDD-ED5B-471F-B019-12CDA71574B6}&sc_apikey=68C22CFE-5A44-4471-8BA8-13BB1A3E58C0';
       
        return (
           alert( fetch(`${url}`)
                .then(response => response)
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                }))
        );
    }
}

export default new BreadcrumbAPI();


import React, {PureComponent} from 'react';
import {withSitecoreRouter} from 'utils/withRouter';
import {updateSearchUrl} from '../../utils/tagupdate';
import Moment from 'react-moment';
import {getDictionaryValue} from 'utils/dictionary/index';
import {CardTags} from 'modules/cardtags';

class ArticleCardComponent extends PureComponent {
    /**
     * @method contructor
     * @description Lifecycle method
     * @param {object} props incoming props
     */
    constructor(props) {
        super(props);
        const {
            URL,
            destinationUrl,
            ImageSrc,
            mainImage,
            Title,
            title,
            PublishDate,
            publishDate,
            Description,
            teaserText,
            editorialType,
            Tags,
            tags,
        } = props;

        this.state = {
            link: destinationUrl || URL || '',
            imageSrc: this.getImageSrc(ImageSrc, mainImage),
            title: title || Title || '',
            publishDate: publishDate || PublishDate || '',
            description: teaserText || Description || '',
            articleType: editorialType || '',
            keys: tags || Tags || [],
        };

        this.renderCardTags = this.renderCardTags.bind(this);
        this.tagClick = this.tagClick.bind(this);
        this.updateCardValues = this.updateCardValues.bind(this);
        this.getImageSrc = this.getImageSrc.bind(this);
        this.updateCardValues();
    }

    /**
     * Tag click event
     * @returns {JSX} Card tags
     */
    tagClick(event) {
        const {match, router, filterKey, tagKey} = this.props;
        const tag = event.target.firstChild.nodeValue;
        updateSearchUrl(tag, router, match, filterKey, tagKey);
    }

    componentDidMount() {
        this.updateCardValues();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.updateCardValues();
        }
    }

    updateCardValues() {
        const {
            search,
            URL,
            ImageSrc,
            mainImage,
            Title,
            Tags,
            publishDate,
            teaserText,
            destinationUrl,
            PublishDate,
            Description,
            title,
            tags,
            editorialType,
            keys = []
        } = this.props;

        const imageSrc = this.getImageSrc(ImageSrc, mainImage);
        this.setState({

            link: search ? destinationUrl : URL,
            imageSrc: imageSrc,
            title: search ? title : Title,
            publishDate: search ? publishDate : PublishDate,
            description: search ? teaserText : Description,
            articleType: editorialType,
            keys: [ ...(Tags || tags || keys)],
        });

    }

    getImageSrc(imageSrc, mainImage) {
        return imageSrc ? imageSrc.replace('/-/media', '/-/jssmedia').replace('/sitecore/shell', '')
            : mainImage ? mainImage.replace('/-/media', '/-/jssmedia').replace('/sitecore/shell', '')
            : ``;
    }

    /**
     * Render Card Tags
     * @returns {JSX.Element} Card tags
     */
    renderCardTags() {
        const {link, Tags} = this.state;
        return (
            <CardTags
                className="imc-articlecard__tag"
                id=""
                tags={Tags}
                destinationUrl={link}
                clickEvent={this.tagClick}
                maxTagsToDisplay={2}
            />
        );
    }

    render() {
        const {extrasClass} = this.props;
        const {link, title, publishDate, imageSrc, articleType, description} = this.state;

        return (
            <div className={`imc-articlecard--border imc-articlecard ${extrasClass}`}>
                <div className="imc-articlecard__header">
                    <a className="imc-articlecard__title" href={link}>{title}</a>
                    <div className="imc-articlecard__date">
                        <Moment format="MMMM DD, YYYY">
                            {publishDate}
                        </Moment>
                    </div>
                </div>
                <div className="imc-articlecard__body imc-gallery imc-gallery--33-66">
                    <div className="imc-gallery__item imc-content--center-mobile">
                        <img className="imc-articlecard__image" src={imageSrc} alt={title}/>
                    </div>
                    <div className="imc-gallery__item">

                        <div className="imc-articlecard__teasertext imc-padding--top--medium--mobile">
                            {articleType &&
                                <p className="imc-type--title-5-ui imc-padding--bottom--smallmedium"
                                   style={{textTransform: 'capitalize'}}>{articleType}</p>
                            }
                            <p className="imc-type--title-2-ui">{description}</p>
                        </div>
                    </div>
                    <div className="imc-gallery__item imc-padding--top--medium--desktop">
                        <a
                            className={'imc-button--primary-inverted'}
                            href={link}
                        >{getDictionaryValue('seeMoreDetails', 'See More Details')}</a>
                    </div>
                    <div className="imc-gallery__item imc-padding--top--medium">
                        {this.renderCardTags()}
                    </div>
                </div>
            </div>
        );
    }
}

ArticleCardComponent.displayName = 'ArticleCardComponent';

export default withSitecoreRouter(ArticleCardComponent);

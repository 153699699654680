/**
 * Converts a JSON object to a serialized string
 * @param {object} obj Object to convert
 * @returns {string} Serialized string
 * @private
 */
export function serialize(obj) {
    return Object.keys(obj).map((key) => {
        let value;
        if (typeof obj[key] === 'string') {
            value = encodeURIComponent(obj[key]);
        } else if (Array.isArray(obj[key])) {
            value = obj[key].map((item, index) => {
                if (index === 0)
                    return `${item}`;
                return `${encodeURIComponent(key)}=${item}`;
            }).join('&');
        } else {
            value = encodeURIComponent(JSON.stringify(obj[key]));
        }
        return `${encodeURIComponent(key)}=${value}`;
    }).join('&');
}

export function serializeTags(obj) {
    
    const _resp = [];
    Object.keys(obj).forEach((key) => {
        let value;
        switch (key) {
            case 'f':
                Object.keys(obj[key]).forEach((filterKey) => {
                    _resp.push(`${encodeURIComponent(key)}:${filterKey}=${obj[key][filterKey]}`);
                })

                break;
            case 'tags':
                Object.keys(obj[key]).forEach((tagsKey) => {
                    if(obj[key][tagsKey].toString()!='true')
                        {_resp.push(`${encodeURIComponent(key)}=${obj[key][tagsKey]}`);}
                })
                break;
            default:
                if (typeof obj[key] === 'string') {
                    value = encodeURIComponent(obj[key]);
                } else if (Array.isArray(obj[key])) {
                    value = obj[key].map((item, index) => {
                        if (index === 0)
                            return `${item}`;
                        // if(index === obj[key].lenght)
                        //     return `${item}`;
                        return `${item}`;
                    }).join(',');
                } else {
                    value = encodeURIComponent(JSON.stringify(obj[key]));
                }
                _resp.push(`${encodeURIComponent(key)}=${value}`);

                break;
        }
    });
    return _resp.join('&');
}
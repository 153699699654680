/**
 * Initial state for the store
 * @type {{}}
 */
export const InitialState = {
    search: {
        download: '',
        searchSaved: false,
        saveSearchModalOpen: false,
    },
};

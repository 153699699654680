import React from 'react';
import FiltersAPI from 'api/search/filters';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { EmailQueryModal } from '../../modules/emailQuery';
import { DropDown } from 'modules/formelements';

class FilterOptions extends React.Component {
	/**
	* @method contructor
	* @description Lifecycle method
	* @param {object} props incoming props
	*/
	constructor(props) {
		super(props);
		if (!props) {
	        if (isEditorActive()) {
	            return <h1 className="alarm">Datasource isn't set.</h1>;
	        }
	        return (
	            <h1 className="alarm">
	                Data is not provided. Contact administrators, please.
	          </h1>
	        );
	    }

	    this.state = { 
			value: "",
			showEmailModal: false,
			query:'',
		};
		this.options=[];
		this.options.push('Email');
		this.options.push('Download')
		this.chooseAction = this.chooseAction.bind(this);
		this.handleClosePopup = this.handleClosePopup.bind(this);
		this.downloadResults = this.downloadResults.bind(this);
		this.downloadResults = this.downloadResults.bind(this)
	}

	downloadResults() {
        // this.props.actions.downloadSearch(this.props.filterKey);
    }

	changeFilterOptions(event){
		console.log(event)
		alert(event)
	}

    handleClosePopup() {
        this.setState({ showEmailModal: false });
    }

     /**
     * @method render
     * @description Renders the DOM element
     */
    handleOpenPopup() {
        this.setState({ showEmailModal: true });
    }

	chooseAction(updatedName, updatedVal) {
		
        if (updatedVal === 'Download') {
            this.downloadResults();
        } else if (updatedVal === 'Email') {
            this.setState({ showEmailModal: true });
        }
    }
	render() {
		return(
			// <div className="imc-formfield imc-dropdown imc-content imc-exhibitors__cta 
            //                 imc-dropdown--secondary 
            //                 imc-dropdown--secondary--thin 
            //                 imc-breakpoint-display--hide-mobile imc-jumpto imc-dropdown--inline-block imc-content--padded-small-right">
			//     <div className="imc-vr--xsmall imc-visuallyhide">
			//         <label htmlFor="dropdown-filter-options">
			//         	Options
			//         </label>
			//     </div>
			   <div>
				<DropDown
                        additionalClass={'imc-searchform--dropdown'}
                        firstOption={'Options'}
                        hideLabel
                        label={'Options'}
                        name={'Options'}
                        options={this.options}
                        updateValue={this.chooseAction}
                    />
				{this.state.showEmailModal ? <EmailQueryModal
                        showModal={this.state.showEmailModal}
                        closePopup={this.handleClosePopup}
                        // listHeading={this.props.labels.searchResults.emailModalTitle}
                        name={'Some name goes here'}
                        query={this.state.query}
				/> :null }
			</div>
		);
	}
}

export default FilterOptions;
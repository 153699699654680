// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Module dependencies
import { Checkbox } from 'modules/formelements';

/**
 * Component for displaying group of checkboxes
 */
class CheckboxesGroup extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the state
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        let prefs = [];

        if(typeof this.props.data != 'undefined' && this.props.data != null){
            this.props.data.forEach((item) => {
                if(item.checked){
                    prefs.push(item.key);
                }
            });
        }

        this.state = {
            preferences: prefs,
        };
        this.inputFieldChangeHandler = this.inputFieldChangeHandler.bind(this);
        this.createCheckbox = this.createCheckbox.bind(this);

    }

    /**
     * Updates the state
     * @param {object} nextProps Updated props
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.value && nextProps.value !== this.state.preferences) {
            this.setState({
                preferences: nextProps.value,
            });
        }
    }
    /**
     * Handler for updating state on change of field
     * @param {string} fieldName input field name
     * @param {object} value input field value
     */
    inputFieldChangeHandler(fieldName, value) {
        const { updateValue, name, data, labelKey, valueKey, updateStore, updateFieldValue } = this.props;
        let code = 0;
        data.forEach((preference) => {
            if (fieldName === preference[labelKey]) {
                code = preference[valueKey];
            }
        });
        const newPreferences = [...this.state.preferences];
        if (value) {
            newPreferences.push(code);
        } else {
            newPreferences.splice(newPreferences.indexOf(code), 1);
        }
        this.setState({
            preferences: newPreferences,
        });
        updateStore(newPreferences);
        updateFieldValue(fieldName, value);
        updateValue(name, newPreferences);
    }
    /**
     * Component for rendering checkbox of checkboxes group
     * @param {string} result name of checkbox
     * @returns {Element} Rendered checkbox of checkboxes group
     */
    createCheckbox(result) {
        const { name, labelKey, valueKey, labelClass, additionalClass, checkboxClasses } = this.props;
        const listName = result[labelKey];
        const listId = result[valueKey];
        console.log(result)
        return (
            <Checkbox
                label={listName}
                name={listName}
                value={listName}
                stableSelector={`${name}.${listName.split(' ').join('').toLowerCase()}`}
                labelClass={labelClass}
                additionalClass={additionalClass}
                checkboxClasses={checkboxClasses}
                updateValue={this.inputFieldChangeHandler}
                checked={Boolean(this.props.value.includes(listId))}
                key={`checkbox-item-${listId}`}
            />
        );
    }
    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { data, wrapperClass } = this.props;
        return (
            <div className={wrapperClass}>
                {data.map(result => this.createCheckbox(result))}
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{checkboxesData: *, labelSelect: *, checkboxName: *}}
 */
CheckboxesGroup.propTypes = {
    data: PropTypes.array.isRequired, // Data for buyer preferences
    name: PropTypes.string.isRequired, // key name corresponding to submission
    additionalClass: PropTypes.string,
    labelKey: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    wrapperClass: PropTypes.string,
    checkboxClasses: PropTypes.string,
    value: PropTypes.array, // value corresponding to submission
    updateFieldValue: PropTypes.func,
    updateValue: PropTypes.func, // Function to update state of form
    updateStore: PropTypes.func, // Function to update state of form
};

/**
 * @property defaultProps
 * @type {{labelSelect: string}}
 */
CheckboxesGroup.defaultProps = {
    additionalClass: '',
    wrapperClass: '',
    checkboxClasses: '',
    labelKey: '',
    valueKey: '',
    labelClass: '',
    value: [],
    type: 'checkbox',
    updateFieldValue: () => {},
    updateValue: () => {},
    updateStore: () => {},
};

// Export the react component
export default CheckboxesGroup;

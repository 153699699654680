// Library dependencies
import React from 'react';
import { render } from 'react-dom';

// Module dependencies
import { getDataAttributes } from 'utils/getattributes';

// Local dependencies
import Carousel from '../components/Carousel';
import CarouselItem from '../components/CarouselItem';

/**
 * @method getCarouselElements
 * @description
 * Retrieved the carousel elements from the DOM
 * @param {string} selector css selector to not repeat with Optimizely
 * @returns {NodeList} List of dom carousel elements
 */
function getCarouselElements(selector = '[data-imc-carousel]') {
    return document.querySelectorAll(selector);
}

/**
 * @method renderCarouselItem
 * @description Parses the carouselItemElement and creates a CarouselItem component based
 * on the inner html of the element
 * @param {Element} carouselItemElement The dom element to create the CarouselItem from
 * @returns {Element} CarouselItem React element
 */
function renderCarouselItem(carouselItemElement) {
    const carouselContent = carouselItemElement.querySelectorAll('[data-carousel-content]')[0];
    const carouselContentChildren = React.createElement('div',
        { className: 'imc-carousel__inner-content imc-featured-events--display-flex',
            dangerouslySetInnerHTML: { __html: carouselContent.outerHTML } });

    return (
        React.createElement(CarouselItem, { header: '' }, carouselContentChildren)
    );
}
/**
 * @method renderCarousel
 * @description
 * Creates Carousel component instances from the carouselElement element and its attributes
 * @param {Element} carouselElement The DOM element object to create a Carousel component from
 *
 * @private
 */
function renderCarousel(carouselElement) {
    const carouselItemElements = [].slice.call(carouselElement.querySelectorAll('[data-carousel-item]'));
    const carouselItemComponents = [];
    const carouselAttrs = getDataAttributes(carouselElement);
    carouselAttrs.carouselLength = carouselItemElements.length;
    carouselAttrs.carouselType = carouselAttrs.carouselType || 'full';
    carouselAttrs.desktopGallery = carouselAttrs.desktopGallery === 'true';
    carouselAttrs.galleryColumns = carouselAttrs.galleryColumns ? parseInt(carouselAttrs.galleryColumns, 10) : 2;
    carouselAttrs.carouselColumns = carouselAttrs.carouselColumns ? parseInt(carouselAttrs.carouselColumns, 10) : 1;

    [].forEach.call(carouselItemElements, (item) => {
        carouselItemComponents.push(renderCarouselItem(item));
    });

    render(
        <Carousel {...carouselAttrs} >
            {carouselItemComponents.map((carouselItem, index) =>
                React.cloneElement(carouselItem, {
                    key: index,
                }),
            )}
        </Carousel>,
        carouselElement,
    );
}

/**
 * @method createCarousel
 * @description
 * Initializes the module by fetching all of the Carousel elements from the DOM and creating Carousel
 * component instances and rendering them to the DOM
* @param {string} selector css selector to not repeat with Optimizely
 */
function createCarousel(selector = '[data-imc-carousel]') {
    const carouselElements = getCarouselElements(selector);

    for (let i = 0; i < carouselElements.length; i++) {
        renderCarousel(carouselElements[i]);
    }
}

//window.imcReactRender = window.imcReactRender || {};
//window.imcReactRender.createCarousel = createCarousel;

/**
 * Exports public API methods
 */
export default {
    createCarousel,
};

import { getSitecoreApiHost, getSitecoreApiKey}  from '../AppGlobals';
//const token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0IjoxNTkxNzk0NDE2LCJ2YWx1ZSI6Ijk1MzkxZTg5MjQ4YWEwN2FjMDRlZjA3YWZkOTJhNTkyMTQxN2Y1OGIifQ.1wgR6hO9kpFfk7hqZ3RWn_WmB3QPFWGsIFASMzg3NSg";

export default class MeridianAPI {

    constructor() {
        //this.getUserLists = this.getUserLists.bind(this);
    }

    /**
     *
     * @param {string} locationId Location ID
     * @param {string} uid ShowRoom UID. (building - floor - showRoomNo)
     */
    getPlacemarksByUid(locationId, uid) {

        const url = `${getSitecoreApiHost()}/imc-api/v1/meridian/locations/${locationId}/placemarks?uid=${uid}&sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                }})
            .then(response => response.json())
            .catch((err) => {
                // TODO: Error Handling
                console.error('getPlacemarksByUid error', err);
            })
        );
    }

     /**
      *
      * @param {string} locationId
      */

    getFloors(locationId) {

        const url = `${getSitecoreApiHost()}/imc-api/v1/meridian/locations/${locationId}/floors?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                }})
            .then(response => response.json())
            .catch((err) => {
                // TODO: Error Handling
                console.error('getFloors error', err);
            })
        );
    }


}

/**
 * Stores the latast id to increment
 * @type {number} Number stored for the next ID
 */
let lastId = 0;

/**
 * Creates a unique ID to use
 * @param {string=} prefix Prefix to set for the id
 * @returns {string} Unique ID
 */
export function uniqueid(prefix = 'id') {
    lastId++;
    return `${prefix}${lastId}`;
}

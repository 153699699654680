import React from 'react';
import {
    withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';
import PropTypes from 'prop-types';
// modules
import { createAppState } from 'modules/redux-app-state';

// reducers
import marketPlannerChannelSelectorReducer from './reducers/marketPlannerChannelSelectorReducer';
// action imports
import * as dataActions from './actions/dataActions';
import UserUtility from 'utils/userutility/index';

const marketPlannerChannelSelectorState = createAppState();

class ChannelSelectorComponent extends React.Component {
    constructor(props) {
        super(props);
        marketPlannerChannelSelectorState.reducerRegistry.register({
            marketPlannerChannelSelectorReducer
        });
        this.fetchData = this.fetchData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fetchData();
    }

    /**
     * Fetch the article data
     * @param { string } queryObj term used to conduct search for
     */
    fetchData() {
        this.props.dataActions.getAccountChannelSelector();
    }

    /**
     * @method setHistory
     * @description Push to history object
     */
    onChange(event) {
        window.location.href = event.target.options[event.target.selectedIndex].value;
    }
    render() {
        const { sitecoreContext, fields, channels } = this.props;
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
        if (typeof fields === 'undefined') {
            if (isEditing) {
                return <h1 className='alarm'>Datasource isn't set.</h1>;
            }
            return (
                <h1 className='alarm'>
                    Data is not provided. Contact administrators, please.
                </h1>
            );
        }

        if (channels.length === 0) {
            return <select><option>Channel Selector</option></select>
        }

        let extraClass = "";
        if (fields.class != null) {
            extraClass = fields.class.fields.className.value;
        }

        return (
            <div className={"imc-formfield imc-dropdown imc-dropdown--secondary--thin imc-channel-selector-mobile" + extraClass}>
                <select
                    className={(!!fields.class && !!fields.class.fields) ? fields.class.fields.className.value : ''}
                    onChange={(event) => this.onChange(event)}>
                    {channels.map((channel, index) => <option key={index} selected={channel.siteCode === UserUtility.getChannelData().name} value={`${channel.channelUrl}/Market-Planner`}>{channel.channelName}</option>)}
                </select>
            </div>
        );
    }

}


const defaultProps = {
    dataActions: {
        getAccountChannelSelector: () => { },

    },
    channels: [],
};
const propTypes = {
    dataActions: PropTypes.object,
    channels: PropTypes.array,
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
    if (state.marketPlannerChannelSelectorReducer)
        return {
            channels: state.marketPlannerChannelSelectorReducer.channels,
        };
    else {
        return {
            channels: state.channels,
        };
    }
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
    return {
        dataActions: bindActionCreators({ ...dataActions }, dispatch)

    };
}

ChannelSelectorComponent.propTypes = propTypes;
ChannelSelectorComponent.defaultProps = defaultProps;

export default withSitecoreContext()(
    withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ChannelSelectorComponent))
);


import { createAppState } from "modules/redux-app-state";
import {SET_ERROR_MESSAGE, CLEAR_ERROR_MESSAGE} from "modules/errormessage/actions/actionTypes.js";
const errorMessageState = createAppState();
let errorUtility;
export default class ErrorUtility {
    constructor() {
        if (errorUtility) { 
            return errorUtility;
        }

        errorUtility = this;
        
    }
    
    static logError(err, message, errorLevel='INFO' )
    {
        errorMessageState.store.dispatch({
            type: SET_ERROR_MESSAGE,
            message: message,
            errorLevel: errorLevel
        })
        console.error(err);
    }   
}
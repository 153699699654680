import { OPEN_SAVE_SEARCH, CLOSE_SAVE_SEARCH } from '../actions/actionTypes';
import { InitialState } from './initialState';
import UserUtility from 'utils/userutility';
const initialState = InitialState.search.saveSearchModalOpen;

/**
 * Reducer for download search
 * @param {object} state State of the downloaded content
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function saveSearch(state = initialState, action) {
    switch (action.type) {
    case OPEN_SAVE_SEARCH:
        if(!UserUtility.isLoggedIn()) {
            UserUtility.redirectToLogin();
            return;
        }
        return action.saveSearchModalOpen;
    case CLOSE_SAVE_SEARCH:
        return action.saveSearchModalOpen;
    default:
        return state;
    }
}

import * as actionTypes from  '../actions/actionTypes';
//import produce from "immer"

const initialState = {
    data:[],
    deleteSucess: null
};
  
export default function enquiriesReducer(state = initialState, action) {
    
    switch (action.type) {
    case actionTypes.SET_DATA:
        return Object.assign({}, state, {
            data: action.data,
        });

    case actionTypes.DELETE_MY_ENQUIRY_SUCCESS:
        const newArr = state.data.filter(item => item.organizationEnquiryId !== action.id);
        return { ...state, data: newArr };
    
    case actionTypes.DELETE_MY_ENQUIRY_FAIL:
        return Object.assign({}, state, {
            deleteSucess: false
        });
        
    default:
        return state;
    }
}

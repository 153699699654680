import { serialize } from 'utils/serialize';
import { filterquerymodifier } from 'utils/filterquerymodifier';
import { getAPIEndpoint } from 'utils/getapiendpoint';

const searchEndpointKey = 'exhibitorProductSearchUrl';

/**
 * Size of the page to fetch for products
 */
const PRODUCT_PAGE_SIZE = '16';

/**
 * Events API
 * todo: Add domain
 */
export default class ExhibitorProductAPI {
    /**
     * Constructor
     */
    constructor() {
        this.getProducts = this.getProducts.bind(this);
        this.getFilterProducts = this.getFilterProducts.bind(this);
        this.getFeaturedProducts = this.getFeaturedProducts.bind(this);
        this._apiKey = searchEndpointKey;
        // this.filterPath = 'http://qa-elb.imcinternal.com:80/search-api/exhibitor/{id}/products';
    }


    /**
     * Gets search results
     * @param {number} id Incoming props
     * @param {bool} isLine  is Line page
     * @param {string} pagenum of results
     * @returns {Promise.<T>} Promise object that returns the SearchCountAPI results
     */
    getProducts(id, isLine, pagenum) {
        if (isLine) {
            // eslint-disable-next-line max-len
            this._serviceUrl =
            `${window.baseAPIUrl}/search-api/line/$1/products?from=${pagenum ||
                1}&size=${PRODUCT_PAGE_SIZE}&sortorder=relevancy`.replace('$1', id);
            // this._serviceUrl = getAPIEndpoint(this._apiKey).replace('{id}', id);
        } else {
            const partialURL = getAPIEndpoint(this._apiKey);
            this._serviceUrl = `${partialURL}?from=${pagenum ||
                1}&size=${PRODUCT_PAGE_SIZE}&sortorder=relevancy`.replace('{id}', id);
        }


        return fetch(this._serviceUrl)
            .then(response => response.json());
    }

    /**
     * Gets a list of exhibitors based on provided search term
     * @param {string} query Query search
     * @param {object} filters object of selected filters
     * @param {string} exhibitId of current exhibitor
     * @param {bool} isLine  is Line page
     * @param {string} pagenum of results
     * @returns {Promise.<T>} Promise object that returns the ExhibitorsAPI results
     */
    getFilterProducts(query, filters = {}, exhibitId, isLine, pagenum) {
        let url;
        if (isLine) {
            url = `${window.baseAPIUrl}/search-api/line/$1/products`.replace('$1', exhibitId);
            // this._serviceUrl = getAPIEndpoint(this._apiKey).replace('{id}', id);
        } else {
            url = getAPIEndpoint(this._apiKey).replace('{id}', exhibitId);
        }
        let querystring = '';
        let sortorder = '';
        if (!filters.sortorder) {
            sortorder = 'relevancy';
        } else {
            sortorder = filters.sortorder;
        }
        const queryObj = {
            ...filters,
            sortorder,
            from: pagenum || 1,
            size: PRODUCT_PAGE_SIZE,
        };

        if (query && query !== '') {
            queryObj.q = query;
        }

        querystring = serialize(queryObj);
        querystring = filterquerymodifier(querystring);

        return (
            fetch(`${url}?${querystring}`)
            // .then(this.dummyExhibitorCallback)
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }


    /**
     * Gets a list of exhibitors based on provided search term
     * @param {string} query Query search
     * @param {object} filters object of selected filters
     * @param {string} exhibitId of current exhibitor
     * @param {bool} isLine  is Line page
     * @param {string} pagenum of results
     * @returns {Promise.<T>} Promise object that returns the ExhibitorsAPI results
     */
    getFeaturedProducts(query, filters = {}, exhibitId, isLine, pagenum) {
        let url;
        if (isLine) {
            url = `${window.baseAPIUrl}/search-api/line/$1/products`.replace('$1', exhibitId);
            // this._serviceUrl = getAPIEndpoint(this._apiKey).replace('{id}', id);
        } else {
            url = getAPIEndpoint(this._apiKey).replace('{id}', exhibitId);
        }
        let querystring = '';
        const featured = '1';
        const sortorder = 'relevancy';
        const queryObj = {
            ...filters,
            featured,
            sortorder,
            from: pagenum || 1,
            size: PRODUCT_PAGE_SIZE,
        };

        if (query && query !== '') {
            queryObj.q = query;
        }

        querystring = serialize(queryObj);
        querystring = filterquerymodifier(querystring);
        return (
            fetch(`${url}?${querystring}`)
            // .then(this.dummyExhibitorCallback)
                .then(response => response.json())
                .catch((err) => {
                    // todo: Error Handling
                    console.log('handle err here', err);
                })
        );
    }
}


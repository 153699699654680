// break up the text into phrases depending on the number of phrases
export const divideUpText = (text: string, n: any, recurse: boolean = true): any[] => {
    if (text.includes("<br>")) {
        return text.split("<br>");
    }
    if (n == "auto" || n == "" || n == "0" || n == 1) {
        return [text];
    }
    const words = text.split(' ').map((text) => text.replace(/&nbsp;/g, " "));
    const numWords = words.length;
    const lines = Math.min(parseFloat(n), numWords);
    const maxCharsPerLine = Math.ceil(text.length / lines);
    let phrases = [];
    let newPhrases = [];

    if (lines == words.length) {
        return words;
    }

    let currentLine = '';
    for (let i = 0; i < numWords; i++) {
        const word = words[i];
        if ((currentLine + ' ' + word).length <= maxCharsPerLine) {
            // Add the word to the current line
            currentLine += (currentLine.length > 0 ? ' ' : '') + word;
        } else {
            // Start a new line with the word
            if (i == 0) {
                currentLine = word;
                phrases.push(currentLine);
                currentLine = '';
            } else {
                phrases.push(currentLine);
                currentLine = word;
            }
        }
    }

    // Add the remaining line
    if (currentLine.length > 0) {
        phrases.push(currentLine);
    }
    if (recurse && phrases.length > lines) {
        newPhrases = divideUpText(text, lines - 1, false);
        if (newPhrases.length < lines) {
            let lastLine = phrases.pop();
            phrases[phrases.length - 1] += " " + lastLine;
            return phrases;
        } else {
            return newPhrases;
        }
    } else {
        return phrases;
    }
}

let letters = [
    2,  3,  4,  5,  6,  7,  8,  9, 10,
11, 12, 13, 14, 15, 16, 17, 18, 20, 22,
24, 26, 28, 30, 32, 34, 36, 40, 44, 48,
];

export const getCharacterLength = (containerWidth: any, wordLength: any): number => {
    let characters = Math.floor((1.0 / containerWidth) * wordLength);
    if (characters > 18) characters = 2 * Math.floor(characters / 2);
    if (characters > 48) characters = 48;
    return characters;
}
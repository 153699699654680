import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';

const propTypes = {
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  suppressRowHoverHighlight: PropTypes.bool,
  loading: PropTypes.bool,
};

const defaultProps = {
  columnDefs: [
    {
      headerName: "Test",
      field: "fieldName"
    }
  ],
  rowData: [
    { fieldName: "Test" },
  ],
  suppressRowHoverHighlight: true,
}

export const DataGrid = ({
  columnDefs,
  rowData,
  width = '100%',
  height = 'auto',
  suppressRowHoverHighlight = true,
}) => {
  return (
    <div className="ag-theme-alpine" style={{ width, height }}>
      <AgGridReact
        rowHeight={80}
        columnDefs={columnDefs}
        rowData={rowData}
        suppressRowHoverHighlight={suppressRowHoverHighlight}
        domLayout='autoHeight'
        overlayLoadingTemplate={"Loading results now. Please wait ..."}
        overlayNoRowsTemplate={"Check back closer to market for a list of Show Specials."}
      />
    </div>
  )
}

DataGrid.propTypes = propTypes;
DataGrid.defaultProps = defaultProps;

export default DataGrid;
import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {withSitecoreRouter} from 'utils/withRouter';
import PropTypes from "prop-types";
import { createAppState } from "modules/redux-app-state";
import { Loading } from "modules/loading";
// reducers
import exhibitedOnFloorReducer from "./reducers";
// action imports
import * as dataActions from "./actions/dataActions";

//utils
import { getDictionaryValue } from "utils/dictionary";
import {isMarketCtaShouldBeShown} from '../../utils/general';
//modules
import TypeAheadSearchForm from "../Type-Ahead-Search";
import Line from './components/Line';
import { exhibitorIsLine } from 'utils/exhibitor';


const TopHeaderName = "Top-Header-Component";
const TypeAheadSearchName = "Type-Ahead-Search";
const exhibitedOnFloorState = createAppState();
const searchFields = {
  "showMultiSelect": { "value": false },
  "showSort": { "value": false },
  "showShare": { "value": false },
  "disableTypeahead": { "value": true },
  "showClearSearch": { "value": false },
  "maxLength": { "value": "" },
  "hideSubmitButton": { "value": false },
  "minLengthForSearch": { "value": "0" },
  "showSavedSearches": { "value": false },
  "extraClass": { "value": "imc-searchform--global" },
  "resultsRedirectUrl": { "value": "/search/exhibitors" },
  "showMessage": { "value": false },
  "hideSortResultBy": { "value": false },
  "searchType": [],
  "sortResultsBy": [],
  "hideFilterBy": { "value": false },
  "hideSearchType": { "value": false }
};

class ExhibitedOnFloor extends React.Component {
  constructor(props) {
    super(props);
    exhibitedOnFloorState.reducerRegistry.register({
      exhibitedOnFloorReducer,
    });
    this.fetchData = this.fetchData.bind(this);
    this.selectSortFilter = this.selectSortFilter.bind(this);
    this.sortOption = this.sortOption.bind(this);
    this.setLoadingState = this.setLoadingState.bind(this);
    this.getFloorAndBuildingData(false);
    this.options=[];
    this.options.push(getDictionaryValue('sortFilter', 'Sort / Filter'));
		this.options.push(getDictionaryValue('sortAZ', 'Sort A-Z'));
    if (isMarketCtaShouldBeShown(this.props)) {
      this.options.push(getDictionaryValue('linesOnJuniperMarket', 'Lines on @Market'));
    }
    this.state = {
      query: "",
      hasProducts: false,
      shopZioAvailable: false,
      sortBy: "organizationname-asc",
      selectedFilter: "Sort A-Z",
      term: "",
      buildingId: this.buildingId,
      floorId: this.floorId,
      loading: true,
      noExhibitors: true
    };

    this.floorPlanTypeAheadProps = this.getTypeAheadSearchSettings();

  }


  getTypeAheadSearchSettings() {
    let floorPlanTypeAheadProps = {};
    this.floorPlanSearchState = this.props.sitecoreContext.route.placeholders["jss-header"];
    this.floorPlanSearchState.forEach((data) => {

      if(data.componentName === TopHeaderName) {

        data.placeholders["imc-header-placeholder"].forEach((typeAhead) => {

          if(typeAhead.componentName === TypeAheadSearchName) {
            floorPlanTypeAheadProps = typeAhead;
          };
        })
      }
    });

    return floorPlanTypeAheadProps;
  }

  getFloorAndBuildingData(updateState) {
    let routeParams = this.props.router.location.pathname.split("/");
    if (!routeParams.length - 3 < 0) {
      this.floorId = routeParams[routeParams.length - 1];
      this.buildingId = routeParams[routeParams.length - 3];

      if (updateState) {
        this.setState({
          buildingId: this.buildingId,
          floorId: this.floorId,
        });
      }
    }
  }

  /**
   * Sort Option
   * @param { string } type Query to update to the state
   */
  sortOption(sortBy) {
    if (sortBy === this.state.sortBy) sortBy = sortBy.replace("-asc", "-desc");

    this.setState({
      sortBy: sortBy,
    });
  }

  filterProdOnly() {
    this.setState({
      hasProducts: !this.state.hasProducts,
    });
  }

  filterShopZioOnly() {
    this.setState({
      shopZioAvailable: true,
    });
  }

  selectSortFilter(updatedName, updatedVal) {

    if (updatedName.currentTarget.value === getDictionaryValue('sortAZ', 'Sort A-Z')) {
        this.sortOption('organizationname-asc');
        this.setState({
          shopZioAvailable: false,
          selectedFilter:updatedName.currentTarget.value
        });
    } else if (updatedName.currentTarget.value === getDictionaryValue('linesOnJuniperMarket', 'Lines on @Market')) {
        this.filterShopZioOnly();
        this.setState({
         selectedFilter:updatedName.currentTarget.value
       });
    }
    else{
      this.setState({
        shopZioAvailable: false,
        selectedFilter:getDictionaryValue('sortFilter', 'Sort / Filter'),
        sortBy: "",
      });
    }
  }


  setLoadingState(loading) {
    this.setState({ loading })
  }

  /**
   * Kick off bound fetch data
   */
  componentDidMount() {
    if (this.buildingId !== null || this.floorId !== null) {
      this.fetchData(
        this.buildingId,
        this.floorId,
        500,
        this.state.term,
        this.state.hasProducts,
        this.state.shopZioAvailable,
        this.state.sortBy,
      );
    }
  }

  /**
   * Fetch the article data
   * @param { string } exhibitorId  used to getInfo
   * */
  fetchData(
    buildingId,
    floorId,
    count,
    term,
    hasProducts,
    shopZioAvailable,
    sortType
  ) {

    //set load state to true when loading
    this.setLoadingState(true);

    //TODO: replace for real dataActions
    this.props.dataActions.getExhibitedOnThisFloor(
      buildingId,
      floorId,
      count,
      term,
      hasProducts,
      shopZioAvailable,
      sortType,
      this.setLoadingState,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.router.location != prevProps.router.location) {
      //navigation to another floor
      if (this.props.router.location.pathname.includes("building/"))
        this.getFloorAndBuildingData(true);
    }

    if (prevState.loading !== this.state.loading) {
      return
    }

    if (this.compareStates(prevState, this.state)) {
      this.fetchData(
        this.state.buildingId,
        this.state.floorId,
        500,
        this.state.term,
        this.state.hasProducts,
        this.state.shopZioAvailable,
        this.state.sortBy
      );
    }
  }

  compareStates(prev,now)
  {
     return (prev.buildingId!=now.buildingId || prev.floorId != now.floorId || prev.term !=now.term || prev.hasProducts!=now.hasProducts
      || prev.shopZioAvailable!=now.shopZioAvailable || prev.sortBy!=now.sortBy);

  }
  customSubmit(term) {
    this.setState({
      term: term,
    });
  }

    getActionItem(exhibitor) {
        const isLine = (exhibitor.lineId);
        return {
            showroomName: isLine ? exhibitor.companyName : exhibitor.name,
            exhibitorId: exhibitor.exhibiterId,
            lines: isLine ? [{
                name: exhibitor.name,
                lineGuid: exhibitor.lineId,
            }] : [],
            lastVisited: null,
            photosCount: 0,
        }
    }

  renderLines(lines) {
    const { actions, loginUrl } = this.props;

    return (
      <div className={"imc-gallery am-grid imc-gallery--1-3 imc-gallery--1-2--tablet imc-gallery--1-1--mobile imc-gallery--50-50 imc-gallery--align-flex-stretch imc-gallery--justify-left imc-gallery--xlarge-padded"}>
        {lines.map((line, index) => {
          let newLine = {...line}
          if (line.lineId) {
            lines.map((exhibitor) => {
              if (exhibitor.lineId == null && exhibitor.exhibiterId == line.exhibiterId) {
                newLine.companyName = exhibitor.name;
              }
            });
          }
          return (
            <Line
              key={index}
              line={newLine}
              index={index}
              getActionItem={this.getActionItem}
              actions={actions}
              loginUrl={loginUrl}
            />
          )
        })}
      </div>
    );
  }

  renderNoResults() {
    const { actions, loginUrl } = this.props;

    return (
      <div
        className={
          "imc-content--display-flex-wrap imc-content--display-flex-center "
        }
      >
        <img src={`${process.env.PUBLIC_URL}/assets/images/noresults.svg`} />
        <p className="imc-heading--h5">Sorry, no results found.</p>
      </div>
    );
  }

  render() {
    const { lines, linesCount } = this.props;
    const { query, loading } = this.state;
    let haveExhibitors = true;
    const { showClearSearch } = this.floorPlanTypeAheadProps.fields;
    if (linesCount > 0 || this.state.hasProducts || this.state.shopZioAvailable) {
      haveExhibitors = true;
    }
    else {
      haveExhibitors = false;
    }

    return (
      <div className="imc-manufacturing-line--alternated-section imc-lines-overview  imc-section--padded-bottom-large imc-section--padded-left-large imc-section--padded-right-large imc-section--padded-top-large">
        <p style={{display: this.state.loading === true ? 'block' : 'none'}} className="imc-heading--h5">
          {getDictionaryValue("loadingExhibitorsPleaseWait", "Loading Exhibitors Please Wait ...")}
        </p>
        <p style={{display: haveExhibitors === false && this.state.loading === false ? 'block' : 'none'}} className="imc-heading--h5 hidden">
          {getDictionaryValue("noExhibitorsInFloor", "No Exhibitors on This Floor")}
        </p>
        <Loading
          showLoading={this.props.showLoading}
          showPageLoading={false}
        ></Loading>
        <div style={{display: haveExhibitors === true &&  this.state.loading === false ? 'block' : 'none'}} className="imc-content--display-flex-wrap imc-content--left-desktop">
          <span className="imc-heading--h5">
            {getDictionaryValue(
              "exhibitedOnThisFloor",
              "Exhibitors on This Floor"
            )}
          </span>
        </div>
        <div
          className={
            "imc-content--display-flex imc-content--display-flex-space-between imc-lines-overview-actions"
          }
        >
          <div
            className={
              "imc-content--display-flex imc-lines-overview-sort-bar"
            }
          >
              <TypeAheadSearchForm
                formClass="imc-type-ahead imc-searchform__form"
                inputClass="imc-searchform__input"
                btnClass="imc-button imc-searchform__submit"
                secClass="imc-searchform imc-exhibited"
                query={query}
                inputId="floor-plan-search"
                showClearSearch
                forceShowClearSearch = {showClearSearch.value}
                mobileSearchButton
                fields={searchFields}
                placeholderText={getDictionaryValue("filterByName", "Filter By Name")}
                customSubmit={(label, suggestion) => this.customSubmit(label, suggestion)}
                disableTypeahead={true}
              />

              <div className={"imc-searchform--section"}>
              <div className={"imc-formfield imc-dropdown imc-floor-plan--dropdown-container"}>
                <select
                    className={'imc-searchform--dropdown imc-floor-plan--dropdown'}
                    onChange={(event) => this.selectSortFilter(event)}>
                    {this.options.map((option, index) => <option key={index} selected={option === this.state.selectedFilter} value={option}>{option}</option>)}
                </select>
            </div>
             {/* <DropDown
                        additionalClass={'imc-searchform--dropdown'}
                        firstOption={'Sort / Filter'}
                        hideLabel
                        label={'Sort / Filter'}
                        name={'Options'}
                        options={this.options}
                        updateValue={this.selectSortFilter}
                        value={this.selectedFilter}
            />*/}

              </div>
          </div>
        </div>
        <div className={"imc-content--padded-top-bottom"}>
          {lines && lines.length > 0 && this.renderLines(lines)}
          {this.state.loading === true || !lines || (lines && lines.length == 0) && this.renderNoResults()}
        </div>

      </div>

    );

  }
}

const defaultProps = {
  dataActions: {},
  getInfoActions: {},
  actions: {},
  lines: [],
  linesCount: 0,
};
const propTypes = {
  dataActions: PropTypes.object,
  actions: PropTypes.object,
  getInfoActions: PropTypes.object,
  lines: PropTypes.array,
  linesCount: PropTypes.number,
};

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{ search: *}} State to props mapping
 */
function mapStateToProps(state) {
  let _props = {};
  if (state.exhibitedOnFloorReducer)
    Object.assign(_props, {
      lines: state.exhibitedOnFloorReducer.lines,
      linesCount: state.exhibitedOnFloorReducer.linesCount,
      showLoading: state.exhibitedOnFloorReducer.showLoading,
      term: state.exhibitedOnFloorReducer.term,
      sortBy: state.exhibitedOnFloorReducer.sortBy,
      hasProducts: state.exhibitedOnFloorReducer.hasProducts,
      shopZioAvailable: state.exhibitedOnFloorReducer.shopZioAvailable,
      buildingId: state.exhibitedOnFloorReducer.buildingId,
      floorId: state.exhibitedOnFloorReducer.floorId,
      selectedFilter : state.exhibitedOnFloorReducer.selectedFilter
    });
  else
    Object.assign(_props, {
      lines: state.lines,
      linesCount: state.linesCount,
      showLoading: state.showLoading,
      term: state.term,
      sortBy: state.sortBy,
      hasProducts: state.hasProducts,
      shopZioAvailable: state.shopZioAvailable,
      buildingId: state.buildingId,
      floorId: state.floorId,
      selectedFilter : state.selectedFilter
    });
  return _props;
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
 */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

ExhibitedOnFloor.propTypes = propTypes;
ExhibitedOnFloor.defaultProps = defaultProps;

export default withSitecoreContext()(
  withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ExhibitedOnFloor))
);

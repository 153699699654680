import {
    SHOW_LOADING,
    SET_VIDEOS,
    SET_VIDEO_COUNT,
    SET_VIDEOS_ERR,
} from './actionTypes';
import VideosAPI from 'api/search/videos';


/**
 * Instantiates the Images API
 * @type {SearchImagesAPI}
 * @private
 */
const _searchVideos = new VideosAPI();

/**
 * Action to get videos
 * @param {array} tags List of tags to pass into the API
 * @param {string} pagenum of page numbers
 * @returns {function(*)} Promise object with fetch to the SearchVideos
 */
export function fetchVideos(tags = [], pagenum, pagesize, datasourceIds) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _searchVideos.getVideos(tags, pagenum, pagesize, datasourceIds).then((response) => {
            const { itemsList, totalCount } = response || {};
            // TO-DO Remove this, they need to send a status :|
            const status = 200;
            if (status === 200) {
               
                dispatch({
                    type: SET_VIDEOS,
                    videos: itemsList,
                    count: totalCount,
                });
                dispatch({
                    type: SET_VIDEO_COUNT,
                    count: totalCount,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_VIDEOS_ERR,
                    status,
                });
                dispatch({
                    type: SET_VIDEO_COUNT,
                    count: totalCount,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}



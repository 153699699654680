import {
    SET_MARKET_PLANNER_CHANNEL_SELECTOR,SET_MARKET_PLANNER_CHANNEL_SELECTOR_ERR
} from './actionTypes';
import AccountAPI from 'api/account';

/**
 * Instantiates the Account API
 * @type {AccountAPI}
 * @private
 */
const _accountApi = new AccountAPI();

/**
 * Action to get Digital Showroom Info
 * @param {array} accountId Id of Account
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getAccountChannelSelector() {
    return (dispatch) => {
        return _accountApi.getAccountChannelSelector().then((response) => {
            if(!!response)
            dispatch({
                type: SET_MARKET_PLANNER_CHANNEL_SELECTOR,
                channels: response,
            });
            else
            dispatch({
                type: SET_MARKET_PLANNER_CHANNEL_SELECTOR_ERR,
                
            });
        });
    }
}

import {
    SET_EXHIBITOR_DETAIL,
    SET_EXHIBITOR_DETAIL_ERR,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {
    exhibitor: {},
    showLoading: true,
};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function digitalShowroomLinesReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_EXHIBITOR_DETAIL:
            return Object.assign({}, state, {
                exhibitor: {},
                showLoading: true
            });
        case SET_EXHIBITOR_DETAIL_ERR:
            return Object.assign({}, state, {
                exhibitor: {},
                errCode: action.status || 404,
            });
        default:
            return state;
    }
}
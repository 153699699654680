import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

import { getExhibitorURL, getExhibitorLineURL } from '../../../utils/exhibitor';
import { divideArrays } from 'utils/general/index';
import Link from '../../Link';

const EXHIBITOR_TYPE = "exhibitor";
const SCREEN_WIDTH = [
  {
    name: 'MOBILE_LANDSCAPE',
    width: 480,
    columns: 1,
  },
  {
    name: 'MED_TABLET',
    width: 768,
    columns: 2,
  },
  {
    name: 'DESKTOP',
    width: 992,
    columns: 3,
  },
  {
    name: 'WIDE_DESKTOP',
    width: 1366,
    columns: 4,
  },
]

function ExhibitorList({ exhibitors = [], loading = false, noData = false }) {
  const [columnCount, setColumnCount] = useState(null);
  useEffect(() => {
    createColumns()
    window.addEventListener('resize', createColumns)

    // Cleans up event listener when unmounting
    return (() => window.removeEventListener('resize', createColumns))
  })

  const createColumns = () => {
    if (window) {
      const width = window?.innerWidth;
      let screenType = SCREEN_WIDTH[0];;

      SCREEN_WIDTH.forEach((item) => {
        if (width >= item.width) {
          screenType = item;
        }
      })
      setColumnCount(screenType.columns)
    }
  }

  const generateLink = (exhibitor) => {
    if (exhibitor.type === EXHIBITOR_TYPE) {
      return getExhibitorURL(exhibitor?.exhibitorId)
    }
    return getExhibitorLineURL(exhibitor?.exhibitorId, exhibitor?.lineId)
  }

  const getColumnLength = () => {

    return Math.ceil(exhibitors.length / columnCount);
  }

  const renderExhibitors = () => {
    if (exhibitors.length === 0) return
    const columnLength = getColumnLength();
    const columns = divideArrays(exhibitors, columnLength)
    return columns.map((col, colIndex) => {
      return (
        <div key={`col${colIndex}`} className='exhibitor-list--section'>
          {col.map((item, i) => {
            return <Link
              key={`${item.exhibitorId} ${i}`} className='exhibitor-list--item'
              href={generateLink(item)}>
              <span class="exhibitor-list--item-text imc-type--body-3" title="{item.name}">{item.name}</span>{(item.type != EXHIBITOR_TYPE) && <span class="exhibitor-list--line-indicator imc-type--body-3">&nbsp;|&nbsp;Shown By {item.exhibitorName}</span>}
            </Link> 
          })}
        </div>
      )
    })
  }

  const scrollToTop = () => {
    if (window) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

  return (
    <>
      <button onClick={scrollToTop} className='az-directory--top'><i className='az-directory--arrow-up' />Top</button>
      {noData && <p className='exhibitor-list--loading'>No Data Available</p>}
      {loading && <p className='exhibitor-list--loading'>Loading exhibitors please wait ...</p>}
      {!loading && <div className='exhibitor-list--container'><div class="exhibitor-list--section-spacer">&nbsp;</div>{renderExhibitors()}</div>}
    </>
  )
}

ExhibitorList.propTypes = {
  exhibitors: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
}

export default React.memo(ExhibitorList);

/**
 * If the user has downloaded the search
 * * @type {string}
 */
export const SEARCH_SAVED = 'SEARCH_SAVED';

/**
 * Download Search
 * * @type {string}
 */
export const DOWNLOAD_SEARCH = 'DOWNLOAD_SEARCH';

/**
 * Open Save Search
 * * @type {string}
 */
export const OPEN_SAVE_SEARCH = 'OPEN_SAVE_SEARCH';

/**
 * Close Save Search
 * * @type {string}
 */
export const CLOSE_SAVE_SEARCH = 'CLOSE_SAVE_SEARCH';

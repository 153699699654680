import pushDataLayer from "../dataLayer";
import UserUtility from "../../userutility/index";
import { getGtmEnvironmentName } from '../../../AppGlobals';

class PageTracking{


    constructor() {
        this.type = undefined;
        this.category = undefined;
        this.title = undefined;
        this.path = undefined;

        this.setPageData = this.setPageData.bind(this);
        this.push = this.push.bind(this);
    }

    /**
    * set basic page data
    *
    * @param {string} type one of the types listed here: https://docs.google.com/spreadsheets/d/1ccj2mvu3fth083Mr1BJskf2tt-tF37Qd_PjbhCqjjhQ/edit?ts=5f739ee2#gid=1735816907
    * @param {string} category
    * */
    setPageData(type, category, title){
        this.type = type;
        this.category = category;
        this.title = title;
    }

    getUser(){
        var user = UserUtility.getUserData();
        var isLogged = UserUtility.isLoggedIn();
        var userType = [];

        if (!!user && !!user.demandDriverId)
            userType.push("Buyer");

        let userData =  {
            //'authenticationStatus': isLogged,
            'rdsId': !!user?user.accountId:null,
            'email': !!user?user.email:null,
            'exhibitorGroupId': !!user?user.organizationId:null,
            //'registrationStatus': true,
            'loggedin': isLogged,
            'newOrReturning': true,
            // 'primaryInterestCategory': 'Funiture',
            // 'secondaryInterestCategory': 'Kitchenware',
           // 'type': userType.join(",")
            //'registrationDate': '08/27/2020',
            //'role': 'Attendee'
        };

        if (!!window && !!window.userProfile){
            const p = window.userProfile
            if (!!p.revenueGenerators){
                userType.push("Exhibitor");
            }
            userData.type = userType.join(",");
            if (!!p.digitalIdentity && Array.isArray(p.digitalIdentity.categories)){
                if (p.digitalIdentity.categories.length>0){
                    userData.primaryInterestCategory =p.digitalIdentity.categories[0].value;
                }
                if (p.digitalIdentity.categories.length>1){
                    userData.secondaryInterestCategory =p.digitalIdentity.categories[1].value;
                }
            }
        }
        if (!userData.type){
            userData.type = "Buyer";
        }
        return userData;
    }

    /**
     * Puhses recorded data to GTM datalayer
     */
    push(){

        const EVENT_NAME = "pageview";
        const data = {
            event: EVENT_NAME,
            page: {
              ecosystem: 'LVM Web',
              environment: getGtmEnvironmentName(),
              type: this.type,
              category: this.category,
              title: this.title,
              path: document.location.pathname,
          },
            'user': this.getUser()
        };

        pushDataLayer(data);
    }
}

export default PageTracking;

import React, { Component } from 'react';
import ExtendedTooltip from '../../components/ExtendedTooltip';

export default function withTooltip(WrappedComponent) {

  return class Enhancer extends React.Component {
    TooltipId = null;
    
    render() {
      
      const {fields} = this.props;
      //run the replacement code (probably not here and maybe only if experience editor is not active )
      if (fields) {
        if (JSON.stringify(fields).indexOf("{Tooltip:")>0){
            Object.keys(fields).forEach(x => {
            this.props.fields[x] = this.traverseElement(this.props.fields[x]);
          });
        }
      }
      var tooltipProps={ fields:{TooltipKey: this.props.fields?.TooltipKey||{ fields:{content: null}}, TooltipId:this.TooltipId}};
      if(!!tooltipProps.fields.TooltipId || (!!tooltipProps.fields.TooltipKey && !!tooltipProps.fields.TooltipKey.fields.content))
        return <div class="imc-newtooltip_wrapper"><WrappedComponent {...this.props} /><ExtendedTooltip {...tooltipProps}></ExtendedTooltip></div>
      else return <WrappedComponent {...this.props} />;
    }

    traverseElement(element) {
      
      if (element && typeof element === 'string') {
          if (element.indexOf("{Tooltip:") > 0) {
            const regex = /.*{Tooltip:\s?(\w*)}/;
            const found = element.match(regex);
            if(found){
              element = element.replace("{Tooltip:" + found[1] + "}", "");
              this.TooltipId = found[1];
            }
          }
      }
      else if (element && typeof element === 'object') 
      {
        Object.keys(element).forEach(x => {
          element[x] = this.traverseElement(element[x]);
        });
          
      }
      return element;
    }
  };
}

import React from 'react';
import { getDictionaryValue } from 'utils/dictionary/index';

/**
 * Render MORE Cta for card tags
 * @param {object} props incoming props
 * @returns {XML} More CTA component
 */
export default function MoreCta(props) {
    const { remainingTags = 0,  destinationUrl } = props;
    const updatedText = getDictionaryValue('showMoreTags',`+${remainingTags} More Tags`, { count: remainingTags});
    return (
        <a
            className={`imc-content--delta imc-content--light imc-link imc-link--alt
            imc-content--alt-darkred imc-eventlanding--padding-top-xsmall`}
            href={destinationUrl}
        >
            {updatedText}
        </a>
    );
}


import React from "react";
import {
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {withSitecoreRouter} from 'utils/withRouter';
import PropTypes from "prop-types";
import { createAppState } from "modules/redux-app-state";

// reducers
import digitalShowroomProductsOverviewReducer from "./reducers";
import digitalShowroomInfoReducer from "../Digital-Showroom-Info/reducers/digitalShowroomInfoReducer";
// action imports
import * as dataActions from "./actions/dataActions";

//modules
import ProductsStripe from 'modules/productsStripe';
//utils
import { locationIsLine } from 'utils/exhibitor'

const digitalShowroomState = createAppState();
const SPECFLOW_ID = "digitalShowRoomProducts";


class DigitalShowroomProductsOverview extends React.Component {
  constructor(props) {

    super(props);
    digitalShowroomState.reducerRegistry.register({
      digitalShowroomProductsOverviewReducer,
      digitalShowroomInfoReducer
    });
    this.fetchData = this.fetchData.bind(this);
    const { sitecoreContext } = this.props;
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
      this.exhibitorId = sitecoreContext.exhibitor.id;
    }
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
      this.exhibitorId = sitecoreContext.exhibitor.id;
      this.lineId = sitecoreContext.lineProduct.lineId;
    }

  }

  /**
 * Kick off bound fetch data
 */
  componentDidMount() {
    if (this.exhibitorId !== null || this.lineId !== null)
      this.fetchData(this.exhibitorId, this.lineId);
  }

  componentDidUpdate(prevProps) {
    const { sitecoreContext } = this.props;
    let exhibitorId, lineId;
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
      exhibitorId = sitecoreContext.exhibitor.id;
    }
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
      exhibitorId = sitecoreContext.exhibitor.id;
      lineId = sitecoreContext.lineProduct.lineId;
    }

    if (lineId != this.lineId || exhibitorId != this.exhibitorId) {
      this.exhibitorId = exhibitorId;
      this.lineId = lineId;
      this.fetchData(this.exhibitorId, this.lineId);
    }

  }

  /**
 * Fetch the article data
 * @param { string } exhibitorId  used to getInfo
 */
  fetchData(exhibitorId, lineId) {
    const pageId = this.props.sitecoreContext.route.itemId;
    if (locationIsLine(this.props.router.location)) {
      this.props.dataActions.getDigitalShowroomLineProductsOverview(lineId, this.props.sitecoreContext.route.itemId);
    }
    else {
      this.props.dataActions.getDigitalShowroomProductsOverview(exhibitorId, this.props.sitecoreContext.route.itemId);
    }
    this.props.dataActions.getExhibitorDetail(exhibitorId, pageId);
  }

  render() {
    const { products, productsCount, shopZioURL, router : {location} } = this.props;

    if (productsCount > 0) {
      return (
        <div id={SPECFLOW_ID} className="imc-exhibitors--alternated-section imc-section--padded imc-section--full-width-mobile imc-content--display-flex imc-content--display-flex-grow-1">
          <div className="imc-section imc-section--width-100-percent">
            <ProductsStripe
              products={products}
              productsCount={productsCount}
              shopZioURL={shopZioURL}
              location={location}
              sitecoreContext = {this.props.sitecoreContext}
            ></ProductsStripe>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}
const defaultProps = {
  dataActions: {
    getDigitalShowroomContactInfo: () => { },
  },
  getInfoActions: {
    getDigitalShowroomProductsOverview: () => { },
  },
  products: [],
  productsCount: 0,
};
const propTypes = {
  dataActions: PropTypes.object,
  getInfoActions: PropTypes.object,
  products: PropTypes.array,
  productsCount: PropTypes.number,
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
  let _props = {};
  if (state.digitalShowroomProductsOverviewReducer)
    Object.assign(_props, {
      products: state.digitalShowroomProductsOverviewReducer.products,
      productsCount: state.digitalShowroomProductsOverviewReducer.productsCount,
      showLoading: state.digitalShowroomProductsOverviewReducer.showLoading,
    });
  if (state.digitalShowroomInfoReducer) {
    Object.assign(_props, {
      shopZioURL: state.digitalShowroomInfoReducer.shopZioURL,
    });
  }
  return _props;
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

DigitalShowroomProductsOverview.propTypes = propTypes;
DigitalShowroomProductsOverview.defaultProps = defaultProps;

export default withSitecoreContext()(
  withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomProductsOverview))
);

import { GET_EXHIBITORSPECIALS_PRODUCTS_SUCCESS, GET_EXHIBITORSPECIALS_PRODUCTS_FAIL, SHOW_LOADING, END_LOADING } from '../actions/actionTypes';
import { InitialState } from './initialState';
/**
 * Reducer for loader actions
 * @param {object} state State of the exhibitor categories
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function exhibitorSpecialsReducer(state = InitialState.exhibitorSpecials.isLoading, action) {
    
    switch (action.type) {
    case GET_EXHIBITORSPECIALS_PRODUCTS_SUCCESS:
        return {
            ...state,
            specials: action.specials,
            specialsCount: action.count,
            specialsUpToDate: true,
            error: false
        };

    case GET_EXHIBITORSPECIALS_PRODUCTS_FAIL:
        return {
            ...state,
            error: true,
            specials: [],
            specialsCount: 0,
            specialsUpToDate: false,
        };

    case SHOW_LOADING:
        return {
            ...state,
            isLoading: true
        };

    case END_LOADING:
        return {
            ...state,
            isLoading: false
        };

    default:
        return state;
    }
}

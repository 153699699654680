export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SHOW_LOADING = 'SHOW_LOADING';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const CHANGE_COMPANY_NAME = 'CHANGE_COMPANY_NAME';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_ALL_PRODUCT_INTEREST = 'GET_ALL_PRODUCT_INTEREST';
export const UPDATE_PRODUCT_INTERESTS = 'UPDATE_PRODUCT_INTERESTS';
export const GET_ALL_ATTENDEE_TYPES = 'GET_ALL_ATTENDEE_TYPES';
export const UPDATE_ATTENDEE_TYPE = 'UPDATE_ATTENDEE_TYPE';
export const GET_ALL_COMPANY_TYPES = 'GET_ALL_COMPANY_TYPES';
export const UPDATE_COMPANY_TYPES = 'UPDATE_COMPANY_TYPES';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_ZIP_CODE = 'UPDATE_ZIP_CODE';
export const UPDATE_COMPANY_NAME = 'UPDATE_COMPANY_NAME';
export const ADD_NEW_EMAIL = 'ADD_NEW_EMAIL';
export const DELETE_EMAIL = 'DELETE_EMAIL';
export const SET_EMAIL_AS_DEFAULT = 'SET_EMAIL_AS_DEFAULT';
export const GET_NEXT_MARKET_REGISTER_NOW_BUTTON = 'GET_NEXT_MARKET_REGISTER_NOW_BUTTON';
export const GET_FUTURE_MARKET_LINK_REGISTRATION_BUTTON = 'GET_FUTURE_MARKET_LINK_REGISTRATION_BUTTON';
export const LINK_MY_MARKET_REGISTRATION = 'LINK_MY_MARKET_REGISTRATION';
export const ASSOCIATE_EXHIBITOR = 'ASSOCIATE_EXHIBITOR';
export const UPDATE_FAIL = 'UPDATE_FAIL';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
import React,{Component} from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import TooltipAPI from 'api/tooltip';
const questionMarkIcon = process.env.PUBLIC_URL + '/assets/icons/tooltip.png';
const closeIcon = process.env.PUBLIC_URL + '/assets/icons/close.svg';
const tooltipAPI = new TooltipAPI();
class ExtendedTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, 
      pending: false,
      error: false,
      tooltipData: null,
      position: {direction:'ltr'}
    };

    this.openToolTip = this.openToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.checkBounds = this.checkBounds.bind(this);
    this.getTooltipData = this.getTooltipData.bind(this);

  }
  openToolTip() {
    this.setState({ open: true });
  }
  closeToolTip() {
    this.setState({ open: false });
  }

  componentDidMount() {
    
    document.addEventListener('mousedown', this.handleClickOutside);
    
  }

  checkBounds=(e)=>{
    if ((e.clientX) > window.innerWidth * 0.76)   
        this.setState({position:{direction:'rtl'}});
  }
  
  getTooltipData=(id)=>{
      return tooltipAPI.getTooltip(id);
  }
componentDidUpdate(){
  const { fields } = this.props;
  
  if(!!fields.TooltipKey && this.props.fields.TooltipId && this.state.open && !this.state.error && !this.state.pending)
    this.getTooltipData(fields.TooltipId).then((result)=>this.setState({tooltipData: result})).catch(this.setState({error:true})).finally(this.setState({pending:false}));
}

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeToolTip();
    }
  }

  render() {
    const { fields} = this.props;
    const { open, tooltipData, position } = this.state;

    const visible = !!fields.TooltipKey || !!fields.TooltipId || !!fields.data?.datasource?.TooltipKey;
  
    return (<>
              { visible && 
                  <div class="imc-newtooltip imcNewtooltip2" ref={(e) => this.wrapperRef = e} style={position}>
                    <svg
                        width="16"
                        height="16"
                        role="img"
                        aria-labelledby="btn-search-title btn-search-desc"
                        onClick={(e)=>{this.openToolTip();this.checkBounds(e)}}
                    ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#tooltip" />
                    </svg>
                   
                    {!!open && <div class="imc-newtooltip_popup">
                    <svg className="imc-newtooltip_close"
                        width="16"
                        height="16"
                        role="img"
                        aria-labelledby="btn-search-title btn-search-desc"
                        onClick={this.closeToolTip} 
                        ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"  />
                      </svg>
                        <RichText cssClass={fields.TooltipKey?.fields?.cssClass} field={{value:fields.TooltipKey?.fields?.content?.value||fields.data?.datasource?.TooltipKey?.value||tooltipData?.Content}} />
                      </div>
                    }
                </div>
            }
            </>);
 
  }
}
export default ExtendedTooltip;

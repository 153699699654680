import React, { Component } from 'react';
import { createAppState } from 'modules/redux-app-state';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Checkbox } from 'modules/formelements';
import { getDictionaryValue } from 'utils/dictionary';
import Link from '../Link';
import * as actions from './actions/feActions';
import listDisplayReducer from './reducers/reducer';
import {withSitecoreRouter} from 'utils/withRouter';
import { isMobile } from "react-device-detect";

const theState = createAppState();

class ListDisplayControlComponent extends Component {
  constructor(props) {
    super(props);

    theState.reducerRegistry.register({ listDisplayReducer });

    this.changeViewModeClicked = this.changeViewModeClicked.bind(this);
    this.groupByExhibitor = this.groupByExhibitor.bind(this);
    this.back = this.back.bind(this);
  }

  changeViewModeClicked(mode) {
    if (this.props.viewMode != mode) {
      this.props.actions.setViewMode(mode);
    }
  }

  groupByExhibitor(name, value) {
    this.props.actions.setGroupedByExhibitor(value);
  }
  back() {
    this.props.router.navigate(-1);
  }

  render() {
    const gridExtraCss = this.props.viewMode == "List" ? "imc-button--no-border" : "";
    const listExtraCss = this.props.viewMode == "Grid" ? "imc-button--no-border" : "";
    return (
      <div className="imc-gallery imc-market-planner-list-display imc-vr--large">
        <div className="imc-content--padded-large-left imc-breakpoint-display--hide-mobile">
          <div className="imc-market-planner-list-display__back-icon">
            <Link className="imc-type--title-1-ui-link" href={getDictionaryValue("backToAllListsLink", "/Market-Planner/Lists")}>{getDictionaryValue("backToAllLists", "Back to All Lists")}</Link>
          </div>
        </div>
        <div className="imc-gallery__item imc-gallery__item--push-right imc-breakpoint-display--hide-mobile">
          <div className="imc-gallery">
            <div className="imc-gallery__item">
              {this.props.viewMode != "Grid" && <Checkbox
                additionalClass="imc-type--title-3-ui imc-type--color-neutral-heavy-medium imc-padding--top--xsmall"
                checkboxClasses="imc-checkbox--small"
                name="GroupbyExhibitor"
                label={getDictionaryValue("groupByExhibitor", "Group by exhibitor")}
                checked={this.props.isGrouped}
                updateValue={this.groupByExhibitor}
              />}
            </div>
            <div className="imc-gallery__item">
              <button onClick={() => this.changeViewModeClicked("Grid")} className={"imc-icon-Grid imc-button--icon-only imc-padding--all--xsmall " + gridExtraCss}></button>
              <button
                onClick={() => this.changeViewModeClicked("List")}
                className={"imc-icon-List imc-button--icon-only imc-padding--all--xsmall " + listExtraCss}
              ></button>
            </div>
          </div>
        </div>

        {/* back go back to lists from editing a list */}
        <div className="imc-breakpoint-display--hide-desktop imc-content--display-flex imc-content--display-flex-space-between imc-content--display-flex-center imc-section--margin-medium imc-breakpoint-display--hide-desktop">
          <a
            onClick={() => this.back()}
            className="imc-button--primary-inverted imc-content--display-flex imc-content--display-flex-center"
          >
            <svg width="16" height="16">
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconBackArrow"></use>
            </svg>
          </a>
        </div>

        {/* <p>{JSON.stringify(this.props)}</p> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  if (!!state.listDisplayReducer)
    return {
      viewMode: state.listDisplayReducer.viewMode,
      isGrouped: state.listDisplayReducer.isGrouped,
    }
  else {
    return {
      viewMode: state.viewMode,
      isGrouped: state.isGrouped,
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ListDisplayControlComponent));


/**
 * Fetches Exhibitors based on provided query
 * @type {string}
 */
export const GET_EXHIBITORS = 'GET_EXHIBITORS';

/**
 * Fetches the predefined searches
 * @type {string}
 */
export const GET_PREDEFINED_SEARCH = 'GET_PREDEFINED_SEARCH';

/**
 * Updates exhibitors of interest
 * @type {string}
 */
export const SET_EXHIBITORS = 'SET_EXHIBITORS';

/**
 * Set Filters Relevant to exhibitors & products
 * * @type {string}
 */
export const SET_EXHIBITOR_FILTERS = 'SET_EXHBITOR_FILTERS';

/**
 * Action to get filters for exhibitors & products
 * @type {string}
 */
export const GET_EXHIBITOR_FILTERS = 'GET_EXHIBITOR_FILTERS';

/**
 * Action to setting updated exhibitor count
 * @type {string}
 */
export const SET_EXHIBITOR_COUNT = 'SET_EXHIBITOR_COUNT';

/**
 * Action to set exhibitor sorting options
 * @type {string}
 */
export const SET_EXHIBITOR_SORT = 'SET_EXHIBITOR_SORT';

/**
 * Action to set exhibitor jump to values
 * @type {string}
 */
export const SET_EXHIBITOR_JUMPTO = 'SET_EXHIBITOR_JUMPTO';

/**
 * Action to set exhibito search err
 * @type {string}
 */
export const SET_EXHIBITOR_SEARCH_ERR = 'SET_EXHIBITOR_SEARCH_ERR';

/**
 * Action to make search data out of data
 * @type {string}
 */
export const SET_EXHIBITOR_DATA_OUT_OF_DATA = 'SET_EXHIBITOR_DATA_OUT_OF_DATA';

/**
 * Action to set exhibitor jump to err
 * @type {string}
 */
export const SET_EXHIBITOR_JUMPTO_ERR = 'SET_EXHIBITOR_JUMPTO_ERR';

/**
 * Action to set exhibitor filter err
 * @type {string}
 */
export const SET_EXHIBITOR_FILTERS_ERR = 'SET_EXHIBITOR_FILTERS_ERR';

/**
 * Action to set exhibitor sort order err
 * @type {string}
 */
export const SET_EXHIBITOR_SORT_ERR = 'SET_EXHIBITOR_SORT_ERR';
/**
 * Action to set selected filters
 * @type {string}
 */
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
/**
 * Success for saved searches details
 * @type {string}
 */
export const GET_MARKETPLANNER_SAVED_SEARCH_SUCCESS = 'GET_MARKETPLANNER_SAVED_SEARCH_SUCCESS';

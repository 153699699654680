import React from 'react';
import { Link, Image, isEditorActive } from '@sitecore-jss/sitecore-jss-react';

const SocialIconListComponent = (props) => {
  if (!!!props.rendering.fields || !!!props.rendering.fields.data) {
    if (isEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    }
    return (
      <h1 className="alarm">
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }
  return (<div className="imc-optin-social">
    {
      props.rendering.fields.data.model && props.rendering.fields.data.model.itemList && props.rendering.fields.data.model.itemList.map((item, key) =>
        <div className="imc-optin-social__item imc-vr--large" key={key}>
          <Link
            field={item.link.data}
            className="imc-socialicons"
          >
            {item.icon.data && item.icon.data.value.svgCode !== undefined &&
              <div dangerouslySetInnerHTML={{__html:item.icon.data.value.svgCode}} />
            }
            {item.icon.data && item.icon.data.value.svgCode === undefined &&
              <Image className="imc-image--responsive" media={item.icon.data} />
            }
          </Link>
        </div>
      )
    }
  </div>);
};

export default SocialIconListComponent;

import React from 'react';
import { Image,Placeholder, withSitecoreContext, RichText, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Moment from 'react-moment';
import ImcDataLayer from 'utils/datalayer';

class Article extends React.Component {
  constructor(props) {
    super(props);
    
    this.key = [];
    
    if (!props) {
      if (isEditorActive()) {
          return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
          <h1 className="alarm">
              Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    this.fields = props.sitecoreContext.route.fields;
    if(this.fields['Tags']){
      this.keys = this.fields['Tags'].map(item => { return <span class="imc-blog-tag-module__tag">{item.fields.name.value}</span>  });
    }
    this.image = [];
    if(this.fields['Preview Image']){
      this.image = this.fields['Preview Image'].value
      this.image.width = null;
      this.image.height = null;
    }
    
    ImcDataLayer.pushArticleViewEvent(this.fields['Article Title'].value, props.sitecoreContext.itemId, '', !!this.fields['Publish Date']?this.fields['Publish Date'].value:'');
  }


  
  render() {
    const {rendering } = this.props;

    return (
      <section id="content" class="imc-section imc-section--full-width">
	      <div class="imc-static-hero imc-section imc-section--full-width imc-content--center">
          <Image className="imc-image--responsive" field={this.image} ></Image>
        </div>
      <section className="imc-section imc-eventdetail__box--padding imc-section--padded-inner-mobile imc-content imc-section--alt-gray-light ">
          <div className="imc-section--padded-inner imc-gallery imc-gallery--13-87  imc-gallery__item--center">
            <div className="imc-eventdetail__no-author-img imc-circle-image-text__image  imc-eventdetail__type--secondarycolor imc-content--center-mobile imc-circle-image-text__image--nopadding imc-circle-image-text__image--nomargin imc-vr--xlarge" data-xpath="article.details">
              <div class="imc-content--medium imc-eventdetail__type--secondarycolor">
              <Moment format="MMMM DD, YYYY">
                {this.fields['Publish Date'] && this.fields['Publish Date'].value}
              </Moment>
              </div>
            </div>

          </div>
          <h1 className="imc-content--center-mobile imc-content--padded-mobile imc-content--padded-xlarge-desktop imc-heading imc-heading--kilo imc-section--padded-inner">
          {this.fields['Article Title'] && this.fields['Article Title'].value}
          </h1>	
          <section class="imc-rich-text imc-section    ">
              <RichText  field={this.fields['Body']} />
          </section>
          
          <div className="imc-blog-tag-module imc-vr--titan imc-breakpoint-display--hide-mobile"> 
            <span className="imc-blog-tag-module__head">Tag:</span> 
            {/* <span className="imc-blog-tag-module__tag"></span>      */}
            {this.keys}
          </div>
        </section>
        <Placeholder name="imc-article-content" rendering={rendering}/>
      </section>
    );
}
}
export default withSitecoreContext()(Article);

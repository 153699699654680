import {
    GET_ALLSPECIALS_PRODUCTS_SUCCESS,
    GET_ALLSPECIALS_PRODUCTS_FAIL,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {
    specials: [], 
    specialsCount: 0,
    exhibitorId: null,
    lineId: null,
    showLoading: true,
};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function digitalShowroomSpecialsOverviewReducer(state = initialState, action) {
    
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case GET_ALLSPECIALS_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                specials: action.specials, 
                specialsCount: action.specialsCount,
                showLoading: true
            });
        case GET_ALLSPECIALS_PRODUCTS_FAIL:
            return Object.assign({}, state, {
                specials: initialState.specials, 
                specialsCount: initialState.specialsCount,
                errCode: action.status || 404,
            });
        default:
            return state;
    }
}
import DownloadResultsAPI from '../../../api/search/download';
import SaveSearchAPI from '../../../api/search/saveSearch';
import * as types from './actionTypes';
/**
 * Action to download search results
 * @param {string} filterKey Filter key indicating which filters to use
 * @returns {Function} Dispatcher used to download search results
 */
export function downloadSearch(filterKey) {
    const _downloadSearch = new DownloadResultsAPI();
    return _downloadSearch.downloadResults(filterKey);
}



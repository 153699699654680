/**
 * Success for getting quick add modal list
 * @type {string}
 */
export const GET_EXHIBITORSPECIALS_PRODUCTS_SUCCESS = 'GET_EXHIBITORSPECIALS_PRODUCTS_SUCCESS';

export const GET_EXHIBITORSPECIALS_PRODUCTS_FAIL = 'GET_EXHIBITORSPECIALS_PRODUCTS_FAIL';

export const SHOW_LOADING = 'SHOW_LOADING';

export const END_LOADING = 'END_LOADING';
import React from 'react';
import { getDictionaryValue } from 'utils/dictionary';

import Link from '../../Link';

import {
    getExhibitorURL, productCategoriesToArray, getTitleName
  } from 'utils/exhibitor';
import { getBooth } from 'utils/floorplan';

class ExhibitorModal extends React.Component {
    constructor(props) {
        super(props);
        this.renderContactInfo = this.renderContactInfo.bind(this);
    }

    isLocalMarket(lease) {
        return (typeof window !== 'undefined' && 'channel' in lease && lease.channel.siteCode === window.channel.name);
    }

    getLocalMarket(exhibitor) {

    return exhibitor.activeLeases && exhibitor.activeLeases.filter(this.isLocalMarket);
    }

    renderLogo() {
        const { exhibitor } = this.props;
        if (!exhibitor.companyLogoImage){
            return null;
        }

        return (
                <div className="imc-exhibitors--detail-image-container">
                  <img
                    src={exhibitor.companyLogoImage}
                    alt={exhibitor.companyName}
                    className="imc-exhibitors--detail-image"></img>

                </div>
            );
      }


      renderLocations(exhibitor) {
          const localLease = this.getLocalMarket(exhibitor);

        if (localLease) {

          //let currentShowroom = localLease.showrooms.filter(sr=>sr.showroomBuildingName==building && sr.showroomFloor==floorNo);
          let currentShowroom= [];
          localLease.forEach((lease)=>currentShowroom = currentShowroom.concat(lease.showrooms));
          //currentShowroom.push();
          return (
            <div className={'imc-content--block imc-type--title-6'}>
              {currentShowroom.length>0 && <span className="imc-icon-Location imc-margin--right--xxsmall imc-icon--center"></span>}
              {
                currentShowroom.map((location, index)=>{
                  let response;
                  response = (
                      <span className={`imc-content--inline-block}`}>
                        {getBooth(location.showroomBuildingName, location.showroomFloor, location.showroom)}
                      </span>
                    );
                  return (
                    <span key={index} className="imc-content--pipe">
                      {response}
                    </span>
                  );

                })}
            </div>
          );
        }
        else {
          return <></>;
        }
      }

    renderContactInfo(exhibitor){
        if (!exhibitor.contactInfo)
        return null;

        return(
            <div className="imc-content--full-width">
                {exhibitor.contactInfo.primaryPhoneNo &&
                    <div className="imc-vr--xsmall">
                        <p className="imc-type--title-4" >
                            {exhibitor.contactInfo.primaryPhoneNo}
                        </p>
                    </div>
                }

                 {(!!exhibitor.companySite) &&
                    <div className="imc-vr--xsmall">
                        <a className="imc-type--title-5-link"
                           href={exhibitor.companySite}
                           target="new">
                               {exhibitor.companySite}
                        </a>
                    </div>
                }
                {(exhibitor.contactInfo.directoryContactEmail) &&
                    <div className="imc-vr--xsmall">
                        <a className="imc-type--title-5-link"
                           href={"mailto:" + exhibitor.contactInfo.directoryContactEmail}
                           target="new">
                               {exhibitor.contactInfo.directoryContactEmail}
                        </a>
                    </div>
                }
            </div>
        );
    }

    render() {
        const { exhibitor, building, floorNo  } = this.props;
        let categories = undefined;

        if (Array.isArray(exhibitor.productCategories) && exhibitor.productCategories.length > 0 )
            categories = productCategoriesToArray(exhibitor.productCategories);

        let ctrl = (
            <div className="imc-map-dockRight" style={{maxHeight: this.props.maxHeight}}>
                {/* <ReactModal
                    ariaHideApp={false}
                    isOpen={this.props.showModal}
                    overlayClassName="imc-modal--overlay"
                    className="imc-modal--box imc-modal--box-height-auto"> */}
                <div>
                    <div className="imc-content--padded-medium-desktop">
                        <div className="imc-gallery imc-gallery__item--flexcolumn imc-gallery--align-flex-start-desktop">

                            <div className="imc-gallery__item  imc-vr--xlarge imc-content--center-mobile">
                                <h5 className="imc-type--color-primary-medium">{getTitleName(exhibitor)}</h5>
                                <div className="imc-type--body-5">Exhibitor</div>
                            </div>
                            <div className="imc-gallery__item imc-vr--xlarge imc-gallery__item--self-stretch imc-content--center-mobile">
                                {this.renderLogo()}
                            </div>
                            <div className="imc-gallery__item imc-vr--xlarge imc-content--center-mobile">
                                {this.renderLocations(exhibitor)}
                                <div className="imc-type--title-7">{building}</div>
                                <div className="imc-type--title-7">Floor {floorNo}</div>
                            </div>
                            <div className="imc-gallery__item imc-gallery__item--self-stretch imc-gallery__item--text-center imc-content--center-mobile imc-vr--xlarge">
                                <Link className="imc-button imc-content--center-margin" href={getExhibitorURL(exhibitor.exhibitId)}>
                                    {getDictionaryValue("viewDigitalShowRoom", "View Digital Showroom")}
                                </Link>
                            </div>
                            <div className="imc-gallery__item imc-content--center-mobile imc-vr--xlarge">
                                {!!categories &&
                                <div className="imc-gallery imc-gallery--justify-left imc-gallery--1-2">
                                    {categories.slice(0, 15).map((category)=> {
                                        return <div className="imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right imc-type--title-8 imc-padding--bottom--xsmall" key={category.id}>{category.name}</div>;
                                    })
                                    }
                                </div>
                                }
                                {(Array.isArray(exhibitor.productCategories) && exhibitor.productCategories.length === 0) &&
                                <span className="imc-content--neutral-medium">
                                    {getDictionaryValue('productCategoriesNotAvailable', 'Product Categories not available')}
                                </span>
                                }
                            </div>
                            <div className="imc-gallery__item imc-content--center-mobile">
                                {this.renderContactInfo(exhibitor)}
                            </div>
                        </div>
                    </div>
                    <button onClick={this.props.closePopup} className="imc-map-dock--close imc-modal--close imc-button--modal-close imc-button--round"></button>

                {/* </ReactModal> */}
                </div>
            </div>
        );
        return ctrl;
    }
}

ExhibitorModal.defaultProps = {
    showModal: true,
    onOK: ()=>{console.log("on OK")},
    closePopup: ()=>{console.log("on closePopup")},
    UID: undefined,
    loading:true
};

export default ExhibitorModal;

import React from 'react';
import {newComponent, contentSectionComponentRendering, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'
import ContentSectionComponent from '../Content-Section-Component';

interface HeroGenericProps {
    fields?: any;
}

const HeroGeneric: React.FC<HeroGenericProps> = ({fields}) => {
    const backgroundSection = contentSectionComponentRendering();
    let contentSection = contentColumnSectionRendering(fields);

    // ------------------------ //
    // build background section //
    // ------------------------ //
    backgroundSection.fields.backgroundColor = newComponent(fields.backgroundColor);
    backgroundSection.fields.backgroundImage = newComponent(fields.image);
    backgroundSection.fields.styles = {}
    if (fields?.videoFile?.value?.href) {
        backgroundSection.fields.videoFile = {
            value: {
                src: fields.videoFile.value.href,
                description: fields.videoFile.value.description
            }
        };
    }
    if (fields?.backgroundColor?.value || fields?.backgroundOpacity?.value || fields?.backgroundOverride?.value) {
        backgroundSection.fields.backgroundColor.value += ' imc-hero__overlay';
        if (fields?.backgroundColor?.value) {
            backgroundSection.fields.backgroundColor.value += ` imc-hero__overlay--${fields.backgroundColor.value}`;
        }
        if (fields?.backgroundOpacity?.value) {
            backgroundSection.fields.styles["--opacity"] = `${fields.backgroundOpacity.value}%`;
        }
        if (fields?.backgroundOverride?.value) {
            backgroundSection.fields.styles["--overlay"] = `${fields.backgroundOverride.value}`;
        }
    }
    if (fields.minHeight?.length > 0) {
        fields.minHeight.forEach((height?: any) => {
            if (height.fields?.className?.value) {
                if (height.fields.className.value.includes("CLASS-")) {
                    backgroundSection.fields.backgroundColor.value += ` imc-hero__min-height${height.fields.className.value.split("CLASS")[1]}`;
                } else {
                    backgroundSection.fields.styles["minHeight"] = `${height.fields.className.value}`;
                }
            }
        });
    }
    backgroundSection.fields.backgroundColor.value += ` am-hero`;
    // ------------------------ //

    // ------------------------ //
    // build  content  section  //
    // ------------------------ //
    fields.headingExtraClasses.unshift({
        fields: {
            className: {
                value: "HEADING-1"
            }
        }
    });
    let spreadLines = false;
    if (fields?.headingExtraClasses?.length > 0) {
        fields.headingExtraClasses.forEach((c?: any) => {
            if (c.fields?.className?.value.includes("andmore-width-") || c.fields?.className?.value.includes("andmore-line-")) {
                fields.headingExtraClasses.unshift( {
                    fields: {
                        className: {
                            value: "andmore-width-99"
                        }
                    }
                });
            }
        });
    }
    contentSection = contentColumnSectionRendering(fields);
    contentSection.fields.backgroundColor = newComponent(fields.accentColor);
    if (fields?.extraClasses?.length > 0) {
        fields.extraClasses.forEach((c?: any) => {
            if (c.fields?.className?.value) {
                contentSection.fields.backgroundColor.value += ` ${c.fields.className.value}`;
            }
        });
    }
    if (fields?.accentColor?.value || fields?.accentOpacity?.value || fields?.accentOverride?.value) {
        contentSection.fields.backgroundColor.value += ' imc-hero__overlay';
        if (fields?.accentColor?.value) {
            contentSection.fields.backgroundColor.value += ` imc-hero__overlay--${fields.accentColor.value}`;
        } 
        if (fields?.accentOpacity?.value || fields?.accentOverride?.value) {
            contentSection.fields.styles = {}
        }
        if (fields?.accentOpacity?.value) {
            contentSection.fields.styles["--opacity"] = `${fields.accentOpacity.value}%`;
        }
        if (fields?.accentOverride?.value) {
            contentSection.fields.styles["--overlay"] = `${fields.accentOverride.value}`;
        }
    }
    contentSection.fields.paddingTop.value = 'jumbo';
    contentSection.fields.paddingBottom.value = 'jumbo';
    contentSection.fields.paddingLeft.value = 'xlarge';
    contentSection.fields.paddingRight.value = 'xlarge';
    // ------------------------ //

    backgroundSection.placeholders['imc-jss-content-section'].push(contentSection);

    return (
        <ContentSectionComponent {...backgroundSection} rendering={backgroundSection} />
    
    );

//     return (
// <div>
//     <h1>{fields.headline.value}</h1>
//     <p>{fields.body.value}</p>
// </div>
//     );
};

export default HeroGeneric;

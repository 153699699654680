// Library dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserUtility from 'utils/userutility';
import PropTypes from 'prop-types';
import Link from '../../Link'; 
import { toggleDrawerState } from 'modules/marketplan/actions/actions'



/**
 * Component for displaying a navigation mobile item (tier 1) and maintaining its state
 */
class NavigationMobileTier1 extends Component {

    /**
     * @method constructor
     * @description On instantiation dynamically sets the tier 1 navigation item
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.itemsref = [];
        this.setTier1 = this.setTier1.bind(this);
        this.renderTiers = this.renderTiers.bind(this);
    }

    /**
     * Checks animation for keyboard focus
     */
    componentDidMount() {
        // detect if focus should be triggered
        if (this.props.currentTier1 === -1 &&
            this.props.currentTier2 === -1) {
            setTimeout(() => {
                if (this.props.eventType !== 'click') {
                    this.itemsref[0].focus();
                }
            }, 500);
        }
    }

    /**
     * Sets the current tier1 item
     * @param {object} e Event object
     * @param {number} idx Tier 1 index number
     */
    setTier1(e, idx) {
        const code = e.keyCode || e.which;

        if (code === undefined || code === 13 || code === 32) {
            this.props.setTier1(idx, e.type);
        }
    }

    /**
     * Renders the Tier 1 menu display
     * @returns {Array} Array of JSX tier 1 menu items
     */
    renderTiers() {
        
        const {isLoggedIn}=this.props;
        const tier1 = [];
        const navigationItems = this.props.items.filter((item) => item.type.name === "Navigation 1st Level Mobile");
        let indexCount = 0;
        [].forEach.call(navigationItems, (item, index) => {
            
            const linkProps = {
                role: 'menuitem',
                tabIndex: index,
                className: 'imc-link imc-link--invert  imc-link--to-upper-case imc-link--icon',
                ref: (node) => { this.itemsref[index] = node; },
                target: item.target === 'internal' ? '_self' : '_blank',
            };

            linkProps.onClick = e => this.setTier1(e, index);
            linkProps.onKeyDown = e => this.setTier1(e, index);
            const active = (this.props.currentTier1 === index) ? 'imc-navigation-mobile__tierItem--activetier1' : '';
            if((isLoggedIn && item.nodeName==="Login") || (!isLoggedIn && item.nodeName==="Logout"))
                return;
            tier1.push(
                <div
                    key={index}
                    className={`imc-navigation-mobile__tierItem ${active}`}
                    data-xpath="navigation.mobile.tier1link"
                >
                    <a {...linkProps} 
                        
                    >
                        <span 
                        dangerouslySetInnerHTML={{ __html: item.title && item.title.value }}></span>
                        <svg width="36" height="36" className="imc-link--icon__arrow-right">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconForwardArrow"></use>
                        </svg>
                    </a>
                </div>,
            );
            indexCount = index;
        });
        indexCount++;
        const linkItems = this.props.items.filter((item) => item.type.name === "Link");
        [].forEach.call(linkItems, (item, index) => {
            if((isLoggedIn && item.nodeName==="Login") || (!isLoggedIn && item.nodeName==="Logout")) {
                return;
            } else if (item.nodeName==="MarketPlan") {
                if (isLoggedIn && this.props.isRegistered) {
                    tier1.push(
                        <div key={indexCount + linkItems.length} className={`imc-navigation-mobile__tierItem`}>
                            <a tabIndex={linkItems.length}
                                onClick={() => {
                                    this.props.closeMenu();
                                    this.props.dispatch(toggleDrawerState(true));
                                }}
                                className="imc-link imc-link--invert" style={{ backgroundColor: `${(item?.color?.fields?.hexColor?.value) ? item.color.fields.hexColor.value : `#ec5115`}` }}>Market Plan</a>
                        </div>
                    );
                }
            } else if (item.nodeName==="Login") {
                tier1.push(
                    <div key={indexCount + index} className={`imc-navigation-mobile__tierItem`}>
                        <a
                            onClick={(event) => {
                                event.preventDefault();
                                UserUtility.redirectToLogin();
                                this.props.closeMenu()
                            }} tabIndex={index}
                            className="imc-link imc-link--invert"
                            style={{ backgroundColor: !!item.color && item.color.fields.hexColor.value }}
                        >
                                {item.link.value.text}
                        </a>
                    </div>,
                );
            } else if (item.nodeName==="Logout") {
                tier1.push(
                    <div key={indexCount + index} className={`imc-navigation-mobile__tierItem`}>
                        <a href={item.link.value.href} tabIndex={index} onClick={() => this.props.closeMenu()} className="imc-link imc-link--invert" style={{ backgroundColor: !!item.color && item.color.fields.hexColor.value }}>{item.link.value.text}</a>
                    </div>,
                );
            } else if (item.nodeName==="Register") {
                if (!this.props.isRegistered) {
                    tier1.push(
                            <div key={indexCount + linkItems.length} className={`imc-navigation-mobile__tierItem`}>
                                <Link field={item.link} tabIndex={linkItems.length} onClick={() => this.props.closeMenu()} className="imc-link imc-link--invert" style={{ backgroundColor: !!item.color && item.color.fields.hexColor.value }}></Link>
                            </div>,
                    );
                }
            } else if (item.link) {
                tier1.push(
                    <div key={indexCount + index} className={`imc-navigation-mobile__tierItem`}>
                        <Link field={item.link} tabIndex={index} onClick={() => this.props.closeMenu()} className="imc-link imc-link--invert" style={{ backgroundColor: !!item.color && item.color.fields.hexColor.value }}></Link>
                    </div>,
                );
            }
        });

        return tier1;
    }

    /**
     * @method render
     * @description Renders the Mobile Navigation DOM element
     * @returns {*} Rendered component
     */
    render() {
        return (
            <>
                {this.renderTiers()}
            </>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{items}}
 */
NavigationMobileTier1.propTypes = {
    currentTier1: PropTypes.number.isRequired, // Current selected tier 1
    currentTier2: PropTypes.number.isRequired, // Current selected tier 1
    items: PropTypes.array, // Array of items
    setTier1: PropTypes.func.isRequired, // Function to set the tier 1 item
    windowWidth: PropTypes.number, // Current window width
    eventType: PropTypes.string, // Event type of how the menu is being navigated
    // translateX: PropTypes.string, // Current translateX (for focus detection)
};

/**
 * @property defaultProps
 * @type {{overviewLabel: string}}
 */
NavigationMobileTier1.defaultProps = {
    windowWidth: '768px',
    eventType: 'click',
    translateX: '0px',
};

const mapStateToProps = (state) => {
    return {
        isRegistered: state.marketPlanReducer.isRegistered,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMobileTier1);
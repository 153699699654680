import {
    SET_MARKET_PLANNER_MY_INFO,
    SET_MARKET_PLANNER_MY_INFO_ERR,
    SHOW_LOADING
} from './actionTypes';
import {GET_MY_INFO_MOCK} from './mockData';
import AccountAPI from 'api/account';
import UserUtility from '../../../utils/userutility';

/**
 * Instantiates the Account API
 * @type {AccountAPI}
 * @private
 */
const _accountApi = new AccountAPI();

function getCheckedItemDisplayValue(data, valueType) {
    return ((data.dropDown[valueType].some((type) => !!+type.checked )) ? data.dropDown[valueType].find((type) => !!+type.checked).displayValue : '');
}

/**
 * Action to get Digital Showroom Info
 * @param {array} accountId Id of Account
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getAccountMyInfo() {
    const userInfo = UserUtility.getUserData();
    const channelInfo = UserUtility.getChannelData();
    let accountId = userInfo.accountId;
    let channel = channelInfo.name;

    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });
        return _accountApi.getAccountMyInfo(accountId, channel).then((response) => {
            if (typeof response !== 'undefined') {
                console.log(response);
                dispatch({
                    type: SET_MARKET_PLANNER_MY_INFO,
                    account: response,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_MARKET_PLANNER_MY_INFO_ERR,
                    status: typeof response !== 'undefined' ? response.code : false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}

export function fetchMockAccountMyInfo() {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        window.setTimeout (()=>{
                dispatch({
                    type: SET_MARKET_PLANNER_MY_INFO,
                    account: GET_MY_INFO_MOCK,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }, 1000);
    };
}
/**
 * Base class for promotions events
 */
class PromotionEvent{
    
    constructor() {
        this.promotions = [];
        this.add = this.add.bind(this);
    }


    /**
     * Returns a promotion object
     * @param {string} name 
     * @param {string} id 
     * @param {string} creative
     * @param {string} position The position of the creative (e.g. zone1, zone2, zone3, etc for homepage zones).
     * @returns {any} a promotion object
     */
      getPromotion(name, id, creative,position ){
        return {
            name: name,
            id: id,
            creative: creative,
            position: position
        };
    }


    /**
     * add one promotion impression to the collection to be pushed later.
     * @param {string} name 
     * @param {string} id 
     * @param {string} creative
     * @param {string} position The position of the creative (e.g. zone1, zone2, zone3, etc for homepage zones).
     */
    add (name, id, creative,position){
        const promotion = this.getPromotion(name, id, creative,position);
        this.promotions.push(promotion);
    }
}

export default PromotionEvent;
/**
 * Action to set Images on success
 * @type {string}
 */
export const SET_IMAGES = 'SET_IMAGES';

/**
 * Action to set count on image fetch
 * @type {string}
 */
export const SET_IMAGES_COUNT = 'SET_IMAGES_COUNT';

/**
 * Action to set error on Image fetch failure
 * @type {string}
 */
export const SET_IMAGE_ERR = 'SET_IMAGE_ERR';

/**
 * Used for updates when new search is ran, etc
 * marking any current results as out of date
 * @type {string}
 */
export const RUN_SEARCH = 'RUN_SEARCH';

/**
 * Show Results Tabs
 * @type {string}
 */
export const SHOW_LOADING = 'SHOW_LOADING';

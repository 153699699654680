import React from 'react';
import FlexGrid from '../Flex-Grid';
import BasicSingleColumnComponent from '../Basic-Single-Column-Component';
import RichTextComponent from '../Rich-Text-Component';
import ImcCta from '../Imc-cta';
import StandaloneImageComponent from '../Standalone-Image-Component';
import {newComponent, contentSectionComponentRendering, richTextRendering, flexGridRendering, imcCtaRendering, standaloneImageRendering, returnMediaSection, returnRichTextHeading, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'


// ---------------------- //
// define props interface //
// ---------------------- //
interface BasicCardBorderProps {
    fields?: any;
};
// ---------------------- //

// ------------------------ //newComponent
// Basic Two Column Section //
// ------------------------ //
const BasicCardBorder: React.FC<BasicCardBorderProps> = ({fields}) => {
    const defaultJson = require('./props.json');
    let cardComponent = newComponent(defaultJson);

    cardComponent.fields.headline = newComponent(fields.headline);
    cardComponent.fields.subheading = newComponent(fields.subheading);
    cardComponent.fields.body = newComponent(fields.body);
    cardComponent.fields.image = newComponent(fields.image);
    cardComponent.fields.videoFile = newComponent(fields.videoFile);
    cardComponent.fields.primaryCTA = newComponent(fields.primaryCTA);
    cardComponent.fields.linkHeadline = newComponent(fields.linkHeadline);
    cardComponent.fields.linkImage = newComponent(fields.linkImage);

    if (fields?.headingExtraClasses?.length > 0) cardComponent.fields.headingExtraClasses = newComponent(fields.headingExtraClasses);
    if (fields?.primaryCTAclass?.length > 0) cardComponent.fields.primaryCTAclass = newComponent(fields.primaryCTAclass);
    if (fields?.backgroundColor?.value) cardComponent.fields.backgroundColor = newComponent(fields.backgroundColor);
    if (fields?.accentColor?.value) cardComponent.fields.accentColor = newComponent(fields.accentColor);
    if (fields?.headlineOverride?.value) cardComponent.fields.headlineOverride = newComponent(fields.headlineOverride);
    if (fields?.accentOverride?.value) cardComponent.fields.accentOverride = newComponent(fields.accentOverride);
    if (fields?.backgroundOverride?.value) cardComponent.fields.backgroundOverride = newComponent(fields.backgroundOverride);

    return (
        <BasicSingleColumnComponent {...cardComponent} rendering={cardComponent} />
    );
};
// ------------------------ //

export default BasicCardBorder;

import {
    SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW,
    SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW_ERR,
    SHOW_LOADING,
    SET_LINE_DETAIL_RELATED_PRODUCTS_OVERVIEW,
    SET_LINE_DETAIL_RELATED_PRODUCTS_OVERVIEW_ERR,
} from './actionTypes';
import ExhibitorsAPI from 'api/exhibitor';

/**
 * Instantiates the Exhibitors API
 * @type {ExhibitorsAPI}
 * @private
 */
const _exhibitorsApi = new ExhibitorsAPI();



/**
 * Action to get Digital Showroom Info
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getDigitalShowroomRelatedProductsOverview(exhibitorId, categories) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getDigitalShowroomRelatedProductsOverview(exhibitorId, categories).then((response) => {
            if (response) {
                dispatch({
                    type: SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW,
                    products: response.data,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}



/**
 * Action to get Digital Showroom Info
 * @param {array} lineId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getDigitalShowroomLineRelatedProductsOverview(lineId, categories) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getDigitalShowroomLineRelatedProductsOverview(lineId, categories).then((response) => {
            if (response) {
                dispatch({
                    type: SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW,
                    products: response.data,
                    productsCount: response.count,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}







import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from '../../../common-components/Button/Button'
import Image from 'modules/image/components/Image';
import { getExhibitorURL } from 'utils/exhibitor';
import UserUtility from 'utils/userutility';
import EventsUtility from '../../../utils/events/index.ts';
import Link from "../../Link";
const CEU = "CEU";


export default class EventCard extends PureComponent {
  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    eventData: PropTypes.object.isRequired,
    learnMoreLabel: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.ceu = false;

    this.props.eventData.tags.map((tag) => {
      if (tag.toUpperCase() === CEU) {
        this.ceu = true;
      }
    });

    this.renderDates = this.renderDates.bind(this);
  }

  renderDates() {
    const { startDate, endDate } = this.props;

    if (startDate === endDate) {
      return <p>{startDate}</p>
    }

    return <p>{startDate} - {endDate}</p>
  }

  openLinkNewWindow(url){
    window.open(url, "_blank")
  }

  render() {
    const { eventData, learnMoreLabel } = this.props;

    return (
      <div className='event-card--container'>
        <Image
          className='event-card--image'
          src={eventData.mainImage}
        />
        <div className='event-card--info'>
          <div className='event-card--child-container'>
            <div className='event-card--title--container'>
              <Link
                href={eventData.destinationUrl}
              >
                <p className='event-card--title'>
                  {eventData.title}
                </p>
              </Link>
            </div>
            <div className='event-card--right-container'>
              <p className='event-card--event-type'>{eventData.eventType ? eventData.eventType.toUpperCase() : null}</p>
              {eventData.exhibitorName && <Link href={getExhibitorURL(eventData.exhibitorId)} className='event-card--exhibitor-name'>{eventData.exhibitorName}</Link>}
            </div>
          </div>
          {this.renderDates()}
          <div>
            <p>{eventData.time.startTime} - {eventData.time.endTime} {eventData.time.timezone ? eventData.time.timezone.title : (UserUtility.getUserData().timeZone ? UserUtility.getUserData().timeZone : "")}</p>
          </div>
          {/* Location */}
          <div className='event-card--location'>
            {

              eventData.location.length >= 1 && (
              <p>
                <span className="imc-icon-Location imc-margin--right--xxsmall imc-icon--center event-card--location-icon"></span>
                {
                  `${EventsUtility.getEventLocation(eventData.location[0]).DisplayLocation}`
                }
              </p>
            )}
          </div>
          {/* popup button */}
          {/* <div className='event-card--actions'>
            <AddToFavorites item={this.getAddToListData(eventData)} iconSize={18} />
            <PopupExpand formatData={this.getAddToListData} item={eventData} />
          </div> */}

          <div className='event-card--child-container event-card--child-container--bottom'>
            {this.ceu && (
              <span className='event-card--ceu'>
                {CEU}
              </span>)}
            <div className='event-card--register-div'>
              <Link
                className="imc-link--caret-after imc-type--body-1-link"
                href={eventData.destinationUrl}
              >
                {learnMoreLabel?.value}
              </Link>
              {eventData.registrationLink &&
                  <Button
                      displayName={(eventData.ctaTitle) ? eventData.ctaTitle : "Register"}
                name = 'event-card-cta'
                cssClass = "event-card--event-action-btn"
                onClick={() =>{this.openLinkNewWindow(eventData.registrationLink)} }
                />
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_PRODUCT_DETAIL_ERR = 'SET_PRODUCT_DETAIL_ERR';

/**
 * Show Loading
 * @type {string}
 */
export const SHOW_LOADING = 'SHOW_LOADING';

/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW = 'SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW_ERR = 'SET_SHOWROOM_RELATED_PRODUCTS_OVERVIEW_ERR';

/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_LINE_DETAIL_RELATED_PRODUCTS_OVERVIEW = 'SET_LINE_DETAIL_RELATED_PRODUCTS_OVERVIEW';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_LINE_DETAIL_RELATED_PRODUCTS_OVERVIEW_ERR = 'SET_LINE_DETAIL_RELATED_PRODUCTS_OVERVIEW_ERR';
import pushDataLayer from '../../dataLayer';
import PromotionEvent from "./promotionsBaseEvent";

class PromotionImpressions extends PromotionEvent{
    constructor() {
        super();
        this.push = this.push.bind(this);
    }

    /**
     * Puhses recorded data to GTM datalayer
     */
    push(){
        if (this.promotions.length==0)
            return;

        const EVENT_NAME = "promotionImpressions";
        const data = {
            event: EVENT_NAME,
            ecommerce: {
                promoView: {
                  promotions: this.promotions
                }
            }
        }
        
        pushDataLayer(data);
    }
}

export default PromotionImpressions;
/**
 * Returns numbers only when passing in the string
 * @param {string} str String to pass in
 * @param {boolean} returnAsString Outputs as either a string or number
 * @param {boolean} returnZero If output is NaN, determines if it should return 0 or blank
 * @param {boolean} allowZeros Determines if all zeros in the string should be allowed (i.e. 01, etc)
 * @returns {string} Final formatted number string
 */
export function numbersonly(str, returnAsString, returnZero, allowZeros) {
    let final = allowZeros ? str.replace(/\D/g, '') : parseFloat(str.replace(/\D/g, ''));

    if (isNaN(final)) {
        final = (returnZero) ? 0 : '';
    }
    return (returnAsString) ? final.toString() : final;
}

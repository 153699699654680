import {
    serialize
} from 'utils/serialize';
import {
    getSitecoreApiHost,
    getSitecoreApiKey
} from '../AppGlobals';
import ErrorUtility from 'utils/errorutility';
import { getDictionaryValue } from 'utils/dictionary';

export default class UserProfileAPI {
    constructor() {
    }

    getUserProfile(accountId, channel) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/account/${accountId}/profile?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'channel': channel,
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response.json();

                    throw new Error(getDictionaryValue('error.profileData', 'An error ocurred while getting user profile data.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.profileData', 'An error ocurred while getting user profile data.'), 'ERROR');
                })
        );
    }

    changeCompanyName(userId, companyName) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/change-company-name?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            id: userId,
            companyName: companyName
        };
        return (
            fetch(url, {
                method: 'put',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.updateCompanyName', 'An error ocurred while updating company name.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.updateCompanyName', 'An error ocurred while updating company name.'), 'ERROR');
                })
        );
    }

    resetPassword(userId) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/reset-password?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ accountId: userId })
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.resetPassword', 'An error ocurred while reseting password.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.resetPassword', 'An error ocurred while reseting password.'), 'ERROR');
                })
        );
    }

    getAllProductInterest() {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/product/interests?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url)
                .then(function (response) {
                    if (response != null && response.ok)
                        return response.json();

                    throw new Error(getDictionaryValue('error.updateCompanyName', 'An error ocurred while updating company name.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.updateCompanyName', 'An error ocurred while updating company name.'), 'ERROR');
                })
        );
    }

    updateProductInterests(accountId, categories) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/product/interests?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            AccountId: accountId,
            Categories: categories
        };
        console.log(data);
        return (
            fetch(url, {
                method: 'put',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.updateProductInterest', 'An error ocurred while updating product interests.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.updateProductInterest', 'An error ocurred while updating product interests.'), 'ERROR');
                })
        );
    }

    getAllAttendeeTypes() {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/attendee/types?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url)
                .then(function (response) {
                    if (response != null && response.ok)
                        return response.json();

                    throw new Error(getDictionaryValue('error.attendeeTypes', 'An error ocurred while getting attendee types.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.attendeeTypes', 'An error ocurred while getting attendee types.'), 'ERROR');
                })
        );
    }

    updateAttendeeType(accountId, attendeeTypeKey, attendeeTypeValue) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/attendee/types?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            accountId: accountId,
            attendeeType: {
                key: attendeeTypeKey,
                value: attendeeTypeValue
            }
        };
        return (
            fetch(url, {
                method: 'put',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.updateAttendeeType', 'An error ocurred while updating attendee type.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.updateAttendeeType', 'An error ocurred while updating attendee type.'), 'ERROR');
                })
        );
    }

    getAllCompanyTypes() {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/company/types?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url)
                .then(function (response) {
                    if (response != null && response.ok)
                        return response.json();

                    throw new Error(getDictionaryValue('error.companyNames', 'An error ocurred while getting company names.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.companyNames', 'An error ocurred while getting company names.'), 'ERROR');
                })
        );
    }

    updateCompanyTypes(accountId, companyTypeKey, companyTypeValue) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/company/types?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            accountId: accountId,
            companyType: {
                key: companyTypeKey,
                value: companyTypeValue
            }
        };
        return (
            fetch(url, {
                method: 'put',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.updateCompanyType', 'An error ocurred while updating company type.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.updateCompanyType', 'An error ocurred while updating company type.'), 'ERROR');
                })
        );
    }

    updateZipCode(accountId, zipCode) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/zip/code?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            AccountId: accountId,
            ZipCode: zipCode
        };
        return (
            fetch(url, {
                method: 'put',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.updateZipCode', 'An error ocurred while updating zip code.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.updateZipCode', 'An error ocurred while updating zip code.'), 'ERROR');
                })
        );
    }

    updateCompanyName(accountId, companyName) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/company/name?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            accountId: accountId,
            companyName: companyName
        };
        return (
            fetch(url, {
                method: 'put',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.updateCompanyName', 'An error ocurred while updating company name.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.updateCompanyName', 'An error ocurred while updating company name.'), 'ERROR');
                })
        );
    }

    addNewEmail(id, email) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/emails?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            AccountId: id,
            email: email
        };
        return (
            fetch(url, {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.addEmail', 'An error ocurred while adding new email.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.addEmail', 'An error ocurred while adding new email.'), 'ERROR');
                })
        );
    }

    deleteEmail(id, email) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/emails?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            AccountId: id,
            email: email
        };
        return (
            fetch(url, {
                method: 'delete',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.deleteEmail', 'An error ocurred while deleting email.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.deleteEmail', 'An error ocurred while deleting email.'), 'ERROR');
                })
        );
    }

    setEmailAsDefault(id, email) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/emails?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            AccountId: id,
            email: email
        };
        return (
            fetch(url, {
                method: 'put',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.setEmailAsDefault', 'An error ocurred while setting default email.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.setEmailAsDefault', 'An error ocurred while setting default email.'), 'ERROR');
                })
        );
    }

    getNextMarketRegisterNowButton() {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/next/market?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url)
                .then(function (response) {
                    if (response.ok)
                        return response!=null?response.json().catch(err => {
                            console.error(`'${err}' happened while getting next market`);
                            return null;
                          }):null;
                    
                    throw new Error(getDictionaryValue('error.getNextMarketRegister', 'An error ocurred while getting next markets.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.getNextMarketRegister', 'An error ocurred while getting next markets.'), 'ERROR');
                })
        );
    }

    getFutureMarketLinkRegistrationButton() {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/future/markets?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url)
                .then(function (response) {
                    if (response != null && response.ok)
                        return response.json();

                    throw new Error(getDictionaryValue('error.getFutureMarketLink', 'An error ocurred while getting future markets.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.getFutureMarketLink', 'An error ocurred while getting future markets.'), 'ERROR');
                })
        );
    }

    linkMyMarketEmail(email, marketId) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/link-my-market-email?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            email: email,
            marketId: marketId
        };
        return (
            fetch(url, {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.linkMyMarketPlannet', 'An error ocurred while setting my market email.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.linkMyMarketPlannet', 'An error ocurred while setting my market email.'), 'ERROR');
                })
        );
    }

    associateExhibitor(exhibitorTitle) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/associate-exhibitor?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            exhibitorTitle: exhibitorTitle,
        };
        return (
            fetch(url, {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.associateExhibitor', 'An error ocurred while associating an exhibitor.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.associateExhibitor', 'An error ocurred while associating an exhibitor.'), 'ERROR');
                })
        );
    }

    updateName(accountId, firstName, lastName) {
        const url = `${getSitecoreApiHost()}/imc-api/profile/v1/user/name?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            accountId: accountId,
            firstName: firstName,
            lastName: lastName
        };
        return (
            fetch(url, {
                method: 'put',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.updateName', 'An error ocurred while update user name.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.updateName', 'An error ocurred while update user name.'), 'ERROR');
                })
        );
    }

    deleteAccount(AzureId, EmailAddress, Name) {
        const url = `${getSitecoreApiHost()}/imc-api/market-planner/v1/marketplanneraccount/delete?sc_apikey=${getSitecoreApiKey()}`;
        var data = {
            AzureId,
            EmailAddress,
            Name,
        };
        return (
            fetch(url, {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    if (response != null && response.ok)
                        return response;

                    throw new Error(getDictionaryValue('error.deleteEmail', 'An error ocurred while deleting email.'));
                })
                .catch((err) => {
                    ErrorUtility.logError(err, getDictionaryValue('error.deleteEmail', 'An error ocurred while deleting email.'), 'ERROR');
                })
        );
    }
}
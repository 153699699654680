import {
    SET_ERROR_MESSAGE,
    CLEAR_ERROR_MESSAGE,
} from '../actions/actionTypes';
const initialState = {
    messages: []
};

/**
 * Reducer for error message
 */
export default function errorMessageReducer(state = initialState, action) {
    
    switch (action.type) {
        
        case SET_ERROR_MESSAGE:
            return Object.assign({}, state, {
                messages: [...state.messages, {text:action.message, errorLevel:action.errorLevel}]
            });
        case CLEAR_ERROR_MESSAGE:
            return Object.assign({}, state, {
                messages: [],
            });
        default:
            return state;
    }
}
import {
    SET_MARKET_PLANNER_MY_INFO,
    SET_MARKET_PLANNER_MY_INFO_ERR,
    SHOW_LOADING,
} from '../actions/actionTypes';
const initialState = {account: {
    accountId: "9090acd1-f5fd-445e-931f-32ff79e93b67",
    userType: "DigitalIdentity",
    name: "",
    organizationName: "",
    attendeeType: "Buyer",
    interiorDesigner: "no",
    primaryProductCategory: null,
    productInterests: [
    ],
    organizationType: "Commercial Real Estate",
    secondaryProductCategories: null,
    //OldValues
    primaryCategoryOfInterest: 'empty',
    interests: {
    'furniture': [],
    'gifts': [],
    'homeDecor': [],
    },
}};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function marketPlannerMyInfoReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_MARKET_PLANNER_MY_INFO:
            return Object.assign({}, state, {
                account: action.account
            });
        case SET_MARKET_PLANNER_MY_INFO_ERR:
            return Object.assign({}, state, {
                account: initialState,
                errCode: action.status || 404,
            });
        default:
            return state;
    }
}
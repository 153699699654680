import {
    GET_FLOOR_PLAN,    
    SHOW_LOADING,
} from '../actions/actionTypes';

const initialState = {
    data: undefined,
    showLoading: true,
};

export default function floorPlanReducer(state = initialState, action) {
    switch (action.type) {
        case GET_FLOOR_PLAN:
            return Object.assign({}, state, {
                data: action.data
            });

        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
            
        default:
            return state;
    }
}
/**
 * Initial state for the store
 * @type {{}}
 */
export const InitialState = {
    typeaheadsearch: {
        total_results: 0,
        options: [],
        query: '',
    },
};

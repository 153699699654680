import React, { useState, useEffect, useRef } from 'react';
import {  Image, Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Navigation from './components/Navigation.jsx'
import Link from '../Link/index.js';
import { getTopMatter } from "utils/scrollhelpers";
import TypeAheadSearch from '../Type-Ahead-Search';

interface HeaderNavigationComponentProps {
  fields?: any;
  rendering?: any;
  handleSearch?: boolean;
  hideMenu?: boolean;
  searchProps?: any;
  searchPlaceholders?: any;
}

const HeaderNavigationComponent: React.FC<HeaderNavigationComponentProps> = (props) => {

  const {fields, rendering, handleSearch, hideMenu, searchProps, searchPlaceholders} = props;
  const [ssr, setSSR] = useState<boolean>(true);
  const [scrollingDown, scrollDown] = useState<boolean>(false);
  const [isScrollingUp, setIsScrollingUp] = useState<boolean>(false);
  const [isScrollingUpToTop, setIsScrollingUpToTop] = useState<boolean>(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [navContainer, setNavContainer] = useState<number>(0);
  const searchRef = React.createRef();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const navRef = useRef<HTMLInputElement>(null);
  const placeholderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSSR(false);
    setNavContainer(window?.innerHeight ? window.innerHeight : 0);
  }, [ssr]);

  useEffect(() => {

    const handleScroll = () => {
      const {campusHeight, headerHeight, navHeight, currentScrollTop, minimumScrollUp, minimumScrollDown, stickyHeaderMinScroll,  stickyNavMinScroll, stickyNavTop} = getTopMatter();
      const headerPosition = document.getElementsByClassName('imc-header__container-wrapper ')[0].getBoundingClientRect().top;
      const velocity = lastScrollTop - currentScrollTop;
      let scrollingUp = (!isScrollingUp && velocity > minimumScrollUp) || (isScrollingUp && velocity >= minimumScrollDown * -1);

      if (!stickyHeaderMinScroll) {
        scrollingUp = false;
      }

      if ((stickyNavMinScroll || scrollingUp) && window.scrollY != 0) {
        scrollDown(true);
        if (placeholderRef.current) placeholderRef.current.style.height = `${navHeight}px`;
      } else {
        scrollDown(false);
        if (placeholderRef.current) placeholderRef.current.style.height = ``;
      }

      if (navRef.current && (stickyNavMinScroll || scrollingUp)) {
        navRef.current.style.top = `${stickyNavTop}px`;
      } else {
        if (navRef.current) navRef.current.style.top = ``;
      }

      setIsScrollingUp(scrollingUp);
      setLastScrollTop(currentScrollTop);

    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [navRef, placeholderRef, isScrollingUp, lastScrollTop]);

  function closeSearch() {
    if (searchRef.current) {
    }
  }
  
  function renderTier2(navigation2st: any) {
    return navigation2st.map((item: any, key: number) =>
      <div className="imc-navigation-2tier" key={key}>
        <Link className="imc-navigation-2tier-link" field={item.link.data} />
        <Link className="imc-navigation-2tier-imagelink" field={item.link.data} >
          <Image className="imc-navigation-2tier-image" field={item.image.data} />
        </Link>
        <Text className="imc-navigation-2tier-description" field={item.description.data}></Text>
      </div>
    );
  }

  function renderNavigatonColumns(navigationColumn: any) {
    return navigationColumn.map((item: any, key: number) =>
      <div className="imc-navigation-col" key={key}>
        {renderTier2(item.navigation2st)}


      </div>
    );
  }
  function renderTier1() {
    return fields.data.model.navigation1st.map((item: any, key: number) =>
      <div className="imc-navigation__tier1Item imc-navigation__tier1Item--fullwidth" key={key}>
        <div className="imc-navigation__tier1Item-inner">
          <a href="/" className="imc-navigation__tier1Link" target="_self" title={item.title.data.value}>
            <Text field={item.title.data} />
          </a>
          <div className="imc-navigation__menu imc-content imc-navigation__menu--fullwidth imc-navigation-col-wrapper">
            {renderNavigatonColumns(item.navigationColumn)}
          </div>
        </div>
      </div>
    );
  }

  if (typeof fields === 'undefined' || (!!fields.data && fields.data.model === null )) {
    if (isEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    }
    return (
      <h1 className="alarm">
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }

  const searchFields = {
    ...searchProps,
    "showMultiSelect": { "value": false },
    "showSort": { "value": false },
    "showShare": { "value": false },
    "disableTypeahead": { "value": false },
    "showClearSearch": { "value": false },
    "maxLength": { "value": "" },
    "hideSubmitButton": { "value": false },
    "minLengthForSearch": { "value": "3" },
    "showSavedSearches": { "value": false },
    "extraClass": { "value": "imc-searchform--global" },
    "resultsRedirectUrl": { "value": "/search/exhibitors" },
    "showMessage": { "value": false },
    "hideSortResultBy": { "value": false },
    "searchType": [],
    "sortResultsBy": [],
    "hideFilterBy": { "value": false },
    "hideSearchType": { "value": false },
  }

  return (
    <>
      <div ref={navRef} className={`imc-header__nav imc-inline-divide imc-inline-divide--noline${(handleSearch) ? '' : ' imc-breakpoint-display--hide-tablet'}${scrollingDown ? ' nav-scroll' : ''}${isScrollingUp ? ' nav-scroll-up' : ''}${isScrollingUpToTop ? ' nav-origin' : ''}${ssr ? ' preload' : ''}`}>
        <nav className={`imc-navigation-container${(hideMenu) ? ` no-padding` : ``}`}>
          {(!hideMenu) && <Navigation
            items={fields.data.model.navigation1st}
          ></Navigation>}
          {(handleSearch) && <div className={`imc-breakpoint-display--hide-medium-desktop`}>
              <div className={`imc-section--padded-left-large imc-section--padded-right-large`}>
                  <TypeAheadSearch
                      btnClickRequired="false"
                      navContainer={navContainer}
                      mobileSearchButton={true}
                      fields={searchFields}
                      onAfterSubmit={closeSearch}
                      extraFormClass="imc-searchform--mobile imc-searchform--drawer imc-searchform--drawer-mobile"
                      searchRef={searchRef}
                      inputSearchRef={inputSearchRef}
                      showClearSearch={{
                          value: true,
                      }}
                      forceShowClearSearch={true}
                      extraContainerClass={`suggestions-container--mobile`}
                      extraClass={`imc-searchform--mobile`}
                      closeIcon={`juniper-close-v2`}
                      closeIconSize={20}
                      clearText={`Clear`}
                      hideClearOnEmpty={true}
                      lockPage={true}
                      isDrawer={true}
                      alwaysPad={true}
                      makeSuggestionsScrollable={true}
                      placeholders={searchPlaceholders}
                      exitText={`Cancel`}
                  />
              </div>
          </div>}
        </nav>
      </div>
      <div ref={placeholderRef}></div>
    </>
  );

}
export default HeaderNavigationComponent;

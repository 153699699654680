import * as types from '../actions/actionTypes';

/**
 * Root reducer for an AppState
 * @param {object} state Initial state
 * @param {object} action Action object
 * @returns {*} State object
 */
export default function rootReducer(state = {}, action) {
    const initAction = Object.assign({}, action);
    delete initAction.type;

    switch (action.type) {
    case types.INIT_STATE:
        return Object.assign({}, state, initAction);

    default:
        return state;
    }
}

/**
 * Returns string of final search query with replacement of [], | etc.
 * @param {string} str String to check
 * @returns {string} String returned
 */
export function filterquerymodifier(str) {
    let strFinal = str.replace(/%5B/g, '');
    strFinal = strFinal.replace(/%5D/g, '');
    strFinal = strFinal.replace(/%22%2C%22/g, '%7C');
    strFinal = strFinal.replace(/%22/g, '');
    strFinal = strFinal.replace(/%E2%80%99/g, '%27');

    return strFinal;
}

export function tagsquerymodifier(str){
    let tagsStr = str.substr(str.indexOf("tags=")).split("&")[0];
    let strFinal = str.replace( tagsStr, tagsStr.replace("|","&tags="));
    return strFinal;
}

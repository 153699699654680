import { getSitecoreApiHost, getSitecoreApiKey}  from '../AppGlobals';

/**
 * FloorPlan API
 */
export default class FloorPlanAPI {

    getFloorPlan(datasourceId, buildingName, floor, channel) {
        const url = `${getSitecoreApiHost()}/imc-api/v1/floor-plan/datasource/${datasourceId}/building/${buildingName}/floor/${floor}?sc_apikey=${getSitecoreApiKey()}`;
        return (
            fetch(url, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'channel': channel,
                }})
            .then(response => response.json())
            .catch((err) => {
                // todo: Error Handling
                console.error('handle err here', err);
            })
        );
    }
}
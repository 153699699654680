import { SHOW_LOADING, CLEAR_SEARCH } from '../../searchresults/actions/actionTypes';
import {
    SET_EXHIBITORS,
    SET_EXHIBITOR_SEARCH_ERR,
    SET_EXHIBITOR_COUNT } from './actionTypes';
import ExhibitorsAPI from '../../../api/exhibitor';

const _searchExhibitors = new ExhibitorsAPI();

/**
 * Action to get exhibitors related to privided search term
 * @param {string} query Query to run
 * @param {object} filters Object of selected filters
 * @param {string} pagenum of page numbers
 * @param {string} type of search
 * @returns {Function} Promise object with fetch to the SearchExhibitors
 */
export function fetchExhibitors(query, filters = {}, pagenum, type) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });
        return _searchExhibitors.getExhibitors(query, filters, pagenum, type).then((response) => {
            const { status, exhibitors, count } = response || {};
            if (status === 200) {
                dispatch({
                    type: SET_EXHIBITORS,
                    exhibitors,
                    count,
                });
                dispatch({
                    type: SET_EXHIBITOR_COUNT,
                    count,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_EXHIBITOR_SEARCH_ERR,
                    err: status,
                });
                dispatch({
                    type: SET_EXHIBITOR_COUNT,
                    count,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}

import {
    SET_MARKET_PLANNER_CHANNEL_SELECTOR,
} from '../actions/actionTypes';
const initialState = {
    channels: []
};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function marketPlannerChannelSelectorReducer(state = initialState, action) {
    switch (action.type) {
        case SET_MARKET_PLANNER_CHANNEL_SELECTOR:
            return Object.assign({}, state, {
                channels: action.channels.channels,
            });
        default:
            return state;
    }
}
import React from 'react';
import { isEditorActive, Image, Link } from '@sitecore-jss/sitecore-jss-react';

const FooterFlags = (props) => {
  if (typeof props.fields === 'undefined' || props.fields?.data == null || props.fields?.data?.model === null) {
    if (isEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    }
    return (
      <h1 className="alarm">
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }
return(
  <div>
    <div className="imc-content imc-content--invert imc-vr--large imc-content--center-mobile imc-footer__languages imc-hide-print" data-xpath="footer.languages">
      {props.fields.data.model.label && props.fields.data.model.label.data.value}
    </div>
    <div className="imc-footer-flags imc-hide-print">
      {props.fields.data.model.flags && props.fields.data.model.flags.map((flagItem, index) => (
        <div key={index} className="imc-footer-flags__item">
          <Link field={flagItem.languageLink.data.value}>
            <Image className="text-class-remove" media={flagItem.flag.data.value} />
          </Link>
        </div>
      ))}
    </div>
  </div>
  );
}
export default FooterFlags;

import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';

export default class StickyBannerComponent extends React.Component {



    /**
     * @method constructor
     * @description Constructs the StickyHeader component and sets the current selected tier to -1 (none)
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            height: 0,
            smallHeader: false,
        };
        this.measureHeight = this.measureHeight.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    /**
     * Renders the mobile navigation upon mounting
     */
    componentDidMount() {
        setTimeout(() => {
            this.measureHeight();
        }, 500);
        window.addEventListener('resize', this.measureHeight);
        window.addEventListener('scroll', this.handleScroll);
    }

    /**
     * Measures the height of the sticky header contents
     */
    measureHeight() {
        if (this.stickyheader)
            this.setState({
                height: this.stickyheader.clientHeight,
            });
    }
    /**
     * Measures the height of the sticky header contents
     */
    handleScroll() {
        if (window && window.pageYOffset > this.stickyheader.clientHeight) {
            this.setState({
                smallHeader: true,
            });
        } else {
            this.setState({
                smallHeader: false,
            });
        }
        this.measureHeight();
    }

    /**
     * @method render
     * @description Renders the StickyHeader DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { height, smallHeader } = this.state;
        const stickyClass = (smallHeader) ? 'imc-shrinkheader' : '';
        const { fields, rendering } = this.props;

        if (!fields) {
            if (isEditorActive())  // Experience editor view
                return <h1 className="alarm">Datasource isn't set.{JSON.stringify(this.props)}</h1>;
            else
                return null;
        }
        return (
            <div
                className="imc-stacked"
                style={{
                    height,
                }}
            >
                <div
                    ref={(node) => { this.stickyheader = node; }}
                    className={`imc-stickyheader imc-stickyheader--fixed ${stickyClass}`}
                    style={{
                        backgroundColor: fields.backgroundColor.value,
                    }}
                >
                    <div className="imc-content imc-content--invert imc-content--center">
                        <div className="imc-inline-divide imc-inline-divide--noline-desktop">
                            <div className="imc-inline-divide__item imc-sticky__divide imc-inline-divide__item--invert" style={{ borderColor: fields.backgroundColor.value }}>
                                <span className="imc-heading imc-heading--quaternary imc-heading--zeta-kilo imc-sticky__font imc-heading--invert" style={{ color: fields.fontColor.value }} >{fields.notificationText.value}</span>
                                &nbsp;
                              <span className="imc-content imc-heading--zeta-kilo imc-content--invert imc-sticky__font" style={{ color: fields.fontColor.value }}><em>{fields.marketDate.value}</em></span>
                            </div>
                            <div className="imc-inline-divide__item imc-sticky__divide imc-inline-divide--caret">
                                <Placeholder name='imc-cta-placeholder' rendering={rendering} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


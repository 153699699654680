export const DATA = {
    firstName: "Fran Jizhi",
    lastName: "Liu",
    emails: [
        "info@viva-rosa.com"
    ],
    demandDriver: {
        demandDriverName: "Viva la Rosa",
        registrations: [
            {
                name: "LVMJan20"
            },
            {
                name: "LVMJan18"
            }
        ]
    },
    revenueGenerator: {
        exhibitorName: "Doug's Chairs",
        role: "Market Manager"
    },
    digitalIdentity: null,
    //Not in official documentation.
    attendeeType: "buyer",
    companyName: "decorativeaccessorystore",
    productInterests: [
        {
            key : "gifts",
            Value : "Gifts"
        },
        {
            key : "apparel",
            Value : "Apparel"
        },
        {
            key : "home",
            Value : "Home"
        },
        {
            key : "seasonal",
            Value : "Seasonal"
        }]
}
// Library dependencies
import { Component, Children } from 'react';
import PropTypes from 'prop-types';

/**
 * Component for providing the `reducerRegistry` to its children through context
 *
 * Note: to access the the `reducerRegistry` within a child component, the contextType
 * will need to be defined within the child's properties.
 *
 * eg:
 *
 * ChildComponent.contextTypes = {
 *      reducerRegistry: PropTypes.object
 * };
 *
 */
class ReducerProvider extends Component {
    /**
     * Constructor
     * @param {object} props Incoming props
     * @param {object} context Context object
     */
    constructor(props, context) {
        super(props, context);

        this.reducerRegistry = props.reducerRegistry;
    }

    /**
     * @description Provides the `reducerRegistry` to the childContext
     * @returns {{reducerRegistry: *}} reducerRegistry attribute
     */
    getChildContext() {
        return {
            reducerRegistry: this.reducerRegistry,
        };
    }

    /**
     * @description Renders children nodes of the component
     * @returns {XML} Child nodes
     */
    render() {
        return Children.only(this.props.children);
    }
}

/**
 * @property
 * @description Defined incoming property types for component
 * @type {{reducerRegistry: *, children: *}}
 */
ReducerProvider.propTypes = {
    reducerRegistry: PropTypes.object.isRequired,
    children: PropTypes.element.isRequired,
};

/**
 * @property
 * @description Defined childContext types for component
 * @type {{reducerRegistry: *}}
 */
ReducerProvider.childContextTypes = {
    reducerRegistry: PropTypes.object.isRequired,
};

/**
 * Exports react component
 */
export default ReducerProvider;

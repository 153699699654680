/**
 * given a string with :params
 * replace params with provided paramObject values
 * @param {string} baseString string to be cleaned
 * @param {object} paramObject object with replacement values
 * @returns {string} cleaned string
 */
export function replaceparams(baseString = '', paramObject = {}) {
    let updatedString = baseString;
    // eslint-disable-next-line no-unused-expressions
    paramObject ? Object.keys(paramObject).forEach((paramKey) => {
        const value = paramObject[paramKey];
        updatedString = updatedString.replace(`:${paramKey}`, value);
    }) : null;

    // remove optional params indicators from baseString
    while (updatedString.indexOf('?') !== -1) {
        updatedString = updatedString.replace('?', '');
    }
    return updatedString;
}

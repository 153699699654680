import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';
import { createAppState } from 'modules/redux-app-state';
import { Loading } from 'modules/loading';
import DataGrid from '../DataGrid/DataGrid';
// Module dependencies
import Dropdown from 'modules/dropdown/Dropdown.jsx';
import { Image } from 'modules/image/index';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getDictionaryValue } from 'utils/dictionary';
// action imports
import * as dataActions from './actions/dataActions';
//reducers
import allSpecialsReducer from './reducers/allSpecialsReducer';
//Utils
import { getFloorPlanURL, getBooth } from 'utils/floorplan';
import Link from "../Link";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import ImcDataLayer from 'utils/datalayer';

import { ReactComponent as LocationIcon } from '../../assets/icons/las-vegas-market/Location.svg';

const propTypes = {
  specials: PropTypes.array.isRequired,
  dataActions: PropTypes.object,
  sortOrder: PropTypes.string,
};

const defaultProps = {
  dataActions: {
    fetchAllSpecials: () => { },
  },
  sortOrder: '',
  specials: []
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  page: { flexDirection: "column", padding: 25 },
  table: {
    fontSize: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch"
  },
  rowColnames: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    width: "100%"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    width: "100%",
    height: "34px"
  },
  col1: {
    fontSize: 10,
    textAlign: 'left',
    fontFamily: 'Oswald',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100px",
    alignSelf: "stretch",
    paddingLeft: 2,
    minWidth: "32%",
    maxWidth: "32%"
  },
  col2: {
    fontSize: 10,
    textAlign: 'left',
    fontFamily: 'Oswald',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100px",
    paddingLeft: 2,
    alignSelf: "stretch",
    minWidth: "13%",
    maxWidth: "13%"
  },
  col3: {
    fontSize: 10,
    textAlign: 'left',
    fontFamily: 'Oswald',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "400px",
    alignSelf: "stretch",
    paddingLeft: 2,
    minWidth: "55%",
    maxWidth: "55%"
  },
  col1st: {
    fontSize: 12,
    textAlign: 'left',
    fontFamily: 'Oswald',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100px",
    alignSelf: "stretch",
    paddingLeft: 2,
    minWidth: "32%",
    maxWidth: "32%"
  },
  col2st: {
    fontSize: 12,
    textAlign: 'left',
    fontFamily: 'Oswald',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100px",
    paddingLeft: 2,
    alignSelf: "stretch",
    minWidth: "13%",
    maxWidth: "13%"
  },
  col3st: {
    fontSize: 12,
    textAlign: 'left',
    fontFamily: 'Oswald',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "400px",
    alignSelf: "stretch",
    paddingLeft: 2,
    minWidth: "55%",
    maxWidth: "55%"
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Oswald',
    paddingBottom: 15
  },

  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Oswald'
  },

  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    fontFamily: 'Oswald',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
});

//appState
const SpecialsState = createAppState();
/**
 * TabMyLists component
 * @constructor
 */
class ShowSpecialsComponent extends Component {
  /**
   * @method constructor
   * @param {object} props Incoming props
   */
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      carouselKey: 0,
      pageNum: 1,
      pageSize: 50,
      showLoading: true,
      multiSelectMode: false,
      count: 0,
      pdfRendered: false,
      pdfDoc: null,
      rowData: null,
      isMobile: true,
      columnDefs: [
        {
          headerName: "Exhibitor Name",
          field: "exhibitor",
          cellRenderer: params => this.createExhibitorNameText(params.value),
          sortable: true,
          comparator: (a, b) => {
            let itemA = a.lineName ? a.lineName : a.exhibitorName;
            let itemB = b.lineName ? b.lineName : b.exhibitorName;
            return (itemB > itemA) ? 1 : -1;
          },
          flex: 2
        },
        {
          headerName: "Location",
          field: "location",
          cellRenderer: params => this.createLocationLink(params.value),
          flex: 1
        },
        { headerName: "Show Specials Details", field: "details", flex: 3 },
        {
          headerName: "",
          field: "viewDetails",
          cellRenderer: params => this.createBrandDetailsLink(params.value),
          flex: 1
        },
      ],
    }
    SpecialsState.reducerRegistry.register({ allSpecialsReducer });
    this.createShowSpecials = this.createShowSpecials.bind(this);
    this.createBrandDetailsLink = this.createBrandDetailsLink.bind(this);
    this.createLocationLink = this.createLocationLink.bind(this);
    this.createExhibitorNameText = this.createExhibitorNameText.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.setMobileState = this.setMobileState.bind(this);
    this.renderCardView = this.renderCardView.bind(this);
    this.sortExhibitorsByName = this.sortExhibitorsByName.bind(this);

    Font.register({
      family: 'Oswald',
      src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.setMobileState);

    this.props.dataActions.fetchSpecials(this.state.pageNum, this.state.pageSize, this.props.sitecoreContext.route.itemId);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setMobileState()

    if (prevProps.specials !== this.props.specials) {
      this.createShowSpecials()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMobileState)
  }

  setMobileState() {
    const { isMobile } = this.state;
    const mobileWidth = 480;

    if (!isMobile && window.innerWidth <= mobileWidth) {
      this.setState({ isMobile: true })
    } else if (isMobile && window.innerWidth > mobileWidth) {
      this.setState({ isMobile: false })
    }
  }

  createShowSpecials() {
    const { specials, count } = this.props;
    const specialsKeys = Object.keys(specials)
    let showSpecials = [];
    if (specialsKeys.length > 0) {
        specialsKeys.sort().map((index) => {
            showSpecials = showSpecials.concat(specials[index]?.specials);
        });
        const rowData = showSpecials.map((special) => {
        return {
            exhibitor: special,
            location: special,
            details: special.description,
            viewDetails: special,
        }
        })
        this.setState({ showSpecials, rowData })
    } else if (count == 0) {
        let rowData = [];
        this.setState({ showSpecials, rowData });
    }
  }

  createBrandDetailsLink(currentSpecial) {
    let url;

    if (currentSpecial.lineName) {
      url = `/exhibitor/${currentSpecial?.exhibitorId}/line/${currentSpecial?.lineId}`
    } else {
      url = `/exhibitor/${currentSpecial?.exhibitorId}`
    }

    return <Link href={url} className="imc-showSpecial--link">View Brand Details&nbsp;&rarr;</Link>
  }

  createLocationLink(currentSpecial) {
    const { exhibitors } = this.props;
    const exhibitor = exhibitors[currentSpecial.exhibitorId];
    var leaseForChannel = exhibitor.activeLeases.find(x => x.channel.siteCode == window.channel.name);

    if (leaseForChannel != null && leaseForChannel != 'undefined') {
      return (exhibitor.activeLeases.find(x => x.channel.siteCode == window.channel.name).showrooms.map(location => {
        return (
          <Link
            onClick={() => this.gtmClickSpecial(currentSpecial)}
            className={"imc-link imc-showSpecial--locationLink"}
            href={getFloorPlanURL(location.showroomBuildingName, location.showroomFloor, location.showroom)}
          >
            <LocationIcon className='imc-showSpecial--locationIcon' />
                {getBooth(location.showroomBuildingName, location.showroomFloor, location.showroom)}
          </Link>)
      }))
    }
    return (
        <></>
    )
  }

  createExhibitorNameText(currentSpecial) {
    const { isMobile } = this.state;

    if (isMobile) {
      if (currentSpecial.lineName) {
        return (
          <>
            <p>{currentSpecial.lineName}</p>
            <p>{getDictionaryValue('shownBy', 'Shown By')} {currentSpecial.exhibitorName}</p>
          </>
        )
      }
      return <p>{currentSpecial?.exhibitorName}</p>
    } else {
      if (currentSpecial.lineName) {
        return (
          <div style={{ display: "flex" }}>
            <Image
              src={currentSpecial?.exhibitorLogo}
              className='imc-showSpecial--card-image'
              imageWidth={60}
              imageHeight={60}
              title={currentSpecial.lineName}
              alt={currentSpecial.lineName}
            />
            <div className='imc-showSpecial--tableContainer'>
              <p>{currentSpecial.lineName}</p>
              <p>{getDictionaryValue('shownBy', 'Shown By')} {currentSpecial.exhibitorName}</p>
            </div>
          </div>
        )
      }
      return (
        <div style={{ display: "flex" }}>
          <Image
            src={currentSpecial?.exhibitorLogo}
            className='imc-showSpecial--card-image'
            imageWidth={60}
            imageHeight={60}
            title={currentSpecial.exhibitorName}
            alt={currentSpecial.exhibitorName}
          />
          <div className='imc-showSpecial--tableContainer'>
            <p>{getDictionaryValue('shownBy', 'Shown By')} {currentSpecial.exhibitorName}</p>
          </div>
        </div>
      )
    }
  }

  renderHeader() {
    const { isMobile, showSpecials } = this.state;

    if (isMobile) {
      return (
        <div className='imc-showSpecial--mobileHeader'>
        <span className='imc-showSpecial--header--title imc-type--title-1'>
          Show Specials
        </span><br />
          {showSpecials && showSpecials.length > 0 && this.renderPDF()}
          <Dropdown
            className='imc-showSpecial--mobileDropdown'
            width={'100%'}
            options={[
              { value: "asc", label: "Exhibitor Name A-Z" },
              { value: "des", label: "Exhibitor Name Z-A" }
            ]}
            onSelect={(params) => this.sortExhibitorsByName(params)}
          />
          <p className='imc-showSpecial--mobileResults'>
            {showSpecials?.length ? showSpecials?.length : 0} Results shown
          </p>
        </div>
      )
    } else {
      return (
        <div className='imc-showSpecial--header'>
          <span className='imc-showSpecial--header--title imc-type--title-1'>
            Show Specials
          </span>
          <div className='imc-showSpecial--header--buttonRow'>
            <p>{showSpecials?.length ? showSpecials?.length : 0} Results shown</p>
            {showSpecials && showSpecials.length > 0 && this.renderPDF()}
          </div>
        </div>
      )
    }
  }

  sortExhibitorsByName(type) {
    const { showSpecials } = this.state;
    const sortedShowSpecials = [...showSpecials];

    sortedShowSpecials.sort((a, b) => {
      let itemA = a.lineName ? a.lineName : a.exhibitorName;
      let itemB = b.lineName ? b.lineName : b.exhibitorName;

      if (type?.value === "asc") {
        if (itemA < itemB) { return -1 }
        if (itemA > itemB) { return 1 }
        return 0;
      } else {
        if (itemA > itemB) { return -1 }
        if (itemA < itemB) { return 1 }
        return 0;
      }
    })
    this.setState({ showSpecials: sortedShowSpecials })
  }

  renderCardView() {
    const { showSpecials } = this.state;
    if (showSpecials && showSpecials.length > 0) {
      return showSpecials.map((special) => {
        return (
          <div key={special.showSpecialsId} className='imc-showSpecial--card'>
            {/* Image title and location */}
            <div className='imc-showSpecial--card-imageRow'>
              <Image
                src={special?.exhibitorLogo}
                className='imc-showSpecial--card-image'
                imageWidth={77}
                imageHeight={'auto'}
                title={special.exhibitorName}
                alt={special.exhibitorName}
              />
              <div className='imc-showSpecial--card-textRow'>
                <strong>{this.createExhibitorNameText(special)}</strong>
                {this.createLocationLink(special)}
              </div>
            </div>
            {/* Details and brand details link */}
            <div className='imc-showSpecial--card-brandDetailsRow'>
              <p className='imc-showSpecial--card-description'>
                <strong>Show Special Details<br /></strong> {special.description}
              </p>
              {this.createBrandDetailsLink(special)}
            </div>
          </div>
        )
      })
    }
  }

  gtmClickSpecial(special) {
    if (!special)
      return;

    ImcDataLayer.PushPromotionClick(special);
  }

  createPDFSpecialElements(special) {
    return (
      <View style={styles.row} wrap={false} >
        <Text style={styles.col1} wrap={false}>
          {special.lineName &&
            `${special.lineName} ${getDictionaryValue('shownBy', 'Shown By')} ${special.exhibitorName}`
          }
          {!special.lineName &&
            special.exhibitorName
          }
        </Text>
        <Text style={styles.col2} wrap={false}>
          {this.renderPDFShowrooms(special)}
        </Text>
        <Text style={styles.col3}  >
          {special.description}
        </Text>
      </View>
    );
  }

  renderPDFShowrooms(special) {
    const { exhibitors } = this.props;
    const exhibitor = exhibitors[special.exhibitorId];
    var leaseForChannel = exhibitor.activeLeases.find(x => x.channel.siteCode == window.channel.name);

    if (leaseForChannel != null && leaseForChannel != 'undefined') {
      return (exhibitor.activeLeases.find(x => x.channel.siteCode == window.channel.name).showrooms.map(location => {
        return (<>{getBooth(location.showroomBuildingName, location.showroomFloor, location.showroom)}</>)
      }))
    }
  }

  renderPDFSpecials() {
    const { showSpecials } = this.state;
    return showSpecials.map((special => {
      return this.createPDFSpecialElements(special)
    }))
  }

  renderPDFMarket() {
    return (
      <>
        <Text wrap={false} style={styles.title}>
          {this.state.showSpecials[0]?.marketName}
          {getDictionaryValue("specialsTab.specials", "Specials")}
        </Text>
        <View style={styles.table}>
          <View style={styles.rowColnames}>
            <Text style={styles.col1st}>
              {getDictionaryValue("specialsTab.exhibitorName", "Exhibitor Name")}
            </Text>
            <Text wrap={false} style={styles.col2st}>
              {getDictionaryValue("specialsTab.showroom", "Showroom")}
            </Text>
            <Text wrap={false} style={styles.col3st}>
              {getDictionaryValue("specialsTab.listDescription", "Show Special Details")}
            </Text>
          </View>
          {this.renderPDFSpecials()}
          <Text style={styles.title} />
        </View>
      </>)
  }

  renderPDF() {
    return (
      <PDFDownloadLink
        className="imc-button imc-showSpecial--mobileButton imc-button--small custom-identifier-pdf"
        document={this.getDoc()}
        fileName="specials.pdf"
      >
        {getDictionaryValue('specialsTab.downloadPdf', 'Download PDF version')}
      </PDFDownloadLink>);
  }

  getDoc() {
    const { showSpecials } = this.state;
    const pdfDocument = (
      <Document wrap={false} >
        <Page style={styles.page} orientation="landscape" wrap={false}>
          {this.renderPDFMarket(showSpecials)}
          <Text style={styles.title} fixed  ></Text>
          <Text style={styles.title} fixed  ></Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed wrap={false} />
        </Page>
      </Document>
    );

    return pdfDocument;
  }

  /**
   * @method render
   * @description Renders the DOM element
   * @returns {*} Rendered component
   */
  render() {
    const { columnDefs, isMobile, rowData } = this.state;
    return (
      <div className="imc-showSpecial--section">
        <Loading showLoading={this.props.showLoading} showPageLoading={false}></Loading>
        {this.renderHeader()}
        {isMobile && this.renderCardView()}
        {!isMobile && (
          <DataGrid
            columnDefs={columnDefs}
            rowData={rowData}
          />
        )}
      </div>
    );
  }
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
 */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

function mapStateToProps(state) {
  if (state.allSpecialsReducer)
    return {
      specials: state.allSpecialsReducer.specials,
      exhibitors: state.allSpecialsReducer.exhibitors,
      count: state.allSpecialsReducer.specialsCount,
      showLoading: state.allSpecialsReducer.isLoading,
      error: state.allSpecialsReducer.errCode,

    }
  else {
    return {
      specials: [],
      exhibitors: state.exhibitors,
      count: state.count,
      showLoading: state.showLoading,
      error: state.errCode,
    };
  }
}

ShowSpecialsComponent.propTypes = propTypes;
ShowSpecialsComponent.defaultProps = defaultProps;

// Export the react component
export default withSitecoreContext()(
  withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ShowSpecialsComponent))
);

// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {  SubmitButton } from 'modules/formelements';
import { Loading } from 'modules/loading';
import i18n from 'i18next';
import Moment from 'react-moment';

/**
 * EnquiryItemModal
 * @param {function} props dat
 * @returns {element} EditableCta
 */
class EnquiryItemModal extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the state
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            errorMessages: '',
            showError: false,
            loading: false,
            showSuccess: false,
            messageSuccess: '',
        };

        this.renderSuccess = this.renderSuccess.bind(this);
        this.closeEmailModal = this.closeEmailModal.bind(this);
        
        this.renderTopics = this.renderTopics.bind(this);
    }

    /**
     * Fetches data in case not available
     */
    componentDidMount() {

    }

    /**
     * @method closeEnquiryItemModal
     * @description close the email modal and turn state false
     */
    closeEmailModal() {
        this.setState({
            showError: false,
            showSuccess: false,
        });
        
        this.props.closePopup();
    }

    /**
     * @method renderError
     * @description this will Return Error component
     * @param {object} message error messages from response
     * @returns {*} Rendered component
     */
    renderSuccess() {
        if (this.state.showSuccess) {
            return (
                <p className="imc-content imc-content--success imc-vr--xxxlarge">
                    {this.state.messageSuccess}
                </p>
            );
        }
        return (
            <div />
        );
    }

    /**
     * @method renderError
     * @description this will Return Error component
     * @param {object} message error messages from response
     * @returns {*} Rendered component
     */
    renderError() {
        if (this.state.showError) {
            return (
                <p className="imc-content imc-content--error imc-vr--medium">
                    {this.state.errorMessages}
                </p>
            );
        }
        return (
            <div />
        );
    }

    renderTopics(topics){
        if (typeof topics == 'undefined')
            return null;
        return topics.map((item, index) => {
            return (
                <div className='imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right'>{item}</div>
            );
        })
    }

    deleteEnquiry(){
        this.props.dataActions.deleteSavedSearch(this.state.selectedEnquiryId);
        this.props.showModal = false;
        this.handleClosePopup();
    }

    

    /**
     * EmailListModal
     * @returns {element} EditableCta
     */
    render() {
        const { showError, showSuccess } = this.state;
        const { listHeading } = this.props;
        console.log("Data: "+this.props.topics);
        return (
            
            <div>
                <ReactModal
                    isOpen={this.props.showModal}
                    ariaHideApp={false}
                    overlayClassName="imc-modal--overlay"
                    className="imc-modal--box imc-modal--box-height-auto"
                > 
                    <Loading showLoading={this.state.loading} />
                    
                    <div className="imc-section imc-section--padded">
                       
                        {
                            showError && this.renderError()
                        }
                        {
                            showSuccess && this.renderSuccess()
                        }
                        
                        
                            <div>
                           
                                <div className="imc-market-planner-modal-heading">
                                   To: {this.props.exhibitorName}
                                </div>
                                <div className="imc-market-planner-modal-heading">
                                    Date: {this.props.dateSent}
                                </div>
                                <div className="imc-market-planner-modal-content">
                                    <p>{this.props.message}</p>

                                </div>
                                <div className="imc-heading imc-heading--beta imc-heading--headingh2 imc-vr--medium">
                                    Topics Selected
                                </div>
                                <div className="imc-gallery imc-gallery--1-2 imc-gallery--1-2--mobile-full-width imc-market-planner-modal-content">
                                    { this.renderTopics(this.props.topics) }
                                </div>
                            </div>
                            <div class="imc-content imc-content--right">
                                <a href="#" className="imc-button--only-text imc-button--round imc-content--margin-left-large" onClick={() => this.props.selectToDelete(this.props.organizationEnquiryId)}>Delete</a>
                                <a href="#" className="imc-button--secondary imc-button--secondary-inverted imc-button--round imc-content--margin-left-large" onClick={this.closeEmailModal}>Close</a>
                            </div>
                    </div>
                </ReactModal>
            </div>

        );
    }
}

/**
 * @property defaultProps
 * @type {{sideNavLink: string}}
 */
EnquiryItemModal.propTypes = {
    showModal: PropTypes.bool,

   
};

/**
 * @property defaultProps
 * @type {{applicationProperties: {}}}
 */
EnquiryItemModal.defaultProps = {
    showModal: true,
    
};


export default EnquiryItemModal;

